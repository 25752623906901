<div class="progress-bar-container" [class.hidden]="progressBarService.hidden" *ngIf="progressBarService.barOn">
  <ul class="steps">
    <li *ngFor="let step of progressBarService.barData.steps.slice().reverse()" [class.active]="step.active" [class.done]="step.done" [class.error]="step.error">
      <div class="bg default-bg"></div>
      <div class="bg active-bg"></div>
      <div class="bg done-bg"></div>
      <div class="bg error-bg"></div>
      <div class="process" [class.active]="step.active" *ngIf="!step.done">{{step.activeText}}</div>
      <div class="process done" *ngIf="step.done" [class.error]="!!step.error">{{step.doneText}}</div>
      <div class="icon-container">
        <div class="icon" [class.done]="step.done" [class.error]="step.error">
          <fa-icon *ngIf="step.done && !step.error" [icon]="checkIcon"></fa-icon>
          <fa-icon *ngIf="step.done && !!step.error" [icon]="errorIcon"></fa-icon>
        </div>
      </div>
    </li>
  </ul>
</div>
