<p *ngIf="fieldSettings.suggestedExtensions && !minInput" class="extension-switch">
    <label (click)="toggleExtensions()" [for]="fieldSettings.id + 'showSuggenstions'">Filter files by suggested extension: <strong>{{fieldSettings.suggestedExtensions}}</strong></label>
    <lib-toggle-switch [id]="fieldSettings.id + 'showSuggenstions'" [checked]="showSuggestedExtensions" (change)="toggleExtensions()" [labelOff]="'All'" [labelOn]="'Filtered'"></lib-toggle-switch>
</p>

<p class="input-instruction" *ngIf="fieldSettings.instruction">{{fieldSettings.instruction}}</p>

<div class="file-input-container" *ngIf="!minInput"
  [formGroup]="getFormGroupName()" [class.min-layout]="minLayout && fileSelected"
  [class.is-valid]="fieldValid === true"
  [class]="{'is-invalid': (fieldSettings.errorMessages && fieldSettings.errorMessages.length > 0) || requiredInvalid() }">

    <div class="drop-container" [class.disabled]="fieldSettings.disabled" libDnd (fileDropped)="onFileDropped($event)">
        <input
            type="file"
            [accept]="fieldSettings.allowedExtensions || (showSuggestedExtensions ? fieldSettings.suggestedExtensions : '')"
            #fileDropRef
            [id]="fieldSettings.id"
            (change)="fileBrowseHandler($event.target['files'])" />
        <fa-icon [icon]="uploadIcon"></fa-icon>
        <h4>Upload <span class="label-name" [class.required]="fieldSettings.required">{{fieldSettings.label}}</span></h4>
        <p>Drag and drop file here<br><span>or</span></p>
        <label [for]="fieldSettings.id" class="secondary">Browse</label>

        <p class="input-instruction" *ngIf="fieldSettings.allowedExtensions">Supported files: <strong>{{fieldSettings.allowedExtensions}}</strong></p>
        <p class="input-instruction" *ngIf="allowedSize">Allowed file size: <strong>{{allowedSize}}</strong></p>
    </div>
    <div class="file-selected-container">
        <div class="file-selected" [@slideInOutVertical] *ngIf="fileSelected; else noFile">

            <div class="fileIcon">
                <fa-icon [icon]="fileIcon"></fa-icon>
                <span class="extension-name">{{fileExtension}}</span>
            </div>
            <div class="file-name-container">
                <span class="file-name">{{fileName}}</span>
                <button class="clear" title="clear the file" (click)="deleteFile()"><fa-icon [icon]="removeIcon"></fa-icon></button>
            </div>

            <div class="file-size">File size: <span class="value">{{fileSize}}</span></div>
        </div>
        <ng-template #noFile>
            <div class="file-not-selected"  [@slideInOutVertical]>
                <div class="fileIcon">
                    <fa-icon [icon]="fileIcon"></fa-icon>
                    <span class="extension-name">?</span>
                </div>
                <div>
                    <span class="file-name">No File Selected</span>
                </div>
            </div>
        </ng-template>
        <div class="file-errors-container" [class.right-side-errors]="rightSideErrors" [@slideInOutVertical]>
            <span class="inline-file-error" *ngFor="let errMgs of fieldSettings.errorMessages" [@slideInOut]>{{errMgs}}</span>

        </div>
    </div>
    <div class="errors-container" [class.right-side-errors]="rightSideErrors">
        <span class="inline-field-errors" *ngIf="requiredInvalid()" [@slideInOut]>{{fieldSettings.requiredErrorMessage}}</span>
    </div>

</div>

<div *ngIf="minInput" class="min-input">
  <!-- <label for="">{{fieldSettings.label}}</label> -->
  <input
  type="file"
  [accept]="fieldSettings.allowedExtensions || (showSuggestedExtensions ? fieldSettings.suggestedExtensions : '')"
  #fileDropRef
  [id]="fieldSettings.id"
  (change)="fileBrowseHandler($event.target['files'])" />

  <div *ngIf="fieldSettings.suggestedExtensions && minInput" class="filters" dropdown  #dropdownContainer="bs-dropdown">
    <div class="filter-container">
      <fa-icon [icon]="filterIcon" class="filter-icon" [class.active]="showSuggestedExtensions" dropdownToggle></fa-icon>
      <fa-icon [icon]="checkIcon" class="check-icon" *ngIf="showSuggestedExtensions"></fa-icon>
    </div>

    <p *dropdownMenu class="filter-popup extension-switch dropdown-menu dropdown-dropup">
      <label (click)="toggleExtensions()" [for]="fieldSettings.id + 'showSuggenstions'">Filter by extension: <strong>{{fieldSettings.suggestedExtensions}}</strong></label>
      <lib-toggle-switch [id]="fieldSettings.id + 'showSuggenstions'" [checked]="showSuggestedExtensions" (change)="toggleExtensions()" [labelOff]="'All'" [labelOn]="'Filtered'"></lib-toggle-switch>
    </p>
  </div>

  <label [for]="fieldSettings.id" class="secondary">Browse</label>
  <span *ngIf="fileSelected" class="file-name">
    <span class="name-part" [title]="fileNameBase">{{fileNameBase}}</span>
    <span class="name-extension">.{{fileExtension}}</span>
  </span>
  <!-- <div class="fileIcon" *ngIf="fileSelected">
    <fa-icon [icon]="fileIcon"></fa-icon>
    <span class="extension-name">{{fileExtension}}</span>
  </div> -->

</div>
