import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';
import { ProductService } from 'projects/nine-gold-lib/src/lib/services/product.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { ConfirmDialogService, NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  userLoggedIn: boolean = false;
  userCanTrial: boolean = false;
  canQuote = true;

  constructor(
    public router: Router,
    private datePipe: DatePipe,
    private location: Location,
    private authService: AuthService,
    private userService: UserService,
    private productService: ProductService,
    private apiService: ApiService,
    private notif: NotificationService,
    private confirmService: ConfirmDialogService,
    private uiService: UserInterfaceService
  ) { }

  ngOnInit(): void {
    this.userLoggedIn = !!this.userService.currentUser;
    if(this.userLoggedIn) {
      this.userCanTrial = this.productService.apiProductCategories.find(p => p.id === 'rest_generator')?.canTrial;
      this.canQuote = this.productService.apiProductCategories.find(p => p.id === 'rest_generator').canQuote;
    }
    this.productService.productsUpdated.subscribe(user => {
      this.userLoggedIn = !!this.userService.currentUser;
      if(this.userLoggedIn) {
        this.userCanTrial = this.productService.apiProductCategories.find(p => p.id === 'rest_generator')?.canTrial;
        this.canQuote = this.productService.apiProductCategories.find(p => p.id === 'rest_generator').canQuote;
      }
    });

  }

  getQuote(licenseType?: string):void {
    if(licenseType) {
      this.router.navigate([{ outlets: { sidePopup: [ 'quote','restgen','new',licenseType ] } }]);
    } else {
      this.router.navigate([{ outlets: { sidePopup: [ 'quote','restgen','new' ] } }]);
    }
  }

  scheduleDemo():void {
    this.router.navigate([{ outlets: { sidePopup: ['demo','Platform']}}])
  }

  getTrial(): void {
    this.confirmService.confirm(this.uiService.getConfirmMessage('setTrial',['REST Generator']))
        .subscribe((answer) => {
          if(answer) {
            this.apiService.createTrialLicense('restgen',this.userService.currentUser.company.id).subscribe({
              next: data => {
                let endDate = this.datePipe.transform(data.end, 'fullDate');
                this.notif.success('Trial License Created',`You trial license just started and will end at <strong>${endDate}</strong>.`,FormResultMessageFormat.popup,12000,null,true);
              },
              error: err => {}
            });
          }
        }
      );
  }

  getTrialLink(): void {
    this.authService.getRegistrationLink(true).then(link => window.location.href = link);
  }

}
