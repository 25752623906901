<section class="accordion-container" [class.inside-group]="insideGroup">
    <header *ngIf="!hideHeader">
        <button *ngIf="!hideButton"
            class="accordionButton" (click)="toggleAccordion($event)"
            [class.accordion-open]="open" type="button">
        </button>
        <ng-container *ngTemplateOutlet="accordionHeader"></ng-container>
        <!-- header template goes here -->
    </header>
    <div class="accordion-content" [@accordionAnimation]="accordionState">
        <ng-container *ngTemplateOutlet="accordionBody"></ng-container>
        <!-- content template goes here -->
    </div>
</section>
