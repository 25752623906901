<section class="subpage-content">
    <header class="app-page-header">
        <h2><fa-icon [icon]="codeIcon"></fa-icon>REST Generator</h2>
    </header>

    <article *ngIf="formCreated && obs$  | async as obs; else loading" [@enterAnimation]>
        <lib-box-container
            id="restformData"
            [firstOnTheTop]="true">
            <div [fullScreen]="true" [libLoading]="generatorService.loading || generatorParseService.loading">


              <div class="fields-group" [class.fields-group-disabled]="loadFileAccordionState === 'closed'" id="loadFile" [libLoading]="formState==='parsing'">

                <div [class.target-review]="loadFileAccordionState === 'closed'">
                  <h4 [class.disabled-heading]="loadFileAccordionState === 'closed'">Target Application Schema</h4>
                  <p class="field-display-value" [hidden]="loadFileAccordionState === 'open'" style="margin-bottom: 0;">
                    <span class="value">
                      <span>{{targetFileName}}</span>
                      <button class="edit" (click)="clearParsedFile()">
                        <fa-icon title="Reload File" [icon]="editIcon"></fa-icon>
                      </button>
                    </span>

                    <span class="verified"><fa-icon [icon]="verifiedIcon"></fa-icon> Verified</span>
                  </p>
                </div>
                <div *ngIf="loadFileAccordionState === 'closed'" class="multiple-buttons no-bottom-margin form-group form-button-container">
                  <button class="primary smaller-button icon-with-text" [disabled]="loadingEndpoints" (click)="clearParsedFile()">
                    <fa-icon [icon]="reloadIcon"></fa-icon>
                    <span>Reload File</span>
                  </button>
                  <button [disabled]="disabledDocButton" class="primary smaller-button icon-with-text" (click)="viewDocumentation()">
                    <span class="swagger-button"><span class="swagger-icon">&#123;<span class="swagger-icon-dots">..</span>&#125;</span> View Documentation</span>
                  </button>
                </div>


                <div class="parse-file-container" [@accordionAnimation]="loadFileAccordionState">
                  <div class="file-fields-group flex-inputs" [class.has-file-name]="!!generatorParseService.formData.value['targetContract']">
                    <div class="flex-col col-no-shrink parse-file-heading">
                      <h4>Parse File</h4>
                    </div>

                    <div class="flex-col">
                      <lib-form-field
                        [inlineLayout]="false"
                        (dropdownChanged)="updateFileValidation($event, 'targetContract', true)"
                        [formGroupParameter]="generatorParseService.formData"
                        [inlineLayout]="true"
                        [noLabel]="true"
                        [noBottomMargin]="true"
                        [fieldSettings]="generatorParseService.formSettings['targetType']">
                      </lib-form-field>
                    </div>

                    <div class="flex-col col-grow">
                      <lib-file-input
                        (fileValidStatus)="setFileStatus($event, 'targetContract', true)"
                        (fileUpdated)="updateFile($event, 'targetContract', true)"
                        (setPristine)="setFilePristine('targetContract',true)"
                        [minInput]="true"
                        [formGroupParameter]="generatorParseService.formData"
                        [fieldSettings]="generatorParseService.formSettings['targetContract']"
                        [inputFile]="generatorParseService.formData.value['targetContract']">
                      </lib-file-input>
                    </div>
                    <div class="flex-col paser-button-container">
                      <button (click)="parseFile()"
                      [disabled]="!generatorParseService.formData.get('targetType').valid || !generatorParseService.formData.get('targetContract').valid"
                      class="primary smaller-button">Parse</button>
                    </div>

                  </div>

                </div>



              </div>

                <div class="fields-group" id="generatedApi" [class.fields-group-disabled]="smartProjectState === 'closed'">

                    <!-- <button (click)="generatorService.getEndpoits()">GET ENPOINTS - temp</button> -->

                    <!-- <button (click)="showToolbar()">show toolbar</button>
                    <button (click)="setActive()">set active</button>
                    <button (click)="setCompleted()">set completed</button>
                    <button (click)="setError()">set error</button> -->

                    <!-- <button (click)="generatorService.getParsedStatus()">GET STATUS</button>

                    <button (click)="getDOcumentation()">Documentation</button> -->

                    <h4>Generate API Code</h4>

                    <div class="flex-inputs-group">
                      <div class="field-with-set-default-button">
                        <lib-form-field
                          [minBottomMargin]="true"
                          [inlineLayout]="false"
                          [value]="generatorService.formData.get('language').value"
                          [formGroupParameter]="generatorService.formData"
                          (dropdownChanged)="updateDefaultButton($event)"
                          [fieldSettings]="generatorService.formSettings['language']">
                        </lib-form-field>

                        <button *ngIf="showLanguageSetDefault" class="text-button" (click)="changeLanguageDefault()">Set As Default</button>
                      </div>


                      <div class="field-with-set-default-button">
                        <lib-form-field
                          [minBottomMargin]="true"
                          [inlineLayout]="false"
                          [minLabelWidth]="6"
                          [value]="generatorService.formData.get('generatedType').value"
                          (dropdownChanged)="includeOptionalFields($event)"
                          [formGroupParameter]="generatorService.formData"
                          [fieldSettings]="generatorService.formSettings['generatedType']">
                        </lib-form-field>

                        <button *ngIf="showGeneratedTypeSetDefault" class="text-button" (click)="changeGeneratedTypeDefault()">Set As Default</button>
                      </div>
                    </div>

                    <div class="fields-group-border-with-title">
                      <h5>Maven</h5>
                      <div  class="flex-inputs-group">
                        <lib-form-field
                        [minBottomMargin]="true"
                        [inlineLayout]="false"
                        [formGroupParameter]="generatorService.formData"
                        [fieldSettings]="generatorService.formSettings['projectName']"
                        [value]="generatorService.formData.value['projectName']"
                        (blurEvent)="updateField('projectName')"
                        (onValidation)="updateField('projectName')">
                      </lib-form-field>
                      <lib-form-field
                        [minBottomMargin]="true"
                        [inlineLayout]="false"
                        [inlineInstruction]="true"
                        [formGroupParameter]="generatorService.formData"
                        [fieldSettings]="generatorService.formSettings['groupId']"
                        [value]="generatorService.formData.value['groupId']"
                        (blurEvent)="updateGroupIdField('groupId')"
                        (onValidation)="updateField('groupId')">
                      </lib-form-field>
                      </div>

                    </div>



                    <div class="file-fields-group" [libLoading]="loadingEndpoints">
                      <div class="form-group centered">


                        <div class="flex-inputs-group">
                          <div>
                            <label class="required">Endpoints</label>
                            <p class="field-display-value input-instruction">
                              <span class="label">Total Endpoints</span>
                              <span class="value">{{totalEndpoints || 'N/A'}}</span>
                            </p>
                          </div>
                          <div style="text-align: center;">

                            <span class="label centered">How many endpoints to use?</span>
                            <div class="flex-grow centered">

                              <div class="btn-group centered smaller-button" [(ngModel)]="radioModel" [disabled]="!totalEndpoints || totalEndpoints === 0" btnRadioGroup>
                                <label class="btn btn-success mb-0 smaller-button" [disabled]="!totalEndpoints" [class.active]="allEndpoints" btnRadio="all" value="all" (click)="toggleEndpointsSelector('all')">All</label>
                                <label class="btn btn-success mb-0 smaller-button" [disabled]="!totalEndpoints" [class.active]="!allEndpoints" btnRadio="custom" value="custom" (click)="toggleEndpointsSelector('custom')">Custom Selected</label>
                              </div>
                            </div>

                          </div>

                        </div>




                      </div>

                      <div [@accordionAnimation]="endpointsAccordionState">
                        <lib-multiselect [stringInitialList]="generatorService.inputEndpoints" [stringInput]="true"
                        (exportOutputList)="setEndpoints($event)" [initialList]="endpoints" [events]="eventsSubject.asObservable()"
                        [inputListName]="'Target Endpoints'" [outputListName]="'Generate Endpoints'" [multiselectorVisible]="!allEndpoints"></lib-multiselect>
                      </div>

                    </div>

                    <div class="form-group no-bottom-margin" [@accordionAnimation]="accordionState">
                        <div style="padding-top: 1em;">
                            <div class="file-fields-group">


                                <lib-file-input
                                    (fileValidStatus)="setFileStatus($event,'generatedContract')"
                                    (fileUpdated)="updateFile($event,'generatedContract')"
                                    [formGroupParameter]="generatorService.formData"
                                    [fieldSettings]="generatorService.formSettings['generatedContract']">
                                </lib-file-input>

                            </div>
                        </div>


                        <lib-form-field
                          [minBottomMargin]="true"
                          [disabled]="generatorService.formSettings['mapping'].disabled"
                          [formGroupParameter]="generatorService.formData"
                          [fieldSettings]="generatorService.formSettings['mapping']"
                          (blurEvent)="updateField('mapping')"
                          (onValidation)="updateField('mapping')">
                        </lib-form-field>
                    </div>




                </div>


                <div class="form-group form-button-container sticky-bottom-buttons multiple-buttons" >
                  <div>
                    <button
                        [disabled]="generatorService.loading || generatorService.formData.pristine"
                        type="reset" class="secondary smaller-button"
                        (click)="clearSmartProjectForm()">
                        Clear Smart Project Form
                    </button>

                    <button
                        [disabled]="generatorService.loading || !generatorService.formData.valid || formState==='loadingEndpoints'"
                        (click)="generate()"
                        class="primary smaller-button"
                        type="submit">
                        Generate
                    </button>
                  </div>
                </div>
            </div>
        </lib-box-container>
    </article>

    <ng-template #loading>
        <div class="loading-spinner-container">
            <div class="loading-spinner"></div>
        </div>
    </ng-template>

</section>

<ng-template #successMessage>
    <div *ngIf="successDetails">
      <div class="modal-header">successDetails
        <h2 class="modal-title pull-left">Successful Request</h2>
        <button class="close-popup-button close-button modal-close" (click)="modalRef.hide()">close</button>
      </div>
      <div class="modal-body">
        <p class="centered">Your request was successfully verified.</p>
        <p *ngIf="successDetails.status === 'processing'" class="ellipsis-animation">
            <span class="text">Processing</span>
            <span class="ellipsis"></span>
        </p>
        <p class="centered">You can see your results in <a class="pointer" (click)="closeModal()" >Downloads List</a></p>
      </div>
    </div>
</ng-template>
