<div class="side-modal-backdrop" (click)="cancel()"></div>
<div *ngIf="demoForm" class="side-form-container" [fullScreen]="true" [libLoading]="loading">
    <form id="demoForm" stickyScroll scrollContainerId="demoContainer" [bottomOffset]="0" novalidate  [formGroup]="demoForm">
        <div class="scrollable-fields-container">
            <h2>{{productName}}</h2>

            <h3>Request Product Demo</h3>

            <lib-accordion [accordionBody]="contactInfoBody" [accordionHeader]="contactInfoHeader" #contactAccordion [startOpen]="true"></lib-accordion>

            <ng-template #contactInfoHeader>
              <h4>Contact Information <a class="pointer" *ngIf="contactFieldsDisabled" (click)="setContactFieldsEditable()"><fa-icon [icon]="editIcon"></fa-icon></a></h4>
              <p *ngIf="contactFieldsDisabled" class="input-instruction">Contact Info is prefiled with info on the account. Click <button [attr.disabled]="(contactFieldsDisabled ? null : 'disabled')" role="button" class="text-button auto-height" (click)="setContactFieldsEditable()">Edit Contact Info</button>, if you want to change it.<br>The changes won't affect information stored on your account.</p>
            </ng-template>

            <ng-template #contactInfoBody>

              <lib-form-field [formGroupParameter]="demoForm" [fieldSettings]="demoFormSettings['fullName']" [minBottomMargin]="true"></lib-form-field>

              <lib-form-field [formGroupParameter]="demoForm" [fieldSettings]="demoFormSettings['email']" [minBottomMargin]="true"></lib-form-field>

              <lib-form-field [formGroupParameter]="demoForm" [fieldSettings]="demoFormSettings['phone']" [minBottomMargin]="true"></lib-form-field>

              <lib-form-field [formGroupParameter]="demoForm" [fieldSettings]="demoFormSettings['company']" [minBottomMargin]="true"></lib-form-field>

              <lib-form-field [formGroupParameter]="demoForm" [fieldSettings]="demoFormSettings['job']" [minBottomMargin]="true"></lib-form-field>

              <lib-form-field [formGroupParameter]="demoForm" [fieldSettings]="demoFormSettings['industry']" [minBottomMargin]="true"></lib-form-field>

            </ng-template>

            <div class="fields-group blue-background">

              <h4>Date and Time</h4>

              <p class="input-instruction">Choose at least 1 and up to 3 date and time slots that would best work for you. The demo shall take between 15 and 30 minutes of your time.<br><strong>The time slots are displayed in EST (Eastern US Timezone).</strong></p>

              <div formArrayName="timeSlots">
                <div [formGroupName]="i"
                    *ngFor="let timeSlot of timeSlots.controls; let i=index">

                    <div class="flex-columns two-columns-with-button">
                        <lib-form-field [arrayName]="'timeSlots'" [index]="i" [formGroupParameter]="demoForm" [fieldSettings]="timeSlotsSettings['date']"></lib-form-field>

                        <lib-form-field [arrayName]="'timeSlots'" [index]="i" [formGroupParameter]="demoForm" [fieldSettings]="timeSlotsSettings['time']"></lib-form-field>

                        <div class="form-group button-container">
                            <button type="button" [disabled]="timeSlots.controls.length === 1" (click)="removeTimeslot(i)" class="primary icon-button demo-minus-button" disabled="disabled"><fa-icon [icon]="faTrashAltIcon"></fa-icon></button>
                        </div>
                    </div>

                  </div>
              </div>

              <div class="row clear form-button-container" style="padding-left: 1em;margin-bottom: 1em;">
                  <button [disabled]="timeSlots.controls.length === timeslotsAvailable" type="button" (click)="addTimeslot()" class="primary  demo-plus-button">
                    <fa-icon [icon]="faPlusIcon"></fa-icon>
                    <span>Add another Time Slot</span>
                  </button>
              </div>

            </div>





            <div class="fields-group centered">
              <div class="recaptcha-container">
                <re-captcha
                    name="captcha"
                    required
                    formControlName="captcha"
                    siteKey="6LcYsIIUAAAAAC4v-rzZ4zovoSM3oDPbLan3KbwM">
                </re-captcha>
              </div>
            </div>


            <lib-form-field [formGroupParameter]="demoForm"  [fieldSettings]="demoFormSettings['comments']"></lib-form-field>


        </div>

        <div class="form-group bottom-absolute">
            <button (click)="cancel()" type="button" class="secondary">Cancel</button>
            <button (click)="submit()" [disabled]="!demoForm.valid" class="primary" type="submit">Submit</button>
        </div>
    </form>
</div>
