import { Component, ElementRef, HostListener, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router, NavigationEnd, RouterOutlet, ActivatedRoute } from '@angular/router';
import { fadeInAnimation } from 'projects/nine-gold-lib/src/lib/animations/fade-in.animation';
import { ApiUser, IUser } from 'projects/nine-gold-lib/src/lib/models/user';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { MenuItemType, NgNavigation, NgNavigationApps } from 'projects/nine-gold-lib/src/lib/shared/ng-navigation/ng-navigation';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { NotificationService, StatusBarService } from 'projects/nine-gold-lib/src/public-api';
import { FormsModule } from '@angular/forms';
import { GeneratorSettingsService } from './services/generator-settings.service';
import { Title } from '@angular/platform-browser';
import { ProductService } from 'projects/nine-gold-lib/src/lib/services/product.service';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

function SmoothScroll(target, speed, smooth) {
	if (target === document)
		target = (document.scrollingElement
              || document.documentElement
              || document.body.parentNode
              || document.body); // cross browser support for document scrolling

	var moving = false;
	var pos = target.scrollTop
  var frame = target === document.body
              && document.documentElement
              ? document.documentElement
              : target; // safari is the new IE

	target.addEventListener('mousewheel', scrolled, { passive: false });
	target.addEventListener('DOMMouseScroll', scrolled, { passive: false });

	function scrolled(e) {
    if(!moving) {
    	pos = target.scrollTop;
	  }
		e.preventDefault(); // disable default scrolling

		var delta = normalizeWheelDelta(e);

		pos += -delta * speed;
    // pos = Math.max(0, Math.min(pos, (target.scrollHeight - frame.clientHeight) + (smooth * 2))) // limit scrolling
    pos = Math.max(0, Math.min(pos, (target.scrollHeight - frame.clientHeight))); // limit scrolling

		if (!moving) update();
	}

	function normalizeWheelDelta(e){
		if(e.detail){
			if(e.wheelDelta)
				return e.wheelDelta/e.detail/40 * (e.detail>0 ? 1 : -1); // Opera
			else
				return -e.detail/3; // Firefox
		}else
			return e.wheelDelta/120; // IE,Safari,Chrome
	}

	function update() {
		moving = true;

		var delta = absRound((pos - target.scrollTop) / smooth);

		target.scrollTop += delta;

		if (Math.abs(delta) >= 1)
			requestFrame(update)
		else
			moving = false;
  }

  function absRound(num) {
    if(num < 0) {
        return -1*Math.round(-1*num);
    } else {
        return Math.round(num);
    }
}

	var requestFrame = function() { // requestAnimationFrame cross browser
		return (
			window.requestAnimationFrame ||
			// window.webkitRequestAnimationFrame ||
			// window.mozRequestAnimationFrame ||
			// window.oRequestAnimationFrame ||
			// window.msRequestAnimationFrame ||
			function(func) {
				window.setTimeout(func, 1000 / 50);
			}
		);
	}()
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInAnimation]
})
export class AppComponent {
  title = 'REST Generator';
  scrolledDown = false;
  scrolling = false;
  offsetY: number = 0;
  scrollingUp = false;
  noHeader = false;
  scrollableHeader = true;
  userPages = false;
  appLayout = false;
  authLayout = false;

  hideFooter = false;

  demoChecked = false;

  headerNavItems: NgNavigation[];
  productNavItems: NgNavigation[];
  solutionsNavItems: NgNavigation[];
  resourcesNavItems: NgNavigation[];
  footerNavItems: NgNavigation[];
  appNavItems: NgNavigationApps[];

  @ViewChild('outerAppContainer') outerAppContainer: ElementRef;



  constructor(
    public uiService: UserInterfaceService,
    private renderer: Renderer2,
    private router: Router,
    private titleService: Title,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private notifications: NotificationService,
    public statusBar: StatusBarService,
    private productService: ProductService,
    private appSettings: AppSettingsService,
    protected gaService: GoogleAnalyticsService,
  ) {
    this.headerNavItems = appSettings.getTopNav();
    this.productNavItems = appSettings.getProductLinks();
    this.solutionsNavItems = appSettings.getSolutionsLinks();
    this.resourcesNavItems = appSettings.getResourcesLinks();
    this.footerNavItems = appSettings.getFooterNav();
    this.appNavItems = appSettings.getAppLinks();

    let appPagesTimeout;

    router.events.subscribe((val) => {

      if(val instanceof NavigationStart) {
        this.hideFooter = true;
        document.body.style.height = document.body.clientHeight + "px";
        document.body.classList.add('navigation-transition');
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        let spinnerContainer = document.body.getElementsByClassName('app-loading-spinner-container')[0];
        if(spinnerContainer) {
          spinnerContainer.classList.remove('active');
          setTimeout(() => {
            spinnerContainer.remove();
          }, 1000);
        }
      }

      if(val instanceof NavigationEnd) {

        this.gaService.pageView(val.url, this.title);
        this.noHeader = false;
        this.hideFooter = false;
        this.authLayout = false;

        setTimeout(() => {
          this.hideFooter = false;
          document.body.style.height = "";
          document.body.classList.remove('navigation-transition');
          this.checkForScroll();
        }, 1000);

        if(val.url.startsWith('/login')) {
          this.noHeader = true;
        } else {
          this.noHeader = false;
        }
        if(val.url.startsWith('/user')) {
          this.userPages = true;
          this.scrollableHeader = false;
          this.appLayout = false;
        } else if (val.url.startsWith('/app')) {
          this.scrollableHeader = false;
          this.appLayout = true;
          this.userPages = false;
        } else if (val.url === '/') {
          this.scrollableHeader = true;
          this.userPages = false;
          setTimeout(() => {
            this.appLayout = false;
          }, 500);
        } else {
          this.scrollableHeader = false;
          this.appLayout = false;
          this.userPages = false;


          if(activeRoute.root.firstChild.snapshot.data['layout'] === 'user'
            || activeRoute.root.firstChild.snapshot.data['layout'] === 'app') {
            this.userPages = true;
            this.appLayout = true;
            this.uiService.sideMenu = true;
            if(appPagesTimeout) {
              clearTimeout(appPagesTimeout);
            }
          } else if(activeRoute.root.firstChild.snapshot.data['layout'] === 'auth') {
            this.noHeader = true;
            this.hideFooter = true;
            this.authLayout = true;
          } else {
            this.noHeader = false;
            this.hideFooter = false;
            this.authLayout = false;
            appPagesTimeout = setTimeout(() => {
              this.userPages = false;
            }, 500);
            this.uiService.sideMenu = false;
            this.uiService.sideMenuWide = (window.innerWidth > 800);
          }


          // setTimeout(() => {
          //   this.appLayout = false;
          // }, 500);
          // this.userPages = false;
        }



        if(this.appLayout || this.userPages) {
          document.body.classList.add("body-app-layout");
        } else {
          document.body.classList.remove("body-app-layout");
        }
        // console.log(val);
      }
    });
  }

  ngOnInit(): void {
    this.uiService.showDemo = false;
    this.uiService.showQuote = false;
    this.productService.productsUpdated.subscribe(prods => {
      this.demoChecked = true;
      setTimeout(() => {
        this.setDemoAndQuote();
      }, 800);    });
    // this.userService.userUpdated.subscribe(user => {
    //   setTimeout(() => {
    //     this.setDemoAndQuote();
    //   }, 500);

    // });
    // this.authService.loginChange.subscribe(loggedIn => {
    //   // this.isLoggedIn = loggedIn;
    //   console.log("LOGIN")
    //   this.setDemoAndQuote();
    // });
    setTimeout(() => {
      if(this.demoChecked) {
        this.setDemoAndQuote();
      }

    }, 1500);

    this.titleService.setTitle(this.title);
    let token: string;
    this.userService.clearUser();
    token = this.userService.getToken();
    this.productService.productsUpdated.subscribe(products => {
      this.appNavItems = this.appSettings.getAppLinks();
    });

    // this.productService.setProductsListNew();
    if(token) {
      this.authService.getUserInfo(token).subscribe({
        next: result => {
          // console.log(result);
          let user = new ApiUser;
          user = this.userService.map(user, result);
          this.userService.setUser(user);
          this.notifications.success('User Logged In', `User: ${user.username} was successfully logged in.`,FormResultMessageFormat.popup, null, null);
          this.productService.setProductsListNew(user.company.id);
          this.productService.setProductCategories();
          this.productService.setLicensesFromApi();
          // this.router.navigate([this.returnUrl]);

          // this.user = plainToClass(IUser, result, {
          //   //excludeExtraneousValues: true,
          // });
        },
        error: err => console.log(err)
      })
    } else {
      this.productService.setProductsListNew();
      this.productService.setProductCategories();
    }
    // new SmoothScroll(document,120,12);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // console.log(this.outerAppContainer.nativeElement.scrollHeight);
      // console.log(this.outerAppContainer.nativeElement.clientHeight);
      const verticalOffset = window.pageYOffset
            || document.documentElement.scrollTop
            || document.body.scrollTop || 0;
      // console.log(verticalOffset);
    }, 1500);

    // console.log(document.body.scrollHeight);
    // console.log(window.outerHeight);
    // if(document.body.scrollHeight <= document.body.clientHeight) {
    //   this.scrolledDown = true;
    // }
  }

  @HostListener('window:scroll',['$event']) onScroll(event) {
    this.checkForScroll();
  }

  checkForScroll(): void {
    // console.log(event);
    // console.log(this.outerAppContainer.nativeElement.scrollHeight);
    // console.log(this.outerAppContainer.nativeElement.clientHeight);
    const verticalOffset = window.pageYOffset
          || document.documentElement.scrollTop
          || document.body.scrollTop || 0;
    // console.log(verticalOffset + this.outerAppContainer.nativeElement.clientHeight + 100);
    this.scrollingUp = verticalOffset < this.offsetY;
    this.scrolling = verticalOffset > 50
    this.scrolledDown = verticalOffset + this.outerAppContainer.nativeElement.clientHeight + 100 > this.outerAppContainer.nativeElement.scrollHeight;
    this.offsetY = verticalOffset;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  onPageSwitch(event): void {
    // console.log(event);
  }
  onDeactivateMain(event):void {
    // this.uiService.setUserDropdownStatus(false, true);
  }

  onActivate(event):void {
    // console.log(event);
    this.renderer.addClass(document.body, 'modal-open');
  }
  onDeactivate(event):void {
    // console.log(event);
    this.uiService.setUserDropdownStatus(false);
    this.renderer.removeClass(document.body, 'modal-open');
  }

  setDemoAndQuote(): void {
    // console.log(this.userService.currentUser);
    if (!this.userService.currentUser) {
      this.uiService.showDemo = true;
      this.uiService.showQuote = true;
    } else {
      // this.uiService.showDemo = false;
      // this.uiService.showQuote = false;
      let prod = this.productService.apiProducts.find(pr => pr.id === 'restgen');
      if(prod) {
        this.uiService.showDemo = prod.canQuote;
        this.uiService.showQuote = prod.canQuote;
      }
      // console.log(prod);
    }
    this.demoChecked = true;


  }
}
