import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { faCode, faDownload } from '@fortawesome/free-solid-svg-icons';
import { DownloadService } from 'projects/nine-gold-lib/src/lib/services/download.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
// import { ColumnFilter, NgListColumns, NgListDataRow } from 'projects/nine-gold-lib/src/lib/shared/ng-list/ng-list-data';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Uses } from 'projects/nine-gold-lib/src/lib/models/uses';
import { ButtonTypes } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';
import { NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { DownloadsGeneratorService } from '../downloads-generator.service';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GeneratorFormService } from '../../generator/generator-form.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadsComponent implements OnInit {
  downloadIcon = faDownload;
  copyIcon = faCopy;

  useDetails: Uses = null;
  loading = false;

  highlightedId: {};

  loadedList: Subscription;

  updatingProcessingItems: any;

  @ViewChild('restGenUseDetails') restGenUseDetails: TemplateRef<any>;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    public downloadService: DownloadsGeneratorService,
    private generatorService: GeneratorFormService,
    private http: HttpClient,
    private notif: NotificationService,
    private appSettings: AppSettingsService,
    private apiService: ApiService
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.highlightedId = navigation?.extras.state;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.downloadService.LoadPage(1,true);
    }, 100);
  }

  private updateProcessingItems() {
    clearInterval(this.updatingProcessingItems);
    this.updatingProcessingItems = setInterval(()=>{
      let progressStatusList = this.downloadService.apiResutls.filter(el=>el.status === 'processing');
      if(progressStatusList.length > 1) {
        this.apiService.getUsesByProduct('restgen').subscribe((results:Uses[])=>{
          progressStatusList.forEach(existingEl=>{
            let receivedEl = results.find(r=>r.id === existingEl.id);
            if (this.downloadService.updateStatus(existingEl,receivedEl)) {
              this.generatorService.generateSuccess();
              this.notif.info('Status Updated',`Status of a project <strong>${existingEl.projectName}</strong> was updated`,FormResultMessageFormat.popup,5000,null, true);
            }
          });
        });
      } else if(progressStatusList.length === 1) {
        this.apiService.getSingleUse(progressStatusList[0].id,this.userService.getCompanyId()).subscribe((result:Uses)=>{
           if (this.downloadService.updateStatus(progressStatusList[0],result)) {
            this.generatorService.generateSuccess();
            this.notif.info('Status Updated',`Status of a project <strong>${progressStatusList[0].projectName}</strong> was updated`,FormResultMessageFormat.popup,5000,null, true);
           }
        });
      } else {
        // NO MORE ELEMENTS
        clearInterval(this.updatingProcessingItems);
      }
    },10000)
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    clearInterval(this.updatingProcessingItems);
    if (this.loadedList) this.loadedList.unsubscribe();
  }

  ngAfterViewInit() {
    this.loadedList = this.downloadService.loadResultsEnded.subscribe(res=>{
      this.loadedList.unsubscribe();
      if (this.downloadService.apiResutls.some(el=>el.status === 'processing')) this.updateProcessingItems();

      if(this.highlightedId) {
        setTimeout(() => {
          this.downloadService.highlightRow(this.highlightedId['id']);
          setTimeout(()=>{
            this.downloadService.unHighlightRow(this.highlightedId['id']);
            this.downloadService.LatestRow(this.highlightedId['id']);
          },4500)
        }, 500);
      }
    });
  }

  buttonClick(data: {}): void {
    this.downloadFile(data["button"].value);
  }

  downloadFile(url: string): void {
    let token = this.userService.getToken();
    let fileName = /[^/]*$/.exec(url)[0];
    this.http.get(url,{ responseType: "blob", headers: {'X-Auth-Token': token} }  ).subscribe((response)=>{
      var blob = new Blob([response['body']], {type: "application/zip"});
      saveAs(blob,fileName);
    });
  }

  actionButtonClicked(data:{}): void {
    if(data['button'].type === ButtonTypes.info) {
      let useId = data['row'].rowIdentifier;
      this.useDetails = this.downloadService.apiResutls.find(el => el.id === useId);
      this.useDetails.params.endpoints = this.useDetails.params.endpoints.substring(1,this.useDetails.params.endpoints.length - 1);
      this.notif.info(null,"", FormResultMessageFormat.modalLargeHtml,0,this.restGenUseDetails);
      // this.notif.error('Error', 'There was an Error loading the file.',FormResultMessageFormat.popup,5000,null);
    } else if([ButtonTypes.cell_download,ButtonTypes.download].includes(data['button'].type) ) {
      this.loading = true;
      let useId = data['row'].rowIdentifier;
      this.useDetails = this.downloadService.apiResutls.find(el => el.id === useId);
      let useFileUrl = this.useDetails?.results?.completedFileDownload;
      document.body.click();

      if(useFileUrl) {
        this.http.get(this.appSettings.baseSetting.apiUrl + useFileUrl, {withCredentials: true, responseType: 'arraybuffer'}).subscribe((response) => {
          let blob = new Blob([response],{type: 'application/octet-stream'});
          let newUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = newUrl;
          a.download = this.useDetails.results.completedFile;
          a.click();
          this.loading = false;
        },
        (error:any) => {
          this.loading = false;
          this.notif.error('Error', 'There was an Error loading the file.',FormResultMessageFormat.popup,5000,null);
        })
      }



    }
  }

  private textToClipboard (text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    // TODO: find altertative to execCommand above
    document.body.removeChild(dummy);
  }

  protected copyToClipboard(code: string): void {
    this.textToClipboard(code);

    this.toastr.info( "Copied to clipboard", "The CODE was copied to clipboard", {timeOut: 6000, enableHtml: false, positionClass: 'toast-bottom-right' });
  }

}
