import { Directive, ElementRef, OnInit, HostListener, Input, AfterViewInit } from '@angular/core';
@Directive({
    selector: '[stickyScroll]'
})

export class StickyScrollDirective implements OnInit, AfterViewInit{
    scrolledToBottom = false;
    formContainer: any;
    bottomContainer: any;
    scrollContainer: any;
    @Input() bottomOffset: number = 0;
    @Input() scrollContainerId: string = null;

    constructor(private elementRef: ElementRef) {
        // console.log("STICKY SCROLLLLLL");
    }

    ngAfterViewInit() {
        this.checkScrollPosition();
    }
    ngOnInit() {
        // console.log("STICKY SCROLLLLLL");
        this.bottomOffset = parseInt(this.bottomOffset.toString());
        this.elementRef.nativeElement.classList.add('full-height');
        this.formContainer = this.elementRef.nativeElement.children[0];
        this.bottomContainer = this.elementRef.nativeElement.children[1];
        this.formContainer.classList.add('scrollable-fields-container');
        this.bottomContainer.classList.add('bottom-absolute');
        if (this.scrollContainerId) {
            this.scrollContainer = document.getElementById(this.scrollContainerId);
            document.getElementById(this.scrollContainerId).addEventListener('scroll', e => {
                this.checkScrollPosition();
            });
        } else {
            this.scrollContainer = window;
            window.addEventListener('scroll', e => {
               this.checkScrollPosition();
            }); 
        }
        
    }

    checkScrollPosition() {
        const verticalOffset = (this.scrollContainerId ? this.scrollContainer.scrollTop :
            window.pageYOffset 
            || document.documentElement.scrollTop 
            || document.body.scrollTop || 0);
        const containerHeight = (this.scrollContainerId ? this.scrollContainer.clientHeight : window.innerHeight);
        if ((verticalOffset + containerHeight + this.bottomOffset + 1) >= this.elementRef.nativeElement.offsetHeight) {
          this.bottomContainer.classList.add('scrolled-bottom');
        } else {
          this.bottomContainer.classList.remove('scrolled-bottom');
        }
    }
}