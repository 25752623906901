import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { faBug, faRobot, faShippingFast, faSmile } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';
import { CounterComponent } from 'projects/nine-gold-lib/src/lib/shared/visual/counter/counter.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  bugIcon = faBug;
  smileIcon = faSmile;
  fastIcon = faShippingFast;
  autoIcon = faRobot;

  test: boolean;
  pathLength: number;
  percentageText: number = 0;
  counters:CounterComponent[] = [];
  @ViewChild('numbersList') numbersList: ElementRef;
  @ViewChild('counter1') counter1: CounterComponent;
  @ViewChild('counter2') counter2: CounterComponent;
  @ViewChild('counter3') counter3: CounterComponent;
  // @ViewChild('counter4') counter4: CounterComponent;
  showListItem: boolean[] = [false, false, false];
  checked = false;

  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    public router: Router,
    ) {
    this.renderer.listen('window', 'scroll', this.detectElms.bind(this));
  }

  ngOnInit(): void {
  }

  Register() {
    this.authService.getRegistrationLink().then(link => window.location.href = link);
  }

  detectElms() {
    if (!this.checked &&  isInViewport(this.numbersList.nativeElement)) {
      let i = 0;
      this.counters.forEach((counter, index) => {
        var co = counter;

        setTimeout(() => {
          counter.start();
          this.showListItem[index] = true;
        }, 800 * i);
        i++;
      })

      this.percentageText = 0.8
    }
  }

  ngAfterViewInit(): void {
    this.counters = [this.counter1,this.counter2,this.counter3];
  }

  RegisterWithTrial() {
    this.authService.getRegistrationLink(true, null).then(link => window.location.href = link);
  }

}

function isInViewport (elm) {
  var elementTop = elm.getBoundingClientRect().top;
  var elementBottom = elementTop + 100;
  // in this specific case the scroller is document.documentElement (<html></html> node)
  var viewportTop = document.documentElement.scrollTop;
  var viewportBottom = viewportTop + document.documentElement.clientHeight;

  return elementBottom < document.documentElement.clientHeight;
}
