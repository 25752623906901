<div [class.open-full]="openFull" [class.open]="isOpened()" class="user-button-container">
    <button class="avatar-button secondary icon-button" (click)="_toggleSidebar()">
        {{userInfo?.initials}}
        <!-- FW -->
        <fa-icon [icon]="faCaretDownIcon"></fa-icon>
    </button>
</div>

<ng-sidebar-container>

    <!-- A sidebar -->
    <ng-sidebar
    #sidebar
    [(opened)]="opened"
    [mode]="'over'"
    [position]="'right'"
    [sidebarClass]="sidebarClass"
    [class.open-full]="openFull"
    [closeOnClickOutside]="true"
    (onClosed)="onSidebarClosed()"
    [autoFocus]="false">
        <nav class="user-nav">
            <div class="user-info">
                <user-role-icon *ngIf="userInfo" class="short" [userRole]="userRoleIcon" [isGlobalAdmin]="(userInfo.roles.includes('administrator') ? true : false)"></user-role-icon>
                <p class="user-fullname">{{userInfo?.fullName}}</p>
            </div>

            <p *ngIf="userInfo?.jobTitle" class="user-jobtitle">{{userInfo?.jobTitle}}</p>
            <p class="user-companyname">{{userInfo?.company?.name}}</p>
            <hr>
            <div class="row" *ngFor="let navItem of userService.userNav">
                <button [ngClass]="navItem.customClass" *ngIf="!navItem.outerLink" [routerLink]="navItem.link" (click)="sidebar.close()" class="text-button">
                    <fa-icon [icon]="navIcons[navItem.name]?.icon"></fa-icon>
                    <span class="text">{{navItem.text}}</span>
                </button>
                <a role="button" [ngClass]="navItem.customClass" *ngIf="navItem.outerLink" [href]="navItem.link" target="_blank" (click)="sidebar.close()" class="text-button">
                    <fa-icon [icon]="navIcons[navItem.name]?.icon"></fa-icon>
                    <span class="text">{{navItem.text}}</span>
                </a>
            </div>
            <div class="row">
              <button (click)="sidebar.close()" class="text-button" [routerLink]="['',{outlets: {sidePopup: 'supportrequest'}}]">
                <fa-icon [icon]="supportIcon"></fa-icon>
                <span class="text">Support Request</span>
              </button>
            </div>
            <!-- <div class="row">
                <button [routerLink]="'user/info'" (click)="sidebar.close()" class="text-button">
                    <fa-icon [icon]="faCogIcon"></fa-icon>
                    User Info
                </button>
            </div>
            <div class="row">
                <button [routerLink]="'user/license'" (click)="sidebar.close()" class="text-button">
                    <fa-icon [icon]="faLicenseIcon"></fa-icon>
                    User License
                </button>
            </div>
            <div class="row">
                <button [routerLink]="'user/subusers'" (click)="sidebar.close()" class="text-button">
                    <fa-icon [icon]="faSubusersIcon"></fa-icon>
                    Sub-Users
                </button>
            </div>
            <div class="row">
                <button [routerLink]="'user/security'" (click)="sidebar.close()" class="text-button">
                    <fa-icon [icon]="faSecurityIcon"></fa-icon>
                    Security
                </button>
            </div> -->
            <div class="row">
                <button (click)="NewLogout()" class="text-button">
                    <fa-icon [icon]="faPowerOffIcon"></fa-icon>
                    Logout
                </button>
            </div>
            <!-- <div class="row">
                <button [routerLink]="'pricing'" (click)="sidebar.close()" class="text-button">
                    Pricing
                </button>
            </div> -->

        </nav>

        <div class="user-popup-container">
            <router-outlet
            name="userPopup"
            (activate)='onActivate($event)'
            (deactivate)='onDeactivate($event)'></router-outlet>
        </div>
    </ng-sidebar>



    <!-- Page content -->
    <div ng-sidebar-content>
      <button (click)="_toggleSidebar()">Toggle sidebar</button>
    </div>

  </ng-sidebar-container>
