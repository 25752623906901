import { EventEmitter, Injectable } from '@angular/core';
import { faClock, faCog, faShieldAlt, faStar, faUser, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiUser, ChangeTypes, InternationalPhoneNumber, ITrialStatus, UserRoles, UserTypesData, UserUpdateData } from '../models/user';
import { NgNavigation } from '../shared/ng-navigation/ng-navigation';
import { FormResultMessageFormat } from '../shared/notification/notification';
import { NotificationService } from '../shared/notification/notification.service';
import { ApiService } from './api.service';
import { AppSettingsService } from './app-settings.service';
import { ProductService } from './product.service';
import { UiSettingsService } from './ui-settings.service';
import { UserInterfaceService } from './user-interface.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // * INITIAL PROPERTIES

  // default list of permissions
  userPermissions = {
    canEditCompanyName: false,
    canSendInvitations: false,
    canViewInvitations: false,
    canViewLicense: false,
    canEditLicense: false,
    canListCompanies: false,
    canPromoteAdmin: false,
    canDemoteAdmin: false,
    canPromoteOwner: false,
    canArchiveUser: false, // remove user from Platform
    canRemoveUser: false, // remove user from company
    canChangeCompanyName: false,
    canViewActions: false
  }

  userUpdated:EventEmitter<ApiUser> = new EventEmitter<ApiUser>();
  public activeUserRoles: UserTypesData[];
  public highestActiveRole: UserTypesData;

  // * PRIVATE PROPERTIES
  defaultUserPermissions: any;  //  full list of permissions as point of reference (used when new user logs in)
  // ToDo: check if currentUser should be private
  currentUser: ApiUser;
  // ToDo: check is userRolesList is private
  userRolesList: UserTypesData[];

  // ToDo: check if userAuthenticated is used anywhere
  private userAuthneticated: boolean = false;

  // * PUBLIC PROPERTIES
  // ToDo: check if aff readOnly keyword
  public userNav: NgNavigation[];

  constructor(
    private uiService: UserInterfaceService,
    private appSettings: AppSettingsService,
    private apiService: ApiService,
    private notif: NotificationService,
    private productService: ProductService,
    private uiSettingsService: UiSettingsService
  ) {
    this.defaultUserPermissions = this.userPermissions;
    this.userRolesList = this.setUserRolesList();
  }

  // * Private constructor methods

  /**
   * Setting User roles list with permission
  */
  //  ToDo: check if private
  setUserRolesList(): UserTypesData[] {
    return [
      {
        name: UserRoles.registered,
        text: 'Registered user',
        class: '',
        icon: faUser,
        permissions: [],
        level: 0
      },
      {
        name: UserRoles.trial,
        text: 'Trial User',
        class: '',
        icon: faUser,
        smallIcon: faClock,
        permissions: [],
        level: 1
      },
      {
        name: UserRoles.company_administrator,
        text: 'Company Administrator',
        class: 'bigger-icon',
        icon: faUser,
        smallIcon: faCog,
        permissions: ['canSendInvitations', 'canViewLicense', 'canViewInvitations','canPromoteAdmin','canViewActions'],
        level: 2
      },
      {
        name: UserRoles.company_owner,
        text: 'Company Owner',
        icon: faUser,
        smallIcon: faStar,
        class: 'star',
        permissions: ['canEditLicense', 'canEditCompanyName','canDemoteAdmin','canRemoveUser','canChangeCompanyName','CanGetTrial'],
        level: 3
      },
      {
        name: UserRoles.administrator,
        text: 'Global Administrator',
        class: 'bigger-icon shield',
        icon: faUserShield,
        smallIcon: faShieldAlt,
        permissions: ['canListCompanies','canArchiveUser'],
        level: 0
      }
    ]
  }

  // ToDo: check if private
  setUserPermissions(activeRoles: UserTypesData[]):any {
    let permissions = {};
    activeRoles.forEach(role => {
      role.permissions.forEach(perm => {
        permissions[perm] = true;
      })
    });
    return permissions;
  }

  getHighestRole(): UserTypesData {
    let res:number = (this.activeUserRoles ? Math.max.apply(Math,this.activeUserRoles.map(role => {return role.level;})) : null);
    return (res !== null ? this.activeUserRoles.find(role => role.level === res) : null);
  }

  getHighestRoleFromArray(roles: string[]): UserTypesData {
    let res: number = Math.max.apply(
      Math,
      this.userRolesList
        .filter(role => roles.includes(role.name))
        .map(role => {return role.level})
      );
    return this.userRolesList.find(role => role.level === res);
  }


  // ---------------------------------------------------------------------------
  // Updating any property of user with id == userData.id
  // ---------------------------------------------------------------------------
  updateUser(change: UserUpdateData, userData?: ApiUser): Observable<ApiUser> {

    // TODO: make userData required argument and then below line is not needed
    userData = userData || this.currentUser;

    let userDataNew = {};
    // TODO: userDataNew should have data type like ApiUser, but with every field optional
    // ToDo: check if companyId and session->companyId are different or add optional argument for company Id (to use for SUPER admin) - this is for changes done while not impersonating
    // TODO: Once impersonating session will work, we will see how this will work with impersonated session

    if(change.propertyName === 'roles') {
      userDataNew['roles'] = userData.roles;
      if(change.changeType === ChangeTypes.add && !userDataNew['roles'].includes(change.value)) {
        userData.roles.push(change.value);
      } else {
        let index = userDataNew['roles'].indexOf(change.value) || null;
        if(index) userDataNew['roles'].splice(index,1);
      }
    } else if(change.propertyName === 'company') {
      userDataNew['company'] = userData.company;
      userDataNew['company'].name = change.value;
    } else {
      userDataNew[change.propertyName] = change.value;
    }

    return this.apiService.updateUser(userData.id,userDataNew).pipe(
      tap(data => {return true},
        err => {
          this.notif.error('Error',err.error?.friendlyMessage,FormResultMessageFormat.popup,3000,null);
          console.error(err)}
        )
    );
  }





  // removeUser(userId: string, companyId: string): Observable<boolean> {
  //   return this.apiService.removeUser(userId, companyId).pipe(
  //     tap(
  //       data => {return true},
  //       err => {
  //         this.notif.error('Error',err.error?.friendlyMessage,FormResultMessageFormat.popup,3000,null);
  //         console.error(err);
  //       })
  //   );
  // }






  // ---------------------------------------------------------------------------
  // Updating property of current logged in user
  // ---------------------------------------------------------------------------
  updateCurrentUser(change: UserUpdateData): void {
    this.updateUser(change,this.currentUser).subscribe((user:ApiUser) => {
      this.setUser(user);

      let value = (change.propertyName === 'phone' ? (!!user.phone ? this.setDisplayPhone(JSON.parse(user.phone)) : '') : change.value);

      if(!!value) {
        this.notif.showNotification(this.uiService.getNotificationMessage('currentUserUpdated',[change.propertyLabel,value]));
      } else {
        this.notif.showNotification(this.uiService.getNotificationMessage('currentUserPropertyRemoved',[change.propertyLabel]));
      }
    });
  }

  updateCompany(change: UserUpdateData): void {
    let userDataNew = {
      name: this.currentUser.company.name,
      industry: this.currentUser.industry
    };

    userDataNew[change.propertyName] = change.value;
    this.apiService.updateCompany(userDataNew).subscribe(x=>{
      this.notif.success('Company updated',`The ${change.propertyLabel} property was updated succesfully.`,FormResultMessageFormat.popup,6000,null);
      this.currentUser.company.name = userDataNew.name;
      this.currentUser.industry = userDataNew.industry;
      this.userUpdated.emit(this.currentUser);
      this.uiSettingsService.saveSettingsFromUser(this.currentUser);
      this.appSettings.logCompany({id: this.currentUser.company.id, name: this.currentUser.company.name});
    })
  }



  /**
   * Add permissions to User Navigation (used in User-dropdown and User Layout)
   * These permissions are based on userRolesList and CurrentUser roles
  */
  userNavPermissions(permissions: {}, nav: NgNavigation[]): NgNavigation[] {
    let newNav: NgNavigation[] = [];
    nav.forEach(el => {
      if(!el.permissionRequired || (el.permissionRequired && permissions[el.permissionRequired])) {
        newNav.push(el);
      }
    })
    return newNav;
  }

  private checkLicense(): boolean {
    // TODO: this should use License API
    let productCode = this.appSettings.getProductCode();
    // if(this.currentUser.company.products[productCode]) {
    //   if(this.currentUser.company.products[productCode].is_free) {
    //     return true
    //   } else {
    //     let liceseEnds = new Date(this.currentUser.company.products[productCode].license_ends);
    //     let now = new Date();
    //     if(now <= liceseEnds) {
    //       return true;
    //     } else {
    //       this.notif.error("License Expired!", "Your license have expired.", FormResultMessageFormat.popup, 7000, null);
    //       return false;
    //     }
    //   }

    // } else {
    //   // no license for the product
    //   this.notif.error("No License!", "You are not licensed for this action.", FormResultMessageFormat.popup, 7000, null);
    //   return false;
    // }

    return false;
  }

  checkUserLicense(): boolean {
    if(this.currentUser) {
      return this.checkLicense();
    } else {
      // TODO: fix this function (make is async)
      return false;
      this.userUpdated.subscribe(user => {
        return this.checkLicense();
      });
    }
  }

  getCompanyName():string {
    return this.currentUser?.company.name || null;
  }

  getCompanyId(): string {
    return this.currentUser?.company.id || null;
  }

  getTrialStatus(): ITrialStatus {
    let productCode = this.appSettings.getProductCode();

    return null;
    // TODO: Update this as soon as product license API is ready

    // if(this.currentUser.company.products[productCode]?.is_trial) {
    //   return {
    //     isTrial: true,
    //     daysLeft: 14,
    //     generationsLeft: 5,
    //     productName: this.currentUser.company.products[productCode].name
    //   }
    // } else {
    //   return null;
    // }
  }

  /**
   * SetUser function run to set user data received from API to curretUser Object
   * and stored in local storage
  */
  // ToDo: make Map function private and call it from setUser function
  // ToDo: Review where SetUser function is called from (make it called only from AuthSevice)
  private sameUsers(cU: ApiUser, nU: ApiUser): boolean {
    let same = true;
    if(!cU) {
      return false;
    } else {
      let properties = ['email','company','fullName','id','phone','roles','session','username'];
      properties.forEach(p => {
        if(cU[p]?.entries !== nU[p]?.entries) same = false;
      });
      return same;
    }

  }

  public setDisplayPhone(jsonPhone: InternationalPhoneNumber): string {
    if (!!jsonPhone.nationalNumber) {
      return `${(jsonPhone.countryCode || "")} ${jsonPhone.dialCode} ${jsonPhone.nationalNumber}`;
    } else {
      return `+${jsonPhone.dialCode} ${jsonPhone.number}`;
    }

  }
  public setUser(userData: ApiUser, forceUpdate?: boolean):void {
    if(!this.sameUsers(this.currentUser,userData) || forceUpdate) {
      this.currentUser = userData;

      if(this.currentUser.phone) {
        // console.log("PHONE");
        // console.log(this.currentUser.phone);
        // this.currentUser.phone = '{"number":"718-916-8252","dialCode":"+1"}';
        try {
          this.currentUser.jsonPhone = (this.currentUser.phone ? JSON.parse(this.currentUser.phone) : null);
          this.currentUser.displayPhone = this.setDisplayPhone(this.currentUser.jsonPhone);
          // this.currentUser.displayPhone = `${(this.currentUser.jsonPhone.countryCode || "")} ${this.currentUser.jsonPhone.dialCode} ${this.currentUser.jsonPhone.nationalNumber}`;
        } catch (error) {
          console.error('Phone number format wrong!');
        }
      }

      // TODO: see if I need jsonPhone property, if yes add optional' or have this only parse when using for diplay or form input
      this.userAuthneticated = true;

      // this.currentUser.initials = this.setUserInitials(this.currentUser.fullName);
      this.currentUser.initials = this.setInitials(this.currentUser.firstName, this.currentUser.lastName);
      // TODO: initials - set for use or add as optional parameter in USER
      // ToDo: check if this method should be moved somewhere else (maybe header)
      this.uiService.setDemoAndQuote(this.currentUser);
      // this.currentUser.roles.push(UserRoles.administrator);

      localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
      // this.setUserRolesStatus(this.currentUser.isTrial, this.currentUser.roles);
      this.setUserRolesStatus(false, this.currentUser.roles);
      this.highestActiveRole = this.getHighestRole();
      this.userPermissions = this.defaultUserPermissions;
      this.userPermissions = this.setUserPermissions(this.activeUserRoles);
      // this.productService.setProductsList(this.productService.fakeProducts);
      // debugger;
      this.userNav = this.userNavPermissions(this.userPermissions, this.appSettings.getUserNav());

      // this.productService.setProductCategories();
      // TODO: run function like setProductCategories, but this function would call API and only update the trialAvailable property
      this.productService.setProductsListNew(this.currentUser.company.id);
      this.productService.setLicensesFromApi();

      this.userUpdated.emit(this.currentUser);
      this.uiSettingsService.saveSettingsFromUser(this.currentUser);
    }

  }


  // * PUBLIC METHODS


  /**
   * Returs current user object IUser
  */
  public getUserInfo(): ApiUser {
    return this.currentUser;
  }

  public setIndustry(industry: string): void {
    this.currentUser.industry = industry;
  }

  /**
   * Returns user permissions object
  */
  public getPermissions(): any {
    if(this.currentUser) {
      return this.userPermissions;
    } else {
      this.userUpdated.subscribe(user => {
        return this.userPermissions;
      });
    }

  }

  /**
   * Called after user gets logged out - clear user object and it's local storage
  */
  public clearUser():void {
    this.currentUser = null;
    localStorage.removeItem('currentUser');
  }

  // * PRIVATE METHODS

  setUserRolesStatus(trial: boolean, rolesArray: string[]): void {
    this.activeUserRoles = [];
    rolesArray.forEach(role => {
      let tempRole = this.userRolesList.find(el => el.name === role);
      if(this.activeUserRoles.indexOf(tempRole) === -1) {
        if(rolesArray.find(el => el === UserRoles.administrator)) tempRole.isGlobalAdmin = true;
        this.activeUserRoles.push(tempRole);
      }

    });

    if(trial) {
      let tempRole = this.userRolesList.find(el => el.name === "trial");
      this.activeUserRoles.push(tempRole);
    }
  }

  public setInitials(firstName: string, lastName: string): string {
    return (firstName.substring(0,1) + lastName.substring(0,1)).toUpperCase();
  }

  public setUserInitials(fullName: string): string {
    let initials = fullName.match(/\b\w/g) || [];
    let initials2 = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    // var initials = fullName.split(' ').map((str) => { return str ? str[0].toUpperCase() : "";}).join('');
    return initials2;
  }

  // ! Depriciated Methds
  // ? Check if I can safely remove them

  userLoggedIn(): boolean {
    if (localStorage.getItem('currentUser')) {
      return true;
    } else {
      return false;
    }
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  // ToDo: change this public function into private one and have it called from SetUser function
  map(userModel: ApiUser, jsonInput: Object): ApiUser {
    let outputUser = new ApiUser;
    Object.keys(userModel).forEach(
        key => {
            if(key !== "role") {
              outputUser[key] = jsonInput[key] || userModel[key] || null;
            } else {
              outputUser[key] = UserRoles[jsonInput[key]];
            }

        }
    )
    return outputUser;
  }

}
