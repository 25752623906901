import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { UserApiDataPartial } from 'projects/nine-gold-app/src/app/pages/user/services/userApiData';
import { DownloadListItem } from '../models/downloads';
import { Uses } from '../models/uses';
import { FilterPipe } from '../pipes/filter.pipe';
import { OrderByPipe } from '../pipes/orderBy.pipe';
import { ButtonTypes, columnFilterTypes, ColumnTypes, ListUiParams, NgListButton, NgListColumns, NgListDataCell, NgListDataRow, sortingOrder } from '../shared/listComponents/ng-list/ng-list-data';
import { AppSettingsService } from './app-settings.service';
import { FormsService } from './forms.service';
import { ListsService } from './lists.service';
import { UserService } from './user.service';

// type listApiDataType = DownloadListItem;
type listApiDataType = Uses;

@Injectable({
  providedIn: 'root'
})
export class DownloadService extends ListsService {

  // listData: NgListDataRow[] = [];
  // rawListData: NgListDataRow[] = [];
  // fakeData: listApiDataType[] = [];
  // columns: NgListColumns[];

  uiSortFilterPage = true;

  listApiName = 'usersList';
  ApiUrl: string;

  listParameters:ListUiParams = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "started",
      order: sortingOrder.desc
    }
  }

  public loading = false;

  fakeResults = [
    {
        "username": "fwitk",
        "fullname": "John Smith",
        "params": {
            "raml_version": "v1_0"
        },
        "processed": "2021-02-08T16:51:27.317",
        "generation_id": "2c12fd3a-a288-4b1f-a023-511e26992d37",
        "project_name": "Bank_API_version2-2021",
        "project_type": "RAML 1.0 Converter",
        "download_url": "/api/ninegold/download/2c12fd3a-a288-4b1f-a023-511e26992d37/v1/asssa.zip"
    },
    {
        "username": "fwitk",
        "fullname": "Kevin Black",
        "params": {},
        "processed": "2021-02-08T16:50:01.344",
        "generation_id": "19bbfa9b-324a-41f8-acae-5dfa3fba741c",
        "project_name": "add-request.json",
        "project_type": "JSON Example",
        "download_url": "/api/ninegold/download/19bbfa9b-324a-41f8-acae-5dfa3fba741c/v1/add-request.json.zip"
    },
    {
        "username": "fwitk",
        "fullname": "John Smith",
        "params": {
            "raml_version": "v1_0"
        },
        "processed": "2021-02-08T16:49:10.763",
        "generation_id": "ed22b9bd-c126-42ca-9948-f84cb2492d27",
        "project_name": "Bank_API_version1-2021",
        "project_type": "RAML 1.0 Converter",
        "download_url": "/api/ninegold/download/ed22b9bd-c126-42ca-9948-f84cb2492d27/v1/test.zip"
    },
    {
        "username": "fwitk",
        "fullname": "Diana Johnson",
        "params": {
            "raml_version": "v1_0"
        },
        "processed": "2021-02-08T16:48:20.405",
        "generation_id": "c796a07f-91a7-4a53-8a53-521ced0c378a",
        "project_name": "Login_API_V2.2",
        "project_type": "RAML 1.0 Converter",
        "download_url": "/api/ninegold/download/c796a07f-91a7-4a53-8a53-521ced0c378a/v1/test.zip"
    },
    {
        "username": "fwitk",
        "fullname": "Kevin Black",
        "params": {
            "mappings": null,
            "platform": "MULE4",
            "raml_version": "v1_0",
            "input_filename": null,
            "output_filename": "calculator.wsdl"
        },
        "processed": "2021-02-08T09:40:43.426",
        "generation_id": "c30de2ea-5bb9-465c-bf01-d2c0957c90a9",
        "project_name": "calc",
        "project_type": "Generation",
        "download_url": "/api/ninegold/download/c30de2ea-5bb9-465c-bf01-d2c0957c90a9/v1/calc.zip"
    },
    {
        "username": "fwitk",
        "fullname": "John Smith",
        "params": {
            "raml_version": "v1_0"
        },
        "processed": "2021-01-22T18:55:19.357",
        "generation_id": "c06b7303-11dc-4b5c-b729-dc0ab2b53af4",
        "project_name": "Bank_API_version1-2020",
        "project_type": "RAML 1.0 Converter",
        "download_url": "/api/ninegold/download/c06b7303-11dc-4b5c-b729-dc0ab2b53af4/v1/test.zip"
    },
    {
        "username": "fwitk",
        "fullname": "Diana Johnson",
        "params": {},
        "processed": "2021-01-22T18:41:05.863",
        "generation_id": "aea15239-b675-4eee-99d7-ba470cf72b85",
        "project_name": "Login_API_V2.3",
        "project_type": "JSON Example",
        "download_url": "/api/ninegold/download/aea15239-b675-4eee-99d7-ba470cf72b85/v1/add-request.json.zip"
    },
    {
        "username": "fwitk",
        "fullname": "John Smith",
        "params": {},
        "processed": "2021-01-22T18:38:10.293",
        "generation_id": "29a08622-67c1-4316-ad81-299a3b6f519d",
        "project_name": "Bank_API_test",
        "project_type": "JSON Example",
        "download_url": "/api/ninegold/download/29a08622-67c1-4316-ad81-299a3b6f519d/v1/add-request.json.zip"
    },
    {
        "username": "fwitk",
        "fullname": "Kevin Black",
        "params": {
            "raml_version": "v1_0"
        },
        "processed": "2021-01-22T11:09:38.015",
        "generation_id": "aecb8a6c-312a-4030-a8f0-edf4b36c22b3",
        "project_name": "llll",
        "project_type": "RAML 1.0 Converter",
        "download_url": "/api/ninegold/download/aecb8a6c-312a-4030-a8f0-edf4b36c22b3/v1/llll.zip"
    },
    {
        "username": "fwitk",
        "fullname": "Diana Johnson",
        "params": {
            "raml_version": "v1_0"
        },
        "processed": "2021-01-22T11:09:06.265",
        "generation_id": "5eac3265-1675-44ab-8dda-5d99debc9c98",
        "project_name": "Login_API_V2.2",
        "project_type": "RAML 1.0 Converter",
        "download_url": "/api/ninegold/download/5eac3265-1675-44ab-8dda-5d99debc9c98/v1/llll.zip"
    }
];


  apiUrl = "http://restgen-9g-dev.diamondedge-it.com:8081";
  apiDownloadsList = this.apiUrl + "/api/user/downloads";  // this needs to come from config

  parametersUpdated: EventEmitter<{}> = new EventEmitter<{}>();


  setApiName(): void {
    this.listApiName = 'currentCompanyUsesList';
    // this.ApiFunctionName = 'getUsesByProduct';
    // this.ApiParameter = 'restgen'
    this.ApiFunctionName = 'getUsesBYCategory';
    this.ApiParameter = 'rest_generator';
  }

  setSortSettings(): void {
    this.uiSortFilterPage = true;
  }

  setInitialColumns(): void {
    this.columns = [
      {
        columnName: 'id',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: null,
        rowIdentifier: true,
        notVisible: true
      },
      {
        columnName: 'productId',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: null,
        notVisible: true
      },
      {
        columnName: 'projectName',
        columnText: 'Project Name',
        columnType: ColumnTypes.text,
        sortable: true,
        sortColumnName: 'projectName',
        filters: null,
        columnOrder: 0,
        notVisible: false,
        clickForInfo: false
      },
      // TODO: have product name for all download instances, but not for rest generator
      {
        columnName: 'status',
        columnText: 'Status',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: {
          filterId: 'status',
          filterName: 'Status',
          filterType: columnFilterTypes.options,
          filterPlaceholder: 'Choose status',
          optionSelected: null,
          filterOptions: [
            { value: 'completed', text: 'Completed'},
            { value: 'processing', text: 'Processing'},
            { value: 'error', text: 'Error'},
          ]
        },  // TODO: add filters (get statuses from Dave)
        columnOrder: 1,
        notVisible: false,
        canShowHide: true,
        displayFunction: 'setDownloadStatusText',
        tdHtml: true
      },
      {
        columnName: 'user',
        columnText: 'User',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,  // TODO: add auto filter based on all users from list
        columnOrder: 2,
        customMapping: ['user','userName'],
        notVisible: false,
        canShowHide: true,
        // TODO: user value is userId from custom mapping
        // TODO: display is username
        // TODO: add ColumnTypes.user, where it is clickable - get user popup, on hover there is email button, phone, info and filterBy (added by adding actions - standard actions in lists.service)
      },
      {
        columnName: 'resultsMessage',
        columnText: 'Results',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: 3,
        notVisible: true,
        canShowHide: true,
        customMapping: ['results','message']
      },
      {
        columnName: 'started',
        columnText: 'Date',
        columnType: ColumnTypes.date,
        sortable: true,
        sortColumnName: 'started',
        filters: null,
        columnOrder: 4,
        notVisible: false,
        canShowHide: true
      },
      // {
      //   columnName: 'completed',
      //   columnText: 'Completed',
      //   columnType: ColumnTypes.date,
      //   sortable: true,
      //   sortColumnName: 'completed',
      //   columnOrder: 5,
      //   filters: null,
      //   notVisible: true,
      //   canShowHide: true
      // },
      {
        columnName: 'fileName',
        columnText: 'File',
        columnType: ColumnTypes.file_download,
        sortable: false,
        columnOrder: 6,
        filters: null,
        notVisible: false,
        canShowHide: true,
        customMapping: ['results','completedFile']
        // TODO: add file download type and make it work
      },
      {
        columnName: 'info',
        columnText: '',
        columnType: ColumnTypes.info,
        sortable: false,
        filters: null,
        noHeader: true
      },
      // {
      //   columnName: 'empty',
      //   columnText: '',
      //   columnType: ColumnTypes.empty,
      //   sortable: false,
      //   filters: null
      // }
      // {
      //   columnName: 'actions',
      //   columnText: 'Actions',
      //   columnType: ColumnTypes.buttons,
      //   columnClasses: 'fit-content',
      //   sortable: false,
      //   columnOrder: 10,
      //   filters: null,
      //   buttons: [
          // {
          //   buttonText: 'Info',
          //   value: null,
          //   type: ButtonTypes.info
          // },
      //     {
      //       buttonText: 'Download result',
      //       value: null,
      //       type: ButtonTypes.download,
      //       showButtonFunction: 'canDownloadResultFile'
      //     }
      //   ]
      // }
      // {
      //   columnName: 'fullname',
      //   columnText: 'Full Name',
      //   columnType: ColumnTypes.text,
      //   sortable: false,
      //   filters: null,
      //   columnOrder: 0
      // },
      // {
      //   columnName: 'project_name',
      //   columnText: 'Project Name',
      //   columnType: ColumnTypes.text,
      //   sortable: true,
      //   sortColumnName: 'project_name',
      //   filters: null,
      //   columnOrder: 1
      // },
      // {
      //   columnName: 'project_type',
      //   columnText: 'API',
      //   columnType: ColumnTypes.text,
      //   sortable: false,
      //   // filters: null,
      //   filters: this.settings.getDownloadFilters(),
      //   columnOrder: 2,
      // },
      // {
      //   columnName: 'processed',
      //   columnText: 'Date',
      //   columnType: ColumnTypes.date,
      //   sortable: true,
      //   sortColumnName: 'generated',
      //   filters: null,
      //   columnOrder: 3
      // },
      // {
      //   columnName: 'download_url',
      //   columnText: 'Download',
      //   columnType: ColumnTypes.buttons,
      //   sortable: false,
      //   filters: null,
      //   columnOrder: 4,
      //   buttons: [
      //     {
      //       buttonText: 'Download',
      //       type: ButtonTypes.download,
      //       value: null
      //     }
      //   ]
      // },
      // {
      //   columnName: 'generation_id',
      //   columnText: '',
      //   columnType: ColumnTypes.text,
      //   sortable: false,
      //   filters: null,
      //   notVisible: true
      // },
      // {
      //   columnName: 'params',
      //   columnText: '',
      //   columnType: ColumnTypes.text,
      //   sortable: false,
      //   filters: null,
      //   notVisible: true,
      //   customMapping: ['params','raml_version']
      // },
      // {
      //   columnName: 'username',
      //   columnText: '',
      //   columnType: ColumnTypes.text,
      //   sortable: false,
      //   filters: null,
      //   notVisible: true
      // }
    ]
  }

  setApiDataType(): void {
    type listApiDataType = UserApiDataPartial;
  }

  updateListParams(): void {
    this.listParameters = {
      numberOfPages: 1,
      currentPage: 1,
      itemsPerPage: 10,
      filters: [],
      sorting: {
        field: "started",
        order: sortingOrder.desc
      }
    }
  }

  canDownloadResultFile(row: listApiDataType) {
    return !!row.results?.completedFileDownload;
  }


  setDownloadStatusText(status: string): string {
    return `<span class="download-status download-status-${status}">${status}<span>`
  }


  MapParameters(defaultParams: {}): any {
    let apiParams = {
      start: (defaultParams['currentPage'] - 1) * defaultParams['itemsPerPage'] + 1,
      limit: defaultParams['itemsPerPage'],
      filtering: defaultParams['filters'],
      ordering: [defaultParams['sorting']]
    }
    return apiParams;
  }

  // CreateColumns1(): NgListColumns[] {

  //   //  Creating columns object based on initial data
  //   //  TODO: move filters data to project setup (eventually these needs to be received from API) - the filters are generator types
  //   function get_tex_width(txt, font) {
  //     var element = document.createElement('canvas');
  //     var context = element.getContext("2d");
  //     context.font = font;
  //     return context.measureText(txt).width;
  //   };

  //   function addColumnClasses(columns: NgListColumns[]): NgListColumns[] {
  //     columns.forEach(col => {
  //       col.columnClasses = (col.filters ? "filters " : "") +
  //                           (col.sortable ? "sortable " : "") +
  //                           (col.columnType === ColumnTypes.date ? "date-column " : "");// +
  //       if(col.buttons) {
  //         let columnTextWidth = get_tex_width(col.columnText, "16px NunitoSans-Bold");
  //         console.log(col.columnText + ": " + columnTextWidth);
  //         if(col.buttons.length === 1 && columnTextWidth < (112 - 20)) {
  //           col.columnClasses += "one-button";
  //         } else if(col.buttons.length <= 2 && columnTextWidth < (128 - 20)) {
  //           col.columnClasses += "two-buttons";
  //         } else if(col.buttons.length <= 3 && columnTextWidth < (144 - 20)) {
  //           col.columnClasses += "three-buttons";
  //         }
  //       }
  //       // col.sortClasses = (col.sortable ? "sort" : "");

  //       col.columnClasses.trim();
  //     });
  //     return columns;
  //   }

  //   let columns: NgListColumns[] = [
  //     {
  //       columnName: 'fullname',
  //       columnText: 'Full Name',
  //       columnType: ColumnTypes.text,
  //       sortable: false,
  //       filters: null,
  //       columnOrder: 0
  //     },
  //     {
  //       columnName: 'project_name',
  //       columnText: 'Project Name',
  //       columnType: ColumnTypes.text,
  //       sortable: true,
  //       sortColumnName: 'project_name',
  //       filters: null,
  //       columnOrder: 1
  //     },
  //     {
  //       columnName: 'project_type',
  //       columnText: 'Generator',
  //       columnType: ColumnTypes.text,
  //       sortable: false,
  //       filters: this.settingsService.getDownloadFilters(),
  //       // filters: {
  //       //   filterName: 'Generator Type',
  //       //   filterType: columnFilterTypes.options,
  //       //   filterPlaceholder: "All",
  //       //   optionSelected: null,
  //       //   filterOptions: this.settingsService.getDownloadFilters()
  //       //   filterOptions: [
  //       //     {
  //       //       value: 'MULE4',
  //       //       text: 'Generator'
  //       //     },
  //       //     {
  //       //       value: 'JSON_EXAMPLE',
  //       //       text: 'JSON Example'
  //       //     },
  //       //     {
  //       //       value: 'TOOLS_RAML10',
  //       //       text: 'RAML 1.0 Converter'
  //       //     }
  //       //   ]
  //       // },
  //       columnOrder: 2,
  //     },
  //     {
  //       columnName: 'processed',
  //       columnText: 'Date',
  //       columnType: ColumnTypes.date,
  //       sortable: true,
  //       sortColumnName: 'generated',
  //       filters: null,
  //       columnOrder: 3
  //     },
  //     {
  //       columnName: 'download_url',
  //       columnText: 'Download',
  //       columnType: ColumnTypes.buttons,
  //       sortable: false,
  //       filters: null,
  //       columnOrder: 4,
  //       buttons: [
  //         {
  //           buttonText: 'Download',
  //           type: ButtonTypes.download,
  //           value: null
  //         }
  //       ]
  //     }
  //   ];

  //   columns = addColumnClasses(columns);
  //   return columns;
  // }

  // MapData(resultsData: any[], columns: NgListColumns[]): NgListDataRow[] {
  //   let rows: NgListDataRow[] = [];
  //   resultsData.forEach(resultRow => {
  //     let row = <NgListDataRow>{};
  //     row['listRow'] = [];
  //     // this is because of row having identified
  //     // maybe change this so column would have identifier property assign to it
  //     Object.keys(resultRow).forEach(key => {
  //       let element = <NgListDataCell>{};
  //       let column = columns.find(x => x.columnName === key);
  //       if(column) {
  //         element.columnName = column.columnName;
  //         element.columnOrder = column.columnOrder;
  //         element.type = column.columnType;

  //         if(column.columnType === ColumnTypes.buttons) {
  //           element.buttons = [];
  //           column.buttons.forEach(btn => {
  //             let cellBtn = <NgListButton>{};
  //             cellBtn.buttonText = btn.buttonText;
  //             cellBtn.type = btn.type;
  //             cellBtn.value = this.apiUrl + resultRow[key];
  //             element.buttons.push(cellBtn);
  //           });
  //         } else {
  //           element.value = resultRow[key];
  //           element.display = resultRow[key];
  //         }
  //         row.listRow.push(element);
  //       }
  //     });
  //     row.listRow.sort((a, b) => a.columnOrder - b.columnOrder );
  //     row.rowIdentifier = resultRow.generation_id;
  //     rows.push(row);
  //   });
  //   return rows;
  // }

  // async LoadTableData(fromApi = false):Promise<void> {
  //   this.loadResultsStarted.emit();
  //   // let apiParams = this.MapParameters(this.listParameters);
  //   this.listData = [];
  //   // var token = this.userService.getToken();

  //   // ! This it temporary code - remove after API is working

  //   this.listData = this.MapData(this.fakeResults, this.columns);
  //   this.listParameters.numberOfPages = Math.ceil(68 / this.listParameters.itemsPerPage);
  //   this.loadResultsEnded.emit();
  //   this.parametersUpdated.emit(this.listParameters);
  //   this.SetResultsText(68);

  //   // ! End of temporary code - uncommnet code below
  //   // this.formService.postForm(this.apiDownloadsList, apiParams, token).subscribe({
  //   //   next: result => {
  //   //     setTimeout(() => {
  //   //       let list = result['data'];
  //   //       this.listData = this.MapData(result['data'], this.columns);
  //   //       this.listParameters.numberOfPages = Math.ceil(result['totalRecords'] / this.listParameters.itemsPerPage);
  //   //       this.loadResultsEnded.emit();
  //   //       this.parametersUpdated.emit(this.listParameters);
  //   //       this.SetResultsText(result['totalRecords']);
  //   //     }, 0)

  //   //   },
  //   //   error: (err: any) => console.log(err)  // this needs to change to reporting
  //   // })
  // }

  // GetItemsPerPage(): number {
  //   return this.listParameters.itemsPerPage;
  // }

}
