import { Component, OnInit, OnChanges, Input, ViewEncapsulation, Output, EventEmitter, SimpleChanges, Renderer2, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IFormField, InputTypes } from '../../models/formField';
import { FormsService } from '../../services/forms.service';
import { debounceTime } from 'rxjs/operators';


import { faCompressArrowsAlt, faExpand, faExpandArrowsAlt, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { trigger, transition, animate, style, state } from '@angular/animations';
import { CustomValidators } from '../../services/custom.validator';
import { NotificationService } from '../../shared/notification/notification.service';
import { FormResultMessageFormat } from '../../shared/notification/notification';

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MonacoEditorModule, NgxEditorModel, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
// import { CodeModel } from '@ngstack/code-editor';
import { IDisposable } from 'monaco-editor';
// import { CodeEditorService } from '@ngstack/code-editor';

// export interface CodeModel {
//   language: string;
//   value: string;
//   uri: string;

//   dependencies?: Array<string>;
//   schemas?: Array<{
//     uri: string;
//     schema: Object;
//   }>;
// }

@Component({
  selector: 'lib-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({opacity: 0, transform: "translate(-10px, 0)"}),
        animate('300ms ease-in', style({opacity: 1, transform: "translate(0, 0)"}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({opacity: 0, transform: "translate(-10px, 0)"}))
      ])
    ]),
    trigger('toggleOpacity', [
      state('text', style({
          opacity: '0',
          visibility: 'hidden'
      })),
      state('password', style({
          opacity: '1',
          visibility: 'visible'
      })),
      transition('password => text', animate('200ms ease-in-out')),
      transition('text => password', animate('200ms ease-in-out'))
  ])
  ]
})



export class FormFieldComponent implements OnInit {
  @Input() fieldSettings: IFormField;
  @Input() formGroupParameter: FormGroup;
  @Input() rightSideErrors: boolean = false;
  @Input() value: string = "";
  @Input() index: number = null;
  @Input() arrayName: string = null;
  @Input() small: boolean = false;
  @Input() disabled: boolean = false;
  @Input() noLabel: boolean = false;
  @Input() inlineLayout = false;
  @Input() minBottomMargin = false;
  @Input() noBottomMargin = false;
  @Input() selectAddButton = false;
  @Input() selectAddButtonText = "";
  @Input() selectFooterTempalte: TemplateRef<any>;
  @Input() minLabelWidth = 0;
  @Input() inlineInstruction = false;

  @Output() enterKey: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectAddFunction: EventEmitter<any> = new EventEmitter<any>();

  @Output() blurEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() dropdownChanged: EventEmitter<IFormField> = new EventEmitter<IFormField>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKey: EventEmitter<any> = new EventEmitter<any>();

  @Output() onValidation: EventEmitter<boolean> = new EventEmitter<boolean>();



  // New code editor
  // ceTheme = 'vs-dark';

  // ceModel: CodeModel = {
  //   language: 'json',
  //   uri: 'main.json',
  //   value: ''
  // };

  // ceOptions = {
  //   contextmenu: true,
  //   minimap: {
  //     enabled: true
  //   }
  // };

  // Code editor ends

  fieldValid = false;
  hasFocus = false;
  passwordFieldType = 'password';
  charCount = 0;

  phoneInitiated = false;

  @ViewChild('Selecter') ngselect: NgSelectComponent;
  @ViewChild('addValue') addValue: ElementRef;

  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;

  usHolidays = ["11/24/2022","12/26/2022","01/02/2023","01/15/2023","02/20/2023","05/29/2023","06/19/2023","07/04/2023","09/04/2023","10/09/2023","11/10/2023","11/23/2023","12/25/2023"];
  disabledDates: Date[] = [];


  faEyeSlash = faEyeSlash;
  faEye = faEye;
  faQuestionCircle = faQuestionCircle;
  fullScreenIcon = faExpand;
  resetScreenIcon = faCompressArrowsAlt


  @Input() minDate: Date = new Date();
  @Input() noMinDate: Boolean = false;
  @Input() maxDate: Date = null;
  @Input() noMaxDate: Boolean = false;
  @Input() daysFromToday: number = null;

  @Input() minNumber: number = null;
  @Input() maxNumber: number = null;

  @Input() disableUsHolidays = false;
  @Input() noWeekend = true;

  editor: any;
  codeCopied = false;
  editorOptions = {theme: 'vs-dark', readOnly: false, language: 'json', fontSize: "16px", automaticLayout: true};
  listenerFn: any;
  ESCAPE_KEYCODE = 27;
  globalListenFunc: Function;
  codeFsAnimating = false;

  dateRequiredError = false;

  showMonacoPlacehoder = true;




  // code: string= 'function x() {\nconsole.log("Hello world!");\n}';
  codeFullScreen = false;

  onInit(editor) {
    // console.log((window as any).monaco);
    let line = editor.getPosition();
    this.editor = editor;

    // let model = this.editor.getModel();
    // console.log(model)


    // editor.setModelLanguage(editor.getModel(), this.fieldSettings.codeLanguage);
    // console.log(`model language was changed to ${editor.getModel().getLanguageIdentifier().language}`);
    // var modelUri = monaco.Uri.parse('a://b/foo.json'); // a made up unique URI for our model
    this.editorOptions.language = this.fieldSettings.codeLanguage;


    this.editor.onDidFocusEditorWidget(()=>{
      this.showMonacoPlacehoder = false;
    })

    this.editor.onDidBlurEditorWidget(()=>{
      // console.log(this.editor.getValue())
      if (!this.editor.getValue()) this.showMonacoPlacehoder = true;
    })


    this.editor.onDidChangeModelContent(() => {
      this.codeCopied = false;
      // console.log(editor)
      const model = this.editor.getModel();
      var textToValidate = model.getValue();
      // console.log(textToValidate)
      if (model === null)
          return;


      const owner = model.getModeId();
      setTimeout(() => {
        // console.log((window as any).monaco.editor.getModelMarkers());
      }, 1000);
    });
  }

  constructor(private formService: FormsService,
    private notService: NotificationService,
    private renderer: Renderer2) {
  }

  hideMonacoPlaceholder(): void {
    this.showMonacoPlacehoder = false;
    this.editor.focus();
  }


  onFileDropped($event):void {
    // console.log($event);
    let file = $event[0];
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      // console.log(fileReader.result);
      this.getField().setValue(fileReader.result.toString());
      // this.value = fileReader.result.toString();
    }
    fileReader.readAsText(file);

    // this.fileDropVariable.nativeElement.files = $event;
    // this.prepareFilesList($event);
  }

  onCodeChanged(value) {
    // console.log('CODE', value);
  }

  changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}

  keyEntered(): void {
    this.enterKey.emit();
  }

  textInput(): void {
    this.onKey.emit();
  }

  SelectButtonAction(event): void {
    // event.target.nativeElement.blur();
    // let target = event.target || event.srcElement || event.currentTarget;
    // target.blur();
    // let value = this.addValue.nativeElement.value
    setTimeout(() => {
      this.selectAddFunction.emit();
      this.ngselect.close();
    }, 20);

    // this.disabled = true;
  }

  public ngOnChanges(changes: SimpleChanges) {
    // console.log("changes");
    // console.log(changes);
    if(this.fieldSettings.inputType === InputTypes.tel && 'disabled' in changes) {
      let parent = document.getElementById('phoneId');
      // parent.getElementsByTagName('input')[0].setAttribute('disabled',(this.disabled ? 'disabled' : null));
      if(parent) {
        if(this.disabled) {
          parent.getElementsByClassName('iti')[0].classList.add('disabled');
          parent.getElementsByTagName('input')[0].setAttribute('disabled','disabled');
        } else {
          parent.getElementsByClassName('iti')[0].classList.remove('disabled');
          parent.getElementsByTagName('input')[0].removeAttribute('disabled');
        }
      }


    } else if('disabled' in changes) {
      // console.log(changes);
      this.fieldSettings.disabled = changes.disabled.currentValue;
      if(this.fieldSettings.inputType === InputTypes.select) {
        if(changes.disabled.currentValue) {
          this.formGroupParameter.get(this.fieldSettings.inputName).disable();
          this.ngselect.close();
        } else {
          this.formGroupParameter.get(this.fieldSettings.inputName).enable();
        }

      }
    }
    if(this.fieldSettings.inputType === InputTypes.code) {
      this.editorOptions = { ...this.editorOptions, readOnly: this.disabled };
    }
    if('value' in changes && this.fieldSettings.inputType === InputTypes.tel) {
      if(!this.phoneInitiated) {
        this.getField().markAsPristine();
        this.phoneInitiated = true;
      }
    }
    if ('value' in changes && this.fieldSettings.inputType !== InputTypes.tel) {
      if(this.value !== null) {
        this.getField().patchValue(this.value);
        this.validationOnKey(this.value);
      } else {
        this.validationOnChangeFromOutside();
      }

    }

    // this.doSomething(changes.categoryId.currentValue);
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values

  }

  ngOnInit(): void {

    // console.log(this.arrayName)

    if(this.fieldSettings.inputType === InputTypes.date) {
      if(!this.noMinDate) {
        this.minDate.setDate(this.minDate.getDate());
      } else {
        this.minDate = null;
      }


      if(!!this.daysFromToday) {
        this.maxDate = new Date();
        if(!this.noMaxDate) {
          this.maxDate.setDate(this.maxDate.getDate() + this.daysFromToday);
        } else {
          this.maxDate = null;
        }

      }

      if(this.disableUsHolidays) {
        this.disabledDates = [];
        this.usHolidays.forEach(holiday => {
          this.disabledDates.push(new Date(holiday));
        });
        // console.log(this.disabledDates)
      }
    }
    // console.log(this.fieldSettings);
    if(this.fieldSettings.inputType === InputTypes.number) {
      let minRule = this.fieldSettings.validationRules.find(x => x.type === 'min');
      if(minRule) {
        this.minNumber = minRule.parameter;
      }
      let maxRule = this.fieldSettings.validationRules.find(x => x.type === 'max');
      if(maxRule) {
        this.maxNumber = maxRule.parameter;
      }
    }
    // if(this.fieldSettings.inputType === InputTypes.code) {
    //   this.editorOptions.readOnly = this.disabled;
    // }
    if(this.fieldSettings.inputType === InputTypes.tel && this.disabled) {
      setTimeout(() => {
        let parent = document.getElementById('phoneId');
        parent.getElementsByTagName('input')[0].setAttribute('disabled','disabled');
        parent.getElementsByClassName('iti')[0].setAttribute('ng-reflect-is-disabled', 'disabled');
      }, 500);

      // this.phoneField.elRef.nativeElement.firstChild.children[0].disabled = 'true';
    }
    if(this.fieldSettings.inputType === InputTypes.select && this.getField().value === "") {
      this.getField().patchValue(null);
    }


    if(this.fieldSettings.inputType === InputTypes.tel) {
      this.getField().valueChanges.subscribe(
        value => {
          if(!this.phoneInitiated) {
            this.getField().markAsPristine();
            this.phoneInitiated = true;
          }
          this.setMessage();
        }
      );
    } else {
      this.getField().valueChanges.pipe(debounceTime(500)).subscribe(
        value => {
          // console.log('VALUE CHANGES');
          this.setMessage()
        }
      )
    }





    this.getField().statusChanges.pipe(debounceTime(500)).subscribe(
      value => {
        // console.log("STATUS CHANGES")
        this.setMessage()
      }
    );
    if(this.fieldSettings.inputType === InputTypes.select && this.fieldSettings.disabled === true) {
      this.formGroupParameter.get(this.fieldSettings.inputName).disable();
    }

    // this.formGroupParameter.get(this.fieldSettings.inputName).valueChanges.pipe(debounceTime(500)).subscribe(
    //   value => this.setMessage()
    // );
    // this.formGroupParameter.get(this.fieldSettings.inputName).statusChanges.pipe(debounceTime(500)).subscribe(
    //   value => this.setMessage()
    // );
  }

  monacoFocus():void {
    console.log('focus');
  }

  monacoBlur():void {
    console.log('blur');
  }

  selectChanged(event: any): void {
    this.fieldSettings.value = event;
    this.dropdownChanged.emit(this.fieldSettings);
  }

  getFormGroupName(): FormGroup {
    return (this.arrayName ?
      this.formGroupParameter.controls[this.arrayName]['controls'][this.index] :
      this.formGroupParameter)
  }

  getField(): FormControl {
    return (this.arrayName ?
      this.formGroupParameter.controls[this.arrayName]['controls'][this.index].get(this.fieldSettings.inputName) :
      this.formGroupParameter.get(this.fieldSettings.inputName))
  }

  getId():string {
    return (this.index ? this.fieldSettings.id + this.index : this.fieldSettings.id);
  }

  togglePassword(type: string): void {
    this.passwordFieldType = type;
  }

  hasError(error: string): boolean {
    return this.getField().get(this.fieldSettings.inputName).hasError(error) || this.getField().value === "";
    // return this.formGroupParameter.get(this.fieldSettings.inputName).hasError(error) || this.formGroupParameter.get(this.fieldSettings.inputName).value === "";
  }

  inputChanged(value: any) {
    this.validationOnBlur()
    this.onChange.emit();
  }

  getObjectKeys(arg) {
    return Object.keys(arg);
  }

  checkInputType(types: string[]): boolean {
    return types.some(t => t === this.fieldSettings.inputType.toString());
  }

  requiredInvalid(): boolean {
    // console.log("REQUIRED")
    if(this.fieldSettings.inputType === InputTypes.tel) {
      return this.getField().touched && this.getField().errors?.required;
    } else if(this.fieldSettings.inputType === InputTypes.date) {
      return false;
    } else {
      return (this.getField().touched || this.getField().dirty) && this.getField().errors?.required;
    }
    // return (this.formGroupParameter.get(this.fieldSettings.inputName).touched || this.formGroupParameter.get(this.fieldSettings.inputName).dirty) && this.formGroupParameter.get(this.fieldSettings.inputName).errors?.required;

  }



  validationOnKey(value) {
    // console.log('VALIDATION ON KEY');
    this.fieldValid = false;
    // console.log(value.inputType);
    if(value.inputType === 'insertText') {
      if(this.fieldSettings.inputType === InputTypes.tel) {
        // this.formGroupParameter.get(this.fieldSettings.inputName).setValue(this.correctPhoneFormat(value));
      }
      // add instant validation, setMessage() for errors like: maxwidth, number, max, min, (all that do not need time for user)
      // if minLenght validation and lenght is exactly as min lenght set message
      // if max Length validaiotn and lenght is 1 more of max set message
    } else if(value.inputType === "deleteContentBackward") {
      if(this.fieldSettings.inputType === InputTypes.tel) {
        // this.formGroupParameter.get(this.fieldSettings.inputName).setValue(this.correctPhoneFormat(value));
      }
      this.setMessage();
      // if minLenght validation and lenght 1 less than min lenght -> set message
      // if max Length validaiotn and lenght is exactkt as max -> set message
    } else if(value.type === "input") {
      if(this.fieldSettings.inputType === InputTypes.tel) {
        // this.formGroupParameter.get(this.fieldSettings.inputName).setValue(this.correctPhoneFormat(value));
      }
    }
    this.charCount = this.getField().value?.length;
  }

  correctPhoneFormat(value: any): string {
    // console.log('CORRECT PHONE FORMAT');
    // ToDos
    // maybe use (111) 122 - 1313 format
    // or 121 - 121 - 1212
    // maybe add checkbox for non US number
    // or we are going to support phone numbers from around the word use plugin
    let fieldValue = this.getField().value;
    if(value.inputType) {
      if(value.data === null) {
        if (fieldValue.length === 3 || fieldValue.length === 7) {
          return fieldValue.substr(0,fieldValue.length - 1);
        } else {
          return fieldValue;
        }
      } else {
        if((!isNaN(value.data)) && (fieldValue.length <= 12)) {
          if (fieldValue.length === 3 || fieldValue.length === 7) {
            return fieldValue + "-";
          } else {
            return fieldValue;
          }
        } else {
          return fieldValue.substr(0,fieldValue.length - 1);
        }
      }
    } else {
      if(fieldValue.length === 11 && fieldValue.substr(0,1) === "1") {
        return fieldValue.substr(1,3) + "-" + fieldValue.substr(4,3) + "-" + fieldValue.substr(7,4);
      } else {
        return fieldValue.substr(0,3) + "-" + fieldValue.substr(3,3) + "-" + fieldValue.substr(6,4);
      }

    }
    return "";
  }


  setMessage(): void {
    // console.log('SET MESSAGE');
    this.fieldSettings.errorMessages = [];
    let field = this.getField();
    if ((field.touched || field.dirty) && field.errors && this.fieldSettings.errorMessagesList) {
      this.fieldSettings.errorMessages = Object.keys(field.errors).map(
        key => this.fieldSettings.errorMessagesList[key]);
    }
    if(this.fieldSettings.errorMessages[0] === undefined) this.fieldSettings.errorMessages = [];
    this.onValidation.emit(!!this.fieldSettings.errorMessages);

  }

  onFocus() {
    this.hasFocus = true;
    var elmnt = document.getElementById(this.fieldSettings.id);
    elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  validationOnChangeFromOutside() {
    let field = this.getField();

    if(field.pristine && field.untouched && !field.value) {
      this.fieldValid = false;
    }
  }

  validationOnBlur() {
    // console.log('VALIDATION ON BLUR');
    this.hasFocus = false;

    if (typeof this.getField().value === 'string' || this.getField().value instanceof String) this.getField().setValue(this.getField().value?.trim());

    let field = this.getField();
    this.charCount = this.getField().value?.length;
    this.fieldValid = false;
    if(this.fieldSettings.errorMessagesList?.hasOwnProperty("passwordconfirm") && field.parent.errors?.passwordconfirm === true) {
      field.setErrors({'passwordconfirm': true});
    }
    if ((field.touched || field.dirty) && field.errors) {
      this.fieldValid = false;

      this.setMessage();
    } else {
      if(field.valid && field.value === "") {

      } else {
        this.fieldValid = true;
      }

    }
    if(this.fieldSettings.inputType === InputTypes.date) {
      this.dateRequiredError = !field.value;
    }
    this.blurEvent.emit();
  }

  copyToClipboard(): void {
    // var setModelMarkers = this.editor.setModelMarkers;
    // this.editor.setModelMarkers = function (model, owner, markers) {
    //   setModelMarkers.call(monaco.editor, model, owner, markers);
    //   if (markers.length == 0) {
    //     //there are no errors
    //   }
    //   else
    //   {
    //     //there are errors
    //   }
    // }

    // this.editor.onDidChangeModelContent(() => {
    //   this.codeCopied = false;
    //   const model = this.editor.getModel();
    //   if (model === null)
    //       return;

    //   const owner = model.getModeId();
    // });


    if(!this.codeCopied) {
      navigator.clipboard.writeText(this.editor.getValue());
      this.codeCopied = true;
      this.notService.info("Code Copied", "Your current code has been copied to clipboard.", FormResultMessageFormat.popup, 2000);

      // this.listenerFn = this.editor.onDidChangeModelContent((event) => {
      //   console.log(event);
      //   this.codeCopied = false;
      //   this.listenerFn.dispose();
      // });
    }
  }

  codeFullScreenToggle(): void {
    if (!this.codeFullScreen) {
      // this.codeFullScreen = true;
      // this.globalListenFunc = this.renderer.listen('window', 'keydown', e => {
      //   if (e.keyCode === this.ESCAPE_KEYCODE) {
      //     e.preventDefault();
      //     this.codeFullScreen = false;
      //     this.globalListenFunc();
      //     this.editor.focus();
      //   }
      // });

      let elem = document.getElementById(this.fieldSettings.id);
      // function openFullscreen() {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        // } else if (elem.webkitRequestFullscreen) { /* Safari */
        //   elem.webkitRequestFullscreen();
        // } else if (elem.msRequestFullscreen) { /* IE11 */
        //   elem.msRequestFullscreen();
        }
      // }
      // this.renderer.addClass(document.body,'full-screen-code');
      // setTimeout(() => {
      //   this.codeFsAnimating = true;
      //   setTimeout(() => {
      //     this.codeFsAnimating = false;
      //   }, 8000);
      // }, 100);
      this.editor.focus();
    } else {
      this.renderer.removeClass(document.body,'full-screen-code');
      this.codeFullScreen = false;
      this.globalListenFunc();
      this.editor.focus();
    }

  }
}
