import { Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormSummary } from '../directives/form-summary/form-summary';
import { IFormField, InputTypes } from '../models/formField';
import { ConfirmDialog } from '../shared/confirm-dialog/confirm-dialog';
import { ConfirmDialogService } from '../shared/confirm-dialog/confirm-dialog.service';
import { FormResultMessageFormat } from '../shared/notification/notification';
import { AppSettingsService } from './app-settings.service';
import { FormsService } from './forms.service';
import { UserInterfaceService } from './user-interface.service';
import { UserService } from './user.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

export interface ApiMapping {
  ApiProperty: string;
  FieldName: string;
  Disabled?: boolean;
}

export interface ReversedApiMapping {
  responseApi: string,
  FieldName: string
}

@Injectable({
  providedIn: 'root'
})

export class ApplicationFormService implements OnInit {
  formTemp: Observable<IFormField[]>; // TODO: check if I need this
  formDownloaded: boolean = false;  // tru when form is created

  fieldsSummary: FormSummary[];

  customSuccess = false;

  formName: string;

  public loading = false; // true when http call in progress
  public formData: FormGroup; // reactive form
  public formSettings = {} // form display settings, object of IForm fields

  private initialFormValues = {}  // values of empty form, used when clearing or reloading form

  @Output() formCreated: EventEmitter<any> = new EventEmitter<any>(); // event emitted when initial form is created and loaded
  @Output() formReset: EventEmitter<any> = new EventEmitter<any>(); // event emitted when form is cleared
  @Output() formUpdated: EventEmitter<any> = new EventEmitter<any>();

  formApiUrl: string; // API URL
  private ApiData = {}  // placeholder for API data POSTed to API

  preFormData: IFormField[]; // form fields display data used when creating new form

  ApiDataMapping: ApiMapping[] = [];
  reversedApiMapping: ReversedApiMapping[] = [];

  constructor(
    public formService: FormsService,
    public userService: UserService,
    private confirmService: ConfirmDialogService,
    private fb: FormBuilder,
    public appSettingsService: AppSettingsService,
    private uiService: UserInterfaceService,
    public http: HttpClient
  ) {
    this.ApiDataMapping = this.setApiDataMapping()
    this.formName = this.setFormName();
    // this.formApiUrl = this.appSettingsService.getApi('appUse',this.userService.getCompanyId(),this.formName);
    this.setApiUrl();
    this.createForm();
    this.setInitialFieldsSummary();
  }

  public setApiUrl(formName?: string): void {
    formName = formName || this.formName;
    this.formApiUrl = this.appSettingsService.getApi('appUse',this.userService.getCompanyId(),formName);
  }

  updateFieldSummary(key: string, text?: string, value?: any, valid?: boolean): void {
    // console.log(`key: ${key}, text: ${text}, value: ${value}, valid: ${valid}`);
    let displayText;
    let fieldValue = value || this.formData.controls[key].value;
    if(text !== undefined) {
      displayText = text;
    } else {
      if(this.formSettings[key].inputType === InputTypes.select) {
        // console.log(this.formSettings[key].options);
        // console.log(fieldValue)
        displayText = this.formSettings[key].options.find(o => o.value === fieldValue)?.text;
      // } else if(this.formSettings[key].inputType === InputTypes.code) {
      //   displayText = 'CODE';
      } else {
        displayText = this.formData.controls[key].value;
      }
    }
    this.fieldsSummary.find(f=>f.name === key).text = displayText;
    this.fieldsSummary.find(f=>f.name === key).value = fieldValue;
    this.fieldsSummary.find(f=>f.name === key).valid = valid || this.formData.controls[key].valid;
  }

  hideFieldSummary(key: string): void {
    this.fieldsSummary.find(f=>f.name === key).hidden = true;
  }

  showFieldSummary(key: string): void {
    this.fieldsSummary.find(f=>f.name === key).hidden = false;
  }

  customSuccessHandler(result: any): void {
    // placeholder
  }

  setApiDataMapping(): ApiMapping[] {
    return [];
  }

  setInitialFieldsSummary(): void {}

  ngOnInit(): void {
    this.loading = false;
  }

  setFormName(): string {
    return "";
  }

  setPreFormData(): IFormField[] {
    return [];
  }

  triggerFormUpdatedEvent(): void {
    this.formUpdated.emit();
  }

  createForm(): void {
    this.preFormData = this.setPreFormData();
    let tempFormSettings = this.formService.createForm(this.formName, this.preFormData);

    tempFormSettings.forEach(el => {
      this.formSettings[el.inputName] = el;
    });

    this.formData = this.fb.group(this.formService.convertToReactiveForm(tempFormSettings));
    this.initialFormValues = this.formData.value;
    this.formCreated.emit();
  }

  getFieldSettings(fieldName: string): any {
    return this.formSettings[fieldName];
  }

  // TODO: check if this is used anywhere
  getForm():FormGroup {
    return this.formData;
  }

  resetForm(): void {
    setTimeout(() => {
      this.formData.reset(this.initialFormValues);
      Object.entries(this.formSettings).forEach(el=>{
        if(this.formSettings[el[0]].inputType === InputTypes.select) {
          this.formSettings[el[0]].value = this.initialFormValues[el[0]];
        }
      });
      this.formData.markAsPristine();
      this.formData.markAsUntouched();
      this.formReset.emit();
    }, 10);

  }

  resetWithConfirmation() {
    this.confirmService.confirm(this.uiService.getConfirmMessage('clearTheForm'))
      .subscribe((answer) => {
        if(answer) {
          this.resetForm();
        }
      });
  }

  mapFormDataNew(): Object {
    let formValues = this.formData.value;
    let fd = {};
    this.ApiDataMapping.forEach(el => {
      if(!el.Disabled) {
        fd[el.ApiProperty] = formValues[el.FieldName];
      }

    });
    return fd;
  }

  mapFormData(): FormData {
    let formValues = this.formData.value;
    let fd = new FormData();
    this.ApiDataMapping.forEach((el) => {
      if(!el.Disabled) {
        fd.append(el.ApiProperty, formValues[el.FieldName]);
      }

    })
    return fd;
  }

  public submit() {
    this.subminForm(this.formData, this.formSettings);
  }

  protected customDataPreparation(): void {
    // placeholder for custom form changes before sending
  }

  handleError(err: HttpErrorResponse) {
  }

  private subminForm(form: FormGroup, formSettings: {}):  void {
    this.loading = true;
    this.customDataPreparation();
    let fd = this.mapFormData();
    this.formService.postForm(this.formApiUrl, fd).subscribe({
      next: result => {
        this.loading = false;
        // TODO: add special handling (if there is one set)
        if(this.customSuccess) {
          this.customSuccessHandler(result);
        } else {
          this.formService.formSuccess("Generation Successful", FormResultMessageFormat.modal, form);
          this.resetForm();
        }

      },
      error: (err: any) => {
        console.log(err);
        this.loading = false;
        if(this.reversedApiMapping.length > 0) {
          if (err.error.validationErrors) {
            let valErrors = {};
            Object.keys(err.error.validationErrors).forEach(key => {
              let mapped = this.reversedApiMapping.find(el => el.responseApi === key);
              if(mapped) {
                valErrors[mapped.FieldName] = err.error.validationErrors[key];
              } else {
                valErrors[key] = err.error.validationErrors[key];
              }
            });
            err.error.validationErrors = valErrors;
          }
        }
        this.formService.formError(err, form, formSettings, null);
        this.handleError(err);
      }
    })
  }
}
