export interface IFormField {
    inputName: string,
    inputType: InputTypes,
    placeholder?: string,
    value: any,
    required?: boolean,
    id: string,
    rows?: number,
    label: string,
    autocompleteOff?: boolean,
    groupName?: string,
    matchWith?: string,
    validationRules?: IValidationRules[],
    options?: ISelectOptions[],
    instruction?: string,
    instructionHtml?: string,
    disabled?: boolean,
    errorMessages?: string[],
    requiredErrorMessage?: string,
    errorMessagesList?: object,
    clearButton?: boolean,
    countCharacters?: boolean,
    characterLimit?: number,
    tooltipText?: string,
    autofocus?: boolean,
    allowedExtensions?: string,
    suggestedExtensions?: string,
    maxFileSize?: number,
    codeLanguage?: string,
    dateOptions?: DateOptions
}

export interface DateOptions {
  minDate?: Date,
  maxDate?: Date,
  enableWeekends?: boolean
}

export enum InputTypes {
    text = 'text',
    tel = 'tel',
    email = 'email',
    password = 'password',
    passwordConfirm = 'passwordConfirm',
    number = 'number',
    date = 'date',
    toggle = 'toggle',
    textarea = 'textarea',
    select = 'select',
    checkbox = 'checkbox',
    radio = 'radio',
    file = 'file',
    recaptcha = 'recaptcha',
    code = 'code'
}

export interface ISelectOptions {
    value: string,
    text: string,
    disabled?: boolean,
    parameter?: string
}

export interface IValidationRules {
    type: ValidationRuleTypes,
    customRuleName?: string,
    errorMessage: string,
    parameter?: any,
    value: boolean,
}

export enum ValidationRuleTypes {
    required = "required",
    pattern = "pattern",
    email = "email",
    number = "number",
    passwordValidator = "passwordValidator",
    passwordConfirm = "passwordConfirm",
    server = "server",
    min = "min",
    max = "max",
    minLength = "minLength",
    maxLength = "maxLength",
    requiredTrue = "requiredTrue",
    extension = "extension",
    maxFileSize = "maxFileSize"
}

// export enum FormResultMessageFormat {
//     modal = "modal",
//     popup = "popup",
//     inline = "inline"
// }
