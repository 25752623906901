<div class="user-role"
    [tooltip]="text" trigger="click" container="body"
    [title]="text" [ngClass]="userRole.class">
    <fa-icon *ngIf="!userRole.smallIcon" [icon]="userRole.icon"></fa-icon>
    <span class="icon-set" *ngIf="userRole.smallIcon" [ngClass]="userRole.class">
        <fa-icon class="main-icon" [icon]="userRole.icon"></fa-icon>
        <fa-icon class="small-icon" [icon]="userRole.smallIcon"></fa-icon>
        <fa-icon title="Global Admin" *ngIf="isGlobalAdmin" class="global-admin-icon" [icon]="globalAdminIcon"></fa-icon>
    </span>
</div>
