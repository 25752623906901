import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar.component';
import { ProgressBarService } from './progress-bar.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [ProgressBarComponent],
  imports: [CommonModule,FontAwesomeModule],
  exports: [ProgressBarComponent]
})
export class ProgressBarModule {
  static forRoot(): ModuleWithProviders<ProgressBarModule> {
    return {
      ngModule: ProgressBarModule,
      providers: [ProgressBarService]
    }
  }
}

