<div class="notifications">
	<div *ngFor="let notification of notifications" class="notification" [ngClass]="className(notification)">
		<ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
	</div>
</div>

<ng-template #notificationTpl let-notification="notification">

	<div class="title" fxLayout="row" fxLayoutAlign="space-between center">
		<div>{{notification.title}}</div>
		<button mat-icon-button
            (click)="close(notification)"
            matTooltip="Close the notification"
            matTooltipPosition="before">
        <a>cancel</a>
      </button>
	</div>

	<div class="message">{{notification.message}}</div>

</ng-template>

<ng-template #modal>
	<div class="modal-header">
	  <h2 *ngIf="title" class="modal-title pull-left">{{title}}</h2>
	  <button *ngIf="!noClose" class="close-popup-button close-button modal-close" (click)="modalRef.hide()">close</button>
	  <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
		<span aria-hidden="true">&times;</span>
	  </button> -->
	</div>
	<!-- <div class="modal-body" *ngIf="!messageHtml && !html">
		{{message}}
	</div> -->
  <div class="modal-body" *ngIf="!messageHtml" [innerHTML]="message"></div>
	<div class="modal-body" >
		<ng-container *ngTemplateOutlet="template"></ng-container>

	</div>
	<div *ngIf="footerTemplate" class="modal-footer">
		<ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
	</div>
  </ng-template>

  <!-- <ng-template #confirm>
	<div class="modal-header">
	  <h4 class="modal-title pull-left">{{title}}</h4>
	</div>
	<div class="modal-body">
	  {{message}}
	</div>
	<div class="actions">
        <button>No</button>
        <button>Yes</button>
    </div>
	<div class="modal-body text-center">
		<p>Do you want to confirm?</p>
		<button type="button" class="btn btn-default" (click)="confirmClick()" >Yes</button>
		<button type="button" class="btn btn-primary" (click)="decline()" >No</button>
	</div>
  </ng-template> -->
