import { ElementRef, TemplateRef } from '@angular/core';
import { BsEventCallback } from 'ngx-bootstrap/component-loader/listen-options.model';

export class Notification {

    constructor(
      public id: number,
      public type: NotificationType,
      public title: string,
      public message: string,
      public timeout: number,
      public format: FormResultMessageFormat,
      public el?: ElementRef,
      public template?: TemplateRef<any>,
      public html?: boolean,
      public position?: NotificationPosition,
      public footerTemplate?: TemplateRef<any>,
      public onHide?: BsEventCallback,
      
    ) { }

  }

  export interface NotificationMessage {
    type: NotificationType,
    title: string,
    message: string,
    timeout: number,
    format: FormResultMessageFormat,
    el?: ElementRef,
    template?: TemplateRef<any>,
    html?: boolean,
    position?: NotificationPosition
  }

  export enum NotificationType {
    success = 0,
    warning = 1,
    error = 2,
    info = 3,
    // confirm = 4
  }

  export enum FormResultMessageFormat {
    modal = "modal",
    popup = "popup",
    inline = "inline",
    modalHtml = 'modalHtml',
    modalLarge = 'modalLarge',
    modalLargeHtml = 'modalLargeHtml',
    modalHtmlNoClose = 'modalHtmlNoClose'
    // confirm = "confirm"
  }

  export enum NotificationPosition {
    top = 'top',
    bottom = 'bottom'
  }
