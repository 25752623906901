import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pagination'
})
export class PaginationPipe implements PipeTransform {

  transform(list: any[], params: {page: number, perPage: number}): any {
    return [ ...list.slice( params.perPage*(params.page - 1) , params.perPage*(params.page))];
  }

}
