<div class="summary-content" [class.left-border]="leftBorder"
  [class.collapsed]="collapsed" [class.collapsible]="collapsible"
  [class.disabled]="disabled">
  <h4 *ngIf="title">{{title}}</h4>
  <h5 *ngIf="subTitle" [class.valid]="verified" class="valid">{{subTitle}}</h5>

  <!-- <div *ngIf="verified">
    <p class="verified-text valid">{{verifiedText}}</p>
  </div> -->
  <div *ngIf="!grouped && !verified" class="list">
    <ng-container *ngFor="let item of fields | simpleFilter: {field: 'hidden', value: [false, undefined]}">
      <p [class.valid]="item.valid" [class.sub-level]="item.subLevel" [class.sub-title]="item.isSubtitle">
        <span class="label">{{item.label}}</span>
        <span *ngIf="!item.html && !item.subTitle" class="value">{{item.text}}</span>
        <span *ngIf="!!item.html && !item.subTitle" class="value 2" [innerHTML]="item.text"></span>
      </p>
    </ng-container>
  </div>

  <div *ngIf="grouped">
    <ng-container *ngFor="let group of summaryGroup">
      <h3 [class.collapsed]="group.collapsed">{{group.text}}</h3>
      <div class="sub-list" *ngIf="!group.collapsed">
        <ng-container *ngFor="let item of group.fields | simpleFilter: {field: 'hidden', value: [false, undefined]}">
          <p [class.valid]="item.valid">
            <span class="label">{{item.label}}</span>
            <span *ngIf="!item.html" class="value">{{item.text}}</span>
            <span *ngIf="!!item.html" class="value 2" [innerHTML]="item.text"></span>
          </p>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
