import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { IFormField, InputTypes } from 'projects/nine-gold-lib/src/lib/models/formField';
import { delay } from 'rxjs/operators';
import { faCheck, faCode, faEdit, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NotificationService } from 'projects/nine-gold-lib/src/lib/shared/notification/notification.service';
import { FormResultMessageFormat, NotificationPosition, NotificationType } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { Notification } from '../../../../../../nine-gold-lib/src/lib/shared/notification/notification';
import { of, Subject, Subscription } from 'rxjs';
import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { GeneratorFormService } from '../generator-form.service';
import { Uses } from 'projects/nine-gold-lib/src/lib/models/uses';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { Multiselect } from 'projects/nine-gold-lib/src/lib/directives/multiselect/multiselect';
import { MultiselectComponent, StatusBarService } from 'projects/nine-gold-lib/src/public-api';
import { barStatuses, statusClasses } from 'projects/nine-gold-lib/src/lib/shared/status-bar/status-bar';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { GeneratorParseService } from '../generator-parse.service';
import { ProgressBarService } from '../../progress-bar/progress-bar.service';
import { Location } from '@angular/common';
import { ProductService } from 'projects/nine-gold-lib/src/lib/services/product.service';
import { UiSettingsService } from 'projects/nine-gold-lib/src/lib/services/ui-settings.service';


const accordionAnimation = trigger('accordionAnimation', [
    state('open', style({
      overflow: 'visible',
      height: '*',
      opacity: '1',
      'margin-bottom': '2em'
    })),
    state('closed', style({
      overflow: 'hidden',
      height: '0px',
      opacity: '0',
      'margin-bottom': '0'
    })),
    transition('open => closed', [
      style({height: '*',opacity: '1', transform: 'scale(1)', 'margin-bottom': '2em'}),
      group([
        animate('0.5s ease-in', style({opacity: '0', transform: 'scale(.95)'})),
        animate('.5s .5s ease-in-out', style({height: '0px','margin-bottom': '0' }))
      ])
    ]),

    transition('closed => open', [
      style({height: '0px', opacity: '0', transform: 'scale(.95)', 'margin-bottom': '0'}),
      group([
        animate('.5s .5s ease-out', style({opacity: '1', transform: 'scale(1)'})),
        animate('.5s ease-out', style({height: '*', 'margin-bottom': '2em'}))
      ])
    ])
  ]);

  const slideInOut  = trigger(
    'enterAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.5s .5s', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('0.5s', style({opacity: 0}))
      ])
    ]
  );

  export interface Person {
    id: string;
    isActive: boolean;
    age: number;
    name: string;
    gender: string;
    company: string;
    email: string;
    phone: string;
    disabled?: boolean;
}

export interface CodeModel {
  language: string;
  value: string;
  uri: string;

  dependencies?: Array<string>;
  schemas?: Array<{
    uri: string;
    schema: Object;
  }>;
}

export enum FormStates {initial = "initial",parsing="parsing",verified="verified",loadingEndpoints="loadingEndpoints",endpoints="endpoints",generating="generating",completed="completed"};

@Component({
  selector: 'app-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [slideInOut,accordionAnimation],
})



export class GeneratorComponent implements OnInit, AfterViewInit, OnDestroy {

  //! TEMP DATA - remove after done

  radioModel = "";  //  ngModel property for enpoint toggle


  endpointsAccordionState = "closed";
  loadFileAccordionState = "open";
  smartProjectState = "closed";
  totalEndpoints = 0;

  verifiedIcon = faCheck;
  editIcon = faEdit;
  reloadIcon = faSyncAlt;

  targetFileName: string;

  endpotinsList: string[] = [];

  loadingEndpoints: boolean;

  formState = FormStates.initial;

  eventsSubject: Subject<void> = new Subject<void>();

  allEndpoints: boolean = true;

  languageDefault: string = null;
  showLanguageSetDefault = false;

  generatedTypeDefault: string = null;
  showGeneratedTypeSetDefault = false;


  //! ENd of Temp Data


  activeCacheKey: string;
  activeDocumentKey: string;

  obs$ = of(1).pipe(delay(1));

  optionalFieldsDisabled: boolean;

  accordionState = "closed";

  exampleMapping = JSON.stringify({
    "@category": "vars.category",
    "user.surname": "lastname",
    "role": "string.tester"
  }, null, 2);
  faqLink: string;

  codeIcon = faCode;

  formCreated = false;

  successDetails: Uses = null;

  @ViewChild('successMessage') successMessage: TemplateRef<any>;

  modalRef: BsModalRef;

  disabledDocButton = false;
  closeSubscription: Subscription;
  parseSuccessSubscription: Subscription;
  parseErrorSubscription: Subscription;
  endpointsLoadedSubscription: Subscription;

  completeParseStatus: Subscription;
  completeDocumentStatus: Subscription;

  generatorSuccessSubscription: Subscription;
  generatorErrorSubscription: Subscription;

  generatedApiContract: IFormField = {
    inputName: 'generatedApiContract',
    id: 'generatedApiContractId',
    label: 'Generated API Contract',
    inputType: InputTypes.select,
    clearButton: false,
    value: 'auto',
    options: [
      {
        value: "auto",
        text: "Automatic"
      },
      {
        value: "custom",
        text: "Custom"
      }
    ]
  }

  generationFromHistory = false;

  fileInstructions = {
    'targetContract': {
      'wsdl': 'Upload the shcema definition for your downstream service in WSDL format.',
      'raml': 'Upload the shcema definition for your downstream service in RAML format.',
      'oas': 'Upload the shcema definition for your downstream service in OAS format.',
      'postman': 'Upload the shcema definition for your downstream service in Postman format.'
    }
  }


  editorOptions = {theme: 'vs-dark', language: 'yaml', placeholder: "test"};
  code: string= 'function x() {\nconsole.log("Hello world!");\n}';
  currentSection: string = "stepOnePlatform";
  onInit(editor) {
    let line = editor.getPosition();
  }

  successPopup;

  @ViewChild(MultiselectComponent)
  private multiselector!: MultiselectComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public generatorService: GeneratorFormService,
    public generatorParseService: GeneratorParseService,
    private appSettings: AppSettingsService,
    private uiSettings: UiSettingsService,
    private userService: UserService,
    private notSrv: NotificationService,
    public progressBarService: ProgressBarService) {
      this.faqLink = appSettings.getFooterNav().find(l=>l.name === 'faq').link;
  }

  ngOnInit(): void {

    this.activeCacheKey = this.route.snapshot.params['cacheKey'];

    if(this.activeCacheKey) {
      // Loading from recent uploads
      this.generationFromHistory = true;
      this.activeDocumentKey = `doc-${this.activeCacheKey}`;
      this.generatorService.setCacheKey(this.activeCacheKey);
      this.targetFileName = this.route.snapshot.params['fileName'];
      this.progressBarService.resetCounters();
      this.progressBarService.showBar();
      ['parse','status','documentation'].forEach(el=>this.progressBarService.setDone(el));
      this.generatorParseService.generateSuccess();
      this.getEnbdpointsFromAPI();
    }

    if(this.targetFileName) {
      this.loadFileAccordionState = "closed";
      this.smartProjectState = 'open'
    }

    this.generatorService.formSettings['mapping'].instructionHtml = `This is required to map the Integration API (uploaded in REST Generator) fields to the Target API fields where the field names are not an exact match.<br>
    For more information please refer to the <a target="_blank" href="${this.faqLink}#USING_THE_MAPPING_RULES">FAQ page</a>.`;

    //  checking for default langulage dropdown value
    this.checkLanguageDefault();
    this.checkGeneratedTypeDefault();
    this.checkGroupIdValue();
  }


  // *  DOCUMENTATION

  protected viewDocumentation(): void {
    this.router.navigate([{ outlets: { sidePopup: [ 'documentation',this.activeCacheKey,this.activeDocumentKey ] } }]);
  }

  private checkGroupIdValue(): void {
    let val = this.uiSettings.getFormFieldValue(this.generatorService.formName,'groupId');
    if (val) {
      this.generatorService.formData.get('groupId').setValue(val);
      this.generatorService.formData.get('groupId').updateValueAndValidity();
    }
  }

  private checkLanguageDefault(): void {
    let val = this.uiSettings.getFormFieldValue(this.generatorService.formName,'language');
    if (val) {
      this.languageDefault = val;
      this.generatorService.formData.get('language').setValue(val);
      this.generatorService.formData.get('language').updateValueAndValidity();
    } else {
      this.languageDefault = this.generatorService.formData.get('language').value;
    }
  }

  private checkGeneratedTypeDefault(): void {
    let val = this.uiSettings.getFormFieldValue(this.generatorService.formName,'generatedType');
    if (val) {
      this.generatedTypeDefault = val;
      this.generatorService.formData.get('generatedType').setValue(val);
      this.generatorService.formData.get('generatedType').updateValueAndValidity();
      this.generatorService.formSettings['generatedType'].value = val;
    } else {
      this.generatedTypeDefault = this.generatorService.formData.get('generatedType').value;
    }
    this.includeOptionalFields(this.generatorService.formSettings['generatedType']);
  }

  protected updateGeneratedTypeDefaultButton(event: any) {
    this.showGeneratedTypeSetDefault = (this.generatedTypeDefault && event.value !== this.generatedTypeDefault);
  }

  protected updateDefaultButton(event: any) {
    this.showLanguageSetDefault = (this.languageDefault && event.value !== this.languageDefault);
    this.updateField('language');
  }

  protected changeLanguageDefault(): void {
    let val = this.generatorService.formData.get('language').value;
    this.languageDefault = val;
    this.showLanguageSetDefault = false;
    this.uiSettings.addFormFieldValue(this.generatorService.formName,'language',val);
    // TODO: Notification popup
  }

  protected changeGeneratedTypeDefault(): void {
    let val = this.generatorService.formData.get('generatedType').value;
    this.generatedTypeDefault = val;
    this.showGeneratedTypeSetDefault = false;
    this.uiSettings.addFormFieldValue(this.generatorService.formName,'generatedType',val);
    // TODO: Notification popup
  }


  // *  PARSING FILE

  protected parseFile():void {
    this.progressBarService.resetCounters();
    this.progressBarService.showBar();
    this.progressBarService.setActive('parse');
    this.parseSuccessSubscription = this.generatorParseService.success.subscribe(result => {
      this.parsedFileHandler(result);
    });
    this.parseErrorSubscription = this.generatorParseService.error.subscribe(res=>{
      this.parseErrorHandler();
    });
    this.generatorParseService.parseFile();
  }

  private parseErrorHandler(): void {
    this.progressBarService.hideBar();
    this.parseSuccessSubscription.unsubscribe();
    this.parseErrorSubscription.unsubscribe();
  }

  private parsedFileHandler(result): void {
    this.successDetails = result;
    this.parseSuccessSubscription.unsubscribe();
    this.parseErrorSubscription.unsubscribe();
    this.disabledDocButton = true;
    this.loadFileAccordionState = "closed";

    // set data from results
    this.activeCacheKey = result.results['response'].cacheKey;
    this.activeDocumentKey = result.results['response'].documentKey;

    // send data to generator service
    this.generatorService.setCacheKey(this.activeCacheKey);
    this.generatorService.activeDocumentKey = this.activeDocumentKey;
    this.generatorService.parseResults = result.results['response'];

    // update progress bar
    this.progressBarService.setDone('parse');
    let statusApi = this.appSettings.getApi('parseStatus',this.userService.getCompanyId(),result.results['response'].cacheKey);
    this.progressBarService.startStatusApi(statusApi);

    this.completeParseStatus = this.progressBarService.completedStatus.subscribe(res => {
      this.completeParseStatus.unsubscribe();
      if(res.valid) {
        this.getEnbdpointsFromAPI();
      } else {
        if(res.showError) {
          this.notSrv.error('Parsing Not Completed','Parsing was not completed successfully.',FormResultMessageFormat.popup,8000,null);
        }
        setTimeout(() => {
          this.progressBarService.resetCounters();
          this.progressBarService.hideBar();
        }, 5000);
      }

    });

    this.completeDocumentStatus = this.progressBarService.completeDocument.subscribe(res => {
      this.completeDocumentStatus.unsubscribe();
      this.disabledDocButton = false; // enable View Documentation button
    });

  }

  // *  CLEARING PARSED FILE

  clearParsedFile(): void {

    this.clearEndpoints();

    if(this.parseSuccessSubscription) this.parseSuccessSubscription.unsubscribe();
    if(this.endpointsLoadedSubscription) this.endpointsLoadedSubscription.unsubscribe();
    this.targetFileName = null;
    this.loadFileAccordionState = "open";
    this.smartProjectState = 'closed'

    if(this.activeCacheKey) {
      this.activeCacheKey = null;
      this.activeDocumentKey = null;
      this.generatorService.setCacheKey(null);
    }
    if(this.route.snapshot.params && this.route.snapshot.params['cacheKey']) {
      delete this.route.snapshot.params;
      this.location.go('/app/generator');
    }

    this.generatorParseService.clearParseFile();
    if(this.completeParseStatus) this.completeParseStatus.unsubscribe();

    this.progressBarService.resetCounters();
    setTimeout(() => {
      this.progressBarService.hideBar();
    }, 500);

    this.generatorParseService.setTarget(null,null);
  }


  // *  CLEARING SMART PROJECT FORM

  clearSmartProjectForm(): void {
    this.multiselector.restSelection();
    this.generatorService.clearEndpoints(false);
    this.generatorService.resetForm();
    setTimeout(() => {
      if(this.activeCacheKey) {
        this.generatorService.setCacheKey(this.activeCacheKey);
      }
      this.includeOptionalFields(this.generatorService.formSettings['generatedType']);
      this.toggleEndpointsSelector('all');
      this.generatorService.formData.markAsPristine();
    }, 100);
  }




  // *  ENDPOINTS LOADING

  // Getting endpoints from API
  private getEnbdpointsFromAPI(): void {
    this.notSrv.info('Loading Endpoints','Wait for Endpoints to finish loading',FormResultMessageFormat.popup,3000,null,false,NotificationPosition.bottom);
    this.loadingEndpoints = true;
    this.generatorService.getEndpoits(this.activeCacheKey);
    this.progressBarService.setActive('endpoints');
    this.endpointsLoadedSubscription = this.generatorService.endpointsLoaded.subscribe((numberOfEndpoints) => this.endpointsFromApiHandler(numberOfEndpoints));
  }

  private endpointsFromApiHandler(numberOfEndpoints: number): void {
    this.loadingEndpoints = false;

    this.endpointsLoadedSubscription.unsubscribe();
    if(numberOfEndpoints && numberOfEndpoints > 0) {
      this.totalEndpoints = numberOfEndpoints;
      this.progressBarService.setDone('endpoints');
      this.toggleEndpointsSelector('all');
      this.notSrv.success('Success','Endpoints successfully loaded',FormResultMessageFormat.popup,4000,null,false,NotificationPosition.bottom);
    } else {
      this.progressBarService.setError('endpoints');
      this.totalEndpoints = 0;
      if (this.generationFromHistory) {
        this.notSrv.error('Error','This upload is no longer in cache and will be removed from the list. Please reload the file.',FormResultMessageFormat.popup,8000,null,false,NotificationPosition.bottom);
      } else {
        this.notSrv.error('Error','There was an error while loading endpoints',FormResultMessageFormat.popup,4000,null,false,NotificationPosition.bottom);
      }
    }
  }


  // *  ENDPOINTS TEMPLATE functionality

  // Setting endpoints from enpoints selector
  protected setEndpoints(endpoints: string[]): void {
    this.generatorService.setEndpoints(endpoints);
  }

  protected toggleEndpointsSelector(val: string): void {
    this.allEndpoints = (val === 'all');
    this.endpointsAccordionState = (this.allEndpoints ? "closed" : "open");
    if(this.allEndpoints) this.generatorService.setEndpoints(['all']);
  }

  private clearEndpoints(): void {
    this.generatorService.clearEndpoints();


    setTimeout(() => {
      this.endpointsAccordionState = "closed";
      this.allEndpoints = true;
      this.totalEndpoints = 0;
      this.radioModel = '';

    }, 100);
  }




  private unsubscribeAll(): void {
    // TODO: check if subscription and unsubscribe
    // TODO: run this for every case needed
  }

  setActive(): void {
    this.progressBarService.setActive('status')
  }
  setCompleted(): void {
    this.progressBarService.setDone('status');
  }

  setError(): void {
    this.progressBarService.setError('status');
  }

  showToolbar():void {
    this.progressBarService.showBar();
    this.progressBarService.setActive('status')
  }

  private clearSubscriptions(): void {
    // TODO: move all subsciptions clearing functions here
    // TODO: call this from onDestroy, CLEAR THE FORM and from RELOAD (parse) FILE
  }

  ngOnDestroy(): void {
    if(this.progressBarService.gettingStatusOrDocumentation()) {
      this.progressBarService.resetCounters();
      this.progressBarService.hideBar();
    }
    if(this.completeParseStatus) this.completeParseStatus.unsubscribe();
    if(this.completeDocumentStatus) this.completeDocumentStatus.unsubscribe();

    this.generatorService.resetForm();
    this.generatorService.clearEndpoints();
    this.endpointsLoadedSubscription?.unsubscribe();
    this.modalRef?.hide();
    this.progressBarService.leavingGenerator();
    setTimeout(() => {
      if(this.activeCacheKey) {
        this.generatorService.setCacheKey(this.activeCacheKey);
      }
      this.includeOptionalFields(this.generatorService.formSettings['generatedType']);
      this.toggleEndpointsSelector('all');
      this.generatorService.formData.markAsPristine();
    }, 100);
  }

  closeModal():void {
    this.closeSubscription = this.modalRef.onHide.subscribe(()=>{
      setTimeout(() => {
        this.closeSubscription.unsubscribe();
        const navigationExtras: NavigationExtras = {state: {id: this.successDetails.id}};
        this.router.navigate(['/app/downloads'], navigationExtras);
      }, 200);

    });
  }

  setInitialFieldsData(): void {
    for (const field in this.generatorService.formData.controls) {
      const control = this.generatorService.formData.get(field);
      if (control.valid && control.value) {
        if(this.generatorService.getFieldSettings(field).inputType === InputTypes.select) {

        }
        if(this.generatorService.fieldsSummary.some(f=>f.name === field)) {
          this.generatorService.updateFieldSummary(field);
        }

      }
    }

    let temp = this.generatorService.getFieldSettings('language');
    this.optionalFieldsDisabled = !this.generatorService.optionalFieldsDisabled;

    if (!temp) {
      this.generatorService.formCreated.subscribe(form => {
        this.setOptionalFields();
        this.formCreated = true;
        this.updateFileValidation(this.generatorParseService.formSettings['targetType'], 'targetContract',true);
      })
    } else {
      this.setOptionalFields();
      this.formCreated = true;
      this.updateFileValidation(this.generatorParseService.formSettings['targetType'], 'targetContract',true);
    }
    this.generatorService.formReset.subscribe(data => {});
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setInitialFieldsData();
    }, 200);

  }





  // Show or Hide MAPPING and CONTRACT FILE fields based on Specification selected
  includeOptionalFields(event: any): void {

    this.updateGeneratedTypeDefaultButton(event);

    this.generatorService.updateFieldSummary('generatedType',event.options.find(o => o.value === event.value).text, event.value, true);
    this.accordionState = event.value === "CUSTOM" ? "open" : "closed";
    this.generatorService.optionalFieldsDisabled = (event.value !== "CUSTOM");
    if (event.value === "CUSTOM") {
      this.generatorService.formData.markAsDirty();
      this.generatorService.showFieldSummary('generatedContract');
      this.generatorService.showFieldSummary('customSpecification');
      this.generatorService.showFieldSummary('mapping');
    } else {
      this.generatorService.hideFieldSummary('generatedContract');
      this.generatorService.hideFieldSummary('customSpecification');
      this.generatorService.hideFieldSummary('mapping');
    }
  }

  setOptionalFields(){
    this.generatorService.optionalFieldsDisabled = !this.optionalFieldsDisabled;
  }

  getDisplayValue(formInputSettings: any): string {
    let text = "";
    if(formInputSettings.inputType === InputTypes.select) {
      text = formInputSettings.options.find(element => element.value === formInputSettings.value)?.text;
    } else {
      text = formInputSettings.value;
    }
    return text;
  }

  protected generate() {
    this.progressBarService.setActive('generation');
    this.generatorService.submit();
    this.generatorSuccessSubscription = this.generatorService.success.subscribe(result => {
      this.generatorSuccessHandler(result);
    });
    this.generatorErrorSubscription = this.generatorService.error.subscribe(err=>{
      this.generatorErrorHandler(err);
    });
  }

  private generatorErrorHandler(err: any): void {
    this.generatorSuccessSubscription.unsubscribe();
    this.generatorErrorSubscription.unsubscribe();
    this.progressBarService.setError('generation');
    if(this.parseSuccessSubscription) this.parseSuccessSubscription.unsubscribe();
  }

  private generatorSuccessHandler(result: any): void {
    this.generatorSuccessSubscription.unsubscribe();
    this.generatorErrorSubscription.unsubscribe();
    this.progressBarService.setDone('generation');
    if(this.parseSuccessSubscription) this.parseSuccessSubscription.unsubscribe();

    this.successDetails = result;

    this.notSrv.success('Success','Succesfull Generation. Navigating to downloads',FormResultMessageFormat.popup,5000,null);

    setTimeout(() => {
      const navigationExtras: NavigationExtras = {state: {id: this.successDetails.id}};
      this.router.navigate(['/app/downloads'], navigationExtras);
    }, 1000);

  }

  updateGroupIdField(fieldName: string): void {
    if(this.generatorService.formData.get(fieldName).valid) {
      let currentVal  = this.uiSettings.getFormFieldValue(this.generatorService.formName,'groupId');
      let newVal = this.generatorService.formData.get(fieldName).value;
      if (newVal && currentVal !== newVal) {
        this.uiSettings.addFormFieldValue(this.generatorService.formName,fieldName,newVal);
      }
    }
    this.updateField(fieldName, false);
  }


  //* GENERAL FIELD RELATED FUNCTIONS

  updateField(fieldName: string, parseService = false): void {
    let service = (parseService ? this.generatorParseService : this.generatorService);

    if(service.formData.controls[fieldName].valid) {
      service.updateFieldSummary(fieldName);
    } else {
      service.updateFieldSummary(fieldName, "", "", false);
    }
  }


  //* FILE FIELD RELATED FUNCTIONS

  updateFileValidation(event: IFormField, fileFieldName: string, parseService = false): void {
    let service = (parseService ? this.generatorParseService : this.generatorService);

    service.updateFieldSummary(event.inputName);
    let allowedExtensions = event.options.find(el => el.value === event.value).parameter;
    let tempSettings = Object.assign({}, service.formSettings[fileFieldName]);

    tempSettings.instruction = this.fileInstructions[fileFieldName][event.value];

    if(service.formSettings[fileFieldName].errorMessagesList['extension']) {
      tempSettings.allowedExtensions = allowedExtensions;
    } else {
      tempSettings.suggestedExtensions = allowedExtensions;
    }
    service.formSettings[fileFieldName] = Object.assign({}, tempSettings);
  }


  updateFile(file: File, inputName: string, parseService = false): void {
    let service = (parseService ? this.generatorParseService : this.generatorService);

    service.formData.get(inputName).patchValue(file);
    service.formData.markAsDirty();
    service.formData.markAsTouched();
    if(service.formData.get(inputName).valid) {
      service.updateFieldSummary(inputName,file.name,file,true);
      if(inputName === 'targetContract') this.targetFileName = file.name;
    }
  }


  setFilePristine(inputName: string, parseService = false): void {
    let service = (parseService ? this.generatorParseService : this.generatorService);

    service.formData.get(inputName).markAsPristine();
    service.formData.get(inputName).markAsUntouched();
    setTimeout(() => {
      service.updateFieldSummary(inputName,'','',false);
    }, 20);
  }


  setFileStatus(status: boolean, inputName: string, parseService = false): void {

    let service = (parseService ? this.generatorParseService : this.generatorService);

    if (!status) {
      service.formData.get(inputName).setErrors({'incorrect': true});
      setTimeout(() => {
        service.updateFieldSummary(inputName,'','',false);
      }, 10);

    } else {
      service.formData.get(inputName).setErrors(null);
      let file = service.formData.get(inputName).value;
      setTimeout(() => {
        service.updateFieldSummary(inputName,file.name,file,true);
        if(inputName === 'targetContract') this.targetFileName = file.name;
      }, 10);

    }
  }

  sendSuccess() {
    let notif: Notification = {title: "Title", message: "Message", id:0, type: NotificationType.success, timeout: 3000, format: FormResultMessageFormat.popup};
    this.notSrv.changeMessage("Generator");
  }

}
