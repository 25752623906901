import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmDialog } from './confirm-dialog';

@Component({
  selector: 'lib-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  // confirmOptions: ConfirmDialog;
  title: string;
  message: string;
  confirmBtn: string;
  declineBtn: string;
  options: string[];
  answer: boolean = false;
  dangerBtn: boolean;
  html: boolean;

  constructor(public modalRef: BsModalRef) {
    // console.log(this.confirmOptions);
  }

  ngOnInit(): void {
    // console.log(this.message);
    // console.log(this.dangerBtn);
    // console.log(this.confirmOptions);
  }


  // openModal(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  // }

  respond(answer: boolean) {
    this.answer = answer || false;

    this.modalRef.hide();
  }


}
