import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IFormField, InputTypes } from '../../models/formField';
import { debounceTime } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'lib-select-form-field',
  templateUrl: './select-form-field.component.html',
  styleUrls: ['./select-form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({opacity: 0, transform: "translate(-10px, 0)"}),
        animate('300ms ease-in', style({opacity: 1, transform: "translate(0, 0)"}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({opacity: 0, transform: "translate(-10px, 0)"}))
      ])
    ]),
    trigger('toggleOpacity', [
      state('text', style({
          opacity: '0',
          visibility: 'hidden'
      })),
      state('password', style({
          opacity: '1',
          visibility: 'visible'
      })),
      transition('password => text', animate('200ms ease-in-out')),
      transition('text => password', animate('200ms ease-in-out'))
  ])
  ]
})
export class SelectFormFieldComponent implements OnInit {
  // data-related input settings
  @Input() fieldSettings: IFormField;
  @Input() formGroupParameter: FormGroup;

  // text-field related input settings
  @Input() customValue: boolean = false;
  @Input() textFieldSettings: IFormField;
  @Input() addValueButtonText: string = 'Add';
  @Output() valueAdded: EventEmitter<any> = new EventEmitter<any>();
  @Input() addValueUseCustomFunction: boolean = false;


  // TODO: check what arrayName is for, how it's used and add description
  @Input() index: number = null;
  @Input() arrayName: string = null;

  // UI related input settings
  @Input() rightSideErrors: boolean = false;
  @Input() minBottomMargin = false;
  @Input() noBottomMargin = false;
  @Input() noLabel: boolean = false;
  @Input() inlineLayout = false;


  @Output() dropdownChanged: EventEmitter<IFormField> = new EventEmitter<IFormField>();

  @ViewChild('Selector') ngselect: NgSelectComponent;

  fieldValid = false;
  hasFocus = false;

  constructor() { }

  ngOnInit() {
    if(this.fieldSettings.inputType === InputTypes.select && this.getField().value === "") {
      this.getField().patchValue(null);
    }

    this.getField().valueChanges.pipe(debounceTime(500)).subscribe(
      value => this.setMessage()
    );

    this.getField().statusChanges.pipe(debounceTime(500)).subscribe(
      value => this.setMessage()
    );

    if(this.fieldSettings.inputType === InputTypes.select && this.fieldSettings.disabled === true) {
      this.formGroupParameter.get(this.fieldSettings.inputName).disable();
    }
    
  }

  onFocus() {
    this.hasFocus = true;
    var elmnt = document.getElementById(this.fieldSettings.id);
    setTimeout(() => {
      elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' });  
    }, 100);
    
  }

  getField(): FormControl {
    return (this.arrayName ? 
      this.formGroupParameter.controls[this.arrayName]['controls'][this.index].get(this.fieldSettings.inputName) :
      this.formGroupParameter.get(this.fieldSettings.inputName))
  }

  getFormGroupName(): FormGroup {
    return (this.arrayName ?
      this.formGroupParameter.controls[this.arrayName]['controls'][this.index] :
      this.formGroupParameter)
  }

  private setMessage(): void {
    // console.log('SET MESSAGE');
    this.fieldSettings.errorMessages = [];
    let field = this.getField();
    if ((field.touched || field.dirty) && field.errors && this.fieldSettings.errorMessagesList) {
      this.fieldSettings.errorMessages = Object.keys(field.errors).map(
        key => this.fieldSettings.errorMessagesList[key]);
    }
    if(this.fieldSettings.errorMessages[0] === undefined) this.fieldSettings.errorMessages = [];
  }

  validationOnBlur() {
    // console.log('VALIDATION ON BLUR');
    this.hasFocus = false;
    let field = this.getField();
    if(this.fieldSettings.errorMessagesList?.hasOwnProperty("passwordconfirm") && field.parent.errors?.passwordconfirm === true) {
      field.setErrors({'passwordconfirm': true});
    }
    if ((field.touched || field.dirty) && field.errors) {
      this.fieldValid = false;
      
      this.setMessage();
    } else {
      if(field.valid && field.value === "") {

      } else {
        this.fieldValid = true;
      }
      
    }
  }

  getId():string {
    return (this.index ? this.fieldSettings.id + this.index : this.fieldSettings.id);
  }

  selectChanged(event: any): void {
    this.fieldSettings.value = event;
    this.dropdownChanged.emit(this.fieldSettings);
  }


  // Checking if there is error related to required fields
  public requiredInvalid(): boolean {
    if(this.fieldSettings.inputType === InputTypes.tel) {
      return this.getField().touched && this.getField().errors?.required;
    } else {
      return (this.getField().touched || this.getField().dirty) && this.getField().errors?.required;
    }
    // return (this.formGroupParameter.get(this.fieldSettings.inputName).touched || this.formGroupParameter.get(this.fieldSettings.inputName).dirty) && this.formGroupParameter.get(this.fieldSettings.inputName).errors?.required;
    
  }

  search(a, v) {
    if(a[0]['value'] > v['value']) {
        return 0;
    }
    var i=1;
    while (i<a.length && !(a[i]['value'] > v['value'] && a[i-1]['value'] <= v['value'])) {
        i=i+1;
        }
    return i;
  }

  addCustomValue(): void {
    let value = this.formGroupParameter.get(this.textFieldSettings.inputName).value;
    // let value = this.getField().value;


    setTimeout(() => {
      if(!this.formGroupParameter.get(this.textFieldSettings.inputName).valid) {
        this.getField().setValue(null);
        this.ngselect.open();
        setTimeout(() => {
          this.formGroupParameter.get(this.textFieldSettings.inputName)['nativeElement'].focus();
        }, 200);
      } else {
        if(this.addValueUseCustomFunction) {
          this.valueAdded.emit(value);
        } else {
          if(!this.fieldSettings.options.find(x => x.value === value)) {
            this.fieldSettings.options.splice(this.search(this.fieldSettings.options,{value: value, text: value}),0,{value: value, text: value});
          }
          this.getField().setValue(value);
        }
        this.formGroupParameter.get(this.textFieldSettings.inputName).setValue(null);
        this.ngselect.close();
      }
      
    }, 20);
  }

  addButtonDisabled():boolean {
    return (!this.formGroupParameter.get(this.textFieldSettings.inputName).valid || this.formGroupParameter.get(this.textFieldSettings.inputName).pristine);
  }

}
