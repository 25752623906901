<div *ngIf="whitepaperForm" [libLoading]="loading">
  <form novalidate (submit)="sendContactMessage()" [formGroup]="whitepaperForm">
    <lib-form-field
        [minBottomMargin]="true"
        [rightSideErrors]="true"
        [formGroupParameter]="whitepaperForm"
        [fieldSettings]="whitepaperFormSettings['firstName']"
        [value]="whitepaperForm.value['firstName']">
    </lib-form-field>

    <lib-form-field
        [minBottomMargin]="true"
        [rightSideErrors]="true"
        [formGroupParameter]="whitepaperForm"
        [fieldSettings]="whitepaperFormSettings['lastName']"
        [value]="whitepaperForm.value['lastName']">
    </lib-form-field>

    <lib-form-field
        [minBottomMargin]="true"
        [rightSideErrors]="true"
        [formGroupParameter]="whitepaperForm"
        [fieldSettings]="whitepaperFormSettings['email']"
        [value]="whitepaperForm.value['email']">
    </lib-form-field>

    <lib-form-field
        [minBottomMargin]="true"
        [rightSideErrors]="true"
        [formGroupParameter]="whitepaperForm"
        [fieldSettings]="whitepaperFormSettings['phone']"
        [value]="whitepaperForm.value['phone']">
    </lib-form-field>

    <lib-form-field
        [minBottomMargin]="true"
        [rightSideErrors]="true"
        [formGroupParameter]="whitepaperForm"
        [fieldSettings]="whitepaperFormSettings['companyName']"
        [value]="whitepaperForm.value['companyName']">
    </lib-form-field>

    <lib-form-field
        [minBottomMargin]="true"
        [rightSideErrors]="true"
        [formGroupParameter]="whitepaperForm"
        [fieldSettings]="whitepaperFormSettings['jobTitle']"
        [value]="whitepaperForm.value['jobTitle']">
    </lib-form-field>

    <lib-form-field
        [minBottomMargin]="true"
        [rightSideErrors]="true"
        [formGroupParameter]="whitepaperForm"
        [fieldSettings]="whitepaperFormSettings['industry']"
        [value]="whitepaperForm.value['industry']">
    </lib-form-field>

    <p>By registering, you agree to the processing of your personal data by Diamond Edge IT as described in the <a target="_blank" [href]="privacyLink">Privacy Statement</a>.</p>

    <div class="form-group form-button-container multiple-buttons">
      <!-- <button (click)="cancel()" type="button" class="secondary">Cancel</button> -->
      <button [disabled]="!whitepaperForm.valid" class="primary" type="submit">Submit</button>
      <button [disabled]="whitepaperForm.pristine" (click)="reset()" class="secondary" type="button">Clear</button>
  </div>
  </form>
</div>
