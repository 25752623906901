export interface NgNavigation {
    name: string,
    link: string,
    text: string,
    outerLink: boolean,
    newTab: boolean,
    altText: string;
    itemType?: MenuItemType,
    parent?: string,
    pageLink?: boolean,
    appLink?: boolean,
    onlyFor?: string,
    permissionRequired?: string,
    customClass?: string,
    removeOnSelf?: boolean,
    hide?: boolean
}

export enum MenuItemType {
    link = 'link',
    dropdown = 'dropdown'
}

export enum LicenseStatus {
    free = 'free',
    valid = 'valid',
    expiring = 'expiring',
    expired = 'expired',
    invalid = 'invalid',
    trial = 'trial'
}

export interface NgNavigationApps extends NgNavigation {
    licenseStatus: LicenseStatus,
    canQuote?: boolean,
    canRenew?: boolean,
    canLink?: boolean,
    licenseInvalid?: boolean,
    invalidLicenseLink?: string,
    invalidLicenseText?: string,
    invalidLicenseAltText?: string,
    quoteButtonLink?: string,
    quoteButtonText?: string,
    renewButtonLink?: string,
    renewButtonText?: string,
}
