import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faFile, faCloudUploadAlt, faUpload, faTrash, faFilter, faCheck } from '@fortawesome/free-solid-svg-icons';
import { IFormField } from '../../models/formField';
import { DecimalPipe } from '@angular/common';
import { debounceTime } from 'rxjs/operators';
import { BsDropdownConfig, BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'lib-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: true, insideClick: true } }],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({opacity: 0, transform: "translate(-10px, 0)"}),
        animate('300ms ease-in', style({opacity: 1, transform: "translate(0, 0)"}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({opacity: 0, transform: "translate(-10px, 0)"}))
      ])
    ]),
    trigger('slideInOutVertical', [
      transition(':enter', [
        style({opacity: 0, transform: "translate(0, -10px)"}),
        animate('0.5s 0.5s ease-in', style({opacity: 1, transform: "translate(0, 0)"}))
      ]),
      transition(':leave', [
        animate('0.4s ease-in', style({opacity: 0, transform: "translate(0, 10px)"}))
      ])
    ]),
    trigger('toggleOpacity', [
      state('text', style({
          opacity: '0',
          visibility: 'hidden'
      })),
      state('password', style({
          opacity: '1',
          visibility: 'visible'
      })),
      transition('password => text', animate('200ms ease-in-out')),
      transition('text => password', animate('200ms ease-in-out'))
  ])
  ]
})
export class FileInputComponent implements OnInit {
  @Input() fieldSettings: IFormField;
  @Input() formGroupParameter: FormGroup;
  @Input() rightSideErrors: boolean = false;
  @Input() inputFile: File = null;
  @Input() index: number = null;
  @Input() arrayName: string = null;
  @Input() minLayout: boolean = false;
  @Input() minInput: boolean = false;
  @Output() fileUpdated: EventEmitter<File> = new EventEmitter<File>();
  @Output() fileRemoved: EventEmitter<File> = new EventEmitter<File>();
  @Output() fileValidStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setPristine: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fileDropRef') fileDropVariable: ElementRef;

  fileSelected: File;
  fileName: string;
  fileNameBase: string;
  fileExtension: string;
  fileSize: string;
  fieldValid = false;
  allowedSize = "";

  uploadIcon = faCloudUploadAlt;
  fileIcon = faFile;
  removeIcon = faTrash;
  filterIcon = faFilter;
  checkIcon = faCheck;

  showSuggestedExtensions = false;

  constructor(
    private cd: ChangeDetectorRef,
    private numberPipe: DecimalPipe
  ) { }

  ngOnInit(): void {
    if(this.fieldSettings.maxFileSize) {
      this.allowedSize = this.getFileSize(this.fieldSettings.maxFileSize);
    }
    // console.log(this.arrayName)

    this.getField().statusChanges.pipe(debounceTime(500)).subscribe(
      value => {
        // console.log("FILE STATUS CHANGES")
        if (this.fileSelected) this.fieldValid = this.fileValidation(this.fileSelected);
        // this.fileValidStatus.emit(this.fieldValid);
      }
    );
  }

  toggleExtensions(): void {
    this.showSuggestedExtensions = !this.showSuggestedExtensions;
  }

  public ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    if (changes.fieldSettings && !changes.fieldSettings.firstChange && changes.fieldSettings.currentValue?.allowedExtensions !== changes.fieldSettings.previousValue?.allowedExtensions) {
      if (this.fileSelected) this.fieldValid = this.fileValidation(this.fileSelected);
      this.fileValidStatus.emit(this.fieldValid);
    }
    if(changes.inputFile && !changes.inputFile.firstChange && changes.inputFile.currentValue !== changes.inputFile.previousValue) {
      // console.log(changes.inputFile);
      // console.log(this.fileSelected);
      // console.log(this.inputFile);
      if(changes.inputFile.currentValue === null) {
        // console.log('RESETING');
        this.reset();
        this.fieldValid = false;
      }
      // this.fileSelected = this.inputFile;
    }
  }

  onFileDropped($event):void {
    this.fileDropVariable.nativeElement.files = $event;
    this.prepareFilesList($event);
  }

  deleteFile() {
    this.reset();
    this.fileRemoved.emit(null);
  }

  reset() {
    if(!!this.fileSelected) {
      this.fileDropVariable.nativeElement.files = null;
      this.fileDropVariable.nativeElement.value = null;
      this.fileSelected = null;
      this.fileName = null;
      this.fileNameBase = null;
      this.fileSize = null;
      this.fileUpdated.emit(null);

      this.fieldSettings.errorMessages = [];
      if(this.fieldSettings.errorMessagesList && this.fieldSettings.errorMessagesList['serverError']) delete this.fieldSettings.errorMessagesList['serverError'];

    }
    // this.setPristine.emit();
  }

  getFormGroupName(): FormGroup {
    return (this.arrayName ?
      this.formGroupParameter.controls[this.arrayName]['controls'][this.index] :
      this.formGroupParameter)
  }

  // getField(): any {
  //   return this.formGroupParameter.get(this.fieldSettings.inputName);
  // }

  getField(): FormControl {
    return (this.arrayName ?
      this.formGroupParameter.controls[this.arrayName]['controls'][this.index].get(this.fieldSettings.inputName) :
      this.formGroupParameter.get(this.fieldSettings.inputName))
  }

  getId():string {
    return (this.index ? this.fieldSettings.id + this.index : this.fieldSettings.id);
  }

  requiredInvalid(): boolean {
    let field = this.getField();
    return (field.touched || field.dirty) && field.errors?.required;
  }

  prepareFilesList(files: Array<any>) {
    if (files?.length > 0) {
      let reader = new FileReader();
      this.fileSelected = files[0];
      this.fileExtension = this.getExtension(this.fileSelected);
      this.fileSize = this.getFileSize(this.fileSelected.size);
      this.fileName = this.fileSelected.name;
      let period = this.fileName.lastIndexOf('.');
      this.fileNameBase = this.fileName.substring(0,period);
      // this.fileNameBase = this.fileName.split('.')[0];
      if(this.fieldSettings.errorMessagesList && this.fieldSettings.errorMessagesList['serverError']) delete this.fieldSettings.errorMessagesList['serverError'];
      this.fieldValid = this.fileValidation(this.fileSelected);
      this.getFormGroupName().get(this.fieldSettings.inputName).markAllAsTouched();

      if (this.fieldValid) {
        reader.readAsDataURL(this.fileSelected);

        reader.onload = () => {

          this.cd.markForCheck();
          this.fileUpdated.emit(this.fileSelected);
          this.fileValidStatus.emit(true);
        };

      } else {
        this.fileValidStatus.emit(false);
      }
    }
  }

  fileBrowseHandler(files) {
    // console.log(files);
    this.prepareFilesList(files);
  }
  fileValidation(file: File): boolean {
    // console.log("FILE VALIDATION")
    this.fieldSettings.errorMessages = [];
    let valid = true;
    if (this.fieldSettings.maxFileSize && !this.fileSizeValidation(file)) {
      this.fieldSettings.errorMessages.push(this.fieldSettings.errorMessagesList['maxfilesize']);
      valid = false;
    }
    // if(this.fieldSettings.allowedExtensions && !this.fileExtensionValidation(file)) {
    //   this.fieldSettings.errorMessages.push(this.fieldSettings.errorMessagesList['extension']);
    //   valid = false;
    // }
    if(this.fieldSettings.errorMessagesList && this.fieldSettings.errorMessagesList['serverError']) {
      this.fieldSettings.errorMessages.push(this.fieldSettings.errorMessagesList['serverError']);
      valid = false;
      // delete this.fieldSettings.errorMessagesList['serverError'];
    }

    return valid;
  }

  fileSizeValidation(file: File): boolean {
    // console.log(file.size <= this.fieldSettings.maxFileSize);
    return (file.size <= this.fieldSettings.maxFileSize);
  }

  fileExtensionValidation(file: File): boolean {
    let extensions: string[] = this.fieldSettings.allowedExtensions.split(',');
    let currentExt = "." + this.getExtension(file);
    return extensions.includes(currentExt);
  }

  getExtension(file: File): string {
    return file.name.split('.').pop();
  }

  getFileSize(fileSize: number): string {
    if(fileSize < 1024) {
      return `${fileSize} bytes`
    } else if(fileSize < 1048576) {
      let size = fileSize / 1024;
      return `${this.numberPipe.transform(size, '1.2-2')} KB`
    } else {
      let size = fileSize / 1048576;
      return `${this.numberPipe.transform(size, '1.2-2')} MB`
    }
  }

}
