<div *ngIf="fieldSettings" class="form-group"
  [class.min-bottom-margin]="minBottomMargin"
  [class.no-bottom-margin]="noBottomMargin">
  <div [formGroup]="getFormGroupName()" [class.inline-layout]="inlineLayout">

    <label [for]="getId()"
      *ngIf="fieldSettings.label && !noLabel"
      [ngClass]="{'required': fieldSettings.required === true}">{{fieldSettings.label}}</label>

    <span tabindex="0"
      class="input-tooltip-icon"
      *ngIf="fieldSettings.tooltipText"
      tooltip="{{fieldSettings.tooltipText}}"
      animation-duration="500"
      tooltip-class="input-tooltip"
      hide-delay="500"
      hideDelayAfterClick="5000"
      trigger="hover"
      placement="top"
      max-width="300">
      <fa-icon [icon]="faQuestionCircle"></fa-icon>
    </span>

    <p *ngIf="fieldSettings.instruction && !noLabel" class="input-instruction">
      {{fieldSettings.instruction}}
    </p>

    <div class="input-container"
      [class.small]="small"
      [class.is-valid]="fieldValid === true"
      [ngClass]="{'is-invalid': (fieldSettings.errorMessages && fieldSettings.errorMessages.length > 0) || requiredInvalid() }">

      <ng-select
        #Selector
        class="form-control form-control-lg"
        appearance="outline"
        [id]="getId()"
        [disabled]="disabled"
        placeholder="{{fieldSettings.placeholder}}"
        labelForId="fieldSettings.label"
        (focus)="onFocus()"
        [searchable]="false"
        [clearable]="fieldSettings.clearButton || false"
        formControlName="{{fieldSettings.inputName}}"
        (change)="selectChanged($event)"
        [ngClass]="{'is-invalid': (fieldSettings.errorMessages && fieldSettings.errorMessages.length > 0) || requiredInvalid() }"
        [virtualScroll]="true">
        <ng-option *ngFor="let option of fieldSettings.options" [value]="option.value" [innerHTML]="option.text"></ng-option>
        <ng-template *ngIf="customValue" ng-footer-tmp>
          <div class="dropdown-footer-form">
            <div>
                <lib-form-field [small]="true" (enterKey)="addCustomValue()" [noBottomMargin]="true" [formGroupParameter]="formGroupParameter" [fieldSettings]="textFieldSettings"></lib-form-field>
                <!-- <p class="input-instruction">{{textFieldSettings.instruction}}</p> -->
            </div>
            <button class="primary" [disabled]="addButtonDisabled()" type="button" (click)="addCustomValue()">{{addValueButtonText}}</button>
          </div>
        </ng-template>
      </ng-select>

      <div class="errors-container" [class.right-side-errors]="rightSideErrors">
        <span class="inline-field-errors" *ngIf="requiredInvalid()" [@slideInOut]>{{fieldSettings.requiredErrorMessage}}</span>
        <span class="inline-field-errors" *ngFor="let errMgs of fieldSettings.errorMessages" [@slideInOut]>{{errMgs}}</span>
      </div>

    </div>
  </div>
</div>
