import { EventEmitter, Injectable } from '@angular/core';
import { FormSummary, FormSummaryGroup } from 'projects/nine-gold-lib/src/lib/directives/form-summary/form-summary';
import { IFormField, InputTypes } from 'projects/nine-gold-lib/src/lib/models/formField';
import { Uses } from 'projects/nine-gold-lib/src/lib/models/uses';
import { ApiMapping, ApplicationFormService } from 'projects/nine-gold-lib/src/lib/services/application-form.service';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';

@Injectable()

export class GeneratorParseService extends ApplicationFormService {
  formName = "restgen-parse";

  fieldsSummary: FormSummary[];
  fieldSummaryGroups: FormSummaryGroup[];

  customSuccess = true;

  success: EventEmitter<Uses> = new EventEmitter<Uses>();
  error: EventEmitter<any> = new EventEmitter<any>();
  clear: EventEmitter<any> = new EventEmitter<any>();

  parseResults: {};

  setInitialFieldsSummary(): void {
    this.fieldsSummary = [
      {name: 'targetType', label: 'Schema', text: '', value: '', valid: false, parentId: 'target'},
      {name: 'targetContract', label: 'Filename', text: '', value: '', valid: false, parentId: 'target'}
    ];
  }

  generateSuccess(): void {
    this.success.emit();
  }

  customSuccessHandler(result: any): void {
    this.success.emit(result);
  }

  setApiDataMapping(): ApiMapping[] {
    return [
      {
        ApiProperty: 'provided',
        FieldName: 'targetContract'
      },
      {
        ApiProperty: 'targetType',
        FieldName: 'targetType'
      }
    ];
  }

  public setTarget(type: string, name: string): void {
    if (type) {
      this.updateFieldSummary('targetType',type,type,true);
    }
    if(name) {
      this.updateFieldSummary('targetContract',name,name,true);
    } else {
      this.updateFieldSummary('targetContract','','',false);
    }
  }

  setFormName(): string {
    return "restgen-parse";
  }

  setPreFormData(): IFormField[] {
    return [
      {
        inputName: "targetType",
        id: "targetTypeId",
        placeholder: null,
        label: "Definition",
        value: "",
        inputType: InputTypes.select,
        instruction: null,
        suggestedExtensions: ".wsdl,.yaml,.json,.raml"
      },
      {
        inputName: "targetContract",
        id: "targetContractId",
        placeholder: null,
        label: "API Contract",
        value: null,
        inputType: InputTypes.file,
        instruction: "Upload the schema definition for your downstream service."
      }
    ];
  }

  public clearParseFile(): void {
    this.resetForm();
    this.clear.emit();
  }

  parseFile(): void {
    let form = this.formData;
    let fd = new FormData();
    this.loading = true;
    fd.append('language','MULE4');
    fd.append('type',this.formData.value['targetType']);
    fd.append('target',this.formData.value['targetContract']);
    let formApiUrl = this.appSettingsService.getApi('appUse',this.userService.getCompanyId(),'restgen-parse');
    this.formService.postForm(formApiUrl, fd).subscribe({
      next: result => {
        this.loading = false;
        this.parseResults = result['results']?.response;
        // TODO: add special handling (if there is one set)
        if(this.customSuccess) {
          this.customSuccessHandler(result);
        } else {
          this.formService.formSuccess("Generation Successful", FormResultMessageFormat.modal, form);
          this.resetForm();
        }

      },
      error: err=> {
        console.log(err);
        this.loading = false;
        this.formService.formError(err, form, this.formSettings);
        this.error.emit();
      }
    });
  }
}
