import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ProgressBar, ProgressBarEventData } from './progress-bar';
import { ConfirmDialogService, NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { Router } from '@angular/router';
import { Observable, interval } from 'rxjs';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  barOn = false;
  hidden = false;
  doneCounter = 0;
  activeCounter = 0;

  statusApiUrl: string;
  statusCompleted = 'completed';

  checkingParseStatus = false;
  checkingDocumentStatus = false;
  leftGenerator = false;
  parseError = false;

  baseInterval = 1000;  //miliseconds
  retryNumber: number;
  exponential = 2;

  timeoutFunction: any;


  stepsInterval: any;

  completedStatus: EventEmitter<ProgressBarEventData> = new EventEmitter<ProgressBarEventData>();
  completeDocument: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private confirm: ConfirmDialogService,
    private notif: NotificationService
  ) { }

  barData: ProgressBar = {
    steps: [
      {name: 'parse', activeText: 'Submitting', doneText: 'Submitted', active: false, done: false},
      {name: 'status', activeText: 'Parsing', doneText: 'Parsed', active: false, done: false},
      {name: 'documentation', activeText: 'Documentation', doneText: 'Documentation', active: false, done: false},
      {name: 'endpoints', activeText: 'Loading Endpoints', doneText: 'Endpoints', active: false, done: false},
      {name: 'generation', activeText: 'Generating', doneText: 'Generated', active: false, done: false}
    ]
  }

  public showBar(): void {
    this.hidden = true;
    this.barOn = true;
    document.body.classList.add('status-bar');
    setTimeout(() => {
      this.hidden = false;
    }, 1);
  }

  public hideBar(): void {
    this.hidden = true;
    document.body.classList.remove('status-bar');
  }

  public leavingGenerator(): void {
    this.leftGenerator = true;
  }

  private timer(): void {
    let interval = this.baseInterval * Math.pow(this.exponential,this.retryNumber);
    this.retryNumber++;
    this.timeoutFunction = setTimeout(() => {
      this.http.get(this.statusApiUrl,{withCredentials: true}).subscribe({
        next: result => {
          if(!result) {
            this.statusErrorHandler();
          } else {
            // * ERROR statuses
            if(this.checkingParseStatus && ['timeout','error'].includes(result['parseStatus'].toLocaleLowerCase())) {
              // *  Checking PARSE status and comes error
              // Set error, send it back and set parseError to stop checking for documentation
              // Go to #A
              this.completedStatus.emit({valid: false, showError: false});
              this.checkingParseStatus = false;
              this.setError('status');
              this.notif.error('Parsing Status Error',result['parseFriendlyMessage'],FormResultMessageFormat.popup,6000,null);
              this.parseError = true;
            }
            if(!this.parseError && this.checkingDocumentStatus && ['timeout','error'].includes(result['documentationStatus'].toLocaleLowerCase())) {
              // Checking for no parse error prevents setting documentation status twice
              this.completeDocument.emit(false);
              this.checkingDocumentStatus = false;
              this.setError('documentation');
              this.notif.error('Documentation Status Error',result['documentationFriendlyMessage'],FormResultMessageFormat.popup,6000,null);
            }

            // *  COMPLETED statuses
            if(this.checkingParseStatus && result['parseStatus'].toLocaleLowerCase() === 'completed') {
              this.completedStatus.emit({valid:true,showError:true});
              this.checkingParseStatus = false;
              this.setDone('status');
              if(this.leftGenerator) this.parsingCompletedOutside(result['filename'],result['cacheKey']);
            }
            if(!this.parseError && this.checkingDocumentStatus && result['documentationStatus'].toLocaleLowerCase() === 'completed') {
              this.completeDocument.emit(true);
              this.checkingDocumentStatus = false;
              this.setDone('documentation');
            }

            // *  PARSE WAS ERROR
            if(this.parseError && this.checkingDocumentStatus) {
              // * #A
              // If parse error (parseStatus was ERROR) and still checking for documentation status (status IN_PROCESS)
              // Set document status to error
              this.completeDocument.emit(false);
              this.checkingDocumentStatus = false;
              this.setError('documentation');
            }

            // * BOTH COMPLETED OR TIME IS OUT
            if((!this.checkingDocumentStatus && !this.checkingParseStatus) || this.retryNumber === 6) {
              // If status for both parse and documentation changed (to error or completed) do nothing
              if(this.checkingDocumentStatus) {
                // if timer is out but still checking for document status
                this.completeDocument.emit(false);
                this.checkingDocumentStatus = false;
                this.setError('documentation');
              }
              if(this.checkingParseStatus) {
                // if timer is out but still checking for parse status
                this.completedStatus.emit({valid:false,showError:false});
                this.checkingParseStatus = false;
                this.setError('status');
              }

            } else {
              // otherwise check again
              if(!this.parseError) {
                this.timer();
              }

            }
          }

        },
        error: err => {

          this.statusErrorHandler();

        }
      });

    }, interval);
  }

  private statusErrorHandler(): void {
    if(this.retryNumber < 5) {
      this.timer();
    } else {
      if(this.checkingParseStatus) {
        this.completedStatus.emit({valid:false,showError:false});
        this.checkingParseStatus = false;
        this.setError('status');
      }

      if(this.checkingDocumentStatus) {
        this.completeDocument.emit(false);
        this.checkingDocumentStatus = false;
        this.setError('documentation');
      }
    }
  }


  public startStatusApi(api: string): void {
    this.statusApiUrl = api;
    this.leftGenerator = false;
    this.setActive('status');
    this.setActive('documentation');
    this.checkingParseStatus = true;
    this.checkingDocumentStatus = true;
    this.parseError = false;
    let startTime = 0;
    let i = 0;
    this.retryNumber = 0;
    this.timer();
  }

  parsingCompletedOutside(filename: string, cacheKey: string): void {
    let confirmMessage = `Target file <strong>${filename}</strong> was parsed succesfully.<br>Do you want to use it in REST Generator?`;

    this.confirm.confirm({title: 'Parsing Completed',message: confirmMessage,confirmBtn: 'Generate',declineBtn: 'Cancel',html: true}).subscribe(res => {
      if(res) {
        clearTimeout(this.timeoutFunction);
        this.router.navigate(['/app/generator',filename,cacheKey]);
      } else {
        this.resetCounters();
        this.hideBar();
      }
    });
  }

  public setActive(name: string): void {
    this.barData.steps.find(s => s.name === name).active = true;
    this.barData.steps.find(s => s.name === name).error = false;
    this.barData.steps.find(s => s.name === name).done = false;
  }

  public setError(name: string): void {
    let step = this.barData.steps.find(s => s.name === name);
    if(step) {
      step.done = true;
      step.active = false;
      step.error = true;
    }
  }

  public setDone(name: string): void {
    let step = this.barData.steps.find(s => s.name === name);
    if(step) {
      step.done = true;
      step.active = false;
    }
  }

  public gettingStatusOrDocumentation(): boolean {
    return !this.barData.steps.find(s=>s.name === 'status').active && !this.barData.steps.find(s=>s.name === 'documentation').active;
  }

  public nextDone(): void {
    this.doneCounter++;
    this.barData.steps[this.doneCounter - 1].done = true;
    this.barData.steps[this.doneCounter - 1].active = false;
  }

  public nextActive(): void {
    this.activeCounter++;
    this.barData.steps[this.activeCounter - 1].active = true;
  }

  public resetCounters(): void {
    this.doneCounter = 0;
    this.activeCounter = 0;
    this.barData.steps.forEach(s => {
      s.active = false;
      s.done = false;
      s.error = false;
    });
    clearTimeout(this.timeoutFunction);
  }

}
