import { Component, OnInit, ViewEncapsulation, AfterContentInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { ApiUser, IUser, UserTypesData } from '../../models/user';
import { faPowerOff, faCaretDown, faCog, faFingerprint, faIdCard, faUsers, faUserShield, faHistory, faBars, faTools } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lib-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserDropdownComponent implements OnInit {
  public opened: boolean;
  userLoggedIn: boolean;
  openFull = false;
  userInfo: ApiUser;
  sidebarClass = "demo-sidebar";
  faPowerOffIcon = faPowerOff;
  faCaretDownIcon = faCaretDown;
  faCogIcon = faCog;
  faSecurityIcon = faFingerprint;
  faLicenseIcon = faIdCard;
  faSubusersIcon = faUsers;
  supportIcon = faTools;

  // navIcons = {};
  navIcons  = {
    'user-info': {
      icon: faCog
    },
    'user-license': {
      icon: faIdCard
    },
    'user-subusers': {
      icon: faUsers
    },
    'user-security': {
      icon: faFingerprint
    },
    'global-admin': {
      icon: faUserShield
    },
    'actions': {
      icon: faHistory
    },
    'uses': {
      icon: faBars
    }
  };

  userRoleIcon: UserTypesData;
  userPermissions: {};


  @ViewChild('sidebar') sideBar;

  constructor(
    private authService: AuthService,
    public userService: UserService,
    private uiService: UserInterfaceService,
    private notifications: NotificationService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.userInfo = this.userService.getUserInfo();
    // console.log(this.userInfo);
    if(this.userInfo) {
      this.userPermissions = this.userService.getPermissions();
      this.userRoleIcon = this.userService.getHighestRole();
    }

    this.userService.userUpdated.subscribe(
      (user) => {
        this.userInfo = user;
        if(this.userInfo) {
          this.userPermissions = this.userService.getPermissions();
          this.userRoleIcon = this.userService.getHighestRole();
        }
      }
    );
    // this.uiService.userDropdownUpdated.subscribe(
    //   (status) => {
    //     console.log('STATUSSTATUS');
    //     console.log(status);
    //     this.opened = status;
    //   }
    // );
    this.opened = false;
    this.userInfo = this.userService.getUserInfo();
    // this.opened = this.uiService.getUserDropdownStatus();
    // this.sideBar.close();
    // this.userInfo = this.userService.getUserInfo();
    // console.log("INIT SIDEBAR");
    // this._opened = false;
  }
  // ngAfterViewInit(): void {
  //   this.userInfo = this.userService.getUserInfo();
  //   console.log("INIT SIDEBAR");
  //   this._opened = false;
  // }

  public _toggleSidebar() {
    this.opened = !this.opened;
    // this.uiService.setUserDropdownStatus(this.opened);
  }

  isOpened():boolean {
    return this.opened;
  }

  ngAfterContentInit(){
    // this._opened = false;
    // this.sideBar.close();
  }

  public closeSidebar() {
    // this.sideBar.close();
    // this._opened = false;
  }

  // ngAfterViewChecked() {
  //   console.log("after view init");
  //   this.opened = this.uiService.getUserDropdownStatus();
  // }

  onSidebarClosed():void {
    // this._opened=false;
  }
  ngOnDestroy(){
    // console.log("destroying child...")
  }

  NewLogout() {
    this.authService.new_logout();
  }

  Logout() {
    // console.log("Loggin Out");
    this.authService.logout();
    this.userLoggedIn = this.userService.userLoggedIn();
    this.uiService.setDemoAndQuote(null);
    // this.notifications.success('Logout Successful', 'User was successfully logged out.', FormResultMessageFormat.popup, null, null);

    // this.router.navigate(['/login']);
  }

  onActivate(event):void {
    // console.log(event);
    this.openFull = true;
    this.sidebarClass = "demo-sidebar open-full";
    this.renderer.addClass(document.body, 'modal-open');
  }
  onDeactivate(event):void {
    // console.log(event);
    this.openFull = false;
    this.sidebarClass = "demo-sidebar";
    this.renderer.removeClass(document.body, 'modal-open');
  }

}
