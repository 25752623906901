import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { slideInOutAnimation } from 'projects/nine-gold-lib/src/lib/animations/slide-in-out.animation';
import { ApiProductExtended } from 'projects/nine-gold-lib/src/lib/models/product';
import { ProductService } from 'projects/nine-gold-lib/src/lib/services/product.service';
import { ConfirmDialogService } from 'projects/nine-gold-lib/src/lib/shared/confirm-dialog/confirm-dialog.service';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-quote-form',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.scss'],
  animations: [slideInOutAnimation],
  host: { '[@slideInOutAnimation]': '' }
})
export class QuoteFormComponent implements OnInit {
  faTimesIcon = faTimes;
  redirectUrl: string;
  productCode: string;
  licenseType: string = null;

  formDirty = false;

  action: string;

  productInputData: ApiProductExtended;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private productService: ProductService,
    private confirmService: ConfirmDialogService,
    private uiService: UserInterfaceService
  ) { }

  @HostListener('window:beforeunload',['$event'])
  unloadNotification($event:any) {
    if(this.formDirty) {
      $event.returnValue = 'Message';
    }
  }

  ngOnInit(): void {

    let sub = this.route.params.subscribe(params => {
      this.productCode =  params['id']; // (+) converts string 'id' to a number
      this.action = params['action'];

      this.licenseType = params['licenseType'] || null;
      // In a real app: dispatch action to load the details here.
   });

   this.productInputData = this.productService.getProductById(this.productCode);

    this.redirectUrl = this.route.snapshot.queryParams['returnUrl'];
    // this.productCode = this.route.snapshot.queryParams['pcode'];
  }

  clickToClose(event): void {
    let formContainer = document.getElementById('quoteContainer');
    if(event.target !== formContainer || formContainer.contains(event.target)) {
    }
  }

  canDeactivate():Observable<boolean> {
    if (this.formDirty) {
      return this.confirmService.confirm(this.uiService.getConfirmMessage('formNotSaved')).pipe(map(result =>{
        return !!result;
      }));
    } else {
      return of(true);
    }
  }

  formDirtyChange(dirty): void {
    this.formDirty = dirty;
  }

  goBack(): void {
    // this.location.back();
    // this.router.navigate(['pricing']);
    this.router.navigate(
			[
        "../",
				// NOTE: No relative-path navigation is required because we are accessing
				// the parent's "activatedRoute" instance. As such, this will be executed
				// as if we were doing this in the parent view component.
				{
					outlets: {
            sidePopup: null
					}
				}
      ],
      // {
      //   queryParams: null
      // },
			{
				relativeTo: this.route.parent // <--- PARENT activated route.
			}
		);

  }

}
