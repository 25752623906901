import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortColumns'
})
export class SortColumnsPipe implements PipeTransform {

  transform(array: any): any {

    return array.sort((a,b) =>{
      if(a.columnOrder < b.columnOrder) return -1;
        else if(a.columnOrder > b.columnOrder) return 1;
        else return 0;
    });
  }

}
