import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormResultMessageFormat } from '../shared/notification/notification';
import { NotificationService } from '../shared/notification/notification.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {
  private _router: Router;

constructor(private authService: AuthService, private notSrvc: NotificationService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // debugger;
    if (req.withCredentials) {
      return from(this.authService.getAccessToken().then(token => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        const authReq = req.clone({ headers, withCredentials: false });
        return next.handle(authReq).pipe(tap(_ => { }, error => {
          var respError = error as HttpErrorResponse;
          // console.log("INTERCEPTOR ERROR");
          if (respError && (respError.status === 401 || respError.status === 403)) {
            this.notSrvc.error(respError.name, respError.message, FormResultMessageFormat.popup, 3000, null);
            // this._router.navigate(['/unauthorized']);
          } else if(respError && (respError.status === 0)) {
            this.notSrvc.error(respError.name, respError.message, FormResultMessageFormat.popup, 3000, null);
          }
        })).toPromise();
      }));
    } else {
      return next.handle(req).pipe(tap(_ => { }, error => {
        var respError = error as HttpErrorResponse;
        // console.log("INTERCEPTOR ERROR");
        if (respError && (respError.status === 401 || respError.status === 403)) {
          this.notSrvc.error(respError.name, respError.message, FormResultMessageFormat.popup, 3000, null);
          // this._router.navigate(['/unauthorized']);
        } else if(respError && (respError.status === 0)) {
          this.notSrvc.error(respError.name, respError.message, FormResultMessageFormat.popup, 3000, null);
        }
      }));
    }

    // TODO: add condition to attach token to all API except some
    // if (req.url.startsWith(Constants.apiRoot)) {}


    // throw new Error('Method not implemented.');
  }

}
