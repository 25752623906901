import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressBarService } from './progress-bar.service';
import { ProgressBar } from './progress-bar';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProgressBarComponent implements OnInit {
  checkIcon = faCheck;
  errorIcon = faExclamation;


  constructor(
    private router: Router,
    public progressBarService: ProgressBarService
  ) { }



  ngOnInit(): void {
  }

}
