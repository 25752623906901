<main class="whitepaper-form-page">
  <div class="inner">
    <div class="flex-columns auto-columns">
      <div class="info-container">
        <h1>Safeguard Your Business From Technical Debt</h1>
        <p>The cornerstone to code quality and design is defining a set of best practices and design patterns to be standardized across the enterprise. This provides developers a framework of proven solutions to common challenges to help improve the consistency and quality of the code delivered so that it can be supported and scaled easily.</p>
      </div>
      <div class="form-container">
        <!-- <h3>Contact Sales</h3> -->
        <lib-whitepaper-form></lib-whitepaper-form>
      </div>
    </div>
  </div>
</main>
