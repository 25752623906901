import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { NgNavigation } from '../../shared/ng-navigation/ng-navigation';


@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() linksList: NgNavigation[];
  productLinks: NgNavigation[];
  companyLinks: NgNavigation[];
  resourcesLink: NgNavigation[];
  socialLinks: NgNavigation[];
  legalLinks: NgNavigation[];
  currentYear: number;

  faTwitterIcon = faTwitter;
  faYoutubeIcon = faYoutube;
  faEnvelopeIcon = faEnvelope;
  faMapPinAltIcon = faMapMarkerAlt;
  faPhoneAltIcon = faPhoneAlt;
  LinkedInIcon = faLinkedin;

  constructor(public router: Router,) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    if(this.linksList) {
      this.productLinks = this.remapList(this.linksList, 'products');
      this.companyLinks = this.remapList(this.linksList, 'company');
      this.resourcesLink = this.remapList(this.linksList, 'resources');
      this.socialLinks = this.remapList(this.linksList, 'social');
      this.legalLinks = this.remapList(this.linksList, 'legal');
      // console.log(this.productLinks);
    }
  }

  scrollToTop(shouldScroll: boolean): void {
    if (!shouldScroll) {
      return;
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  
    // scroll!
  }

  remapList(inputList: NgNavigation[], parent: string): NgNavigation[] {
    return inputList.filter(el => el.parent === parent);
  }

}
