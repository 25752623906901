<section class="subpage-content">
    <header class="app-page-header">
        <h2>
            <!-- <fa-icon [icon]="downloadIcon"></fa-icon> -->
            <span class="downloads-icon">
                <fa-icon class="icon-1" [icon]="downloadIcon"></fa-icon>
                <fa-icon class="icon-2" [icon]="downloadIcon"></fa-icon>
            </span>
            Downloads: <span class="downloads-product-name">REST Generator</span>
        </h2>
    </header>

    <lib-box-container
        id="downloadsTable"
        [firstOnTheTop]="true">
        <!-- [boxHeading]="'Downloads List'"> -->

        <lib-ng-list (buttonClicked)="actionButtonClicked($event)" [listsService]="downloadService"></lib-ng-list>
        <lib-ng-pagination [listsService]="downloadService"></lib-ng-pagination>

    </lib-box-container>

</section>

<ng-template #restGenUseDetails>
    <div *ngIf="useDetails" class="use-downloads-modal">
        <h2><span class="label">Project name</span><span class="project-name">{{useDetails.projectName}}</span></h2>
        <h3 class="tool-name">REST Generator</h3>
        <!-- <h4 class="generation-status" ngClass="'status-{{useDetails.status}}">{{useDetails.status}}</h4> -->
        <div class="border-with-title accordion-border">
            <h4>Summary</h4>
            <div class="centered-modal-table centered-value">
                <table>
                    <tr>
                        <td class="label">Started</td>
                        <td class="value">{{useDetails.started | date:'longDate'}}</td>
                    </tr>
                    <!-- <tr *ngIf="useDetails.completed">
                        <td class="label">Completed</td>
                        <td class="value">{{useDetails.completed | date: 'longDate'}}</td>
                    </tr> -->
                    <tr>
                        <td class="label">User</td>
                        <td class="value">{{useDetails.user.userName}}</td>
                    </tr>
                    <tr>
                        <td class="label">Status</td>
                        <td class="value">{{useDetails.status}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="border-with-title" *ngIf="useDetails.status === 'completed' && useDetails.results.completedFile">
            <h4>Results</h4>
            <div class="centered-modal-table centered-value">
                <table>
                    <tr>
                        <td class="label">Result file</td>
                        <td class="value">
                            <lib-file-download [fileUrl]="useDetails.results.completedFileDownload" [fileName]="useDetails.results.completedFile"></lib-file-download>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="border-with-title" *ngIf="useDetails.status === 'error'">
          <h4>Error</h4>
          <p class="field-display-value smaller">
            <span class="label">Error message</span>
            <span class="value">
              <span class="ellipsis">{{useDetails.results.message}}</span>
              <a (click)="copyToClipboard(useDetails.results.message)" class="copy-to-clipboard" title="Copy to clipboard">
                <fa-icon [icon]="copyIcon"></fa-icon>
              </a>
            </span>
          </p>
      </div>

        <div class="border-with-title">
            <h4>Input Params</h4>
            <div class="centered-modal-table centered-value">
                <table>
                    <tr>
                        <td class="label">Platform</td>
                        <td class="value">{{useDetails.params.language}}</td>
                    </tr>
                    <tr *ngIf="useDetails.params?.groupId">
                      <td class="label">Group ID</td>
                      <td class="value">{{useDetails.params.groupId}}</td>
                  </tr>
                    <!-- <tr *ngIf="useDetails.params.generatedType">
                        <td class="label">Generated Type</td>
                        <td class="value">{{useDetails.params.generatedType}}</td>
                    </tr>
                    <tr *ngIf="useDetails.params.generatedContract">
                        <td class="label">API Specification Supplied</td>
                        <td class="value">
                            <lib-file-download [fileUrl]="useDetails.params.generatedContractDownload" [fileName]="useDetails.params.generatedContract"></lib-file-download>
                        </td>
                    </tr> -->
                    <tr *ngIf="useDetails.params.targetType">
                        <td class="label">Target Application Type</td>
                        <td class="value">{{useDetails.params.targetType}}</td>
                    </tr>
                    <tr *ngIf="useDetails.params?.targetFilename">
                      <td class="label">Target Application Filename</td>
                      <td class="value">{{useDetails.params.targetFilename}}</td>
                    </tr>
                    <tr>
                      <td class="label">Specification</td>
                      <td class="value">{{useDetails.params.specification}}</td>
                    </tr>
                    <tr *ngIf="useDetails.params?.suppliedFilename">
                      <td class="label">API Specification Filename</td>
                      <td class="value">{{useDetails.params.suppliedFilename}}</td>
                    </tr>
                    <tr>
                        <td class="label">Endpoints</td>
                        <td class="value">{{useDetails.params.endpoints}}</td>
                    </tr>

                    <tr *ngIf="useDetails.params.specification === 'CUSTOM'">
                        <td colspan="2">
                            <div>
                                <span class="label">Mapping</span>
                                <json-formatter [data]="useDetails.params.mapping"></json-formatter>
                            </div>

                        </td>
                    </tr>
                    <tr>
                    </tr>
                </table>
            </div>
        </div>

        <div class="border-with-no-title">
          <p class="field-display-value smaller">
            <span class="label">Tracking Id</span>
            <span class="value">
              <span class="ellipsis">{{useDetails.params.correlationId}}</span>
              <a (click)="copyToClipboard(useDetails.params.correlationId)" class="copy-to-clipboard" title="Copy to clipboard">
                <fa-icon [icon]="copyIcon"></fa-icon>
              </a>
            </span>
          </p>
          <!-- <div class="centered-modal-table">
            <table>
              <tr *ngIf="useDetails.params.correlationId">
                <td class="label">Tracking Id</td>
                <td class="value">
                  <span class="ellipsis">{{useDetails.params.correlationId}}</span>
                  <a (click)="copyToClipboard(useDetails.params.correlationId)" class="copy-to-clipboard" title="Copy to clipboard">
                    <fa-icon [icon]="copyIcon"></fa-icon>
                  </a>
                </td>

              </tr>
            </table>
          </div> -->
        </div>
    </div>
</ng-template>
