import { Injectable } from '@angular/core';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
// import { ColumnFilter } from 'projects/nine-gold-lib/src/lib/shared/ng-list/ng-list-data';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneratorSettingsService extends AppSettingsService {

  env = environment;

constructor() {
  super();

  this.productCode = "restgen";

  this.baseSetting = this.env;

  this.setNavigations(
    this.baseSetting.projectUrls.platform,
    this.baseSetting.projectUrls.generator,
    this.baseSetting.projectUrls.tools
  );

  this.UpdateLocalLinks(this.footerLinks, this.baseSetting.baseUrl);
  this.UpdateLocalLinks(this.appsList, this.baseSetting.baseUrl);
  this.UpdateLocalLinks(this.productLinks, this.baseSetting.baseUrl);
  this.UpdateLocalLinks(this.resourcesLinks, this.baseSetting.baseUrl);
  this.UpdateLocalLinks(this.topNavLinks, this.baseSetting.baseUrl);
  this.UpdateLocalLinks(this.infoNavLinks,this.baseSetting.baseUrl);
  this.topNavLinks = this.RemoveLinks(this.topNavLinks, this.baseSetting.baseUrl);

  this.donwnloadFilters = null;

  this.defaulDownloadParams = {
    numberOfPages: 5,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [
      {
        field: "project_type",
        value: "MULE4"
      }
    ],
    sorting: {
      field: "generated",
      order: "d"
    }
  }

  this.demoAndQoute = true;
}

}
