import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lib-box-container',
  templateUrl: './box-container.component.html',
  styleUrls: ['./box-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BoxContainerComponent implements OnInit {
  @Input() boxHeading: string = "";
  @Input() firstOnTheTop: boolean = false;
  @Input() lessPadding: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
