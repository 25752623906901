<div class="figure">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="150px" height="150px" viewBox="0 0 200 200" enable-background="new 0 0 200 200" xml:space="preserve">
      <path #path class="path" fill="none" stroke="#ffffff" stroke-width="5" stroke-miterlimit="5" d="M100.416,2.001C154.35,2.225,198,46.015,198,100
        c0,54.124-43.876,98-98,98S2,154.124,2,100S45.876,2,100,2"/>
      <!--<path class="path" fill="#FFFFFF" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M100,2C100.139,2,100,2,100,2"/>-->
    </svg>
    <!-- <p>{{percentageText}}</p> -->
    <div #number [style.--percent]="percentageText" class="animated-number" [class.times]="times" [class.reversed]="backwards">
        <div class="description">{{text}}</div>
    </div>

    <!-- <p>faster</p> -->
  </div>
