import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simpleFilter',
  pure: false
})
export class SimpleFilterPipe implements PipeTransform {

  transform(list: any[], filter: {field: string, value: any}): any {
    if(!list || !filter) {
      
      return list;
    }
    if(Array.isArray(filter.value)) {
      return list.filter(item => filter.value.includes(item[filter.field]));
    } else {
      return list.filter(item => item[filter.field] === filter.value);
    }
    
  }

}
