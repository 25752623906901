import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { slideInOutAnimation } from 'projects/nine-gold-lib/src/lib/animations/slide-in-out.animation';

@Component({
  selector: 'lib-support-request',
  templateUrl: './support-request.component.html',
  styleUrls: ['./support-request.component.scss'],
  animations: [slideInOutAnimation],
  host: { '[@slideInOutAnimation]': '' }
})
export class SupportRequestComponent implements OnInit {

  formDirty = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
  }

  formDirtyChange(dirty): void {
    this.formDirty = dirty;
  }

  clickToClose(event): void {
    let formContainer = document.getElementById('quoteContainer');
    if(event.target !== formContainer || formContainer.contains(event.target)) {
    }
  }

  goBack(): void {
    this.router.navigate(
			[
        "../",
				// NOTE: No relative-path navigation is required because we are accessing
				// the parent's "activatedRoute" instance. As such, this will be executed
				// as if we were doing this in the parent view component.
				{
					outlets: {
            sidePopup: null
					}
				}
      ],
			{
				relativeTo: this.route.parent // <--- PARENT activated route.
			}
		);

  }

}
