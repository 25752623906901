<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="text-button close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="!html; else html_content" class="modal-body">
    {{message}}
</div>
<ng-template #html_content>
    <div class="modal-body" [innerHtml]="message"></div>
</ng-template>
<div class="modal-footer">
    <button class="secondary" type="button" (click)="respond(false)">{{declineBtn}}</button>
    <button class="primary" [class.danger]="dangerBtn" type="button" (click)="respond(true)">{{confirmBtn}}</button>
</div>