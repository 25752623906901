import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { InviteAndCompanyDetails } from '../../models/invites';
import { ApiUser } from '../../models/user';
import { ApiService } from '../../services/api.service';
import { AppSettingsService } from '../../services/app-settings.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { FormResultMessageFormat } from '../../shared/notification/notification';
import { NotificationService } from '../../shared/notification/notification.service';
import { ConfirmDialogService } from 'projects/nine-gold-lib/src/public-api';
import { ConfirmDialog } from '../../shared/confirm-dialog/confirm-dialog';

@Component({
    selector: 'app-register-callback',
  template: `<div style="padding: 3vh 1vw;"><h2 style="margin: 0; text-align:center;">You have been registered successfully <br> and will be redirected to homepage in <strong>{{timeleft}}</strong> seconds.</h2></div>`
})

export class RegisterCallbackComponent implements AfterViewInit {


    @ViewChild('modal', { static: false }) autoShownModal: ModalDirective;
    modalRef: BsModalRef;

    timeleft: number;

    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private appSettings: AppSettingsService,
        private notif: NotificationService,
        private apiService: ApiService,
        private userService: UserService,
        private confirmService: ConfirmDialogService
    ) { }

    inviteDetails: InviteAndCompanyDetails;
    inviteCode: string;

    confirmMsg: ConfirmDialog = {
      title: 'Trial already used',
      message: 'Trial license was already used by user with that your email address.',
      confirmBtn: 'OK',
      declineBtn: 'Close',
      html: true
    }

    counter(): void {
      this.timeleft = 5;
      let downloadTimer = setInterval(() => {
        if(this.timeleft <= 0){
          clearInterval(downloadTimer);
          this.router.navigate(["/"], { replaceUrl: false });
        } else {
        }
        this.timeleft -= 1;
      }, 1000);
    }


    ngAfterViewInit() {
      this.authService.silentSigninPromise().then(
        x => {
          this.userService.userUpdated.subscribe(u => {
            if(this.userService.currentUser.trialUsedByEmail) {
              this.confirmMsg.message = `Trial license was already used by user with email: <strong>${this.userService.currentUser.email}</strong>.`;
              this.confirmService.confirm(this.confirmMsg).subscribe(x => {
                this.counter();
              });
            } else {
              this.counter();
            }
          });

        }
      )

    }

    GetInvite(): void {
        this.authService.completeLogin().then(user => {
            let returnRoute = user?.state || '/';
            let inviteCode = this.activatedRoute?.queryParams['value']?.state;
            if(inviteCode) {
                this.apiService.getInvitation(inviteCode).subscribe({
                    next: (result: InviteAndCompanyDetails) => {
                        this.inviteDetails = result;
                    },
                    error: err => console.log(err)
                });
            } else {
              this.router.navigate(["/"], { replaceUrl: false });
            }

        }).catch(err => {
            this.authService.silentSigninPromise().then(
                x => {
                    let inviteCode = this.activatedRoute?.queryParams['value']?.state;
                    this.inviteCode = this.activatedRoute?.queryParams['value']?.state;
                    if(inviteCode) {

                        this.apiService.getInvitation(inviteCode).subscribe({
                            next: (result: InviteAndCompanyDetails) => {
                                this.inviteDetails = result;
                            },
                            error: err => console.log(err)
                        });

                    } else {
                      this.router.navigate(["/"], { replaceUrl: false });
                    }
                }
            );
        });
    }

    declineInvite(): void {
        setTimeout(() => {
            this.router.navigate(["/"], { replaceUrl: false });
        }, 500);
    }

    acceptInvite(): void {
        this.apiService.acceptInvitation(this.inviteCode).subscribe({
            next: result => {
                this.notif.success('Invitation accepted', 'Your have accepted your invitaion successfully', FormResultMessageFormat.popup, 3000, null);
                setTimeout(() => {
                    this.http.get(this.appSettings.getApi('currentUser'), {withCredentials: true}).pipe(
                        map((receivedData: ApiUser) => {
                          this.userService.setUser(receivedData, true);
                        })
                      ).subscribe(
                        response => {
                            this.router.navigate(["/"], { replaceUrl: true });
                        },
                        error => {}
                      );

                }, 1000);
            },
            error: err => {
                console.log(err);
                setTimeout(() => {
                    this.router.navigate(["/"], { replaceUrl: true });
                }, 1000);
            }
        })
    }
}
