export interface UiSettings {
    colorMode: string,
    generalTableUI: generatTableUI,
    tables: tableUI[],
    globalAdmin?: GA_settings
    forms?: formsUI[];
}

export interface formsUI {
  formName: string;
  fields: formFieldUI[];
}

export interface formFieldUI {
  fieldName: string;
  prefaredValue: string;
}


export interface GA_settings {
  hideFreeLicenses?: boolean
}

export interface generatTableUI {
    filters?: boolean;
    lockFirst: boolean;
    dens: Density;
}

export interface tableUI extends generatTableUI {
    table: string;
    cols: ColsUI[]
}

export enum Density {
    default = 'dft',
    comfortable = 'cft',
    compact = 'cpt'
}

export interface ColsUI {
    name: string,
    show: boolean,
    order: number
}
