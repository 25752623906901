<div class="side-modal-backdrop" (click)="cancel()"></div>

<div class="popup-summary" [class.active]="summaryPopupActive" [class.closed]="summaryPopupClosed" (click)="toggleSummaryPopup()">
  <div class="summary-inner">
    <div class="summary-button">
      <p>
        <span>Form Summary</span>
        <span class="close-button-container">
          <button title="close form" class="popup-summary-close-button  ">&#xab;</button>
        </span>
      </p>
    </div>

    <lib-form-summary
      [title]="'Support'"
      [subTitle]="'Request'"
      [fields]="fieldsSummaryNew"
      [leftBorder]="true"></lib-form-summary>

  </div>

</div>

<div *ngIf="supportForm" class="side-form-container" [fullScreen]="true" [libLoading]="loading">
  <form (keydown.enter)="fixEnterKeyIssue($event)" id="quoteForm" stickyScroll scrollContainerId="requestContainer" [bottomOffset]="0" novalidate [formGroup]="supportForm">
    <div class="scrollable-fields-container" >
      <h2>Support Request</h2>

      <lib-accordion [accordionBody]="contactInfoBody" [accordionHeader]="contactInfoHeader" #contactAccordion [startOpen]="true"></lib-accordion>

      <ng-template #contactInfoHeader>
        <h4>Contact Information <a class="pointer" *ngIf="contactFieldsDisabled" (click)="setContactFieldsEditable()"><fa-icon [icon]="editIcon"></fa-icon></a></h4>
        <p *ngIf="contactFieldsDisabled" class="input-instruction">Contact Info is prefiled with info on the account. Click <button [attr.disabled]="(contactFieldsDisabled ? null : 'disabled')" role="button" class="text-button auto-height" (click)="setContactFieldsEditable()">Edit Contact Info</button>, if you want to change it.<br>The changes won't affect information stored on your account.</p>
      </ng-template>

      <ng-template #contactInfoBody>
        <lib-form-field [disabled]="supportFormSettings['fullName'].disabled" [formGroupParameter]="supportForm" [fieldSettings]="supportFormSettings['fullName']" [minBottomMargin]="true"></lib-form-field>

        <lib-form-field [disabled]="supportFormSettings['company'].disabled" [formGroupParameter]="supportForm" [fieldSettings]="supportFormSettings['company']" [minBottomMargin]="true"></lib-form-field>

        <lib-form-field [disabled]="supportFormSettings['jobTitle'].disabled" [formGroupParameter]="supportForm" [fieldSettings]="supportFormSettings['jobTitle']" [minBottomMargin]="true"></lib-form-field>

        <lib-form-field [disabled]="supportFormSettings['email'].disabled" [formGroupParameter]="supportForm" [fieldSettings]="supportFormSettings['email']" [minBottomMargin]="true"></lib-form-field>

        <lib-form-field [disabled]="supportFormSettings['phone'].disabled" [formGroupParameter]="supportForm" [fieldSettings]="supportFormSettings['phone']" [noBottomMargin]="true"></lib-form-field>

        <lib-form-field [disabled]="supportFormSettings['industry'].disabled" [formGroupParameter]="supportForm" [fieldSettings]="supportFormSettings['industry']" [noBottomMargin]="true"></lib-form-field>
      </ng-template>

      <div class="fields-group border">
        <h4>Request Levels</h4>

        <lib-select-form-field
          (dropdownChanged)="dropdownUpdates('priority',$event.value)"
          [inlineLayout]="true"
          [minBottomMargin]="true"
          [formGroupParameter]="supportForm"
          [fieldSettings]="supportFormSettings['priority']">
        </lib-select-form-field>

        <lib-select-form-field
          (dropdownChanged)="dropdownUpdates('severity',$event.value)"
          [inlineLayout]="true"
          [minBottomMargin]="true"
          [formGroupParameter]="supportForm"
          [fieldSettings]="supportFormSettings['severity']">
        </lib-select-form-field>
      </div>

      <div class="fields-group border">
        <lib-select-form-field
          (dropdownChanged)="dropdownUpdates('product',$event.value)"
          [minBottomMargin]="true"
          [formGroupParameter]="supportForm"
          [fieldSettings]="supportFormSettings['product']">
        </lib-select-form-field>

        <lib-form-field [disabled]="supportFormSettings['actionCorrelation'].disabled" [formGroupParameter]="supportForm" [fieldSettings]="supportFormSettings['actionCorrelation']" [minBottomMargin]="true"></lib-form-field>

        <!-- <lib-file-input
            (fileValidStatus)="setFileStatus($event, 'files')"
            (fileUpdated)="updateFile($event, 'files')"
            (setPristine)="setFilePristine('files')"
            [formGroupParameter]="supportForm"
            [fieldSettings]="supportFormSettings['files']"
            [inputFile]="supportForm.value['files']">
        </lib-file-input> -->

      </div>

      <div class="fields-group border">
        <h4>Request Details</h4>
        <lib-form-field [formGroupParameter]="supportForm" [fieldSettings]="supportFormSettings['subject']" (onKey)="updateSubject()" (onBlur)="updateSubject()" (onChange)="updateSubject()" [minBottomMargin]="true"></lib-form-field>
        <lib-form-field [formGroupParameter]="supportForm" [fieldSettings]="supportFormSettings['description']" (onKey)="updateDesc()" (onBlur)="updateDesc()" (onChange)="updateDesc()" [minBottomMargin]="true"></lib-form-field>
      </div>

      <div class="fields-group blue-background">
        <h4>Files (optional)</h4>
        <p class="input-instruction">Optionally upload all files related to your request.</p>
        <div formArrayName="inputFiles">
          <div *ngFor="let inputFile of inputFiles.controls; let i=index" [formGroupName]="i" style="margin-bottom: 1em;">

            <lib-file-input [minLayout]="true" [arrayName]="'inputFiles'" [index]="i" (fileValidStatus)="setFileStatus($event, 'files',i)" (fileUpdated)="updateFile($event, 'files',i)" (fileRemoved)="removeFile($event,i)" (setPristine)="setFilePristine('files')" [formGroupParameter]="supportForm" [fieldSettings]="inputFilesSettings['files']" [inputFile]="inputFiles.value[i]['files']">
            </lib-file-input>
          </div>
        </div>
        <div class="row clear form-button-container" style="padding-left: 1em;margin-bottom: 1em;">
          <button [disabled]="inputFiles.controls.length === inputFilesAvailable" type="button" (click)="addFile()" class="primary demo-plus-button">
            <fa-icon [icon]="faPlusIcon"></fa-icon>
            <span>Add another file</span>
          </button>
        </div>
      </div>

      <div class="fields-group">
        <div class="recaptcha-container">
          <re-captcha
              name="captcha"
              required
              formControlName="captcha"
              siteKey="6LcYsIIUAAAAAC4v-rzZ4zovoSM3oDPbLan3KbwM">
          </re-captcha>
        </div>
      </div>

      

    </div>


    <div class="form-group bottom-absolute">
      <button (click)="cancel()" type="button" class="secondary">Cancel</button>
      <button (click)="submit()" [disabled]="!supportForm.valid" class="primary" type="submit">Submit</button>
  </div>
  </form>
</div>

