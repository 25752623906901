import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import { NineGoldLibModule } from '../../nine-gold-lib.module';
import { NotificationService } from './notification.service';

@NgModule({
    imports: [
      CommonModule
    ],
    declarations: [NotificationComponent],
    exports: [NotificationComponent]
  })
  export class NotificationModule {
    static forRoot(): ModuleWithProviders<NineGoldLibModule> {
        return {
          ngModule: NineGoldLibModule,
          providers: [NotificationService]
        }
      }
   }