import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormSummary } from '../../../directives/form-summary/form-summary';
import { IFormField, InputTypes } from '../../../models/formField';
import { ApiService } from '../../../services/api.service';
import { FormsService } from '../../../services/forms.service';
import { ProductService } from '../../../services/product.service';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { UserService } from '../../../services/user.service';
import { AccordionComponent } from '../../accordion/accordion.component';
import { FormResultMessageFormat } from '../../notification/notification';
import { NotificationService } from '../../notification/notification.service';

function SmoothScroll(target, speed, smooth) {
	if (target === document)
		target = (document.scrollingElement
              || document.documentElement
              || document.body.parentNode
              || document.body) // cross browser support for document scrolling

	var moving = false
	var pos = target.scrollTop
  var frame = target === document.body
              && document.documentElement
              ? document.documentElement
              : target // safari is the new IE

	target.addEventListener('mousewheel', scrolled, { passive: false })
	target.addEventListener('DOMMouseScroll', scrolled, { passive: false })

	function scrolled(e) {
    var x = e.clientX, y = e.clientY,
        elementMouseIsOver = document.elementFromPoint(x, y);

        if(!elementMouseIsOver.parentElement?.classList.contains('scrollable-content')) {
          e.preventDefault(); // disable default scrolling

          var delta = normalizeWheelDelta(e)

          pos += -delta * speed
          pos = Math.max(0, Math.min(pos, (target.scrollHeight - frame.clientHeight) + (smooth * 2))) // limit scrolling

          if (!moving) update()
        }

	}

	function normalizeWheelDelta(e){
		if(e.detail){
			if(e.wheelDelta)
				return e.wheelDelta/e.detail/40 * (e.detail>0 ? 1 : -1) // Opera
			else
				return -e.detail/3 // Firefox
		}else
			return e.wheelDelta/120 // IE,Safari,Chrome
	}

	function update() {
		moving = true

		var delta = (pos - target.scrollTop) / smooth

		target.scrollTop += delta

		if (Math.abs(delta) > 0.5)
			requestFrame(update)
		else
			moving = false
	}

	var requestFrame = function() { // requestAnimationFrame cross browser
		return (
			window.requestAnimationFrame ||
			// window.webkitRequestAnimationFrame ||
			// window.mozRequestAnimationFrame ||
			// window.oRequestAnimationFrame ||
			// window.msRequestAnimationFrame ||
			function(func) {
				window.setTimeout(func, 1000 / 50);
			}
		);
	}()
}

@Component({
  selector: 'lib-support-request-form',
  templateUrl: './support-request-form.component.html',
  styleUrls: ['./support-request-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SupportRequestFormComponent implements OnInit {

  smoothScroll: () => void;

  loading = false;
  faPlusIcon = faPlus;

  @Output() closeForm = new EventEmitter;
  @Input() productCode: string;

  editIcon = faEdit;


  contactFieldsDisabled = false;

  supportFormSettings: {} = {};
  supportForm: FormGroup;
  formReady = false;

  username: string;


  @ViewChild('contactAccordion')
    contactAccordion:AccordionComponent;

  @ViewChild('contactInfoHeader')
    private contactInfoHeader: TemplateRef<any>;

  @ViewChild('contactInfoBody')
    private contactInfoBody: TemplateRef<any>;

  summaryTextValues = {
    'priority': [
      {value: 1, text: 'Low'},
      {value: 2, text: 'Medium'},
      {value: 3, text: 'High'},
      {value: 4, text: 'Urgent'}
    ],
    'severity': [
      {value: 4, text: 'Low'},
      {value: 3, text: 'Minor'},
      {value: 2, text: 'Significant'},
      {value: 1, text: 'Critical'}
    ],
    'product': [
      {value: 'restgen', text: 'REST Generator'},
      {value: 'jsontoramlconverter', text: 'JSON &rArr; RAML',html:true},
      {value: 'oastoramlconverter', text: 'OAS &rArr; RAML',html:true},
      {value: 'ramltojsonconverter', text: 'RAML &rArr; JSON',html:true},
      {value: 'ramltooasconverter', text: 'RAML &rArr; OAS', html: true},
      {text: "JSON &rArr; RAML Example", value: "jsontoramlconverterex", html: true},
      {text: "Postman Generator", value: "postmancollection"},
      {value: 'other', text: 'Other'},
      {value: 'billing', text: 'Billing'}
    ]
  }


  fieldsSummaryNew: FormSummary[] = [
    {name: 'priority',label: 'Priority',text:'',valid:false,value:''},
    {name: 'severity',label: 'Severity',text:'',valid:false,value:''},
    {name: 'product',label: 'Category',text:'',valid:false,value:''},
    {name: 'subject',label: 'Subject',text:'',valid:false,value:''},
    {name: 'description',label: 'Description',text:'',valid:false,value:null},
    // TODO: add list of files
    // {name: 'files', label: 'File', text: '', value:'', valid: false, hidden: false}
  ];

  supportPreForm: IFormField[] = [
    {
      inputName: "fullName",
      id: "fullNameId",
      placeholder: "Your First and Last Name",
      label: "Full Name",
      value: "",
      inputType: InputTypes.text,
      countCharacters: true
    },
    {
      inputName: "phone",
      id: "phoneId",
      placeholder: "212 555 5555",
      label: "Phone Number",
      value: null,
      inputType: InputTypes.tel,
      tooltipText: "Phone will be used to...."
    },
    {
      inputName: "email",
      id: "emailId",
      placeholder: "email@gmail.com",
      label: "Email Address",
      value: "",
      inputType: InputTypes.email
    },
    {
      inputName: "company",
      id: "companyId",
      placeholder: "Software Inc.",
      label: "Company Name",
      value: "",
      inputType: InputTypes.text
    },
    {
      inputName: "jobTitle",
      id: "jobId",
      placeholder: "Software Engineer",
      label: "Job Title",
      value: "",
      inputType: InputTypes.text
    },
    {
      inputName: "industry",
      id: "industryId",
      placeholder: "Select Your Company's Industry",
      label: "Industry",
      value: "",
      inputType: InputTypes.select
    },
    {
      inputName: "priority",
      id: "priorityId",
      placeholder: "Choose priority level",
      label: "Priority",
      value: "",
      inputType: InputTypes.select,
      required: true,
      requiredErrorMessage: "Priority is required"
    },
    {
      inputName: "severity",
      id: "severityId",
      placeholder: "Choose Severity Level",
      label: "Severity",
      value: "",
      inputType: InputTypes.select,
      required: true,
      requiredErrorMessage: "Severity is required"
    },
    {
      inputName: "subject",
      id: "subjectId",
      placeholder: "Summarize issue.",
      label: "Subject",
      value: "",
      inputType: InputTypes.text,
      required: true,
      requiredErrorMessage: "Subject is required"
    },
    {
      inputName: "description",
      id: "descriptionId",
      placeholder: "Describe issue details with steps for support to replicate.",
      label: "Description",
      value: "",
      inputType: InputTypes.textarea,
      rows: 5,
      required: true,
      requiredErrorMessage: "Description is required"
    },
    {
      inputName: "product",
      id: "productId",
      placeholder: "Choose Category",
      label: "Category",
      value: "",
      inputType: InputTypes.select,
      required: true,
      requiredErrorMessage: "This is required",
      instruction: "Choose the category issue is related to or select other for a more general issue."
    },
    {
      inputName: "actionCorrelation",
      inputType: InputTypes.text,
      id: "actionCorrelationId",
      label: "Tracking ID",
      value: "",
      instruction: "Select the Tracking ID if supplied."
    },
    {
      inputName: "captcha",
      id: "captchaId",
      label: "",
      value: "",
      inputType: InputTypes.recaptcha
    }
  ];

  summaryPopupClosed = false;
  summaryPopupActive = false;

  inputFilesSettings:{} = {};
  inputFilesFormObject: {} = {};
  inputFilesAvailable = 10;
  inputFilesPreForm: IFormField[] = [
    {
      inputName: "files",
      inputType: InputTypes.file,
      id: "filesId",
      label: "Input files",
      value: ""
    }
  ];

  get inputFiles(): FormArray{
    return <FormArray>this.supportForm.get('inputFiles');
  }

  constructor(
    private fb: FormBuilder,
    private formService: FormsService,
    private uiService: UserInterfaceService,
    public productService: ProductService,
    private userService: UserService,
    private apiService: ApiService,
    private notif: NotificationService
  ) { }

  ngOnInit() {
    this.smoothScroll = new SmoothScroll(document.getElementsByTagName('lib-support-request-form')[0],120,12);

    let form = this.formService.createForm('supportForm', this.supportPreForm);

    form.forEach(el => {
      this.supportFormSettings[el.inputName] = el;
    });

    let inputFiles = this.formService.createForm('supportForm',this.inputFilesPreForm);
    inputFiles.forEach(el=>{
      this.inputFilesSettings[el.inputName] = el;
    });


    let formReactive = this.formService.convertToReactiveForm(form);
    this.inputFilesFormObject = this.formService.convertToReactiveForm(inputFiles);

    formReactive['inputFiles'] = this.fb.array([this.fb.group(this.inputFilesFormObject)]);

    this.supportForm = this.fb.group(formReactive);
    setTimeout(() => {
      this.summaryPopupActive = true;
    }, 2000);

    // this.supportFormSettings['product'].options = [{value:'billing',text:'Billing'},{value:'other',text:'Other'}];
    // this.productService.getProcutsNoRestgenFiltered().forEach(pr => {
    //   this.supportFormSettings['product'].options.push({
    //     text: this.productService.getShortProductName(pr.id), value: pr.id
    //   });
    // });
    // this.supportFormSettings['product'].options.push(this.supportFormSettings['product'].options.shift());
  }

  ngAfterContentInit():void {
    if(this.userService.currentUser) {
      setTimeout(() => {
        this.setContactInfo();
        setTimeout(() => {
          this.contactAccordion.closeAccordion();
        }, 1000);
      },500);
    }
    setTimeout(() => {
      this.formReady = true;
    }, 1500);
  }

  ngAfterViewInit(): void {
    this.initialStateOfContactAccordion();
  }

  ngOnDestroy(): void {
    // removing scroll function
    this.smoothScroll = null;
  }

  public toggleSummaryPopup():void {
    this.summaryPopupClosed = !this.summaryPopupClosed;
  }

  cancel() {
    this.closeForm.emit();
  }

  addFile(): void {
    this.inputFiles.push(this.fb.group(this.inputFilesFormObject));
  }

  private updateFieldsSummary(key: string, text: string, value: any, valid: boolean, html: boolean): void {
    this.fieldsSummaryNew.find(f=>f.name === key).text = text;
    this.fieldsSummaryNew.find(f=>f.name === key).value = value;
    this.fieldsSummaryNew.find(f=>f.name === key).valid = valid;
    this.fieldsSummaryNew.find(f=>f.name === key).html = html;
  }

  protected dropdownUpdates(property: string, value: string): void {
    let text = this.summaryTextValues[property].find(el => el.value === value)?.text;
    let html = !!this.summaryTextValues[property].find(el => el.value === value)?.html;
    this.updateFieldsSummary(property, text, value, true, html);
  }

  protected updateSubject(): void {
    let value = this.supportForm.get('subject').value;
    this.updateFieldsSummary('subject',value, value, true, false);
  }

  protected updateDesc():void {
    let value = this.supportForm.get('description').value;
    this.updateFieldsSummary('description',value, value, true, false);
  }

  setContactFieldsEditable(): void {
    this.contactFieldsDisabled = false;

    ['fullName','company','jobTitle','industry','email','phone'].forEach(el => this.supportFormSettings[el].disabled = false);
    this.contactAccordion.openAccordion();
  }

  setContactInfo(): void {
    this.supportForm.patchValue(
      {
        'fullName': this.userService.currentUser.fullName,
        'company': this.userService.currentUser.company.name,
        'jobTitle': this.userService.currentUser.jobTitle,
        'industry': this.userService.currentUser.industry,
        'email': this.userService.currentUser.email,
        'phone': JSON.parse(this.userService.currentUser.phone)
      }
    );
    ['fullName','company','jobTitle','industry','email','phone'].forEach(el => this.supportFormSettings[el].disabled = true);
    this.username = this.userService.currentUser.username;
    this.contactFieldsDisabled = true;
    this.supportForm.markAsPristine();
  }

  initialStateOfContactAccordion(): void {
    if(this.contactAccordion) {
      if(this.contactFieldsDisabled) {
        setTimeout(() => {
          this.contactAccordion.closeAccordion();
        }, 1200);

      }
    }
  }

  private clearForm():void {
    // TODO: clearing form - clear fieldSummary
    // TODO: close project/enterprise accordions.
    this.supportForm.reset();
    this.supportForm.markAsUntouched();
    this.supportForm.markAsPristine();
  }

  submit(): void {
    this.loading = true;
    let payload = new FormData();
    payload.append('type','support');

    let contactFields = ['fullName','company','email','jobTitle'];
    contactFields.forEach(f => {
      payload.append(`contact.${f}`,this.supportForm.value[f]);
    });
    payload.append('contact.industry',this.supportForm.value['industry']?.replace('&amp;','&') || '');
    payload.append('contact.phone',this.supportForm.value['phone']?.internationalNumber || '');

    let content:{} = {};
    let contentFields = ['priority','severity','product','description'];
    contentFields.forEach(f => {
      content[f] = this.supportForm.value[f];
    });

    payload.append('subject',this.supportForm.value['subject']);
    payload.append('content',JSON.stringify(content));

    this.inputFiles.controls.filter(f=>f.value['files']).forEach(iF=>{
      payload.append('inputFiles',iF.value['files']);
    });
    this.apiService.sendSupportMessage(payload, true).subscribe({
      next: result => {
        this.loading = false;
        this.notif.success('Support request sent','Your Support Ticket was successfully sent. We will respond shortly.',FormResultMessageFormat.popup,6000,null);
        setTimeout(() => {
          this.clearForm();
          setTimeout(() => {
            this.closeForm.emit();
          }, 100);

        }, 100);
      },
      error: err => {
        this.loading = false;
        this.formService.formError(err, this.supportForm, this.supportFormSettings, null);
      }
    })
  }
  removeFile(file: File, i: number) {
    this.inputFiles.controls.splice(i,1);
    this.inputFiles.controls.forEach(f=>{
      f.updateValueAndValidity();
    })
  }

  updateFile(file: File, inputName: string, i:number): void {
    this.inputFiles.controls[i].get(inputName).patchValue(file);
    // if(!file) {
    //   this.inputFiles.controls.splice(i,1);
    // }
    // this.supportForm.triggerFormUpdatedEvent();
    // if(this.inputFiles.get(inputName).valid) {
    //   this.updateFieldsSummary(inputName,file?.name || '',file,true,false)
    // }
  }


  setFileStatus(status: boolean, inputName: string,i:number): void {
    if (!status) {
      this.inputFiles.controls[i].get(inputName).setErrors({'incorrect': true});
    } else {
      this.inputFiles.controls[i].get(inputName).setErrors(null);
    }
  }

  // TODO: submit form - check API with Dave and display popup with request ID and email sent info

}
