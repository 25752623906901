import { Pipe, PipeTransform } from '@angular/core';
import { ColumnTypes } from '../shared/listComponents/ng-list/ng-list-data';

@Pipe({
  name: 'visibleColumns',
  pure: false
})
export class VisibleColumnsPipe implements PipeTransform {

  transform(items: any[]): any {
    if (!items) {
      return items;
    }
    let newList = items.filter(item => !item.notVisible && item.columnType !== ColumnTypes.buttons && item.columnType !== ColumnTypes.empty && !item.noHeader);
    return newList;
    return null;
  }

}
