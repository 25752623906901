import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whitepaper-confirm',
  templateUrl: './whitepaper-confirm.component.html',
  styleUrls: ['./whitepaper-confirm.component.scss']
})
export class WhitepaperConfirmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  download(): void {
    var link = document.createElement('a');
    link.href = '/assets/downloads/Avoiding Technical Debt With Smart Code.pdf';
    link.download = 'Avoiding Technical Debt With Smart Code.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  }
}
