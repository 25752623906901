import { Component, OnInit, AfterViewInit, OnDestroy, TemplateRef, ContentChild, ViewChild, DoCheck, OnChanges, AfterContentInit, AfterContentChecked, AfterViewChecked, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { NotificationService } from './notification.service';
import { Notification, NotificationType, FormResultMessageFormat, NotificationPosition } from './notification';
import { ToastrService } from 'ngx-toastr';
import { FormsService } from '../../services/forms.service';

@Component({
  selector: 'lib-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements AfterContentChecked, OnInit, OnDestroy {
  title: string;
  message: string;
  messageHtml = false;
  noClose = false;


  notifications: Notification[] = [];
  private subscription: Subscription;
  private subscription1: Subscription;
  modalRef: BsModalRef;
  @Output('result') result: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @ViewChild('modal', { static: false }) autoShownModal: ModalDirective;
  @ViewChild('modal', { static: false }) autoShownModal: TemplateRef<any>;
  @ViewChild('confirm', { static: false }) confirmModal: ModalDirective;
  template: TemplateRef<any>;

  footerTemplate: TemplateRef<any>;


  constructor(private notificationService: NotificationService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private formService: FormsService) { }



  addNotification(notification: Notification) {
    // console.log('adding');
    this.notifications.push(notification);

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout)
    }
  }

  ngAfterContentChecked() {
    // console.log("ngAfterViewChecked");
    // if(this.subscription1) {
    //   console.log("removing");
    //   this.subscription1.unsubscribe();
    // }

    // this.subscription1 = this.notificationService.currentMessage.subscribe(message=> console.log(message));
  }

  ngOnInit() {
    setTimeout(() => {
      this.notificationService.currentMessage.subscribe(message => {
        // console.log(message);
      });
    }, 5000);
    // this.toastr.toastrConfig.toastClass = 'test';
    // this.toastr.toastrConfig.disableTimeOut = true;
    this.toastr.toastrConfig.closeButton = true;

    // this.formService.currentMessage.subscribe(message=> console.log(message));
    // console.log('notification init');
    this.subscription = this.notificationService.getObservable().subscribe(notification => {
      // console.log(notification);
      if(notification.format !== FormResultMessageFormat.popup) this.resetData();
      let postionClass = (notification.position === NotificationPosition.top ? 'toast-top-right' : 'toast-bottom-right');
      if (notification.format === FormResultMessageFormat.popup) {
        switch (notification.type) {
          case 0:
            this.toastr.success(notification.message, notification.title, {timeOut: notification.timeout, enableHtml: notification.html || false, positionClass: postionClass});
            break;
          case 1:
            this.toastr.warning(notification.message, notification.title, {timeOut: notification.timeout, enableHtml: notification.html || false, positionClass: postionClass});
            break;
          case 2:
            this.toastr.error(notification.message, notification.title, {timeOut: notification.timeout, enableHtml: notification.html || false, positionClass: postionClass});
            break;
          case 3:
            this.toastr.info(notification.message, notification.title, {timeOut: notification.timeout, enableHtml: notification.html || false, positionClass: postionClass});
            break;
          default:

            this.toastr.info(notification.message, notification.title, {timeOut: notification.timeout, enableHtml: notification.html || false, positionClass: postionClass});
            break;
        }
        // this.toastr.success('Hello world!', 'Toastr fun!');
        // this.addNotification(notification)
      } else if (notification.format === FormResultMessageFormat.modal || notification.format === FormResultMessageFormat.modalLarge) {
        // this.autoShownModal.show();
        this.title = notification.title;
        this.message = notification.message;
        const config: ModalOptions = (notification.format === FormResultMessageFormat.modalLarge ? { class: 'modal-lg' } : {});
        this.modalRef = this.modalService.show(this.autoShownModal, config);
      } else if(notification.format === FormResultMessageFormat.modalHtmlNoClose) {
        this.title = notification.title;
        this.message = notification.message;
        this.messageHtml = true;
        this.template = notification.template;
        this.footerTemplate = notification.footerTemplate;
        const config: ModalOptions = {backdrop : 'static', keyboard : false};
        this.noClose = true;
        this.modalRef = this.modalService.show(this.autoShownModal, config);
      } else if(notification.format === FormResultMessageFormat.modalHtml || notification.format === FormResultMessageFormat.modalLargeHtml) {
        this.title = notification.title;
        this.message = notification.message;
        this.messageHtml = true;
        this.template = notification.template;
        this.footerTemplate = notification.footerTemplate;
        const config: ModalOptions = (notification.format === FormResultMessageFormat.modalLargeHtml ? { class: 'modal-lg' } : {});
        // this.modalRef = this.modalService.show(this.autoShownModal, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true });
        this.modalRef = this.modalService.show(this.autoShownModal, config);
        this.notificationService.closeModalEvent.subscribe(e => {
          this.modalRef.hide();
        })
        // this.modalService.show()


      // } else if (notification.format === FormResultMessageFormat.confirm) {
      //   this.title = notification.title;
      //   this.message = notification.message;
      //   this.modalRef = this.modalService.show(this.confirmModal);

      //   this.modalService.onHide.subscribe((reason: string | any) => {
      //     if (reason) this.result.emit(false);
      //   });
      } else if (notification.format === FormResultMessageFormat.inline) {
        // notification.el.nativeElement.removeChild();
        while (notification.el.nativeElement.firstChild) {
          notification.el.nativeElement.removeChild(notification.el.nativeElement.firstChild);
        }
        notification.el.nativeElement.insertAdjacentHTML('beforeend', `<div class="form-custom-${this.className(notification)}">${notification.message}</div>`);
        // this.renderer.invokeElementMethod(notification.el.nativeElement + ', ' + insertAdjacentHTML +' ['beforeend', '<div class="two">two</div>']);
      }

    });
  }

  private resetData(): void {
    this.title = "";
    this.message = "";
    this.messageHtml = false;
    this.template = null;
    this.footerTemplate = null;
  }

  confirmClick(): void {
    this.message = 'Confirmed!';
    this.modalRef.hide();
    this.result.emit(true);
  }

  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
    this.result.emit(false);
  }

  // ngAfterViewInit() {
  //   this.subscription = this.notificationService.getObservable().subscribe(notification => this.addNotification(notification));
  // }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(notification: Notification): void {
    this.notifications.filter(notif => notif.id !== notification.id);
  }


  className(notification: Notification): string {

    let style: string;

    switch (notification.type) {

      case NotificationType.success:
        style = 'success';
        break;

      case NotificationType.warning:
        style = 'warning';
        break;

      case NotificationType.error:
        style = 'error';
        break;

      default:
        style = 'info';
        break;
    }

    return style;
  }

}


