<div class="documentation-side-inner">
  <button title="close form" class="close-popup-button close-button modal-close" (click)="goBack()">close</button>


  <div  class="documentation-container">
    <div class="side-modal-backdrop" (click)="goBack()"></div>
    <div class="swagger-container" [libLoading]="loading">
      <div #swagger id="swagger"></div>
    </div>

  </div>
</div>
