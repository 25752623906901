import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ConfirmDialog } from './confirm-dialog';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  // confirm(title: string, message: string, options: string[]): Observable<string> {
    confirm(options: ConfirmDialog): Observable<string> {
      // const initialState = options;
      // console.log(initialState);
    const initialState = {
      title: options.title,
      message: options.message,
      confirmBtn: options.confirmBtn,
      declineBtn: options.declineBtn,
      answer: false,
      dangerBtn: options.dangerBtn || false,
      html: options.html || false
      
    };
    this.bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });

    return new Observable<string>(this.getConfirmSubscriber());
  }

  private getConfirmSubscriber() {
    return (observer) => {
      const subscription = this.modalService.onHidden.subscribe((reason: string) => {
        observer.next(this.bsModalRef.content.answer);
        observer.complete();
      });

      return {
        unsubscribe() {
          subscription.unsubscribe();
        }
      };
    }
  }

}
