import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { PhoneNumberFormat } from "ngx-intl-tel-input";
import { ApiCompanySimple, Company, ICompany } from "./company";
import { UiSettings } from "./ui-settings";

export class IUser {
    username: string = "";
    email: string = "";
    roles: UserRoles[];
    phone: string  = "";
    jsonPhone?: PhoneNumberFormat;
    initials: string = "";
    jobTitle: string  = "";
    fullName: string  = "";  // can we change it to camelcasing
    isTrial: boolean = false;
    company?: Company;
    id?: string;
}

export interface ITrialStatus {
    isTrial: boolean;
    daysLeft: number;
    generationsLeft: number;
    productName: string
}

export interface UserUpdateData {
    propertyName: string,
    value: any,
    changeType: ChangeTypes,
    propertyLabel?: string
}

export enum ChangeTypes {
    replace = 'replace',
    add = 'add',
    remove = 'remove'
}

export interface manager {
  fullName: string,
  email: string,
  role: UserRoles
}


export interface OrgData {
    name: string;
    id: string;
}

// TODO: review and remove trial user role if not needed; or, if needed for UI, extend UserRoles to UserRolesUI
export enum UserRoles {
    registered = "registered",
    administrator = "administrator",
    company_administrator = "company_administrator",
    company_owner = "company_owner",
    trial = "trial"
}

export interface UserTypesData {
    name: UserRoles,
    isGlobalAdmin?: boolean,
    icon: IconDefinition,
    smallIcon?: IconDefinition,
    text: string,
    class: string,
    permissions: string[],
    level: number
}

// API based models
export class ApiUserSimple {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  jobTitle: string;
  enabled: boolean;
  roles: UserRoles[];
  id: string;
  username: string;
  archived: boolean = false;
  fullName: string;
}

export class ApiUser {
    email: string;
    phone: string;
    id: string;
    username: string;
    roles: UserRoles[];
    company: ApiCompanySimple;
    session: ApiSession;
    archived: boolean = false;
    firstName: string;
    lastName: string;
    jobTitle: string;
    fullName: string;
    enabled: boolean;
    // extended properties for UI
    displayPhone?: string;
    rawPhone?: string
    initials?: string;
    jsonPhone?: InternationalPhoneNumber;
    displaySettings?: UiSettings;
    industry?: string;
    trialUsedByEmail?: boolean;
}

export interface InternationalPhoneNumber {
    countryCode: string,
    dialCode: string,
    e164Number: string,
    internationalNumber: string,
    nationalNumber: string,
    number: string,
}




export interface ApiSession {
    currentCompany: ApiCompanySimple
}
