<div class="trial-indicator-container" 
  (click)="toggleTrial()"
  [class.open]="trialOpen">

  <div class="trial" [class.open]="trialOpen">
      <span class="trial-label">
          <span class="trial-label-initial">T</span>
          <span class="trial-label-word">rial</span>
      </span>
      <span class="days">
          <span class="value">{{daysLeft}}</span>
          <span class="unit">days</span>
      </span>
      <span class="generations">
          <span class="value">{{generationsLeft}}</span>
          <span class="unit">generations</span>
      </span>
      <span class="trial-info-icon" (click)="getTrialDetails()">
        <fa-icon [icon]="infoIcon"></fa-icon>
      </span>
  </div>
</div>

<ng-template #trialDetails>
  <div class="border">
    <h3>{{productName}} trial</h3>
    <p>You are on 14 day trial license for {{productName}}.</p>
    <p>The license includes <strong>5 free</strong>  generations.<br> The generations cannot be used for commercial purposes.</p>
    <p>
      <span class="label">Number of remaining days</span>
      <span class="value">{{daysLeft}}</span>
    </p>
    <p>
      <span class="label">Number of remaining generations</span>
      <span class="value">{{generationsLeft}}</span>
    </p>
  </div>
  <div class="border">
    <p>To get the full access to {{productName}}, please fill out form to <button class="text-button">get a quote</button> for the licese that will satisfy your needs.</p>
    <p>If you are not fully are fully convinced or need help with getting to know the product, please <button class="text-button">request a demo</button>.</p>
  </div>
  <div class="buttons-container">
    <button class="secondary">Ask for a Demo</button>
    <button class="primary">Request a Quote</button>
  </div>
</ng-template>