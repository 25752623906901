import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SwaggerUIBundle, SwaggerUIStandalonePreset } from 'swagger-ui-dist';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { slideInOutAnimation } from 'projects/nine-gold-lib/src/lib/animations/slide-in-out.animation';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { FormResultMessageFormat, NotificationPosition } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { SchemasListService } from '../../application/schemas/schemas-list.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
  animations: [slideInOutAnimation],
  host: { '[@slideInOutAnimation]': '' },
  encapsulation: ViewEncapsulation.None
})
export class DocumentationComponent implements OnInit {
  faTimesIcon = faTimes;
  redirectUrl: string;
  open = false;

  cacheKey: string;
  documentKey: string;

  loading: boolean;
  @ViewChild('swagger') swaggerDom: ElementRef<HTMLDivElement>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private userService: UserService,
    private notif: NotificationService,
    private schemaService: SchemasListService
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if(this.cacheKey) this.loadDocumentation();
    }, 10);

  }
  ngOnInit(): void {
    this.loading = true;
    this.documentKey = this.route.snapshot.params['documentKey'];
    this.cacheKey = this.route.snapshot.params['cacheKey'];
    this.redirectUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  loadDocumentation(): void {
    this.apiService.getRestgenSpecification(this.userService.getCompanyId(),this.cacheKey,this.documentKey).subscribe(
      result => {
        SwaggerUIBundle({
          spec: result['results'].response,
          domNode: this.swaggerDom.nativeElement,
          deepLinking: false,
          presets: [SwaggerUIBundle.presets.apis, SwaggerUIStandalonePreset],
          layout: 'StandaloneLayout',
          onComplete: this.onSwaggerComplete()
        });
      },
      error => {
        this.loading = false;
        console.log(error);
        if(error.error.friendlyMessage.startsWith('Cache miss')) {
          this.notif.error('Error','This upload is no longer in cache and will be removed from the list.',FormResultMessageFormat.popup,8000,null,false,NotificationPosition.bottom);
        } else {
          this.notif.error('Error',error.error.friendlyMessage,FormResultMessageFormat.popup,8000,null,false,NotificationPosition.bottom);
        }

        setTimeout(() => {
          this.schemaService.ReloadItems();
          this.goBack();
        }, 1000);

      }
    )
  }

  private onSwaggerComplete(): void {
    this.loading = false;
  }

  goBack(): void {
    this.router.navigate(
			[
        "../",
				{
					outlets: {
            sidePopup: null
					}
				}
      ],
			{
				relativeTo: this.route.parent // <--- PARENT activated route.
			}
		);

  }

}
