import { Pipe, PipeTransform } from '@angular/core';
import { TypesOfCompare } from '../shared/listComponents/ng-list/ng-list-data';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(list: any[], filter: {field: string, value: any, type?: TypesOfCompare}[]): any {
    if (!list || !filter) {
      return list;
    }
    let newList = list.filter(row => {
      let validRow = true;
      filter.forEach(fil => {
        if(fil.type === TypesOfCompare.equal) {
          let col = row.listRow.find(el => el.columnName === fil.field);
          // console.log('col value - ' + col.value);
          // console.log('fil value - ' + fil.value);
          if (!col || col.value !== fil.value) validRow = false;
        } else if(fil.type === TypesOfCompare.partialEqual) {
          let col = row.listRow.find(el => el.columnName === fil.field);
          if (!col || !col.value.toLowerCase().includes(fil.value.toLowerCase()) ) validRow = false;
        }

      });
      return validRow;
    });
    // pages = Math.ceil(newList.length / perPage);
    return newList;
    // return list.filter(i => i[key] === value);
  }

}
