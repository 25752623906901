<lib-status-bar [visible]="statusBar.barVisible" [hidden]="statusBar.hidden" [statusData]="statusBar.activeStatus"></lib-status-bar>
<app-progress-bar></app-progress-bar>
<lib-notification></lib-notification>
<div class="content-container"
    #outerAppContainer
    [class.authLayout]="authLayout"
    [class.app-layout]="appLayout"
    [class.hide-header]="noHeader"
    [class.static-header]="!scrollableHeader"
    [class.scrolled]="scrolledDown"
    [class.scrolling]="scrolling"
    [class.scrollingUp]="scrollingUp">

    <header *ngIf="authLayout">
      <img src="/assets/images/smartcode-logo-white-light-transparent.png" alt="">
    </header>

    <lib-header *ngIf="!noHeader || !authLayout"
        [@fadeInAnimation]
        [appView]="appLayout"
        [hideUserDropdown]="userPages"
        [productsList]="productNavItems"
        [solutionsList]="solutionsNavItems"
        [resourcesList]="resourcesNavItems"
        [appsList]="appNavItems"
        [linksList]="headerNavItems"></lib-header>

    <div class="site-container" [@fadeInAnimation]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet (activate)="onPageSwitch($event)" name="primary" id="scrollContainer" #o="outlet"></router-outlet>
    </div>
</div>

<div class="lib-footer-container" [hidden]="hideFooter">
    <lib-footer *ngIf="!userPages && !noHeader && !appLayout" [linksList]="footerNavItems"></lib-footer>
</div>

<div class="side-popup-container">
    <router-outlet
      name="sidePopup"
      (activate)='onActivate($event)'
    (deactivate)='onDeactivate($event)'></router-outlet>
</div>
