import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FormResultMessageFormat } from '../../shared/notification/notification';
import { NotificationService } from '../../shared/notification/notification.service';

@Component({
  selector: 'lib-trial-indicator',
  templateUrl: './trial-indicator.component.html',
  styleUrls: ['./trial-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrialIndicatorComponent implements OnInit {
  trialOpen = false;
  trialChangingState = false;
  infoIcon = faInfoCircle;
  @Input() daysLeft: number;
  @Input() generationsLeft: number;
  @Input() productName: string;

  @ViewChild('trialDetails')
    private trialDetails: TemplateRef<any>;

  constructor(
    private notif: NotificationService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.trialOpen = true;
      setTimeout(() => {
        this.trialOpen = false;
      }, 6000);
    }, 1000);
  }

  getTrialDetails():void {
    this.notif.info('Trial details','',FormResultMessageFormat.modalHtml,3000,this.trialDetails);
  }

  toggleTrial():void {
    if(!this.trialChangingState) {
      this.trialOpen = !this.trialOpen;
      this.trialChangingState = true;
      setTimeout(() => {
        this.trialChangingState = false;
      }, 1000);
      if(this.trialOpen) {
        setTimeout(() => {
          if(this.trialOpen && !this.trialChangingState) this.trialOpen = false;
        }, 6000);
      }
    }
    
  }

}
