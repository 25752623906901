import { ValidationErrors, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static sameValue(fieldToCompare: AbstractControl): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && c.value && fieldToCompare.value && c.value !== fieldToCompare.value) {
        return { serverError: true };
      }
      return null;
    };
  }
  

  static passwordMatchValidator(passwordName: string, passwordConfirmName: string): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
        const password: string = c.get(passwordName).value; // get password from our password form control
        const confirmPassword: string = c.get(passwordConfirmName).value; // get password from our confirmPassword form control
        if (password !== confirmPassword) {

            return {'passwordconfirm': true};
        }
        return null
    }
    
  }
}