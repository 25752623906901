import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

@Directive({
  selector: '[libLoading]'
})
export class LoadingDirective implements OnInit, OnChanges {
  @HostBinding("style.position")
  hostPosition: string = "relative";
  @Input() libLoading: boolean = false;
  @Input() fullScreen: boolean = false;

  uid: string;

  constructor(
    private targetEl: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.uid = "loading-container-" + uuidv4();

    const loadingContainer = this.renderer.createElement("div");

    this.renderer.addClass(loadingContainer, "loading-spinner-container");
    this.renderer.addClass(loadingContainer, this.uid);

    if(this.fullScreen) this.renderer.addClass(loadingContainer, "full-screen");

    const spinnerElement = this.renderer.createElement("div");
    this.renderer.addClass(spinnerElement, "loading-spinner");
    this.renderer.appendChild(loadingContainer, spinnerElement);
    this.renderer.appendChild(this.targetEl.nativeElement, loadingContainer);

    if(this.libLoading === true) this.renderer.addClass(loadingContainer, "active");
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.libLoading) {
      const container = this.targetEl.nativeElement;
      const loadingContainer = container.querySelector(`.${this.uid}`);
      if(loadingContainer) {
        if(this.libLoading) {
          this.renderer.addClass(loadingContainer, "active");
        } else {
          this.renderer.removeClass(loadingContainer, "active");
        }
      }
    }
  }

}
