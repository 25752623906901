<div class="multiselect-field" [class.side-by-side]="sideBySide">


  <!-- <div>
    <p>
      <button>Add All to Selected</button>
      <button>Clear All Selected</button>
      <button>Reverse Selected</button>
    </p>
  </div> -->

  <div class="multiselect-toolbar">
    <p class="field-display-value">
      <span class="label">Added</span>
      <span class="value">{{getTotalNumber(true)}}<span class="prep">of</span>{{inputTotalNo}}</span>
    </p>
    <!-- <div class="buttons">
      <button class="primary"><fa-icon [icon]="minIcon" name="Minimalize View"></fa-icon></button>
      <button class="primary"><fa-icon [icon]="normalScreenIcon" name="Default View"></fa-icon></button>
      <button class="primary"><fa-icon [icon]="fullScreenIcon" name="Full Screen View"></fa-icon></button>
    </div> -->
  </div>


  <div class="lists">
    <div class="input-list">
      <h2>{{inputListName}}</h2>
      <p class="input-with-button">
        <input (input)="filterInputList(inpuQuery)" #input1 type="text" class="form-control" placeholder="search list" [(ngModel)]="inpuQuery">
        <button class="primary icon-button icon-no-background" (click)="resetQuery()"><fa-icon [icon]="clearIcon"></fa-icon></button>
      </p>
      <ul>
        <li class="heading-list-item">
          <div class="input-container">
            <input (click)="selectAll(selectedAll)" [indeterminate]="inputListSomeChecked()" [(ngModel)]="selectedAll" type="checkbox" class="form-check-input" id="inputListSelectAll">
            <label class="text" for="inputListSelectAll">Select All</label>
            <button title="Invert Selection" class="primary icon-button icon-no-background" (click)="reverseSelected()"><fa-icon [icon]="revertIcon"></fa-icon></button>
            <button title="Add All Selected" class="primary icon-with-text icon-on-right" [disabled]="!getSelectedNumber()" (click)="addSelected()">Add <fa-icon [icon]="addAllIcon"></fa-icon></button>
          </div>
        </li>
        <li *ngFor="let item of filteredInputList" >
          <div class="input-container">
            <input type="checkbox" [(ngModel)]="item.selected" class="form-check-input" [id]="'mainList'+item.id">
            <label class="text" [title]="item.display" [for]="'mainList'+item.id">{{item.display}}</label>
            <button class="primary icon-button icon-no-background" (click)="addItem(item)"><fa-icon [icon]="addIcon2"></fa-icon></button>
          </div>
        </li>
      </ul>
      <div class="summary">
        <p>
          <span class="label">selected</span>
          <span class="value">{{getSelectedNumber()}}</span>
        </p>
        <p>
          <span class="label">filtered</span>
          <span class="value">{{getFilteredNumber()}}</span>
        </p>
        <p>
          <span class="label">total</span>
          <span class="value">{{getTotalNumber()}}</span>
        </p>
      </div>
    </div>

    <div class="buttons-between">
      <button title="Add All Selected" class="primary vertical-button" [disabled]="!getSelectedNumber()" (click)="addSelected()"><fa-icon [icon]="addAllIcon"></fa-icon></button>
      <button class="primary vertical-button" (click)="removeSelected()" [disabled]="!getSelectedNumber(true)"><fa-icon [icon]="removeAllIcon"></fa-icon></button>
    </div>

    <div class="selected-list">
      <h2>{{outputListName}}</h2>
      <p class="input-with-button">
        <input (input)="filterInputList(outputQuery,true)" #input1 type="text" class="form-control" placeholder="search list" [(ngModel)]="outputQuery">
        <button class="primary icon-button icon-no-background" (click)="resetQuery(true)"><fa-icon [icon]="clearIcon"></fa-icon></button>
      </p>
      <ul>
        <li class="heading-list-item">
          <div class="input-container">
            <input (click)="selectAllOutput(selectedAllOutput)" [indeterminate]="inputListSomeChecked()" [(ngModel)]="selectedAllOutput" type="checkbox" class="form-check-input" id="outputListSelectAll">
            <label class="text" for="outputListSelectAll">Select All</label>
            <button class="primary icon-button icon-no-background" (click)="reverseSelected()"><fa-icon [icon]="revertIcon"></fa-icon></button>
            <button class="primary icon-with-text" (click)="removeSelected()" [disabled]="!getSelectedNumber(true)"><fa-icon [icon]="removeAllIcon"></fa-icon>Remove</button>
          </div>
        </li>
        <li *ngFor="let item of filteredOutputList" >
          <div class="input-container">
            <input type="checkbox" [(ngModel)]="item.selected" class="form-check-input" [id]="'selectedList'+item.id">
            <label class="text" [title]="item.display" [for]="'selectedList'+item.id">{{item.display}}</label>
            <button class="primary icon-button icon-no-background" (click)="removeItem(item)"><fa-icon [icon]="removeIcon2"></fa-icon></button>
          </div>
        </li>
      </ul>
      <div class="summary">
        <p>
          <span class="label">selected</span>
          <span class="value">{{getSelectedNumber(true)}}</span>
        </p>
        <p>
          <span class="label">filtered</span>
          <span class="value">{{getFilteredNumber(true)}}</span>
        </p>
        <p>
          <span class="label">total</span>
          <span class="value">{{getTotalNumber(true)}}</span>
        </p>
      </div>
    </div>



  </div>
</div>
