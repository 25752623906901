import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { IFormField, InputTypes } from '../../../models/formField';
import { NotificationService } from '../../notification/notification.service';
import { FormsService } from '../../../services/forms.service';
import { AppconfigService } from '../../../services/appconfig/appconfig.service';
import { faTrashAlt, faPlus, faCircleQuestion, faEdit} from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { FormResultMessageFormat } from '../../notification/notification';
import { AccordionComponent } from '../../accordion/accordion.component';
import { UserService } from '../../../services/user.service';
import { DatePipe } from '@angular/common';

function SmoothScroll(target, speed, smooth) {
	if (target === document)
		target = (document.scrollingElement
              || document.documentElement
              || document.body.parentNode
              || document.body) // cross browser support for document scrolling

	var moving = false
	var pos = target.scrollTop
  var frame = target === document.body
              && document.documentElement
              ? document.documentElement
              : target // safari is the new IE

	target.addEventListener('mousewheel', scrolled, { passive: false })
	target.addEventListener('DOMMouseScroll', scrolled, { passive: false })

	function scrolled(e) {
		e.preventDefault(); // disable default scrolling

		var delta = normalizeWheelDelta(e)

		pos += -delta * speed
		pos = Math.max(0, Math.min(pos, (target.scrollHeight - frame.clientHeight) + (smooth * 2))) // limit scrolling

		if (!moving) update()
	}

	function normalizeWheelDelta(e){
		if(e.detail){
			if(e.wheelDelta)
				return e.wheelDelta/e.detail/40 * (e.detail>0 ? 1 : -1) // Opera
			else
				return -e.detail/3 // Firefox
		}else
			return e.wheelDelta/120 // IE,Safari,Chrome
	}

	function update() {
		moving = true

		var delta = (pos - target.scrollTop) / smooth

		target.scrollTop += delta

		if (Math.abs(delta) > 0.5)
			requestFrame(update)
		else
			moving = false
	}

	var requestFrame = function() { // requestAnimationFrame cross browser
		return (
			window.requestAnimationFrame ||
			// window.webkitRequestAnimationFrame ||
			// window.mozRequestAnimationFrame ||
			// window.oRequestAnimationFrame ||
			// window.msRequestAnimationFrame ||
			function(func) {
				window.setTimeout(func, 1000 / 50);
			}
		);
	}()
}

@Component({
  selector: 'lib-ng-demo',
  templateUrl: './ng-demo.component.html',
  styleUrls: ['./ng-demo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgDemoComponent implements OnInit {

  faTrashAltIcon = faTrashAlt;
  faPlusIcon = faPlus;

  smoothScroll: () => void;

  loading = false;
  @Output() closeForm = new EventEmitter;
  @Input() productName: string;
  @Input() productCode: string;

  editIcon = faEdit;
  questionIcon = faCircleQuestion;

  contactFieldsDisabled = false;

  @ViewChild('contactAccordion')
    contactAccordion:AccordionComponent;

  @ViewChild('contactInfoHeader')
    private contactInfoHeader: TemplateRef<any>;

  @ViewChild('contactInfoBody')
    private contactInfoBody: TemplateRef<any>;

  demoFormSettings: {} = {};
  timeSlotsSettings: {} = {};
  demoForm: FormGroup;
  timeSlotsFormObject: {} = {};
  formReady = false;
  timeslotsAvailable = 3;

  username: string;

  demoPreForm: IFormField[] = [
    {
      inputName: "fullName",
      id: "fullNameId",
      placeholder: "Your First and Last Name",
      label: "Full Name",
      value: "",
      inputType: InputTypes.text,
      countCharacters: true
    },
    {
      inputName: "phone",
      id: "phoneId",
      placeholder: "212 555 5555",
      label: "Phone Number",
      value: "",
      inputType: InputTypes.tel
    },
    {
      inputName: "email",
      id: "emailId",
      placeholder: "email@gmail.com",
      label: "Email Address",
      value: "",
      inputType: InputTypes.email
    },
    {
      inputName: "company",
      id: "companyId",
      placeholder: "Software Inc.",
      label: "Company Name",
      value: "",
      inputType: InputTypes.text
    },
    {
      inputName: "job",
      id: "jobId",
      placeholder: "Software Engineer",
      label: "Job Title",
      value: "",
      inputType: InputTypes.text
    },
    {
      inputName: "industry",
      id: "industryId",
      placeholder: "Select Your Company's Industry",
      label: "Industry",
      value: "",
      inputType: InputTypes.select
    },
    {
      inputName: "captcha",
      id: "captchaId",
      label: "",
      value: "",
      inputType: InputTypes.recaptcha
    },
    {
      inputName: "comments",
      id: "commentsId",
      label: "Comments and Questions",
      inputType: InputTypes.textarea,
      rows:6,
      value: ""
    }
  ];
  timeSlotsPreForm: IFormField[] = [
    {
      inputName: 'date',
      id: 'dateId',
      placeholder: "yy / mm / dd",
      label: "Date",
      value: "",
      inputType: InputTypes.date,
      autocompleteOff: true
    },
    {
      inputName: 'time',
      id: "timeId",
      placeholder: "Please select a time slot.",
      label: "Time Slot",
      inputType: InputTypes.select,
      value: null
    }
  ];

  _notificationSvc: NotificationService;

  get timeSlots(): FormArray{
    return <FormArray>this.demoForm.get('timeSlots');
  }

  constructor(
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private formService: FormsService,
    private appConfig: AppconfigService,
    private http: HttpClient,
    private apiService: ApiService,
    private notif: NotificationService,
    private userService: UserService
  ) {
    this._notificationSvc = new NotificationService();
   }

  ngOnInit(): void {
    // this.smoothScroll = new SmoothScroll(document.getElementsByClassName('side-inner')[0],120,12);
    this.smoothScroll = new SmoothScroll(document.getElementsByTagName('lib-ng-demo')[0],120,12);
    let settings = {};

    let form = this.formService.createForm('demo', this.demoPreForm);

    form.forEach(el => {
      this.demoFormSettings[el.inputName] = el;
    });

    let timeSlots = this.formService.createForm('demo',this.timeSlotsPreForm);
    timeSlots.forEach(el => {
      this.timeSlotsSettings[el.inputName] = el;
    });

    let formReactive = this.formService.convertToReactiveForm(form);
    this.timeSlotsFormObject = this.formService.convertToReactiveForm(timeSlots);

    formReactive['timeSlots'] = this.fb.array([this.fb.group(this.timeSlotsFormObject)]);

    this.demoForm = this.fb.group(formReactive);
    console.log(this.timeSlots);
    // this.formReady = true;
    // console.log(this.demoForm);

  }

  ngAfterContentInit():void {
    if(this.userService.currentUser) {
      setTimeout(() => {
        this.setContactInfo();
        setTimeout(() => {
          this.contactAccordion.closeAccordion();
        }, 1000);

      }, 500);
    } else {
      this.userService.userUpdated.subscribe(user => {
        if(this.userService.currentUser) {
          this.setContactInfo();
          setTimeout(() => {
            this.contactAccordion.closeAccordion();
          }, 1000);
        }
      });
    }
    this.formReady = true;
  }

  setContactFieldsEditable(): void {
    this.contactFieldsDisabled = false;

    ['fullName','company','job','industry','email','phone'].forEach(el => this.demoFormSettings[el].disabled = false);
    this.contactAccordion.openAccordion();
  }

  setContactInfo(): void {
    // console.log('before');
    this.demoForm.patchValue(
      {
        'fullName': this.userService.currentUser.fullName,
        'company': this.userService.currentUser.company.name,
        'job': this.userService.currentUser.jobTitle,
        'industry': this.userService.currentUser.industry,
        'email': this.userService.currentUser.email,
        'phone': JSON.parse(this.userService.currentUser.phone)
      }
    );
    this.username = this.userService.currentUser.username;
    ['fullName','company','job','industry','email','phone'].forEach(el => this.demoFormSettings[el].disabled = true);
    this.contactFieldsDisabled = true;
    // console.log('after')
    this.demoForm.markAsPristine();
    // console.log(this.quoteForm.dirty);
  }

  addTimeslot(): void {
    this.timeSlots.push(this.fb.group(this.timeSlotsFormObject));
  }



  cancel() {
    // clear form
    // go back
    this.closeForm.emit();
  }

  removeTimeslot(index: number):void {
    this.timeSlots.removeAt(index);
  }

  requestDemo():void {

  }

  private clearForm():void {
    // TODO: clearing form - clear fieldSummary
    // TODO: close project/enterprise accordions.
    this.demoForm.reset();
    // this.fieldsSummary = this.initialFieldSummary;
    this.demoForm.markAsUntouched();
    this.demoForm.markAsPristine();
    // setTimeout(() => {
    //   this.formDirtyChange.emit(this.demoForm.dirty);
    // }, 20);
  }



  submit():void {
    this.loading = true;
    let payload = new FormData();
    payload.append('type','contact');
    payload.append('subject',`Demo request for ${this.productName} from ${(!!this.username ? this.username : this.demoForm.value['fullName'] )}`);


    let messageBody = {
      contact: {},
      type: 'demo',
      content:{},
      subject: `Demo request for ${this.productName} from ${(!!this.username ? this.username : this.demoForm.value['fullName'] )}`
    }


    let contactFields = ['fullName','company','industry','email'];
    contactFields.forEach(f => {
      messageBody.contact[f] = this.demoForm.value[f];
      payload.append(`contact.${f}`,this.demoForm.value[f]);
    });
    messageBody.contact['jobTitle'] = this.demoForm.value['job'];
    payload.append('contact.jobTitle',this.demoForm.value['job']);

    messageBody.contact['loggedin'] = (this.username ? 'YES' : 'NO');
    payload.append('contact.loggednin',(this.username ? 'YES' : 'NO'));

    if(this.username) {
      messageBody.contact['username'] = this.username;
      payload.append('contact.username',this.username);
    }

    messageBody.contact['phone'] = JSON.stringify(this.userService.setDisplayPhone((this.demoForm.value['phone'] ? this.demoForm.value['phone'].internationalNumber : '')));
    payload.append('contact.phone',JSON.stringify(this.userService.setDisplayPhone(this.demoForm.value['phone'])));

    messageBody.content['timeSlots'] = [];
    this.demoForm.controls.timeSlots.value.forEach(ts => {
      messageBody.content['timeSlots'].push({date: this.datePipe.transform(ts.date,'mediumDate') ,time: ts.time});
    });
    messageBody.content['comments'] = this.demoForm.value['comments'];
    messageBody.content['site'] = `Form was submitted from ${this.productName} site.`;

    let content = messageBody.content;
    payload.append('content',JSON.stringify(content));

    this.apiService.sendSupportMessage(payload).subscribe({
      next: result => {
        this.loading = false;
        this.notif.success('Demo request sent','Your request to schedule a demo was successfully sent. We will respond shortly.',FormResultMessageFormat.popup,6000,null);
        setTimeout(() => {
          // this.quoteForm.reset();
          // this.quoteForm.markAsPristine();
          this.clearForm();
          setTimeout(() => {
            this.closeForm.emit();
          }, 100);

        }, 100);
      },
      error: err => {
        this.loading = false;
        this.formService.formError(err, this.demoForm, this.demoFormSettings, null);
      }
    })


  }




}
