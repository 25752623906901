import { Injectable, ElementRef, TemplateRef, EventEmitter } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Notification, NotificationType, FormResultMessageFormat, NotificationPosition, NotificationMessage } from './notification';

// @Injectable()
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private subject = new  Subject<Notification>();
  private _searchParamSource: BehaviorSubject<Notification>
  private dataStore: Notification;
  private id = 0;

  closeModalEvent: EventEmitter<any> = new EventEmitter<any>();

  private messageSource = new BehaviorSubject<string>('service');
  currentMessage = this.messageSource.asObservable();

  constructor() {
  }

  hide():void {
    this.closeModalEvent.emit();
    // this.subject.complete();
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  public getObservable(): Observable<Notification> {
    return this.subject.asObservable();
  }

  info(title: string, message: string, format: FormResultMessageFormat, timeout = 3000, template: TemplateRef<any> = null, html: boolean = false, position: NotificationPosition = NotificationPosition.bottom, footerTemplate: TemplateRef<any> = null) {
    this.subject.next(new Notification(this.id++, NotificationType.info, title, message, timeout, format, null, template, html, position, footerTemplate ));
  }

  showNotification(nd: NotificationMessage) {
    // switch (nd.type) {
    //   case NotificationType.success:
    //     this.success(nd.title,nd.message,nd.format,nd.timeout || 3000, nd.template || null, nd.html || false, nd.position || NotificationPosition.bottom)
    //     break;

    //   default:
    //     break;
    // }
    this.subject.next(new Notification(this.id++, nd.type,nd.title,nd.message,nd.timeout || 3000,nd.format,nd.el || null,nd.template || null,nd.html || false,nd.position || NotificationPosition.bottom));
  }
  success(title: string, message: string, format: FormResultMessageFormat, timeout = 3000, el: ElementRef, html: boolean = false, position: NotificationPosition = NotificationPosition.bottom) {
    // console.log("TEST");
    this.subject.next(new Notification(1, NotificationType.success, title, message, timeout, format, el, null, html, position,null));
  }

  warning(title: string, message: string, format: FormResultMessageFormat, timeout = 3000, el: ElementRef, html: boolean = false, position: NotificationPosition = NotificationPosition.bottom) {
    this.subject.next(new Notification(this.id++, NotificationType.warning, title, message, timeout, format, el, null, html, position));
  }

  error(title: string, message: string, format: FormResultMessageFormat, timeout = 3000, el: ElementRef, html: boolean = false, position: NotificationPosition = NotificationPosition.bottom) {
    // console.log("TEST");
    this.subject.next(new Notification(this.id++, NotificationType.error, title, message, timeout, format, el, null, html, position));
  }

  // confirm(title: string, message: string, format: FormResultMessageFormat, timeout = 0) {
  //   this.subject.next(new Notification(this.id++, NotificationType.confirm, title, message, timeout, format));
  // }
}

