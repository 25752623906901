import { IconDefinition } from "@fortawesome/fontawesome-common-types";

// Old interfaces
// TODO: once not user, clean up

export interface IProduct {
    name: string,
    is_free: boolean,
    is_trial: boolean,
    license_ends: string,
    PO_number?: string,
    license_starts?: string
}

export interface IProductNew {
    name: string,
    is_free: boolean,
    is_trial: boolean,
    license_ends: string,
    PO_number?: string,
    license_starts?: string
    code: string,
    license_type: LicenseTypes,
    code_generations_limit: number,
    code_generations_used: number
}

export interface IProductFinal {
    code: string,
    name: string,
    license_type: LicenseTypes,
    PO_number?: string,
    invoice_number?: string,
    license_key?: string,
    license_starts?: string
    license_ends: string,
    code_generations_limit: number,
    code_generations_used: number,
    deployment?: DeploymentType,
    status?: ProductStatus
}

export interface IProductFinalUI extends IProductFinal {
    statusText?: string,
    licenseTypeText?: string,
    generations?: string
}

export enum DeploymentType {
    cloud = 'cloud',
    on_prem = 'on_prem'
}

export interface IProductExtendedNew extends IProductNew {
    status: ProductStatus,      // calculated based on license type and expiration date/time
    generations: string         // will be string "code_generations_used / code_generations_limit"; this might be moved to license-list.service
}
export interface IProductExtended extends IProduct {
    code: string;
    status: ProductStatus,
    license_type: LicenseTypes,
    code_generations_limit: number,
    code_generations_used: number,
    users_limit: number,
    users_used: number,
    generations: string
}

export enum ProductStatus {
    valid = 'valid',
    not_valid = 'not_valid',
    expired = 'expired',
    expiring = 'expiring',
    not_applicable = 'not-applicable',
    not_paid = 'not_paid'
}

export enum LicenseTypes {
    free = 'free',
    trial = 'trial',
    project = 'project',
    enterprise = 'enterprise',
    no_license = 'no_license'
}

export interface IProductStatus {
    name: ProductStatus,
    text: string,
    icon: IconDefinition,
    class: string,
    hoverText: string,
    warningPriority: number
}

export interface IProductType {
    name: LicenseTypes,
    class: string,
    text: string
}

export interface IProductTypeNew {
    name: ApiLicenseType,
    // class: string,
    text: string
}

//* New updated interfaces based on API from Dave
// TODO: once updated everywhere, change names to IProduct....

// Main Product object
// TODO: add extended Product interface for UI

export interface ApiProduct {
    defaultProjectDays: number,
    defaultProjectUses: number,
    freeDays: number,
    id: string,
    isTrialAvailable: boolean,
    name: string,
    status: ApiProductStatus,
    trialUses: number,
    trialDays: number
}

export interface ApiProductCategory {
  id: string,
  name: string,
  status: ApiProductStatus,
  defaultProjectDays: number,
  defaultProjectUses: number,
  trialDays: number,
  trialUses: number,
  freeDays: number,
  isTrialAvailable: boolean,
  desscription: string,
  applicableProducts: string[],
  canTrial?: boolean,
  canQuote?: boolean,
  hasLicense?: boolean,
  statusFromLicense?: string
}


export enum ApiSubscriptionPeriod {
    month = 'month',
    day = 'day',
    week = 'week',
    year = 'year'
}

export enum ApiLicenseType {
    FreeLicense = 'FreeLicense',
    TrialLicense = 'TrialLicense',
    ProjectLicense = 'ProjectLicense',
    EnterpriseLicense = 'EnterpriseLicense',
    BetaTestingLicense = 'BetaTestingLicense',
    UsageLicense = 'UsageLicense'
}


export enum ApiProductStatus {
    trial = 'trial',
    free = 'free'
}

// Models based on API, but extended with properties as per discussion

export interface ApiProductExtended extends ApiProduct {
    // availableStatuses: ApiProductStatusExtended[],//!remove - not needed as status trial will be base on trialAllowed bool, and free status as well. expired will be set in server
    // availableTypes: ApiLicenseTypeExtended[],//! remove since all products will be available for project and enterprise, and for trial we will have other property
    // availableDeployments: ApiDeploymentType[], //TODO: this or onPremAvailable: boolean (so some projects are available only in the Cloud)
    // usesList?: number[], //! REMOVE
    // usesMin?: number,   //! REMOVE
    // usesMax?: number,   //! REMOVE
    // usesFreeValue: boolean,  //! TO REMOVE
    hasLicense?: boolean,    //* ONLY FOR UI
    canTrial?: boolean,
    canQuote?: boolean,
    demoUsed?: boolean,
    canUse?: boolean,
    statusFromLicense?: string,
    // TODO: add as below
    // trialAllowed: boolean
    // demoAllowed: boolean
    // isFree: boolean - or have FreeLicense in available types

}

export interface LicensePeriod {
    type: LicensePeriodType,
    value: number
}

// ******************************
export enum ApiLicenseTypeExtended {
    ProjectLicense = 'ProjectLicense',
    FreeLicense = 'FreeLicense',
    EnterpriseLicense = 'EnterpriseLicense',
    TrialLicense = 'TrialLicense'
}

export enum LicensePeriodType {
    day = 'days',
    week = 'weeks',
    month = 'months',
    year = 'years'
}

export interface LicensePeriodInput {
    type: LicensePeriodType,
    min: number,
    max: number,
    valueLabel?: string
}

export enum ApiProductStatusExtended {
    trial = 'trial',
    expiredTrial = 'expiredTrial',
    free = 'free',
    valid = 'valid',
    expired = 'expired'
}

export enum ApiDeploymentType {
    onPrem = 'onPrem',
    cloud = 'cloud'
}

// ***************************

// New License models based on API
// TODO: check if Date type is ok: add date format (Y-mm-ddThh:m:sZ), or change to string
export interface ApiLicense {
    id: string,
    start: string,
    end: string,
    productId: string,  //! to be removed once removed from API
    productName?: string, //! to be removed
    purchaseOrder: string,
    invoiceNumber: string,
    licenseUrl: string,
    licenseFilename: string
    notes: string,  // multiline
    licenseParams: ApiLicenseParams,
    uses: number,
    status: ApiLicenseStatus,
    productCategoryId: string,
    productCategoryName: string,
    productCategory: ApiProductCategory,
    // productCategory: {
    //   applicableProducts: string[],
    //   defaultProjectDays: number,
    //   defaultProjectUses: number,
    //   description: string,
    //   freeDays: number,
    //   id: string,
    //   name: string,
    //   status: string,
    //   trialAvailable: boolean,
    //   trialDays: number,
    //   trialUses: number
    // },
    isActive?: boolean
}

export interface ApiLicenseParams {
    type: ApiLicenseType,
    onPremExecutionId?: string,
    maxUses?: number,
    maxUsers?: number
    // id: string,
    // start: number[],
    // end: number[],
    // company: string,
    // companyName: string,
    // product: string,
    // productName: string,
    // notes: string,
    // productLicense: {
    //     type: ApiLicenseType,
    //     licenseAvailable: boolean
    // }
}



export enum ApiLicenseStatus {
    valid = 'valid',
    expired = 'expired',
    free = 'free',
    scheduled = 'scheduled',
    low = 'low',
    exhausted = 'exhausted',
    no_license = 'no_license'
}

// ************************

export interface ApiLicenseExtended extends ApiLicense {
    // licenseStatus: ApiProductStatusExtended,
    licenseStatus: ApiLicenseStatus,
    licenseType: ApiLicenseTypeExtended,
    deploymentType: ApiDeploymentType,
    licensePeriodString?: string,
    licensePeriod?: Duration,
    totalUses: number,
    trialUsed?: boolean,
    // status?: string,
    usesOfTotal?: UsesOfTotal,
    remainingTime?: string,
    canQuote?: boolean
}

export interface ApiLicenseAdmin extends ApiLicense {
  addNew?: boolean,
  renew?: boolean,
  upgrade?: boolean,
  licensePeriodString?: string,
  licensePeriod?: Duration,
  licenseTypeText?: string,
  licenseTiming?: string
}

export interface UsesOfTotal {
    total: number,
    used: number,
    available: number
}
