import { EventEmitter, Injectable } from '@angular/core';
import { ApiUser, IUser, UserRoles } from '../models/user';
import { ConfirmDialog } from '../shared/confirm-dialog/confirm-dialog';
import { FormResultMessageFormat, NotificationMessage, NotificationType } from '../shared/notification/notification';

interface listOfMessages {
  [key:string]: ConfirmDialog
}

interface listOfNotifications {
  [key:string]: NotificationMessage
}


@Injectable({
  providedIn: 'root'
})
export class UserInterfaceService {

  public customUIevent: EventEmitter<any> = new EventEmitter<any>();
  public showDemo:boolean;
  public showQuote: boolean;

  private noHeader = false;
  private _minHeader = false;
  get minHeader(): boolean {
    return this._minHeader;
  }
  set minHeader(val: boolean) {
    this._minHeader = val;
  }
  public demoAndQuoteAvailable = true;
  private freeTrialAvailable = true;
  userDropdownOpen = false;
  public sideMenu = false;
  public sideMenuWide: boolean;
  userDropdownUpdated:EventEmitter<boolean> = new EventEmitter<boolean>();


  //* NOTIFICATIONS
  notifications: listOfNotifications = {
    invitationRemoved: {
      title: 'Invitation removed!',
      message: 'Invitation sent to <strong>#param1</strong> was removed.',
      format: FormResultMessageFormat.popup,
      timeout: 5000,
      type: NotificationType.success,
      html: true
    },
    userRemoved: {
      title: 'User removed',
      message: 'User <strong>#param1</strong> was removed from the company <strong>#param2</strong>.',
      format: FormResultMessageFormat.popup,
      timeout: 3000,
      type: NotificationType.success,
      html: true
    },
    userPromoted: {
      title: 'User promoted',
      message: 'User <strong>#param1</strong> was promoted to <i><strong>Company Administrator</strong></i> of <strong>#param2</strong>.',
      format: FormResultMessageFormat.popup,
      timeout: 6000,
      type: NotificationType.success,
      html: true
    },
    userDemoted: {
      title: 'User demoted',
      message: 'User <strong>#param1</strong> was demoted from <i><strong>Company Administrator</strong></i> of <strong>#param2</strong>.',
      format: FormResultMessageFormat.popup,
      timeout: 6000,
      type: NotificationType.success,
      html: true
    },
    currentUserUpdated: {
      title: 'Current User updated',
      message: `Current User's <strong>#param1</strong> was updated.<br/>The new value is <strong>#param2</strong>.`,
      format: FormResultMessageFormat.popup,
      timeout: 6000,
      type: NotificationType.success,
      html: true
    },
    currentUserPropertyRemoved: {
      title: 'Current User updated',
      message: `Current User's <strong>#param1</strong> was <strong>cleared</strong>.`,
      format: FormResultMessageFormat.popup,
      timeout: 6000,
      type: NotificationType.warning,
      html: true
    }
  }

  //* CONFRIM MESSAGES
  confirmMessages:listOfMessages = {
    removeInvite: {
      title: "Delete Invite?",
      message: "Are you sure you want to remove invitation?",
      confirmBtn: "Yes",
      declineBtn: "No",
      dangerBtn: true
    },
    deleteLicense: {
      title: "Remove License",
      message: "You are about to delete a license for <strong>#param1</strong> for the company: <strong>#param2</strong>",
      declineBtn: "Cancel",
      confirmBtn: "DELETE",
      html: true,
      dangerBtn: true
    },
    supportToContactUs: {
      title: "Support Request",
      message: "You must be a registered user to raise a Support Request. Please login or register for our platform to access our products.",
      declineBtn: "Cancel",
      confirmBtn: "OK"
    },
    setTrial: {
      title: "Set Trial License?",
      message: "Please confirm you want to set a Trial License for <strong>#param1</strong> product.",
      confirmBtn: "Continue",
      declineBtn: "Cancel",
      dangerBtn: false,
      html: true
    },
    removeUser: {
      title: "Remove User?",
      message: "Please confirm you want to remove user <strong>#param1</strong> from the company <strong>#param2</strong>.",
      confirmBtn: "Remove the user",
      declineBtn: "Keep the user",
      dangerBtn: true,
      html: true
    },
    demoteUser: {
      title: "Demote User?",
      message: `Please confirm you want to demote user <strong>#param1</strong> from company administrator.`,
      confirmBtn: "Demote the user",
      declineBtn: "Cancel",
      html: true
    },
    promoteUser: {
      title: "Upgrade User?",
      message: `Please confirm you want to promote user <strong>#param1</strong> to company administrator.`,
      confirmBtn: "Upgrade the user",
      declineBtn: "Cancel",
      html: true
    },
    userLogout: {
      title: "Log out?",
      message: "Are you sure you want log out?",
      confirmBtn: "Yes",
      declineBtn: "No"
    },
    formNotSaved: {
      title: "Unsaved Changes",
      message: "There are unsaved changes! Are you sure?",
      confirmBtn: "Yes",
      declineBtn: "No",
      dangerBtn: true
    },
    impersonateCompany: {
      title: "Impersonate Company?",
      message: "Are you sure you want to impersonate owner of <strong>#param1</strong> company?",
      confirmBtn: "Yes",
      declineBtn: "No",
      html: true
    },
    stopImpersonating: {
      title: "Stop Impersonating Company?",
      message: "Are you sure you want to stop impersonating owner of <strong>#param1</strong> company?",
      confirmBtn: "Yes",
      declineBtn: "No",
      html: true
    },
    clearTheForm: {
      title: "Reset the form?",
      message: "Are you sure you want clear the form?",
      confirmBtn: "Yes",
      declineBtn: "No"
    }
  };


  // -------------------------
  // *  FORMS


  // License related forms (Quote, Add license...)
  licenseActionTypes: {id: string, text: string}[] = [
    {
      id: 'new',
      text: 'New Quote'
    },
    {
      id: 'renew',
      text: 'Renewal of license'
    },
    {
      id: 'upgrade',
      text: 'Upgrade to existing license'
    }
  ];

  public fireCustomEvent(property?: any): void {
    this.customUIevent.emit(property || null);
  }

  licenseTypesDropdown: {[key:string]:{value: string, text: string}} = {
    ProjectLicense: {value: 'ProjectLicense',text: 'Project License'},
    EnterpriseLicense: {value: 'EnterpriseLicense',text: 'Enterprise License'},
    TrialLicense: {value: 'TrialLicense',text: 'Trial License'},
    UsageLicense: {value: 'UsageLicense', text: 'Usage License'}
  }

  deploymentTypes: {[key:string]:{value: string, text: string}} = {
    onPrem: {value: 'opPrem',text: 'On Premises'},
    cloud: {value: 'cloud',text: 'Cloud'}
  }

  // ! to be removed
  usesWordForProduct: {[key:string]:{name:string,label:string,placeholder:string,customLabel: string,customPlaceholder:string}} = {
    restgen: {name: 'generations',label: 'Number of Generations',placeholder:'Select number of generations.',customLabel:'Custom Generations',customPlaceholder:'custom generations number'},
    converter: {name: 'conversions',label: 'Number of Conversions',placeholder:'Select number of conversions.',customLabel:'Custom Conversions',customPlaceholder:'custom convertions number'},
    crypto: {name: 'uses',label: 'Number of Uses',placeholder:'Select number of uses.',customLabel:'Custom Uses',customPlaceholder:'custom uses number'},
    tools: {name: 'uses',label: 'Number of Uses',placeholder:'Select number of uses.',customLabel:'Custom Uses',customPlaceholder:'custom uses number'}
  }



  getDemoAndQuote():boolean {
    return this.demoAndQuoteAvailable;
  }

  setDemoAndQuote(userSettings: ApiUser) {
    this.demoAndQuoteAvailable = !(
      userSettings &&
      (userSettings.roles.includes(UserRoles.administrator))
    );

  }

  private getMessage<T>(name: string, messageType: string, params?: string[]): T {
    let m: T = Object.assign({}, this[messageType][name]);
    if(params) {
      params.forEach((param,i) => {
        m['message'] = m['message'].replace(`#param${i+1}`,param)
      });
    }
    return m;
  }

  public getNotificationMessage(name: string, params?: string[]): NotificationMessage {
    return this.getMessage<NotificationMessage>(name, 'notifications', params || null);
  }

  public getConfirmMessage(name: string, params?: string[]): ConfirmDialog {
    return this.getMessage<ConfirmDialog>(name, 'confirmMessages', params || null);
  }



  public getUserDropdownStatus():boolean {
    return this.userDropdownOpen;
  }

  public setUserDropdownStatus(status:boolean, setEvent = false): void {
    this.userDropdownOpen = status;
    if(setEvent) this.userDropdownUpdated.emit(this.userDropdownOpen);
  }

  public toggleSideMenu(): void {
    this.sideMenuWide = !this.sideMenuWide;
  }





  get trial():boolean {
    return this.freeTrialAvailable;
  }

  public hideHeader():void {
    this.noHeader = true;
  }
  public showHeader():void {
    this.noHeader = false;
  }
  public headerVisible(): boolean {
    return this.noHeader;
  }

  constructor() { }


  // * GENERAL USE FUNCTIONS
  public capitalizeString(input: string): string {
    return input.charAt(0).toUpperCase() + input.toString().slice(1).toLowerCase();
  }
}
