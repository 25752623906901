import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiUser, IUser, UserRoles } from '../models/user';
import { IAuth } from '../models/authenticate';
import { User, UserManager } from 'oidc-client';
import { AppSettingsService } from './app-settings.service';
// import { NotificationService } from '../shared/notification/notification.service';
// import { FormResultMessageFormat } from '../shared/notification/notification';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { NotificationService } from '../shared/notification/notification.service';
import { FormResultMessageFormat } from '../shared/notification/notification';
import { ApiService } from './api.service';
import { ApiCompany } from '../models/company';
// import { promise } from 'protractor';




@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userLoggedIn: boolean;
  private userManager: UserManager;
  private user: User;
  private loginChangedSubject = new Subject<boolean>();

  loginChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  loginChange = this.loginChangedSubject.asObservable();

  //  this will be for API context - checking if user has permission
  // user claims and user profile data
  // authContext: AuthContext;


  token: string;
  private apiUrl = {
    authenticate: 'http://restgen-9g-dev.diamondedge-it.com:8081/authenticate',
    getUser: 'http://restgen-9g-dev.diamondedge-it.com:8081/api/user/me'
  }

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
    private notService: NotificationService,
    private userService: UserService,
    private apiService: ApiService,
    private router: Router,
    ) {

    // TODO: this needs to come from environment file
    // IdP provider settings (keycloak in our case)


    const stsSettings = {
      authority: `${this.appSettings.baseSetting.stsData.url}`,
      client_id: `${this.appSettings.baseSetting.stsData.clientName}`,
      redirect_uri: `${this.appSettings.baseSetting.baseUrl}/signin-callback`,
      scope: `${this.appSettings.baseSetting.stsData.scope}`,
      response_type: 'code', // this is for authorization flow with PKCE, for inplicit from use 'id_token token',
      post_logout_redirect_uri: `${this.appSettings.baseSetting.baseUrl}/signout-callback`,
      // filterProtocolClaims: true, // todo check this one
      loadUserInfo: false, // todo check this one
      require_request_uri_registration: false,
      accessTokenExpiringNotificationTime: 30,  // set to 30 seconds to show up popup before token expired
      automaticSilentRenew: true, // for now set to auto renewal, we might want to change it
      silent_redirect_uri: `${this.appSettings.baseSetting.baseUrl}/assets/silent-callback.html`,
      // prompt: "login"
    };

    window['oidcConfig'] = stsSettings; // passed to window to be read by silent-callback.html

    this.userManager = new UserManager(stsSettings);  // setting new oidc manager with above settings

    this.userManager.events.addUserSessionChanged(() => {
    });



    this.userManager.events.addUserSignedOut(() => {
      this.userManager.signoutRedirect();
    });


    this.userManager.events.addAccessTokenExpired(_ => {
      this.loginChangedSubject.next(false);
      setTimeout(() => {
        this.loginChangeEvent.emit(false)
      }, 100);
    });

  }

  // login redirects to IdP login page and back to login redirect specified in settings
  // todo: add custom redirect link, so it can go back to page that request came from
  // todo: check first on PS project if this works witout any changes
  login(returnRoute = null) {
    return this.userManager.signinRedirect({
      state: returnRoute
    });
    // .then(x => console.log("LOGGED IN !!!!!!!!!!!"));
  }

  // this is for SSO: if user not logger in, but session active (open new app or new tab)
  // check if session is active in IdP:
  // if yes -> do silent signin
  silentSignin(): void {
    this.userManager.signinSilent().then(x => {
      this.userManager.getUser().then(x => {
        if (x) {
          // console.log(x);
          // let sessionStart = x?.profile.auth_time;
          // if(sessionStart) {
          //   let sessionEnd = sessionStart + 150;
          //   let now = Math.floor(Date.now()/1000);
          //   let sessionTimeOut = sessionEnd - now;
            // setTimeout(() => {
            //   console.log("SESSION EXPIRING IN 30 seconds");
            //   this.silentSignin();
            // },sessionTimeOut*1000);
          // }
          this.notService.success('Sign in successful', `User ${x.profile.name} was logged in successfully.`, FormResultMessageFormat.popup, 3000, null);
          this.loadSecurityContenxt();
        }
      })
    });
  }

  silentSigninPromise(): Promise<void> {
    return this.userManager.signinSilent().then(x => {
      this.userManager.getUser().then(x => {
        if (x) {
          this.notService.success('Sign in successful', `User ${x.profile.name} was logged in successfully.`, FormResultMessageFormat.popup, 3000, null);
          this.loadSecurityContenxt();
        }
      })
    });
  }

  checkSessionStatus(): Promise<any> {
    return this.userManager.querySessionStatus().then(session => {
        if (!!session && session.session_state) {
            return this.userManager.signinSilent()
            .then(x => {

              return this.userManager.getUser().then(user => {
                const userCurrent = !!user && !user.expired;
                this.userLoggedIn = userCurrent;
                if (userCurrent) {
                  this.loginChangedSubject.next(userCurrent);
                  setTimeout(() => {
                    this.loginChangeEvent.emit(userCurrent);
                  }, 100);

                  this.notService.success('Sign in successful', `User ${x.profile.name} was logged in successfully.`, FormResultMessageFormat.popup, 3000, null);
                  this.loadSecurityContenxt();
                  this.user = user;
                  return userCurrent;
                } else {
                  return null;
                }
              });

              this.loadSecurityContenxt();
            });
        } else {
          return null;
        }
    }).catch(err => {
      console.log(err);
      return null;
    });
  }

  async isLoggedInAndLoaded(): Promise<boolean> {
    // TODO: See if I can make this work and use it
    // TODO: the issue is that it does not wait for userUpdated.toPromise() event
    // TODO: if fixed, it will not call current user API twice when getting to protected page on first app load
    return this.userManager.getUser().then(async user => {
      const userCurrent = !!user && !user.expired;
      if (!this.user || this.user.profile.email !== user.profile.email) {
        if (userCurrent) {
          await this.userService.userUpdated.toPromise();
        }
      }
      return userCurrent;
    });
  }



  async isLoggedIn(): Promise<boolean> {
    return this.userManager.getUser().then(async user => {

        const userCurrent = !!user && !user.expired;
        if (!this.user || this.user.profile.email !== user.profile.email) {

          if (userCurrent) {
            this.loginChangedSubject.next(userCurrent);
            setTimeout(() => {
              this.loginChangeEvent.emit(userCurrent);
            }, 100);

            await this.getCurrentUser().toPromise();
          }
          this.user = user;
        }

        return userCurrent;
    })
  }

  // fired when callback comes from IdP after login
  completeLogin() {
    return this.userManager.signinRedirectCallback().then(user => {
        this.user = user;
        this.loginChangedSubject.next(!!user && !user.expired);
        setTimeout(() => {
          this.loginChangeEvent.emit(!!user && !user.expired);
        }, 100);

        this.loadSecurityContenxt();

        this.notService.success('Sign in successful', `User ${user.profile.name} was logged in successfully.`, FormResultMessageFormat.popup, 3000, null);
        return user;
    });
  }

  new_logout() {
    this.userManager.signoutRedirect();
  }

  completeLogout() {
    this.user = null;
    this.loginChangedSubject.next(false);
    setTimeout(() => {
      this.loginChangeEvent.emit(false);
    }, 100);

    this.notService.success('Logged Out', `User was logged out successfully.`, FormResultMessageFormat.popup, 3000, null);
    return this.userManager.signoutRedirectCallback();
  }

  // Internal functions for auth links generation

  // generates random 32-bit 28-character string
  private generateRandomString(): string {
    let array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
  }

  // encodes input string to SHA-256
  // Calculate the SHA256 hash of the input text.
  // Returns a promise that resolves to an ArrayBuffer

  private sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }


  // Base64-urlencodes the input string
  private base64urlencode(str: ArrayBufferLike): string {
    // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
    // btoa accepts chars only within ascii 0-255 and base64 encodes them.
    // Then convert the base64 encoded to base64url encoded
    //   (replace + with -, replace / with _, trim trailing =)
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
        .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  // Return the base64-urlencoded sha256 hash for the PKCE challenge
  private async pkceChallengeFromVerifier(v): Promise<string> {
    var hashed = await this.sha256(v);
    return this.base64urlencode(hashed);
  }

  // public functions for auth links generation: register, trial, password change, 2-factor validation and invitation


  async getSetupTwoFactorLink(): Promise<string> {

    let link: string;

    let state = this.generateRandomString();
    let nonce = this.generateRandomString();
    let pkceCodeVerifier = this.generateRandomString();

    let code_challange = await this.pkceChallengeFromVerifier(pkceCodeVerifier);
    // link = `${this.appSettings.baseSetting.stsData.url}/protocol/openid-connect/auth?client_id=${this.appSettings.baseSetting.stsData.clientName}&redirect_uri=${encodeURIComponent(this.appSettings.baseSetting.baseUrl + '/password-change-callback')}&state=${state}&response_mode=fragment&response_type=code&scope=openid&nonce=${nonce}&kc_action=UPDATE_PASSWORD&code_challenge=${code_challange}&code_challenge_method=S256`;
    // link = `${this.appSettings.baseSetting.stsData.url}/login-actions/required-action?execution=CONFIGURE_TOTP&client_id=account-console&tab_id=h2BN_TRZJ6g&redirect_uri=http://localhost:3000/user/security`
    link = `${this.appSettings.baseSetting.stsData.url}/protocol/openid-connect/auth?client_id=account-console&redirect_uri=${encodeURIComponent(this.appSettings.baseSetting.baseUrl + '/user/security')}&state=${state}response_mode=fragment&response_type=code&scope=openid&nonce=${nonce}&kc_action=CONFIGURE_TOTP&code_challenge=${code_challange}&code_challenge_method=S256`;
    return link;
  }

  async getPasswordChangeLink(): Promise<string> {
    function generateRandomString() {
      var array = new Uint32Array(28);
      window.crypto.getRandomValues(array);
      return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    }

    // Calculate the SHA256 hash of the input text.
    // Returns a promise that resolves to an ArrayBuffer
    function sha256(plain) {
      const encoder = new TextEncoder();
      const data = encoder.encode(plain);
      return window.crypto.subtle.digest('SHA-256', data);
    }

    // Base64-urlencodes the input string
    function base64urlencode(str) {
      // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
      // btoa accepts chars only within ascii 0-255 and base64 encodes them.
      // Then convert the base64 encoded to base64url encoded
      //   (replace + with -, replace / with _, trim trailing =)
      return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
          .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    // Return the base64-urlencoded sha256 hash for the PKCE challenge
    async function pkceChallengeFromVerifier(v) {
      var hashed = await sha256(v);
      return base64urlencode(hashed);
    }

    let link: string;

    let state = generateRandomString();
    let nonce = generateRandomString();
    let pkceCodeVerifier = generateRandomString();

    let code_challange = await pkceChallengeFromVerifier(pkceCodeVerifier);

    function AddMinutesToDate(date, minutes) {
      return new Date(date.getTime() + minutes*60000);
    }

    var minutesToAdd=30;
    var currentDate = Date.now();
    // debugger;

    var futureDate = currentDate + minutesToAdd*60000;
    // var futureDate = new Date(currentDate.getTime() + minutesToAdd*60000);

    localStorage.setItem(`kc-callback-${state}`, JSON.stringify({
      // expires: futureDate,
      nonce: nonce,
      pkceCodeVerifier: pkceCodeVerifier,
      redirectUri: encodeURIComponent(this.appSettings.baseSetting.baseUrl + '/signin-callback'),
      state: state
    }));

    link = `${this.appSettings.baseSetting.stsData.url}/protocol/openid-connect/auth?client_id=${this.appSettings.baseSetting.stsData.clientName}&redirect_uri=${encodeURIComponent(this.appSettings.baseSetting.baseUrl + '/password-change-callback')}&state=${state}&response_mode=fragment&response_type=code&scope=openid&nonce=${nonce}&kc_action=UPDATE_PASSWORD&code_challenge=${code_challange}&code_challenge_method=S256`;
    return link;
  }

  getInviteResponseLink(code: string, companyName: string, inviter: string, invitee: string): string {
    let link: string;
    let companyCodeParam = (code ? '&cc=' + code + '&cn=' + companyName : '&cc=0');
    let trialParam = '&trial=false';

    link = `${this.appSettings.baseSetting.baseUrl}/invitation-response/${code}/${encodeURIComponent(companyName)}/${encodeURIComponent(invitee)}/${encodeURIComponent(inviter)}`;

    return link;
  }

  getInvitaionResponseLink(code: string, companyName: string): string {
    let link: string;
    let companyCodeParam = (code ? '&cc=' + code + '&cn=' + companyName : '&cc=0');
    let trialParam = '&trial=false';

    link = `${this.appSettings.baseSetting.baseUrl}/register-callback?state=${encodeURIComponent(code)}`;

    return link;
  }

  getInviteLoginLink(code: string, companyName: string): string {
    let link: string;
    let companyCodeParam = (code ? '&cc=' + code + '&cn=' + companyName : '&cc=0');
    let trialParam = '&trial=false';

    link = `${this.appSettings.baseSetting.stsData.url}/protocol/openid-connect/auth?client_id=${this.appSettings.baseSetting.stsData.clientName}&redirect_uri=${encodeURIComponent(this.appSettings.baseSetting.baseUrl + '/register-callback')}&response_type=code&scope=${this.appSettings.baseSetting.stsData.scope}&register=true&state=${encodeURIComponent(code)}&response_mode=query${trialParam}${companyCodeParam}`;

    return link;
  }

  getInviteRegistrationLink(code: string, companyName: string, inviteeEmail?: string): string {

    function sha256(plain) {
      const encoder = new TextEncoder();
      const data = encoder.encode(plain);
      return window.crypto.subtle.digest('SHA-256', data);
    }


    let link: string;
    let companyCodeParam = (code ? '&cc=' + code + '&cn=' + companyName : '&cc=0');
    let trialParam = '&trial=false';
    let email = (inviteeEmail ? '&em=' + encodeURIComponent(inviteeEmail)  : '');

    link = `${this.appSettings.baseSetting.stsData.url}/protocol/openid-connect/registrations?client_id=${this.appSettings.baseSetting.stsData.clientName}&redirect_uri=${encodeURIComponent(this.appSettings.baseSetting.baseUrl + '/register-callback')}&response_type=code&scope=${this.appSettings.baseSetting.stsData.scope}&register=true&state=${encodeURIComponent(code)}&response_mode=query${trialParam}${companyCodeParam}${email}`;

    return link;
  }

  async getRegistrationLink(trial?: boolean, companyName?: string):Promise<string> {
    let crypto = (typeof window !== 'undefined') ? window.crypto : null;

    let trialPamar = (trial ? '&trial=true' : '&trial=false');
    let companyNameParam = (companyName ? '&cn=' + companyName : '&cn=0&cc=0');
    // PKCE HELPER FUNCTIONS

    // Generate a secure random string using the browser crypto functions
    function generateRandomString() {
      var array = new Uint32Array(28);
      window.crypto.getRandomValues(array);
      return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    }

    // Calculate the SHA256 hash of the input text.
    // Returns a promise that resolves to an ArrayBuffer
    function sha256(plain) {
      const encoder = new TextEncoder();
      const data = encoder.encode(plain);
      return window.crypto.subtle.digest('SHA-256', data);
    }

    // Base64-urlencodes the input string
    function base64urlencode(str) {
      // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
      // btoa accepts chars only within ascii 0-255 and base64 encodes them.
      // Then convert the base64 encoded to base64url encoded
      //   (replace + with -, replace / with _, trim trailing =)
      return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
          .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    // Return the base64-urlencoded sha256 hash for the PKCE challenge
    async function pkceChallengeFromVerifier(v) {
      var hashed = await sha256(v);
      return base64urlencode(hashed);
    }

    var state = '0bfb40c9-db8e-4849-b630-318dc505f510';
    localStorage.setItem("pkce_state", state);

    var code_verifier = generateRandomString();
    localStorage.setItem("pkce_code_verifier", code_verifier);

    localStorage.setItem(`oidc.${state}`, JSON.stringify({
      authority: this.appSettings.baseSetting.stsData.url,
      client_id: this.appSettings.baseSetting.stsData.clientName,
      code_verifier: code_verifier,
      created: Date.now(),
      data: "/",
      extraTokenParams: {},
      id: state,
      redirect_uri: this.appSettings.baseSetting.baseUrl + '/signin-callback',
      request_type: "si:r",
      response_mode: "query",
      scope: this.appSettings.baseSetting.stsData.scope,
    }));

    // Hash and base64-urlencode the secret to use as the challenge
    var code_challenge = await pkceChallengeFromVerifier(code_verifier);

    return `${this.appSettings.baseSetting.stsData.url}/protocol/openid-connect/registrations?client_id=${this.appSettings.baseSetting.stsData.clientName}&redirect_uri=${encodeURIComponent(this.appSettings.baseSetting.baseUrl + '/register-callback-trial')}&response_type=code&scope=${this.appSettings.baseSetting.stsData.scope}&register=true&state=${encodeURIComponent(state)}&code_challenge=${encodeURIComponent(code_challenge)}&code_challenge_method=S256&response_mode=query${trialPamar}${companyNameParam}`
  }

  // this is giving token to be used in API call
  // todo: think about saving tokent in localstorage - or check maybe its reading from local storage already
  getAccessToken() {
    return this.userManager.getUser().then(user => {
      if (!!user && !user.expired) {
        return user.access_token;
      } else {
        return null;
      }
    });
  }

  // this goes to API to get user data, after user logs in

  getCurrentUser(): Observable<ApiUser> {
    return this.apiService.getUser().pipe(
      tap((user: ApiUser) => {
        this.userService.setUser(user);
        this.apiService.getCompany().subscribe((c:ApiCompany) => {
          this.userService.setIndustry(c.industry || 'Other');
        })
        this.appSettings.logCompany({id: user.company.id, name: user.company.name});
        return true;
      }));
  }


  // ! Depritiated method; not usedl to be removed
  loadUserDate(): Promise<Object> {
    return this.http.get('https://api.dev.smartcodeplatform.com/v1/companies/mine/users/current', {withCredentials: true})
    .toPromise().then(user => {
      return true;
    })
  }

  loadSecurityContenxt() {
    this.http.get(this.appSettings.getApi('currentUser'), {withCredentials: true}).pipe(
      // tap((receivedData: IUser) => console.log(receivedData)),
      map((receivedData: ApiUser) => {
        this.userService.setUser(receivedData);
        this.apiService.getCompany().subscribe((c:ApiCompany) => {
          this.userService.setIndustry(c.industry || 'Other');
        })
      })
    ).subscribe(
      response => {},
      error => {}
    );
  }








  // OLD FUNCTIONS THAT NEEDS TO BE REMOVED

  authenticate(username: string, password: string): Observable<IAuth> {
    return this.http.post<IAuth>(this.apiUrl.authenticate, {username: username, password: password}).pipe(
      tap(data => console.log(JSON.stringify(data))),
      catchError(this.handleError)
    );
  }
  isUserAuthenticated(): Observable<boolean> {
    const token = localStorage.getItem('token');
    // const token = "16165163163";
    if(token) {

      return this.http.get<any>(this.apiUrl.getUser, {headers: {'X-Auth-Token': token}}).pipe(
        tap(data => {return true}),
        catchError(err => {return of(false)})
      );
    } else {
      return of(false);
    }
  }



  getUserInfo(token: string): Observable<IUser> {
    return this.http.get<any>(this.apiUrl.getUser, {headers: {'X-Auth-Token': token}}).pipe(
      tap(data => console.log(data)),
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    console.log(err);
    return throwError(err);
  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    // console.log(localStorage);
  }
}
