<section class="box-container" [class.first-on-top]="firstOnTheTop">
    <header *ngIf="boxHeading">
        <div class="header-outer">
            <div class="header-inner">
                <h2>{{boxHeading}}</h2>
            </div>
        </div>
    </header>
    <section class="box-content"
        [class.less-padding]="lessPadding">
        <ng-content></ng-content>
    </section>
</section>
