import { EventEmitter, Injectable } from '@angular/core';
import { barStatuses } from './status-bar';
import { HttpClient } from '@angular/common/http';
// import { resourceLimits } from 'worker_threads';

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {

  barVisible: boolean = false;
  apiEndpoint: string;
  activeStatus: barStatuses;
  statusesList: barStatuses[];

  checkingParseStatus = false;
  checkingDocumentStatus = false;

  hidden = false;

  completedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  completeDocument: EventEmitter<boolean> = new EventEmitter<boolean>();

constructor(
  private http: HttpClient
) { }

  showBar():void {
    this.hidden = true;

    this.barVisible = true;
    document.body.classList.add('status-bar');
    setTimeout(() => {
      this.hidden = false;
    }, 10);
  }

  hideBar(): void {
    this.barVisible = false;
    document.body.classList.remove('status-bar');
  }

  setStatuses(inputStatuses: barStatuses[]): void {
    this.statusesList = inputStatuses;
  }

  startAPI(endpoint: string, timer: number, apiStatus: string): void {
    this.checkingParseStatus = true;
    this.checkingDocumentStatus = true;
    this.apiEndpoint = endpoint;
    this.activeStatus = this.statusesList.find(el=>el.apiStatus === apiStatus);
    this.showBar();
    let startTime = 0;
    // TODO: add loop to call api, keep calling until there is change in status
    let i=0;
    const interval = setInterval(
      ()=>{
        // console.log(i);

        this.http.get(this.apiEndpoint,{withCredentials: true}).subscribe({
          next: result => {
            this.activeStatus = this.statusesList.find(el=>el.apiStatus.toLocaleLowerCase() === result['parseStatus'].toLocaleLowerCase());
            if(this.checkingParseStatus && result['parseStatus'].toLocaleLowerCase() === 'completed') {
              console.log("COMPLETED");
              this.completedEvent.emit(true);
              this.checkingParseStatus = false;
              startTime = (new Date().getTime()) / 1000;
              // clearInterval(interval);
            }
            if(this.checkingDocumentStatus && result['documentationStatus'].toLocaleLowerCase() === 'completed') {
              this.completeDocument.emit(true);
              this.checkingDocumentStatus = false;
            }
            if((!this.checkingDocumentStatus && !this.checkingParseStatus) || (startTime && (new Date().getTime()) / 1000 - startTime > 60  )) {
              clearInterval(interval);
              if(this.checkingDocumentStatus) {
                this.completeDocument.emit(true);
                this.checkingDocumentStatus = false;
              }
            }
          },
          error: err => console.log(err)
        })
        // if(i===100) {
        //   this.hidden = true;
        //   setTimeout(() => {
        //     this.activeStatus = this.statusesList.find(el=>el.apiStatus === 'completed');
        //     this.hidden = false;
        //   }, 300);

        //   clearInterval(interval);
        // }
        // i++;
        // TODO: Call parsing status API until status is completed or error
      },timer
    );
  }

  closeBar(): void {
    this.hidden = true;
    setTimeout(() => {
      this.hideBar();
      this.activeStatus = null;
      this.statusesList = [];
    }, 300);
  }

}
