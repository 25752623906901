import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SimpleFilterPipe } from '../../pipes/simple-filter.pipe';
import { FormSummary, FormSummaryGroup } from './form-summary';

@Component({
  selector: 'lib-form-summary',
  templateUrl: './form-summary.component.html',
  styleUrls: ['./form-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormSummaryComponent implements OnInit {
  @Input() fields: FormSummary[];
  @Input() title: string;
  @Input() subTitle: string;
  @Input() leftBorder: boolean = false;
  @Input() grouped: boolean = false;
  @Input() collapsed: boolean = false;
  @Input() collapsible: boolean = false;
  @Input() disabled: boolean = false;
  @Input() verified: boolean = false;
  @Input() verifiedText: string = "";

  @Input() summaryGroup: FormSummaryGroup[] = [];

  constructor() { }

  ngOnInit() {
    if(this.grouped) {
      const unique = [...new Set(this.fields.map(item => item.parentId))]; // [ 'A', 'B']
      unique.forEach(idKey=>{
        this.summaryGroup.find(el=>el.id === idKey).fields = this.fields.filter(el=>el.parentId === idKey);
      });
    }
  }

}
