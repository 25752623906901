import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { barStatuses, statusClasses } from './status-bar';
import { StatusBarService } from './status-bar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class StatusBarComponent implements OnInit {

  @Input() visible = false;
  @Input() statusData: barStatuses;
  @Input() hidden = false;

  statusClass: statusClasses = statusClasses.warning;

  constructor(
    private router: Router,
    public statusBarService: StatusBarService
  ) { }


  ngOnInit() {
  }

  buttonClicked(status: string): void {
    let link = this.statusData.buttonLink;
    this.statusBarService.closeBar();
    this.router.navigate(this.statusData.buttonLink);
  }
}
