import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { slideInOutAnimation } from 'projects/nine-gold-lib/src/lib/animations/slide-in-out.animation';

@Component({
  selector: 'app-demo-form',
  templateUrl: './demo-form.component.html',
  styleUrls: ['./demo-form.component.scss'],
  animations: [slideInOutAnimation],
  host: { '[@slideInOutAnimation]': '' },
  encapsulation: ViewEncapsulation.None
})
export class DemoFormComponent implements OnInit {
  faTimesIcon = faTimes;
  redirectUrl: string;
  open = false;
  productCode: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.redirectUrl = this.route.snapshot.queryParams['returnUrl'];
    this.productCode = this.route.snapshot.queryParams['pcode'];
    // this.open = true;
  }
  ngAfterViewInit():void {
    this.open = true;
  }

  ngOnDestroy(): void {
    this.open = false;
  }

  goBack(): void {
    // this.location.back();
    // this.router.navigate(['pricing']);
    this.router.navigate(
			[
        "../",
				// NOTE: No relative-path navigation is required because we are accessing
				// the parent's "activatedRoute" instance. As such, this will be executed
				// as if we were doing this in the parent view component.
				{
					outlets: {
            sidePopup: null
					}
				}
      ],
      // {
      //   queryParams: null
      // },
			{
				relativeTo: this.route.parent // <--- PARENT activated route.
			}
		);

  }

}
