export interface StatusBar {
  barsByStatus: barStatuses[];
}

export enum statusClasses {
  warning = 'warning',
  info = 'info',
  error = 'error',
  success = 'success'
}

export interface barStatuses {
  apiStatus: string,
  statusClass: statusClasses,
  text: string,
  closeBtn: boolean,
  showBtn: boolean,
  buttonText: string,
  buttonLink: string[],
  loadingIcon?: boolean
}
