import { EventEmitter, Injectable, Output } from '@angular/core';
import { text } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faDollarSign, faExclamation, faExclamationCircle, faExclamationTriangle, faMinus, faMinusCircle, faMinusSquare, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { formatDistance, formatDistanceToNow, intervalToDuration } from 'date-fns';
import { ApiDeploymentType, ApiLicense, ApiLicenseExtended, ApiLicenseStatus, ApiLicenseType, ApiLicenseTypeExtended, ApiProduct, ApiProductCategory, ApiProductExtended, ApiProductStatus, ApiProductStatusExtended, ApiSubscriptionPeriod, IProductExtended, IProductExtendedNew, IProductFinal, IProductFinalUI, IProductNew, IProductStatus, IProductType, IProductTypeNew, LicensePeriod, LicensePeriodInput, LicensePeriodType, LicenseTypes, ProductStatus } from '../models/product';
import { ApiService } from './api.service';
import { AppSettingsService } from './app-settings.service';
import { UserInterfaceService } from './user-interface.service';
import { LicenseStatus } from '../shared/ng-navigation/ng-navigation';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  @Output() licensesUpdated: EventEmitter<any> = new EventEmitter();

  @Output() licenseAdded: EventEmitter<string> = new EventEmitter<string>();

  @Output() usageLicensesUpdated: EventEmitter<ApiLicense[]> = new EventEmitter<ApiLicense[]>();

  productsUpdated: EventEmitter<any> = new EventEmitter();

  // statusCodesList: ProductStatus[] = [
  //   ProductStatus.valid,
  //   ProductStatus.expired,
  //   ProductStatus.expiring,
  //   ProductStatus.not_applicable,
  //   ProductStatus.not_paid,
  //   ProductStatus.not_valid
  // ];

  // TODO: Convert this into warning type, which will show next to the product name
  // TODO: warnings: expiring (less then 2 weeks or 10% of length), low, no uses (or generations)
  statusList: IProductStatus[] = [
    {
      name: ProductStatus.valid,
      text: 'Valid',
      icon: faCheckCircle,
      class: 'green',
      hoverText: 'License is Valid.',
      warningPriority: 0
    },
    {
      name: ProductStatus.expired,
      text: 'Expired',
      class: 'red',
      icon: faTimesCircle,
      // icon: faTimes,
      hoverText: 'License is Expired.',
      warningPriority: 2
    },
    {
      name: ProductStatus.expiring,
      text: 'Expiring Soon',
      class: 'orange',
      // icon: faExclamationCircle,
      // icon: faExclamation,
      icon: faExclamationTriangle,
      hoverText: 'License will expire in less than 2 weeks.',
      warningPriority: 1
    },
    {
      name: ProductStatus.not_valid,
      text: 'No License',
      class: 'gray',
      // icon: faMinusCircle,
      // icon: faMinus,
      icon: faMinusSquare,
      hoverText: 'License never obtained.',
      warningPriority: 0
    },
    {
      name: ProductStatus.not_applicable,
      text: 'N/A',
      class: 'not-applicable',
      icon: null,
      hoverText: 'Product not licensed.',
      warningPriority: 0
    },
    {
      name: ProductStatus.not_paid,
      text: 'Not Paid',
      class: 'not-paid',
      icon: faDollarSign,
      hoverText: 'License waiting for payment',
      warningPriority: 3
    }
  ];


  // ---------------------------------------------------------------------------
  // Options for Product Lenght dropdown - in Quote or New license forms
  // ---------------------------------------------------------------------------
  private productLenthOptions: {term: LicensePeriodType, value: number}[] = [
    {term: LicensePeriodType.month, value: 3},
    {term: LicensePeriodType.month, value: 6},
    // {term: LicensePeriodType.month, value: 9},
    // {term: LicensePeriodType.year, value: 1}
  ];


  shortProductNames:{val:string, text: string}[] = [
    {val: 'jsontoramlconverter', text: 'UTIL Converter: JSON SCHEMA &rArr; RAML'},
    {val: 'ramltojsonconverter', text: 'UTIL Converter: RAML Example &rArr; JSON'},
    {val: 'ramltooasconverter', text: 'UTIL Converter: RAML &rArr; OAS'},
    {val: 'jsontoramlconverterex', text: 'UTIL Converter: JSON &rArr; RAML Example'},
    {val: 'oastoramlconverter', text: 'UTIL Converter: OAS &rArr; RAML'},
    {val: 'postmancollection', text: 'UTIL Generator: Postman'},
    {val: 'restgen', text: 'REST Generator'}
  ];

  getShortProductName(val: string): string {
    return this.shortProductNames.find(el => el.val === val)?.text || "";
  }

  productTypeText: {name: ApiProductStatus, text: string}[] = [
    {
      name: ApiProductStatus.free,
      text: 'Free Tier'
    },
    {
      name: ApiProductStatus.trial,
      text: 'Premium'
    }
  ];

  typeListNew: IProductTypeNew[] = [
    {
      name: ApiLicenseType.FreeLicense,
      text: 'Free Tier'
    },
    {
      name: ApiLicenseType.TrialLicense,
      text: 'Trial'
    },
    {
      name: ApiLicenseType.EnterpriseLicense,
      text: 'Enterprise'
    },
    {
      name: ApiLicenseType.ProjectLicense,
      text: 'Project'
    },
    {
      name: ApiLicenseType.BetaTestingLicense,
      text: 'Beta Testing'
    },
    {
      name: ApiLicenseType.UsageLicense,
      text: 'Usage'
    }
  ];

  typesList: IProductType[] = [
    {
      name: LicenseTypes.free,
      class: 'green',
      text: 'Free Tier'
    },
    {
      name: LicenseTypes.trial,
      class: 'orange',
      text: 'Trial'
    },
    {
      name: LicenseTypes.project,
      class: 'blueLight',
      text: 'Project'
    },
    {
      name: LicenseTypes.enterprise,
      class: 'blueDark',
      text: 'Enterprise'
    },
    {
      name: LicenseTypes.no_license,
      class: 'gray',
      text: 'Unsubscribed'
    }
  ];

  productsList: IProductExtendedNew[] = [];

  private productPeriods: LicensePeriodInput[] = [
    {
      type: LicensePeriodType.day,
      min: 10,
      max: 30
    },
    {
      type: LicensePeriodType.week,
      min: 1,
      max: 6
    },
    {
      type: LicensePeriodType.month,
      min: 1,
      max: 11
    },
    {
      type: LicensePeriodType.year,
      min: 1,
      max: 1
    }
  ];

  // min & max numbers and label text of each of the terms: day,week,month,year
  private licenseLenthTerms: LicensePeriodInput[] = [
    {
      type: LicensePeriodType.week,
      min: 1,
      max: 6,
      valueLabel: 'Number of Weeks'
    },
    {
      type: LicensePeriodType.month,
      min: 1,
      max: 24,
      valueLabel: 'Number of Months'
    }
  ];

  public setTermsDropdown():{text:string,value:string}[] {
    let options: {text: string,value:string}[] = [];
    this.licenseLenthTerms.forEach(op => {
      options.push({
        value: op.type.toLowerCase(),
        text: this.uiService.capitalizeString(op.type)
      });
    });
    return  options;
  }

  fireLicenseCreated(companyId: string): void {
    this.licenseAdded.emit(companyId);
  }

  public apiProducts: ApiProductExtended[] = [];

  public apiProductCategories: ApiProductCategory[] = [];

  public apiLicenses: ApiLicenseExtended[];



  // * This will be new list after Api is updated
  // TODO: change name from newProductsList, to products or something similar
  public newProductsList: ApiProductExtended[];



  private productEnvMapping = {
    restgen: 'generator',
    tools: 'tools',
    // temporary addeed fake products
    restgen_ent_valid: 'generator',
    restgen_trial: 'generator',
    restgen_expiring: 'generator',
    restgen_no_license: 'generator',
    restgen_expired: 'generator'
  }

  licensesLoaded = false;

  constructor(
    private uiService: UserInterfaceService,
    private settings: AppSettingsService,
    private apiService: ApiService,
    private appSettings: AppSettingsService
  ) {}

  // ---------------------------------------------------------------------------
  // Returns licenseLenghtTerm based on its name - term is string: 'day', 'month', 'week' or 'year'
  // ---------------------------------------------------------------------------
  getLicenseTermSettings(term: string): LicensePeriodInput {
    return this.licenseLenthTerms.find(t => t.type === term);
  }


  // ---------------------------------------------------------------------------
  // Adds plural for value > 1 (2 Month->Months) and capitalize the word
  // ---------------------------------------------------------------------------
  public setTermWord(number: number,term: string): string {
    return term.charAt(0).toUpperCase() + (number > 1 ? term.toLocaleLowerCase().slice(1) : term.toLocaleLowerCase().slice(1,term.length-1));
  }

  // ---------------------------------------------------------------------------
  // Set options: text and value for project lenght dropdown
  // Adds plural for value > 1 (2 Month->Months)
  // ---------------------------------------------------------------------------
  public getLicenseOptions():{value: string,text:string}[] {
    let options: {value: string,text:string}[] = [];
    this.productLenthOptions.forEach(op => {

      options.push({
        text: `${op.value} ${this.setTermWord(op.value,op.term)}`,
        value: JSON.stringify(op)
      })
    });
    return options;
  }


  // ---------------------------------------------------------------------------
  // Set values for PRODUCT statusFromLicense property (list of products)
  // statusFromLicense is based on license properties:
  // 1. if license exists for each product
  // 2. license type is FREE or not
  // 3. Status of license for each product: expired or valid
  // Additionally canQuote property is set (to show GET QUOTE button)
  // This is run only if licenses list is loaded from API (when user is logged in)
  // ---------------------------------------------------------------------------
  // TODO: this might be changed to private function
  public setProductsLicenseStatus(licenses: ApiLicenseExtended[]): void {
    this.apiProductCategories.forEach(pr => {
      let l = licenses.find(l => l.productCategoryId === pr.id);
      // console.log(l)
      // TODO: licenses l should be list (not find but filter) and then loop through them
      pr.hasLicense = ((l && l.licenseParams.type !== ApiLicenseType.TrialLicense) ? true : false);
      if(l) {
        switch (l?.licenseParams.type) {
          case ApiLicenseType.FreeLicense:
            pr.statusFromLicense = 'Free';
            pr.canQuote = false;
            break;
          case ApiLicenseType.TrialLicense:
            pr.statusFromLicense = (l.status !== 'expired' ? 'Trial' : 'Expired Trial');
            l.status
            pr.canQuote = true;
            break;
          case ApiLicenseType.EnterpriseLicense:
            pr.statusFromLicense = (l.status !== 'expired' ? 'Enterprise' : 'Expired');
            pr.canQuote = false;
            break;
          case ApiLicenseType.ProjectLicense:
            pr.statusFromLicense = (l.status !== 'expired' ? 'Project' : 'Expired');
            pr.canQuote = false;
            break;
          case ApiLicenseType.BetaTestingLicense:
            pr.statusFromLicense = (l.status !== 'expired' ? 'Beta Testing' : 'Expired Beta Testing')
            pr.canQuote = false;
        }
        pr.canTrial = false;
      } else {
        pr.statusFromLicense = 'NOT Active'
        pr.canTrial = true;
        pr.canQuote = true;
      }
      if(pr.canQuote) {
        this.apiLicenses.filter(l => l.productId === pr.id).forEach(l => {l.canQuote = true});
      }
      // console.log(pr)
    });
  }

  // ! not used - remove
  public setStatusForProducts(licenses: IProductFinal[]): IProductFinal[] {
    let tempProducts: IProductFinal[] = [];
    licenses.forEach(el => {
      let tempProd: IProductFinal = el;
      tempProd.status = this.getLicenseStatus(el.license_ends, el.license_type);
      tempProducts.push(tempProd);
    });
    return tempProducts;
  }


  // ! not used - remove
  public getHighestStatus(licenses: IProductFinal[]): IProductStatus {
    // let statuses: IProductStatus[] = this.statusList.filter(el => licenses.some(el2 => el2.status === el.name));
    let statuses: IProductStatus[] = [];
    this.statusList.forEach(el => {
      if (licenses.some(el2 => el2.status === el.name)) {
        statuses.push(el);
      }
    });
    return null;
  }

  // ! to be removed after companies-list.service and company-license-list.service get updated
  public SetProducsListFromRawData(rawProducts: IProductFinal[]): IProductFinalUI[] {
    let products: IProductFinalUI[] = [];

    rawProducts.forEach(el => {
      let newEl: IProductFinalUI = {
        name: el.name,
        license_ends: (el.license_ends ? el.license_ends.replace('T',' ') + ' UTC' : null),
        PO_number: el.PO_number,
        license_starts: el.license_starts,
        code: el.code,
        license_type: el.license_type,
        code_generations_limit: el.code_generations_limit,
        code_generations_used: el.code_generations_used,
        generations: this.setGenerationsText(el),
        status: this.getLicenseStatus(el.license_ends, el.license_type),
        invoice_number: el.invoice_number,
        deployment: el.deployment
      }
      products.push(newEl);
    })


    return products;
  }

  // * NEW FUNCTIONS FROM API
  public setProductsListNew(companyId?:string): void {

    if(companyId) {
      this.apiService.getProducts(companyId).subscribe({
        next: products => {
          this.apiProducts = products;
          this.setProductActions();
          this.productsUpdated.emit();
        },
        error: err => {console.log(err)}
      });
    } else {
      this.apiService.getProducts().subscribe({
        next: products => {
          this.apiProducts = products;
          this.setProductActions();
          this.productsUpdated.emit();
        },
        error: err => {console.log(err)}
      });
    }
  }

  public setProductCategories(): void {
    // TODO: this needs to be set from API
    this.apiProductCategories = [
      {
        id: "util_converters",
        name: "UTIL Converts",
        status: ApiProductStatus.free,
        defaultProjectDays: 60,
        defaultProjectUses: 5,
        trialDays: 15,
        trialUses: 5,
        freeDays: 365,
        isTrialAvailable: false,
        desscription: 'This category will include all the tools/products with converter functionality.',
        applicableProducts: ["ramltojsonconverter", "jsontoramlconverter", "oastoramlconverter", "ramltooasconverter",'jsontoramlconverterex']
      },
      {
        id: "util_generators",
        name: "UTIL Generators",
        status: ApiProductStatus.free,
        defaultProjectDays: 60,
        defaultProjectUses: 5,
        trialDays: 15,
        trialUses: 5,
        freeDays: 365,
        isTrialAvailable: false,
        desscription: 'This category will include all the tools/products with generation functionality.',
        applicableProducts: ['postmancollection']
      },
      {
        id: "rest_generator",
        name: "REST Generator",
        status: ApiProductStatus.trial,
        defaultProjectDays: 60,
        defaultProjectUses: 5,
        trialDays: 15,
        trialUses: 5,
        freeDays: 365,
        isTrialAvailable: false,
        desscription: 'This category will include specifically restful generation functionality.',
        applicableProducts: ['restgen']
      }
    ];

    this.setProductCategoryActions();
    // TODO: use code  below once the api response is fixed
    this.apiService.getProductCategories().subscribe({
      next: productCategories => {
        // this.apiProductCategories = productCategories;
        // this.setProductCategoryActions();
      },
      error: err => {console.log(err)}
    })
  }

  public getProcutsNoRestgenFiltered(): ApiProductExtended[] {
    return this.apiProducts.filter(pr=>!['restgen-parse','restgen-endpoints','restgen-specifications'].includes(pr.id));
  }

  private setProductCategoryActions():void {
    this.apiProductCategories.forEach(p => {
      p.canTrial = p.trialDays && p.status !== ApiProductStatus.free;
      p.canQuote = p.status !== ApiProductStatus.free;
    })
  }

  private setProductActions():void {
    this.apiProducts.forEach(p => {
      p.canTrial = p.trialDays && p.status !== ApiProductStatus.free;
      p.canQuote = p.status !== ApiProductStatus.free;
    })
  }

  // ===========================================================================
  // Licenses - getting from API and preparing for use
  // ===========================================================================

  // public function to get licenses from API and store them in service.
  // Should be run every time there is update coming for the licenses.
  // Any other service should read from here after licenses are updated
  // There will be event emitted LicensesUpdated
  setLicensesFromApi(): void {
    // TODO: Use API function to get the licenses
    //* Temporary code below:
    this.apiService.getLicenses().subscribe(data =>{
      this.apiLicenses = data;
      this.setLicensesPeriods();
      this.setNonApplicableColumns();
      this.setLicensesRemainingTime();
      this.setProductsLicenseStatus(this.apiLicenses);
      this.setUsesOfTotal();

      setTimeout(() => {
        this.updateAppsLinks();
        this.licensesLoaded = true;
        // this.appSettings.setAppsStatus(this.apiProducts);
        this.licensesUpdated.emit();
        this.productsUpdated.emit();
      }, 500);
      this.updateAppsLinks();
      this.licensesLoaded = true;
      // this.appSettings.setAppsStatus(this.apiProducts);
      this.licensesUpdated.emit();
      this.productsUpdated.emit();
    });
  }

  private updateAppsLinks(): void {
    this.apiProductCategories.forEach(prCat => {
      if(['Expired Trial','Expired','NOT Active'].includes(prCat.statusFromLicense)) {
        let prodOfCat = this.apiProducts.filter(pr => prCat.applicableProducts.includes(pr.id) );
        prodOfCat.forEach(pr => this.appSettings.updateAppLinkStatus(pr.id, true));
      } else {
        let prodOfCat = this.apiProducts.filter(pr => prCat.applicableProducts.includes(pr.id) );
        prodOfCat.forEach(pr => this.appSettings.updateAppLinkStatus(pr.id, false));
      }
    });
  }

  checkIfProductValid(productName: string): boolean {
    // let product = this.apiProducts.find(pr => pr.id === productName);
    let product = this.apiProductCategories.find(pr => pr.id === productName)
    if (product) {
      return !['Expired Trial','Expired','NOT Active'].includes(product.statusFromLicense)
    } else {
      return false;
    }
  }

  // ---------------------------------------------------------------------------
  // License convering private functions
  // ---------------------------------------------------------------------------


  // ---------------------------------------------------------------------------
  // Setting period (license length) based on start and end dates
  // Using date-fns plugin
  // 1 hours added to End Date for correct date-fns intervalToDuration function (otherwise, for example, 6 months would be 5 months and 27 days)
  // For Free Licenses it returns null, which is displayed as N/A
  // ---------------------------------------------------------------------------
  private setLicensesPeriods(): void {
    this.apiLicenses.forEach(l => {
      if(![ApiLicenseType.FreeLicense, ApiLicenseType.UsageLicense].includes(l.licenseParams.type)) {
        let endDate = new Date(l.end);
        endDate.setTime(endDate.getTime() + (60*60*1000));
        l.licensePeriod = intervalToDuration({start: new Date(l.start),end: endDate});
        l.licensePeriod['hours'] = 0;
        if(!l.licensePeriod.years && !l.licensePeriod.months && l.licensePeriod.days % 7 === 0) l.licensePeriod = {weeks: l.licensePeriod.days / 7};
      } else {
        l.licensePeriod = null;
      }
    });
  }

  private setUsesOfTotal(): void {
    this.apiLicenses.forEach(l => {
      l.usesOfTotal = {total: l.totalUses, used: l.uses, available: (l.totalUses > -1 ? l.totalUses - l.uses : -1)}
      // l.usesOfTotal = `${l.licenseParams.maxUses || '&infin;'} / ${l.uses}`;
    })
  }





  private setNonApplicableColumns():void {
    this.apiLicenses.forEach(l => {
      if(l.licenseParams.type === ApiLicenseType.FreeLicense) {
        l.end = null;
        l.licenseParams.maxUses = -1;
        l.totalUses = -1;
        // l.purchaseOrder = 'N/A';
        // l.invoiceNumber = 'N/A';

        // l.uses = 'N/A';
        l.licensePeriod = null;
        l.remainingTime = null;
      } else if(l.licenseParams.type === ApiLicenseType.EnterpriseLicense) {
        l.totalUses = -1;
        l.licenseParams.maxUses = -1;
      } else {
        l.totalUses = l.licenseParams.maxUses;
      }
    });
  }

  setLicensesRemainingTime(): void {
    this.apiLicenses.forEach(l => {
      if(![ApiLicenseType.FreeLicense,ApiLicenseType.UsageLicense].includes(l.licenseParams.type)) {
        l.remainingTime = formatDistance(new Date(),new Date(l.end));
      } else {
        l.remainingTime = 'N/A';
      }

    });
  }



  getLicenseById(licenseId: string): ApiLicenseExtended {
    return this.apiLicenses.find(el=>el.id === licenseId);
  }

  getLicenseList(status?:ApiLicenseStatus[]): ApiLicenseExtended[] {

    let licenses: ApiLicenseExtended[] = [];

    if(this.apiLicenses) {
      if(!!status) {
        if(status.includes(ApiLicenseStatus.valid)) licenses = this.apiLicenses.filter(el => ['valid','low','unknown'].includes(el.status))
          else licenses = this.apiLicenses.filter(el => el.status === 'expired' || el.status === 'exhausted');
        // return this.apiLicenses.filter(el => status.includes(el.licenseStatus))
        if(status.includes(ApiLicenseStatus.expired)) {
          // * remove expired license if for same productCategoryId exist valid license
          // licenses = licenses.filter(l => !this.apiLicenses.some(l2 => l2.productCategoryId === l.productCategoryId && ['valid','low','unknown'].includes(l2.status)));
          // * hide diplicated expired licenses with same productCategoryId
          // licenses = [...new Set(licenses.map(a => a.productCategoryId))].map(id => {return licenses.find(a => a.productCategoryId === id)});

          // licenses.forEach(l => {
          //   let productName = l.productId;
          //   this.apiLicenses.some(l2 => l2.productId === productName && ['available','low','exhausted','unknown'].includes(l2.status))
          // });
        }
        // licenses = licenses.
      } else {
        licenses = this.apiLicenses;
      }
    } else {
      // return [];
    }

    return licenses;
  }

  getLicensesList(status?: ApiLicenseStatus): ApiLicenseExtended[] {
    if(status) {
      return this.apiLicenses.filter(el => el.licenseStatus === status)
    } else {
      return this.apiLicenses;
    }

  }

  public checkForUsageLicense(): boolean {
    return this.apiLicenses.some(l=>l.licenseParams.type === ApiLicenseType.UsageLicense);
  }

  public updateUsageLicense(): void {
    this.apiService.getLicenses().subscribe(results=>{
      let usageLicenses = results.filter(l=>l.licenseParams.type === ApiLicenseType.UsageLicense)
      if(!!usageLicenses.length) {
        usageLicenses.forEach(comingLicense=>{
          let existingLicense = this.apiLicenses.find(l=>l.id === comingLicense.id);
          if(existingLicense) {
            existingLicense.uses = comingLicense.uses;
            existingLicense.usesOfTotal.used = comingLicense.uses;
            existingLicense.usesOfTotal.available = comingLicense.licenseParams.maxUses - comingLicense.uses;
          }
        });
      }

      this.usageLicensesUpdated.emit(usageLicenses);
    });
  }

  public getPremiumLicenseId(): string {
    let entLic = this.apiLicenses.filter(l=>l.licenseParams.type === ApiLicenseType.EnterpriseLicense && l.status === ApiLicenseStatus.valid);
    if(!!entLic.length) {
      return entLic[0].id;
    } else {
      let proLic = this.apiLicenses.filter(l=>l.licenseParams.type === ApiLicenseType.ProjectLicense && l.status === ApiLicenseStatus.valid);
      if(!!proLic.length) {
        return proLic[0].id;
      } else {
        let usaLicValid = this.apiLicenses.filter(l=>l.licenseParams.type === ApiLicenseType.UsageLicense && [ApiLicenseStatus.valid,ApiLicenseStatus.low].includes(l.status) );
        if(!!usaLicValid.length) {
          return usaLicValid[0].id;
        } else {
          let usaLicNotValid = this.apiLicenses.filter(l=>l.licenseParams.type === ApiLicenseType.UsageLicense && [ApiLicenseStatus.exhausted].includes(l.status) );
          if(!!usaLicNotValid.length) {
            return usaLicNotValid[0].id;
          } else {
            let triLic = this.apiLicenses.filter(l=>l.licenseParams.type === ApiLicenseType.TrialLicense && [ApiLicenseStatus.valid,ApiLicenseStatus.low,ApiLicenseStatus.exhausted].includes(l.status) );
            if(!!triLic.length) {
              return triLic[0].id;
            } else {
              return '';
            }

          }
        }
      }
    }

  }

  getProductById(id: string): ApiProductExtended {

    return this.apiProducts.find(x => x.id === id);
  }

  getProductCategoryById(id: string): ApiProductExtended {

    return this.apiProductCategories.find(x => x.id === id);
  }

  public getProductsListNew(): ApiProductExtended[] {
    // TODO: run this only when user logged in and license list not null
    return this.apiProducts;
  }

  public getProductCategoriesList(): ApiProductCategory[] {
    return this.apiProductCategories;
  }

  public setProductsList(productsFromApi: IProductNew[]): void {
    this.productsList = [];
    productsFromApi.forEach(el => {
      let newEl: IProductExtendedNew = {
        name: el.name,
        is_free: el.is_free,
        is_trial: el.is_trial,
        license_ends: (el.license_ends ? el.license_ends.replace('T',' ') + ' UTC' : null),
        PO_number: el.PO_number,
        license_starts: el.license_starts,
        code: el.code,
        license_type: el.license_type,
        code_generations_limit: el.code_generations_limit,
        code_generations_used: el.code_generations_used,
        generations: this.setGenerationsText(el),
        status: this.getLicenseStatus(el.license_ends, el.license_type)
      }
      this.productsList.push(newEl);
    })
  }

  public setGenerationsText(inputProduct: IProductNew | IProductFinalUI | ApiLicenseExtended): string {
    let text: string;
    if('licenseParams' in inputProduct) {
    } else {
      if(inputProduct.license_type === LicenseTypes.enterprise) {
        text = 'unlimited'
      } else if ([LicenseTypes.free, LicenseTypes.no_license].includes(inputProduct.license_type)) {
        text = 'N/A';
      } else {
        text = `${inputProduct.code_generations_used} / ${inputProduct.code_generations_limit}`;
      }
    }
    return text;
  }



  private getLicenseStatus(licenseEnds: string, type: LicenseTypes): ProductStatus {
    if(type === LicenseTypes.free) {
      return ProductStatus.not_applicable;
    } else {
      if(!licenseEnds || type === LicenseTypes.no_license) {
        return ProductStatus.not_valid;
      } else {
        let now = new Date();
        let licenseEndsDate = new Date(licenseEnds.replace('T',' ') + ' UTC');
        let twoWeeks = new Date();
        twoWeeks.setDate(now.getDate() + 14);
        if (licenseEndsDate < now) {
          return ProductStatus.expired;
        } else if (now <= licenseEndsDate && licenseEndsDate <= twoWeeks) {
          return ProductStatus.expiring;
        } else {
          return ProductStatus.valid
        }
      }
    }
  }

  // ! This is not needed anymore - status is set in backend
  private setProductStatus(expirationDate: Date, licenseType: LicenseTypes): ProductStatus {
    let status: ProductStatus;
    // TODO: if free -> not_applicable
    // TODO: in no_license -> not_valid
    // TODO: if (trial || project || enterprise) -> compare expiration date/time to current date/time
    return status;
  }

  // * Standard license status funciton, works on older Product Model
  // ! TO be removed or changed with current status properties
  public getStatusIcon(statusName: ProductStatus): IProductStatus {
    let status: IProductStatus;
    this.statusList.forEach(el => {
      if(el.name === statusName) {
        status = el;
      }
    })
    // let status:IProductStatus = this.statusList.find(el => {el.name.toString() === statusName.toString()});
    return status;
  }

  // ---------------------------------------------------------------------------
  // Setting text for license type
  // ---------------------------------------------------------------------------
  public getTypeItemNew(typeName: string): IProductTypeNew {
    let type: IProductTypeNew;
    this.typeListNew.forEach(el => {
      if(el.name === typeName) type = el;
    });
    return type;
  }

  getProductTypeText(val: ApiProductStatus): string {
    return this.productTypeText.find(el => el.name === val)?.text || 'N/A';
  }

  // ! Remove when not needed; used in companies-list.service, comanies-license-list.service
  public getTypeItem(typeName: LicenseTypes): IProductType {
    let type: IProductType;
    this.typesList.forEach(el => {
      if(el.name === typeName) type = el;
    });
    return type;
  }

}
