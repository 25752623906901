import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
// import { resolve } from 'dns';
// import { rejects } from 'assert';
import { forkJoin, Observable, zip } from 'rxjs';
import { recaptchaObject } from "./congigTypes"

@Injectable()


export class AppconfigService extends BaseService {

  private envUrl1 = 'http://restgen-9g-dev.diamondedge-it.com:8081/api/system/captcha/secret';
  private fakeUrl = "assets/api/secret.json";
  private envUrl = "assets/api/secret.json";
  private configSettings: any = null;
  private configSettings2: any = null;
  private formsValidationData: any[] = [];
  

  get settings() {
    return this.configSettings
  }

  public load2(): Observable<any[]> {
    
    let getCaptcha = this.getAPI(this.envUrl).subscribe((response: any) => {
      // console.log('response from server:::', response);
      this.configSettings = response;
      // resolve(true);
    });
    let getSecond = this.getAPI(this.envUrl).subscribe((response: any) => {
      // console.log('response from server 2:::', response);
      this.configSettings2 = response;
    });

    return forkJoin([getCaptcha, getSecond]);
    // return new Promise((resolve, reject) => {
    //   this.getAPI(this.envUrl).subscribe((response: any) => {
    //     console.log('response from server:::', response);
    //     this.configSettings = response;
    //     resolve(true);
    //   });
    // });
  }

  public getRecaptchaKey(): recaptchaObject {
    return this.configSettings;
  }

  public getValidationRules(formName: string): any {
    return this.formsValidationData[formName];
  }

  public load1(): Promise<any> {
    // console.log("load2");
    return new Promise((resolve, reject) => {
      this.getAPI(this.envUrl).subscribe((response: any) => {
        // console.log('response from server:::', response);
        this.configSettings = response;
        resolve(true);
      });
    });
  }

  public load(): Promise<any>{
    // console.log("load2");

    const getFormsData = new Promise(resolve => {
      this.getAPI("assets/api/forms.json").subscribe((response: any) => {
        // console.log(response);
        response.forEach(el => {
          let key = Object.keys(el)[0]
          this.formsValidationData[key] = el[key];
        });
        // this.formsValidationData = response;
        resolve(true);
      })
    });

    const captcha1 = new Promise(resolve => {
      resolve(true);
      this.getAPI(this.envUrl).subscribe((response: any) => {
        this.configSettings = response;
        resolve(true);
      });
    });

    const captcha2 = new Promise(resolve => {
      this.getAPI(this.envUrl).subscribe((response: any) => {
        this.configSettings = response;
        resolve(true);
      });
    });


    const the_weather = new Promise(resolve => {
      setTimeout(() => {
        // console.log('resolve2');
        resolve({ temp: 29, conditions: "Sunny with Clouds" });
      }, 2000);
    });
    
    const the_tweets = new Promise(resolve => {
      setTimeout(() => {
        // console.log('resolve1');
        resolve(["I like cake", "BBQ is good too!"]);
      }, 500);
    });
    
    // Using RxJs
    let source$ = zip(captcha1, getFormsData);
    source$.subscribe(([weatherInfo, tweetInfo]) =>
      // console.log(weatherInfo, tweetInfo)
    {});
    
    // Using ES6 Promises
    // console.log("load2");
    return Promise.all([captcha1, captcha2]).then(responses => {
      const [captcha1, captcha2] = responses;
      // console.log(captcha1, captcha2);
    });
  }
  
}
