import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input, ViewChild, TemplateRef, OnDestroy, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, FormsModule } from '@angular/forms';
import { IFormField, InputTypes, ValidationRuleTypes } from '../../../models/formField';
import { NotificationService } from '../../notification/notification.service';
import { FormsService } from '../../../services/forms.service';
import { ProductService } from '../../../services/product.service';
import { UserService } from '../../../services/user.service';
import { ApiLicenseExtended, ApiLicenseType, ApiProductExtended, DeploymentType, IProductExtendedNew, LicensePeriodType, LicenseTypes, ProductStatus } from '../../../models/product';
import { faCheck, faCircleQuestion, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AccordionComponent } from '../../accordion/accordion.component';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { FormSummary } from '../../../directives/form-summary/form-summary';
import { ApiService } from '../../../services/api.service';
import { FormResultMessageFormat } from '../../notification/notification';

function SmoothScroll(target, speed, smooth) {
	if (target === document)
		target = (document.scrollingElement
              || document.documentElement
              || document.body.parentNode
              || document.body) // cross browser support for document scrolling

	var moving = false
	var pos = target.scrollTop
  var frame = target === document.body
              && document.documentElement
              ? document.documentElement
              : target // safari is the new IE

	target.addEventListener('mousewheel', scrolled, { passive: false })
	target.addEventListener('DOMMouseScroll', scrolled, { passive: false })

	function scrolled(e) {
    var x = e.clientX, y = e.clientY,
        elementMouseIsOver = document.elementFromPoint(x, y);

        if(!elementMouseIsOver.parentElement?.classList.contains('scrollable-content')) {
          e.preventDefault(); // disable default scrolling

          var delta = normalizeWheelDelta(e)

          pos += -delta * speed
          pos = Math.max(0, Math.min(pos, (target.scrollHeight - frame.clientHeight) + (smooth * 2))) // limit scrolling

          if (!moving) update()
        }

	}

	function normalizeWheelDelta(e){
		if(e.detail){
			if(e.wheelDelta)
				return e.wheelDelta/e.detail/40 * (e.detail>0 ? 1 : -1) // Opera
			else
				return -e.detail/3 // Firefox
		}else
			return e.wheelDelta/120 // IE,Safari,Chrome
	}

	function update() {
		moving = true

		var delta = (pos - target.scrollTop) / smooth

		target.scrollTop += delta

		if (Math.abs(delta) > 0.5)
			requestFrame(update)
		else
			moving = false
	}

	var requestFrame = function() { // requestAnimationFrame cross browser
		return (
			window.requestAnimationFrame ||
			// window.webkitRequestAnimationFrame ||
			// window.mozRequestAnimationFrame ||
			// window.oRequestAnimationFrame ||
			// window.msRequestAnimationFrame ||
			function(func) {
				window.setTimeout(func, 1000 / 50);
			}
		);
	}()
}

@Component({
  selector: 'lib-ng-quote-new',
  templateUrl: './ng-quote-new.component.html',
  styleUrls: ['./ng-quote-new.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgQuoteNewComponent implements OnInit, OnDestroy {


  // ===========================================================================
  // TODO: Once Product API and data format are done, review and fix UI and properties that relates to project data from API
  // TODO: Once License API is done, update properties and UI based on that
  // TODO: remove product and productName properties from JS and HTML code
  // TODO: check and remove this function: this.productService.getProductByCode(this.productCode);
  // ===========================================================================

  squareIcon = faSquare;
  checkIcon = faCheck;
  timesIcon = faTimes;

  username: string;

  loading = false;

  // product: IProductExtendedNew;
  product2: ApiProductExtended;
  smoothScroll: () => void;

  @Output() closeForm = new EventEmitter;
  // @Input() productName: string; //! REMOVE
  @Input() productCode: string;
  @Input() licenseType: string;
  @Input() action: string;
  @Output() formDirtyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  formAction: string = 'new';

  editIcon = faEdit;
  questionIcon = faCircleQuestion;

  quoteFormSettings: {} = {};
  quoteForm: FormGroup;
  formReady = false;
  contactFieldsDisabled = false;

  maxStartDateDays = 30;

  extendedDate: string = null;

  enterpriseDeployment:{val:string,text:string} = {val:'cloud',text:'Cloud'};

  @ViewChild('contactAccordion')
    contactAccordion:AccordionComponent;

  @ViewChild('contactInfoHeader')
    private contactInfoHeader: TemplateRef<any>;

  @ViewChild('contactInfoBody')
    private contactInfoBody: TemplateRef<any>;

  @ViewChild('UsageAccordion')
    UsageAccordion:AccordionComponent;

  @ViewChild('UsageAccordionBody')
    private UsageAccordionBody: TemplateRef<any>;

  @ViewChild('ProjectAccordion')
    ProjectAccordion:AccordionComponent;

  @ViewChild('ProjectAccordionBody')
    private ProjectAccordionBody: TemplateRef<any>;


  @ViewChild('EnterpriseAccordion')
    EnterpriseAccordion:AccordionComponent;

  @ViewChild('EnterpriseAccordionBody')
    private EnterpriseAccordionBody: TemplateRef<any>;


  fieldsSummary: {[key:string]:{label: string,text:string,value:any,valid:boolean}} = {
    licenseType: {label: 'License Type',text:'',valid:false,value:''},
    deployment: {label: 'Deployment',text:'',valid:false,value:''},
    uses: {label: 'Uses',text:'',valid:false,value:0},
    startDate: {label: 'Start Date',text:'',valid:false,value:''},
    licenseLength: {label: 'License Length',text:'',valid:false,value:null},
    endDate: {label: 'End Date',text:'',valid:false,value:''},
    numberOfUsers: {label: 'Team Size',text: '', valid: false, value: ''}
  }

  initialFieldSummary = this.fieldsSummary;

  fieldsSummaryNew: FormSummary[] = [
    {name: 'licenseType',label: 'License Type',text:'',valid:false,value:''},
    {name: 'uses',label: 'Uses',text:'',valid:false,value:0},
    {name: 'startDate',label: 'Start Date',text:'',valid:false,value:''},
    {name: 'endDate',label: 'End Date',text:'',valid:false,value:''},
    {name: 'licenseLength',label: 'License Length',text:'',valid:false,value:null},
    {name: 'numberOfUsers', label: 'Team Size', text: '', value:'', valid: true, hidden: true},
    {name: 'deployment',label: 'Deployment',text:'',valid:false,value:''}
  ];


    quotePreForm: IFormField[] = [
    {
      inputName: "fullName",
      id: "fullNameId",
      placeholder: "Your First and Last Name",
      label: "Full Name",
      value: "",
      inputType: InputTypes.text,
      countCharacters: true
    },
    {
      inputName: "phone",
      id: "phoneId",
      placeholder: "212 555 5555",
      label: "Phone Number",
      value: null,
      inputType: InputTypes.tel
    },
    {
      inputName: "email",
      id: "emailId",
      placeholder: "email@gmail.com",
      label: "Email Address",
      value: "",
      inputType: InputTypes.email
    },
    {
      inputName: "company",
      id: "companyId",
      placeholder: "Software Inc.",
      label: "Company Name",
      value: "",
      inputType: InputTypes.text
    },
    {
      inputName: "job",
      id: "jobId",
      placeholder: "Software Engineer",
      label: "Job Title",
      value: "",
      inputType: InputTypes.text
    },
    {
      inputName: "industry",
      id: "industryId",
      placeholder: "Select Your Company's Industry",
      label: "Industry",
      value: "",
      inputType: InputTypes.select
    },
    {
      inputName: "deployment",
      id: "deploymentId",
      label: "Deployment",
      inputType: InputTypes.radio,
      value: ""
    },
    {
      inputName: "licenseType",
      id: "licenseTypeId",
      label: "License Type",
      inputType: InputTypes.select,
      placeholder: "Select License Type",
      value: ""
    },
    {
      inputName: "comments",
      id: "commentsId",
      label: "Comments and Questions",
      inputType: InputTypes.textarea,
      rows:6,
      value: ""
    },
    {
      inputName: "uses",
      id: "usesId",
      label: "Number of Uses",
      inputType: InputTypes.select,
      value: null,
      placeholder: "Choose number of uses."
    },
    {
      inputName: 'usesFreeField',
      inputType: InputTypes.number,
      id: 'usesFreeFieldId',
      value: '',
      placeholder: 'Custom uses number',
      label: 'Custom Uses',
      instruction: "Minimum is 1 and Maximum is 250."
    },
    {
      inputName: "licenseLength",
      id: "licenseLengthId",
      label: "Project Length",
      inputType: InputTypes.select,
      value: "",
      placeholder: "Choose a length"
    },
    {
      inputName: "startDate",
      id: "startDateId",
      label: "Custom Date",
      inputType: InputTypes.date,
      value: "",
      placeholder: "Choose a date",
      required: false,
      requiredErrorMessage: 'Custom start date is required, if not set to ASAP.'
    },
    {
      inputName: "lengthTerm",
      id: "lengthTermId",
      label: "Term",
      inputType: InputTypes.select,
      value: LicensePeriodType.month,
      placeholder: "Choose length term",
      required: false,
      requiredErrorMessage: 'Need to choose a term'
    },
    {
      inputName: "customLength",
      id: "customLengthId",
      label: "",
      inputType: InputTypes.select,
      placeholder:"",
      value: ""
    },
    {
      inputName: "lengthValue",
      id: "lengthValueId",
      label: "Number of days",
      inputType: InputTypes.number,
      required: false,
      placeholder: 'Put a number',
      requiredErrorMessage: "This is required.",
      errorMessagesList: [
        {min: "Minimum value is"},
        {max: "Maximum value is"}
      ],
      validationRules: [
        {
          type: ValidationRuleTypes.required,
          errorMessage: "Last Name is required",
          value: true
        },
        {
          type: ValidationRuleTypes.min,
          parameter: 1,
          errorMessage: "Min number is 1",
          value: true
        },
        {
          type: ValidationRuleTypes.min,
          parameter: 1,
          errorMessage: "Max number is 100",
          value: true
        }
      ],
      value: ""
    },
    {
      inputName: "numberOfUsers",
      id: "numberOfUsersId",
      label: "Number of Users",
      inputType: InputTypes.select,
      required: false,
      placeholder: "Choose number of users.",
      value: ""
    },
    {
      inputName: "captcha",
      id: "captchaId",
      label: "",
      value: "",
      inputType: InputTypes.recaptcha
    }
  ];

  lengthValueMin = 1;
  lengthValueMax = 10;

  showUsesTextField = false;
  usesTextInstruction = "";
  _notificationSvc: NotificationService;

  customStartDate = false;
  customProjectLength = false;


  summaryPopupClosed = false;
  summaryPopupActive = false;


  formActionText: string;

  licesesList: ApiLicenseExtended[];

  @ViewChild('usesSelect') usesSelect: NgSelectComponent;



  constructor(
    private fb: FormBuilder,
    private formService: FormsService,
    public renderer: Renderer2,
    public productService: ProductService,
    private userService: UserService,
    private uiService: UserInterfaceService,
    private datePipe: DatePipe,
    private apiService: ApiService,
    private notif: NotificationService
  ) {
    this._notificationSvc = new NotificationService();
  }

  ngOnInit(): void {
    this.smoothScroll = new SmoothScroll(document.getElementsByTagName('lib-ng-quote-new')[0],120,12);

    this.setProductData();

    if(this.userService.currentUser) {

    }


    this.userService.userUpdated.subscribe(user => {
      this.product2 = this.productService.getProductById(this.productCode);
      // this.product = this.productService.getProductByCode(this.productCode);
      // this.productName = this.product2.name;
      this.setUiBasedOnLicense();
      // this.setDataBasedOnlicense();
    });

    let form = this.formService.createForm('quote', this.quotePreForm);

    form.forEach(el => {
      this.quoteFormSettings[el.inputName] = el;
    });

    let formReactive = this.formService.convertToReactiveForm(form);
    console.log(formReactive)

    this.quoteForm = this.fb.group(formReactive);
    setTimeout(() => {
      this.summaryPopupActive = true;
    }, 2000);

    // this.quoteForm.statusChanges.subscribe(value => console.log(this.quoteForm.dirty));
    this.quoteForm.valueChanges.subscribe(value => {
      setTimeout(() => {
        this.formDirtyChange.emit(this.quoteForm.dirty);
      }, 20);

    });

    if(this.action === 'renew') {
      setTimeout(() => {
        this.setDataBasedOnlicense();
      }, 1000);

      this.productService.licensesUpdated.subscribe(() => {
        this.setDataBasedOnlicense();
      });
    }

    if(this.licenseType) {
      setTimeout(() => {
        this.quoteForm.get('licenseType').setValue(this.licenseType);
        this.LicenseTypeSelectet(this.licenseType);
      }, 200);

    }





    // this.setOptionsBasedOnProduct();


  }



  //* ---------------------------------------------------------------------------
  //* Form wide functionality
  //* ---------------------------------------------------------------------------
  protected fixEnterKeyIssue(event: any):void {
    if(event.target.tagName !== 'TEXTAREA') {
      event.preventDefault();
    }
  }


  canDeactivate(): Observable<boolean> {
    console.log(this.quoteForm.dirty);
    if (this.quoteForm.dirty) {
      const result = window.confirm('There are unsaved changes! Are you sure?');
      return of(result);
    }

    return of(true);
  }


  toggleProjectLengthValue(value: string) {
    this.customProjectLength = value === 'custom';

    this.setRequired(!this.customProjectLength,'licenseLength','Project Length is required!');

    this.setRequired(this.customProjectLength,'lengthValue','Project Length is required!');

    this.customProjectLenghtSelected(this.quoteForm.get('lengthTerm').value);

    this.setLicenseDates();
  }

  //* ---------------------------------------------------------------------------
  //* PROJECT LENGTH FUNCTIONALITY
  //* ---------------------------------------------------------------------------

  public toggleProjectLength():void {
    this.customProjectLength = !this.customProjectLength;

    this.setRequired(!this.customProjectLength,'licenseLength','Project Length is required!');

    this.setRequired(this.customProjectLength,'lengthValue','Project Length is required!');

    this.customProjectLenghtSelected(this.quoteForm.get('lengthTerm').value);

    this.setLicenseDates();

  }

  public customProjectLenghtSelected(value: string, setFocus = true):void {
    let term = this.productService.getLicenseTermSettings(value);

    this.quoteFormSettings['lengthValue'].label = term.valueLabel;

    this.quoteFormSettings['lengthValue'].min = term.min;
    this.quoteFormSettings['lengthValue'].max = term.max;
    this.lengthValueMin = term.min;
    this.lengthValueMax = term.max;
    this.quoteFormSettings['lengthValue'].placeholder = `${term.min} to ${term.max}`;
    this.quoteFormSettings['lengthValue'].instruction = `Number between ${term.min} & ${term.max}`;
    this.quoteFormSettings['lengthValue'].errorMessagesList['min'] = `Min ${term.min} number of ${term.type}s.`;
    this.quoteFormSettings['lengthValue'].errorMessagesList['max'] = `Max ${term.max} number of ${term.type}s.`;

    this.quoteForm.get('lengthValue').setValue(null);
    this.quoteForm.get('lengthValue').markAsPristine();
    this.quoteForm.get('lengthValue').markAsUntouched();
    this.quoteForm.get('lengthValue').updateValueAndValidity();

    if(setFocus) {
      const element = this.renderer.selectRootElement('#lengthValueId');
      setTimeout(() => element.focus(), 300);
    }
    this.setLicenseDates();

  }

  public customProjectValue():void {
      this.setLicenseDates();
  }




  // ---------------------------------------------------------------------------
  // *  START DATE FUNCTIONALITY
  // ---------------------------------------------------------------------------


  public toggleStartDateValue(value: string) {
    this.customStartDate = value === 'custom';
    if(this.customStartDate) {
      this.setRequired(true,'startDate');
      // this.quoteForm.get('startDate').addValidators(Validators.required);
      // this.quoteFormSettings['startDate'].required = true;
      if(this.quoteForm.get('startDate').invalid || this.quoteForm.get('startDate').pristine) {
        const element = this.renderer.selectRootElement('#startDateId');
        setTimeout(() => element.focus(), 300);
        setTimeout(() => element.click(), 500);
      }

    } else {

      this.setRequired(false,'startDate');
      // this.quoteFormSettings['startDate'].required = false;
      // this.quoteForm.get('startDate').removeValidators(Validators.required);
    }
    this.setLicenseDates();
    // this.quoteForm.get('startDate').updateValueAndValidity();
  }

  public toggleStartDate(): void {
    this.customStartDate = !this.customStartDate;
    if(this.customStartDate) {
      this.setRequired(true,'startDate');
      // this.quoteForm.get('startDate').addValidators(Validators.required);
      // this.quoteFormSettings['startDate'].required = true;
      if(this.quoteForm.get('startDate').invalid || this.quoteForm.get('startDate').pristine) {
        const element = this.renderer.selectRootElement('#startDateId');
        setTimeout(() => element.focus(), 300);
        setTimeout(() => element.click(), 500);
      }

    } else {
      this.setRequired(false,'startDate');
      // this.quoteFormSettings['startDate'].required = false;
      // this.quoteForm.get('startDate').removeValidators(Validators.required);
    }
    this.setLicenseDates();
    // this.quoteForm.get('startDate').updateValueAndValidity();
  }


  public customStartDateUpdated():void {
    this.setLicenseDates();
  }

  public toggleSummaryPopup():void {
    this.summaryPopupClosed = !this.summaryPopupClosed;
  }




  // OTHER
  private setProductData(): void {
    this.product2 = this.productService.getProductById(this.productCode);
    // this.product = this.productService.getProductByCode(this.productCode);
    this.formActionText = this.uiService.licenseActionTypes.find(a => a.id === this.action)?.text || "New Quote";
  }


  private setLicenseLenght():{term:string,value:number} {
    let length = {text:"",value:""};
    let lengthObject:{term:string,value:number} = {term:"",value:0};
    if(!this.customProjectLength) {
      length.value = this.quoteForm.get('licenseLength').value;
      if(length.value) {
        lengthObject = JSON.parse(length.value);
      }

    } else {
      lengthObject.value = +this.quoteForm.get('lengthValue').value;
      if(lengthObject.value && this.quoteForm.get('lengthValue').valid) {
        lengthObject.term = this.quoteForm.get('lengthTerm').value;
        length.value = JSON.stringify(lengthObject);
      }
    }

    if(length.value) {
      length.text = `${lengthObject.value} ${this.productService.setTermWord(lengthObject.value,lengthObject.term)}`;
    }
    this.updateFieldsSummary('licenseLength',length.text,length.value,!!length.value);

    return lengthObject;
  }

  toggleDeployment(val: string, text: string): void {
    this.enterpriseDeployment = {val: val, text: text};
    this.quoteForm.get('deployment').setValue(val);
    this.quoteForm.get('deployment').updateValueAndValidity();
    this.updateFieldsSummary('deployment',text,val,true);
  }



  setEnterpriseDates():void {
    let startDate:{value:string,text:string} = {value:"",text:""};
    let endDate:{value:string,text:string} = {value:"",text:""};
    let lengthObject:{term:string,value:number} = {term:"",value:0};
    let length:{value:string,text:string} = {text:"",value:""};

    let date = new Date();
    startDate.value = date.toISOString();
    startDate.text = this.datePipe.transform(date, 'MMM dd, yyyy');

    lengthObject = {term: 'years', value: 1};
    length.value = JSON.stringify(lengthObject);
    length.text = `${lengthObject.value} ${this.productService.setTermWord(lengthObject.value,lengthObject.term)}`;

    date.setFullYear(date.getFullYear() + lengthObject.value);

    endDate.value = date.toISOString();
    endDate.text = this.datePipe.transform(date, 'MMM dd, yyyy');

    this.updateFieldsSummary('startDate',startDate.text,startDate.value,true);
    this.updateFieldsSummary('endDate',endDate.text,endDate.value,true);
    this.updateFieldsSummary('licenseLength',length.text,length.value,true);
  }



  setLicenseDates():void {

    console.log("AAAAAAAAAAAAAAAA")

    let startDate:{value:string,text:string} = {value:"",text:""};
    let endDate:{value:string,text:string} = {value:"",text:""};
    let lengthObject:{term:string,value:number} = {term:"",value:0};

    if(this.customStartDate) {
      if(this.quoteForm.get('startDate').value) {
        let date = new Date(this.quoteForm.get('startDate').value);
        startDate.value = date.toISOString();
        startDate.text = this.datePipe.transform(date, 'MMM dd, yyyy');
      }

      this.updateFieldsSummary('startDate',startDate.text,startDate.value,!!this.quoteForm.get('startDate').value);

    } else {

      let today = new Date();
      startDate.value = today.toISOString();
      startDate.text = this.datePipe.transform(today, 'MMM dd, yyyy');
      this.updateFieldsSummary('startDate',startDate.text,startDate.value,true);

    }

    if(startDate.value) {

      lengthObject = this.setLicenseLenght();
      let tempDate = new Date(startDate.value);
      if(lengthObject.value && this.quoteForm.get('lengthTerm').valid) {
        switch(lengthObject.term) {
          case LicensePeriodType.day:
            tempDate.setDate(tempDate.getDate() + lengthObject.value);
            // add days
            break;
          case LicensePeriodType.week:
            tempDate.setDate(tempDate.getDate() + lengthObject.value * 7);
            // add weeks
            break;
          case LicensePeriodType.month:
            tempDate.setMonth(tempDate.getMonth() + lengthObject.value);
            // add months
            break;
          case LicensePeriodType.year:
            tempDate.setFullYear(tempDate.getFullYear() + lengthObject.value);
            // add years
            break;
        }
        endDate.value = tempDate.toISOString();
        endDate.text = this.datePipe.transform(tempDate, 'MMM dd, yyyy');

      }

    } else {
      lengthObject = this.setLicenseLenght();
    }
    this.updateFieldsSummary('endDate',endDate.text,endDate.value,!!endDate.value);
  }



  ngAfterContentInit():void {
    if(this.userService.currentUser) {
      // this.product = this.productService.getProductByCode(this.productCode);
      setTimeout(() => {
        this.setContactInfo();
        this.licesesList = this.productService.getLicensesList();
        setTimeout(() => {
          this.contactAccordion.closeAccordion();
        }, 1000);

      }, 500);
      // this.setContactInfo();
      // this.licesesList = this.productService.getLicensesList();
    }
    this.formReady = true;
  }

  ngAfterViewInit(): void {
    this.initialStateOfContactAccordion();
    setTimeout(() => {
      this.setOptionsBasedOnProduct();
    }, 1000);
  }

  ngOnDestroy(): void {
    // removing scroll function
    this.smoothScroll = null;
  }


  // ---------------------------------------------------------------------------
  // LICENSE TYPE CHANGES
  // ---------------------------------------------------------------------------

  protected LicenseTypeSelectet(eventTarget: string) {
    /**
     * Based on license type selected, show project license settings or enterprise license settings; and change requirement settings for these fields
     * Input string for 'project' or 'enterprise'
     */
    if(eventTarget === 'ProjectLicense') {
      this.setRequired(false,'uses');
      this.setRequired(true,'licenseLength');
      this.setRequired(false,'deployment');
      this.setRequired(true,'numberOfUsers');
      this.updateFieldsSummary('deployment','Cloud','cloud',true);
      this.usesUpdated(-1);

      this.showFieldSummary('licenseLength');
      this.showFieldSummary('startDate');
      this.showFieldSummary('endDate');
      this.showFieldSummary('numberOfUsers');
      this.updateFieldsSummary('numberOfUsers','','',false);

      this.EnterpriseAccordion.closeAccordion();
      this.UsageAccordion.closeAccordion()

      this.ProjectAccordion.openAccordion();
      // this.usesUpdated(this.quoteForm.get('uses').value || null)

      this.setLicenseDates();

    } else if(eventTarget === 'UsageLicense') {

      this.setRequired(false,'licenseLength');
      this.setRequired(false,'deployment');
      this.setRequired(false,'numberOfUsers');
      this.hideFieldSummary('numberOfUsers');

      this.setRequired(true,'uses');
      this.updateFieldsSummary('deployment','Cloud','cloud',true);


      this.hideFieldSummary('licenseLength');
      this.showFieldSummary('startDate');
      this.hideFieldSummary('endDate');


      this.ProjectAccordion.closeAccordion();
      this.EnterpriseAccordion.closeAccordion();
      this.UsageAccordion.openAccordion();

      this.usesUpdated(this.quoteForm.get('uses').value || null);
      this.setLicenseDates();

    } else if(eventTarget === 'EnterpriseLicense') {

      this.showFieldSummary('licenseLength');
      this.showFieldSummary('startDate');
      this.showFieldSummary('endDate');
      this.showFieldSummary('numberOfUsers');
      this.updateFieldsSummary('numberOfUsers','Unlimited','-1',true);

      this.ProjectAccordion.closeAccordion();

      this.setRequired(false,'startDate');
      this.setRequired(true,'deployment');
      this.setRequired(false,'uses');
      this.setRequired(false,'licenseLength');
      this.setRequired(false,'numberOfUsers');
      // this.setRequired(true,'deployment');
      // this.updateFieldsSummary('deployment','On Premises','onPremises',true);
      this.updateFieldsSummary('deployment',this.enterpriseDeployment.text,this.enterpriseDeployment.val,true);
      this.usesUpdated(-1);

      this.EnterpriseAccordion.openAccordion();

      this.setEnterpriseDates();
      this.updateFieldsSummary('uses','Unlimited',-1,true);
    }
    let chosenOption = this.quoteFormSettings['licenseType'].options.find(o => o.value === eventTarget);
    if(chosenOption) {
      this.updateFieldsSummary('licenseType',chosenOption.text,chosenOption.value,true);
    }



  }


  // -----------------------------------------------------------------------------
  // USES Dropdown functions
  // -----------------------------------------------------------------------------

  // Update Uses summary field (on dropdown change or on license type change
  protected usesUpdated(value: any): void {
    if(!value) {
      this.updateFieldsSummary('uses','',0,false);
    } else {
      this.updateFieldsSummary('uses',(value === -1 ? 'Unlimited':value),value,true);
    }
  }

  // Adding custom uses value to uses dropdown
  // Value is converted from string to number
  protected addCustomUsesNumber(value: any): void {
    if(!this.quoteFormSettings['uses'].options.find(x => x.value === +value)) {
      // let usesName = this.uiService.usesWordForProduct[this.product2.id].name || 'uses';
      // let optionText = `${value} ${usesName}`;
      let optionText = `${value} uses`;

      this.quoteFormSettings['uses'].options.splice(this.search(this.quoteFormSettings['uses'].options,{value: +value, text: optionText}),0,{text: optionText,value: +value});
    }
    this.quoteForm.get('uses').setValue(+value);
    this.usesUpdated(+value);
  }

  protected usersUpdated(value: any) {
    console.log(value);
    if(!value) {
      this.updateFieldsSummary('numberOfUsers','','',false);
    } else {
      this.updateFieldsSummary('numberOfUsers',(value === -1 ? 'Unlimited' : value), value, true);
    }
  }

  protected numberOfUsersFromNumber(): void {
    let value = this.quoteForm.value['numberOfUsers'];
    console.log(value);
    // this.numberOfUsersUpdated({min:value,max:value});
    let text = (value === 1 ? '1 User' : value + ' Users');
    this.updateFieldsSummary('numberOfUsers',text,value,true);
  }


  protected numberOfUsersUpdated(value: {min:number,max:number}): void {
    // todo: get value as number or string
    // todo: number would be value from number field (exact number of users)
    // todo: string would be range from dropdown
    // todo: value is {min:number,max:number}
    // todo: if min===max - this is specific number
    // todo: if value is object
    // todo: update summary field with value and text, for example 1..10 (range) or 12 (exact number)
  }






  initialStateOfContactAccordion(): void {
      if(this.contactAccordion) {
        if(this.contactFieldsDisabled) {
        // if(['renew','upgrade'].includes(this.formAction)) {
          setTimeout(() => {
            this.contactAccordion.closeAccordion();
            // this.setProjectData();
          }, 1200);

        }
      }
  }


  deploymentChanged():void {
    let deplVal: string = this.quoteForm.get('deployment').value;
    let deplText: string = "";
    if(deplVal) {
      deplText = this.quoteFormSettings['deployment'].options.find(o => o.value === deplVal)?.text || "";
    }

    this.updateFieldsSummary('deployment',deplText,deplVal,!!deplVal);
  }


  setOptionsBasedOnProduct(): void {
    // TODO: Set form options and UI based on current product

    // *  Setting Fields based on Product Data

    // *  License Type - available options
    // this.quoteFormSettings['licenseType'].options = this.setInitialSelectOptions(this.product2.availableTypes,'licenseTypesDropdown');
    // console.log(this.quoteFormSettings['licenseType'].options);
    if(this.quoteFormSettings['licenseType'].options.length === 1) {
      this.quoteFormSettings['licenseType'].value = this.quoteFormSettings['licenseType'].options[0].value;
      this.quoteForm.patchValue({'licenseType': this.quoteFormSettings['licenseType'].options[0].value});
      this.LicenseTypeSelectet(this.quoteFormSettings['licenseType'].options[0].value);

    }

    // *  Deployment Type - available options
    // this.quoteFormSettings['deployment'].options = this.setInitialSelectOptions(this.product2.availableDeployments,'deploymentTypes');
    if(this.quoteFormSettings['deployment'].options.length === 1) {
      this.quoteFormSettings['deployment'].value = this.quoteFormSettings['deployment'].options[0].value;
      this.quoteForm.patchValue({'deployment': this.quoteFormSettings['deployment'].options[0].value});
    }


    // this.setUsesDropdownText();


    // Setting project length options
    this.quoteFormSettings['licenseLength'].options = this.productService.getLicenseOptions();

    this.quoteFormSettings['lengthTerm'].options = this.productService.setTermsDropdown();

    this.quoteForm.get('lengthValue').setValidators([(control: AbstractControl) => Validators.min(this.lengthValueMin)(control),(control: AbstractControl) => Validators.max(this.lengthValueMax)(control)]);

    // TODO: add option value and name in UI settings.
    // TODO: after this is updated, if only one option, make it selected
    // TODO: needs to manage trial here or separatelly
    // Todo: check with Dave if trial only at registration or lates as well, if later as well, we need to check if trial was used for a Product (license info)

  }

  // !  Remove this function and related functionality
  private setUsesDropdownText():void {
    let usesText = this.uiService.usesWordForProduct[this.product2.id];

    // set options text based on product word for uses
    this.quoteFormSettings['uses'].options.forEach(o => {
      o.text = (o.value === -1 ? `Unlimited ${usesText.name}` : `${o.value} ${usesText.name}`);
    });

    // set uses label and placeholder
    this.quoteFormSettings['uses'].label = usesText.label;
    this.quoteFormSettings['uses'].placeholder = usesText.placeholder;

    // set custom field label and placeholder
    this.quoteFormSettings['usesFreeField'].label = usesText.customLabel;
    this.quoteFormSettings['usesFreeField'].placeholder = usesText.customPlaceholder;

    this.fieldsSummary['uses'].label = this.uiService.capitalizeString(usesText.name);
    this.fieldsSummaryNew.find(f=>f.name === 'uses').label = this.uiService.capitalizeString(usesText.name);
  }

  // * The same form can be used for Super Admin to update license (have that code loaded based on permissions)
  // * Or extend this form (class) - check extending angular component

  setDataBasedOnlicense(): void {
    let existingLicenses = this.productService.apiLicenses;
    if(!!existingLicenses) {
      let oldLicense = existingLicenses.filter(x => x.productId === this.productCode);

      console.log(oldLicense);
      if(oldLicense[0]?.licenseParams.type === ApiLicenseType.ProjectLicense) {
        this.quoteForm.get('licenseType').setValue(ApiLicenseType.ProjectLicense);
        this.quoteForm.get('uses').setValue(oldLicense[0]?.licenseParams.maxUses);
        let lengthTerm: string;
        let lenghtValue: number;
        this.customProjectLength = true;
        this.LicenseTypeSelectet(oldLicense[0].licenseParams.type);
        this.toggleProjectLengthValue('custom');
        for (let [key,val] of Object.entries(oldLicense[0].licensePeriod)) {
          if(val > 0) {
            this.quoteForm.get('lengthTerm').setValue(key);
            this.quoteForm.get('lengthValue').patchValue(val);
          }
        }

        this.setLicenseLenght();


        this.quoteForm.get('deployment').setValue(oldLicense[0].deploymentType);
        this.deploymentChanged();

      } else if(oldLicense[0]?.licenseParams.type === ApiLicenseType.EnterpriseLicense) {
        this.quoteForm.get('licenseType').setValue(ApiLicenseType.EnterpriseLicense);
        this.customProjectLength = false;
        this.LicenseTypeSelectet(oldLicense[0].licenseParams.type);


        this.quoteForm.get('deployment').setValue(oldLicense[0].deploymentType);
        this.deploymentChanged();
      }

    }
    // TODO: Set product status based on current license: check if license exists, if yes, check if license status
    // TODO: if license expired, that means reneval of license - set same as existing: license Type, Deployment, same number of uses, same project length

    // TODO: if valid project license (non editable - add "change" license type button): set end date same as existing (non editable, but add edit button), set adding uses as - new form action here (ADD USES or ADD GENERATIONS)
    // TODO: add possibility to change END DATE (additional time) - time will be calculated from current END DATE
    // TODO: add posibility to send free text question about license (display license information as text)
  }

  setUiBasedOnLicense(): void {
  }

  setContactFieldsEditable(): void {
    this.contactFieldsDisabled = false;

    ['fullName','company','job','industry','email','phone'].forEach(el => this.quoteFormSettings[el].disabled = false);
    this.contactAccordion.openAccordion();
  }


  setContactInfo(): void {
    // console.log('before');
    this.quoteForm.patchValue(
      {
        'fullName': this.userService.currentUser.fullName,
        'company': this.userService.currentUser.company.name,
        'job': this.userService.currentUser.jobTitle,
        'industry': this.userService.currentUser.industry,
        'email': this.userService.currentUser.email,
        'phone': JSON.parse(this.userService.currentUser.phone)
      }
    );
    ['fullName','company','job','industry','email','phone'].forEach(el => this.quoteFormSettings[el].disabled = true);
    this.username = this.userService.currentUser.username;
    this.contactFieldsDisabled = true;
    // console.log('after')
    this.quoteForm.markAsPristine();
    // console.log(this.quoteForm.dirty);
  }

  cancel() {
    // this.quoteForm.markAsPristine();
    this.closeForm.emit();
  }

  sendRequest():void {
    this.loading = true;

    let payload = new FormData();
    payload.append('type','quote');
    payload.append('subject',`Quote request for ${this.product2.name} from ${(!!this.username ? this.username : this.quoteForm.value['fullName'] )}`);


    let messageBody = {
      contact: {},
      type: 'quote',
      content:{},
      subject: `Quote request for ${this.product2.name} from ${(!!this.username ? this.username : this.quoteForm.value['fullName'] )}`
    }

    // ,'licenseType','comments'
    let contactInfo = ['fullName','email','company','industry'];
    contactInfo.forEach(el =>{
      messageBody.contact[el] = this.quoteForm.value[el];
      payload.append(`contact.${el}`,this.quoteForm.value[el]);
    });
    messageBody.contact['jobTitle'] = this.quoteForm.value['job'];
    payload.append('jobTitle',this.quoteForm.value['job']);

    messageBody.contact['loggedin'] = (this.username ? 'YES' : 'NO');
    payload.append('loggedin',(this.username ? 'YES' : 'NO'));
    if(this.username) {
      messageBody.contact['username'] = this.username;
      payload.append('username',this.username);
    }

    if(this.quoteForm.value['phone']) {
      messageBody.contact['phone'] = JSON.stringify(this.userService.setDisplayPhone(this.quoteForm.value['phone']));
      payload.append('phone',JSON.stringify(this.userService.setDisplayPhone(this.quoteForm.value['phone'])));
      payload.append('contact.phone',JSON.stringify(this.userService.setDisplayPhone(this.quoteForm.value['phone'])));
    }

    // TODO: Check if I need to do phone number required


    messageBody.content['licenseType'] = this.fieldsSummary['licenseType'].value;

    messageBody.content['startDate'] = this.fieldsSummary['startDate'].text;
    messageBody.content['endDate'] = this.fieldsSummary['endDate'].text;
    messageBody.content['licenseLength'] = this.fieldsSummary['licenseLength'].text;


    if(this.quoteForm.value['licenseType'] === 'ProjectLicense') {
      messageBody.content['uses'] = 'unlimited';
      messageBody.content['deployment'] = DeploymentType.cloud;
      messageBody.content['numberOfUsers'] = this.fieldsSummary['numberOfUsers'].value;
      messageBody.content['endDate'] = this.fieldsSummary['endDate'].text;
      messageBody.content['licenseLength'] = this.fieldsSummary['licenseLength'].text;
    } else if(this.quoteForm.value['licenseType'] === 'UsageLicense') {
      messageBody.content['uses'] = this.fieldsSummary['uses'].value;
      messageBody.content['deployment'] = DeploymentType.cloud;
      messageBody.content['numberOfUsers'] = this.fieldsSummary['numberOfUsers'].value;
    } else {
      // messageBody.content['numberOfUsers'] = this.fieldsSummary['numberOfUsers'].value;
      messageBody.content['numberOfUsers'] = 'unlimited';
      messageBody.content['uses'] = 'unlimited';
      messageBody.content['endDate'] = this.fieldsSummary['endDate'].text;
      messageBody.content['licenseLength'] = this.fieldsSummary['licenseLength'].text;
      messageBody.content['deployment'] = this.enterpriseDeployment.text;
    }

    messageBody.content['comments'] = this.quoteForm.value['comments'];

    let content = messageBody.content;
    payload.append('content',JSON.stringify(content));

    this.apiService.sendSupportMessage(payload).subscribe({
      next: result =>{
        this.loading = false;
        // TODO: replace notification with custom modal
        // TODO: close form button (clear and close)
        // TODO: ok button (to just close modal)
        // TODO: clear form - to clear form, but not close
        this.notif.success('Quote request send','Your request for new quote was successfully sent. We will response ASAP.',FormResultMessageFormat.popup,6000,null);
        setTimeout(() => {
          // this.quoteForm.reset();
          // this.quoteForm.markAsPristine();
          this.clearForm();
          setTimeout(() => {
            this.closeForm.emit();
          }, 100);

        }, 100);


        // Object.keys(this.quoteForm.controls).forEach(key => {
        //   this.quoteForm.get(key).reset();
        //   this.quoteForm.get(key).clearValidators();
        //   this.quoteForm.get(key).markAsPristine();
        //   this.quoteForm.get(key).updateValueAndValidity();
        // });

        // this.quoteForm.clearValidators();
        // this.quoteForm.clearAsyncValidators();

        // this.quoteForm.updateValueAndValidity();
        // console.log(this.quoteForm.pristine);

        // console.log(this.quoteForm.pristine);
        // reset form
        // close form after close button clicked
      },
      error: err => {
        this.loading = false;
        this.formService.formError(err, this.quoteForm, this.quoteFormSettings, null);
      }
    })



    // for (let field of this.quoteForm.value) {
    //   console.log(field)
    // }
    // this.quoteForm.value.forEach(field => {
    //   console.log(field)
    // });
    // TODO: get fields and values, create string message and send to API
  }

  private clearForm():void {
    // TODO: clearing form - clear fieldSummary
    // TODO: close project/enterprise accordions.
    // this.quoteForm.reset();
    this.fieldsSummary = this.initialFieldSummary;
    this.quoteForm.markAsUntouched();
    this.quoteForm.markAsPristine();
    setTimeout(() => {
      this.formDirtyChange.emit(this.quoteForm.dirty);
    }, 20);
  }

  // ===========================================================================
  // Private functions
  // ===========================================================================

   // function to add new option in sorted position
  // TODO: move this function to some service
  private search(a, v) {
    if(a[0]['value'] > v['value']) {
        return 0;
    }
    var i=1;
    while (i<a.length && a[i]['value']!==-1 && !(a[i]['value'] > v['value'] && a[i-1]['value'] <= v['value'])) {
        i=i+1;
        }
    return i;
  }

  // Setting options for any dropdown (value,text)
  // Takes list of available options from given product and get text and value for these options from UI service
  private setInitialSelectOptions(inputOptions: string[], uiSettingsFunctionName: string): {value: string, text: string}[] {
    let newOptions:{value: string, text: string}[] = [];
    inputOptions.forEach(type => {
      newOptions.push(this.uiService[uiSettingsFunctionName][type]);
    });
    return newOptions;
  }

  // Updates summary of field based on field name, input value and validation status
  private updateFieldsSummary(key: string, text: string, value: any, valid: boolean): void {
    this.fieldsSummaryNew.find(f=>f.name === key).text = text;
    this.fieldsSummaryNew.find(f=>f.name === key).value = value;
    this.fieldsSummaryNew.find(f=>f.name === key).valid = valid;
    this.fieldsSummary[key].valid = valid;
    this.fieldsSummary[key].text = text;
    this.fieldsSummary[key].value = value;
  }

  private hideFieldSummary(key: string): void {
    this.fieldsSummaryNew.find(f=>f.name === key).hidden = true;
  }

  private showFieldSummary(key: string): void {
    this.fieldsSummaryNew.find(f=>f.name === key).hidden = false;
  }
  // Sets field required or not-required
  private setRequired(required: boolean, fieldName: string, requiredErrorMessage?: string):void {
    if(required) {
      this.quoteFormSettings[fieldName].required = true;
      if(!this.quoteFormSettings[fieldName].requiredErrorMessage && requiredErrorMessage) {
        this.quoteFormSettings[fieldName].requiredErrorMessage = requiredErrorMessage;
      }
      this.quoteForm.get(fieldName).addValidators(Validators.required);
      this.quoteForm.get(fieldName).markAsUntouched();
    } else {
      this.quoteFormSettings[fieldName].required = false;
      this.quoteForm.get(fieldName).removeValidators(Validators.required);
    }
    this.quoteForm.get(fieldName).updateValueAndValidity();
  }

}
