import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
 name: "orderBy"
})
export class OrderByPipe  implements PipeTransform {
  transform(array: any, sortBy: string, order ): any[] {
    let sortOrder = order === 'a'; // setting default ascending order
   
  
    return array.sort((a,b) => {
      let aVal = a.listRow.find(col => col.columnName === sortBy).value;
      let bVal = b.listRow.find(col => col.columnName === sortBy).value;
      if(sortOrder) {
        if (aVal < bVal) return -1;
        else if (aVal > bVal) return 1;
        else return 0;
      } else {
        if (aVal > bVal) return -1;
        else if (aVal < bVal) return 1;
        else return 0;
      }
    })
  }
    //  return orderBy(array, item =>{
    //    item.listRow.find(col => col.columnName === sortBy).value
    //   }, [sortOrder]);
    //  }
//  transform(array: any, sortingData: {field: string, order: string} ): any[] {
//    console.log(sortingData);
//  const sortOrder = sortingData.order ? sortingData.order : 'a'; // setting default ascending order

//   return orderBy(array, [sortingData.field], [sortOrder]);
//   }
}