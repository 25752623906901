import { EventEmitter, Injectable } from '@angular/core';
import { UserApiDataPartial } from 'projects/nine-gold-app/src/app/pages/user/services/userApiData';
import { UseStatuses, Uses } from 'projects/nine-gold-lib/src/lib/models/uses';
import { DownloadService } from 'projects/nine-gold-lib/src/lib/services/download.service';
import { ColumnTypes, ListUiParams, columnFilterTypes, sortingOrder } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';

type listApiDataType = Uses;

@Injectable({
  providedIn: 'root'
})
export class DownloadsGeneratorService extends DownloadService {

  uiSortFilterPage = true;

  listApiName = 'usersList';
  ApiUrl: string;

  listParameters:ListUiParams = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "started",
      order: sortingOrder.desc
    }
  }

  public loading = false;

  apiUrl = "http://restgen-9g-dev.diamondedge-it.com:8081";
  apiDownloadsList = this.apiUrl + "/api/user/downloads";  // this needs to come from config

  parametersUpdated: EventEmitter<{}> = new EventEmitter<{}>();


  setApiName(): void {
    this.listApiName = 'currentCompanyUsesList';
    this.ApiParameter = 'restgen'
    this.ApiFunctionName = 'getUsesByProduct';

    this.ApiFunctionName = 'getUsesBYCategory';
    // this.ApiFunctionName = 'postUsesByProduct';
    // this.ApiParameter = 'rest_generator';
  }

  setSortSettings(): void {
    this.uiSortFilterPage = true;
  }

  setInitialColumns(): void {
    this.columns = [
      {
        columnName: 'id',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: null,
        rowIdentifier: true,
        notVisible: true
      },
      {
        columnName: 'productId',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: null,
        notVisible: true
      },
      {
        columnName: 'projectName',
        columnText: 'Project Name',
        columnType: ColumnTypes.text,
        sortable: true,
        sortColumnName: 'projectName',
        filters: null,
        columnOrder: 0,
        notVisible: false,
        clickForInfo: false
      },
      // TODO: have product name for all download instances, but not for rest generator
      {
        columnName: 'status',
        columnText: 'Status',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: {
          filterId: 'status',
          filterName: 'Status',
          filterType: columnFilterTypes.options,
          filterPlaceholder: 'Choose status',
          optionSelected: null,
          filterOptions: [
            { value: 'completed', text: 'Completed'},
            { value: 'processing', text: 'Processing'},
            { value: 'error', text: 'Error'},
          ]
        },  // TODO: add filters (get statuses from Dave)
        columnOrder: 1,
        notVisible: false,
        canShowHide: true,
        displayFunction: 'setDownloadStatusText',
        tdHtml: true
      },
      {
        columnName: 'user',
        columnText: 'User',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,  // TODO: add auto filter based on all users from list
        columnOrder: 2,
        customMapping: ['user','userName'],
        notVisible: false,
        canShowHide: true,
        // TODO: user value is userId from custom mapping
        // TODO: display is username
        // TODO: add ColumnTypes.user, where it is clickable - get user popup, on hover there is email button, phone, info and filterBy (added by adding actions - standard actions in lists.service)
      },
      {
        columnName: 'resultsMessage',
        columnText: 'Results',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: 3,
        notVisible: true,
        canShowHide: false,
        customMapping: ['results','message']
      },
      {
        columnName: 'started',
        columnText: 'Date',
        columnType: ColumnTypes.date,
        sortable: true,
        sortColumnName: 'started',
        filters: null,
        columnOrder: 4,
        notVisible: false,
        canShowHide: true
      },
      {
        columnName: 'fileName',
        columnText: 'File',
        columnType: ColumnTypes.file_download,
        sortable: false,
        columnOrder: 6,
        filters: null,
        notVisible: false,
        canShowHide: true,
        customMapping: ['results','completedFile']
        // TODO: add file download type and make it work
      },
      {
        columnName: 'info',
        columnText: '',
        columnType: ColumnTypes.info,
        sortable: false,
        filters: null,
        noHeader: true
      },
    ]
  }

  setApiDataType(): void {
    type listApiDataType = UserApiDataPartial;
  }

  setProductName(val: string): string {
    return this.productService.getShortProductName(val);
  }

  LatestRow(value: string): void {
    let row = this.listData.find(r=>r.rowIdentifier === value);
    if(row) row.listRow.find(c=>c.columnName === 'projectName').highlighted = true;
  }

  async LoadTableData(fromApi?: boolean): Promise<void> {
    this.loading = true;
    if(this.userService.userLoggedIn()) {
      this.loadResultsStarted.emit();

      let timeNow = new Date();
      let timeOut: boolean = !this.hardLoadTimestamp || (timeNow.getTime() - this.hardLoadTimestamp.getTime() >= this.apiHardLoadTimeout);

      if(fromApi || timeOut) {

        await this.apiService[this.ApiFunctionName](['rest_generator']).toPromise().then(
        // await this.apiService[this.ApiFunctionName](this.ApiParameter).toPromise().then(
          (results:listApiDataType[]) => {
            // this.apiResutls = results.filter(el=>el.productId === 'restgen');
            this.apiResutls = results.filter(el=>el.productId === 'restgen');
            this.hardLoadTimestamp = new Date();
            this.rawListData = this.MapDataNew<listApiDataType>(this.apiResutls,this.columns);
            this.loading = false;
          }
        );

      }



      this.loading = false;
      let unorderedList = this.inlineFilter(this.rawListData, this.listParameters.filters);
      this.listParameters.numberOfPages = Math.ceil(unorderedList.length / this.listParameters.itemsPerPage);
      this.listData = this.inlineSort(unorderedList, this.listParameters.sorting.field, this.listParameters.sorting.order);

      setTimeout(() => {

        this.loadResultsEnded.emit();
        this.parametersUpdated.emit(this.listParameters);
      }, 200);

    }
  }

  public updateStatus(existingRow: Uses, incomingRow: Uses): boolean {

    // if(true) {
    if(existingRow && incomingRow?.status !== 'processing') {
      // incomingRow.status = UseStatuses.completed;
      existingRow.status = incomingRow.status;
      existingRow.results.completedFile = incomingRow.results.completedFile;
      existingRow.results.completedFileDownload = incomingRow.results.completedFileDownload;
      let elToUpdate = this.listData.find(el3=>el3.rowIdentifier === incomingRow.id);
      let colToUpdate = elToUpdate.listRow.find(c=>c.columnName === 'status');
      colToUpdate.value = incomingRow.status;
      colToUpdate.display = this.setDownloadStatusText(incomingRow.status);
      let fileCel = elToUpdate.listRow.find(c=>c.columnName === 'fileName');
      fileCel.value = incomingRow.results.completedFile;
      fileCel.display = incomingRow.results.completedFile;
      return true;
    } else {
      return false;
    }
  }

}
