<div [libLoading]="loading" class="table-container" #tableContainer [class.no-margin]="internalTable">
  <div class="table-inner" #downloadsTable (scroll)="onTableScroll($event)" [class.no-results]="noResults">


    <table *ngIf="subRows && data" class="grid-table subrows">
      <thead *ngIf="!hideHeader">
        <tr class="title-row">
          <th class="accordion-button-column">
            <div class="accordion-button-inner">
              <button class=" accordion-collapse-all" (click)="toggleAllAccordions()">
                <div class="button-inner">
                  <span></span>
                </div>
              </button>
              <button class=" accordion-expand-all" (click)="toggleAllAccordions()">
                <div class="button-inner">
                  <span></span>
                </div>
              </button>
            </div>
          </th>
          <th [ngClass]="titleColumn.columnClasses" [colSpan]="titleColSpan">
            <span #columnText class="columnName">{{titleColumn.columnText}}</span>
            <span *ngIf="titleColumn.sortable" class="sort" ngClass="{{titleColumn.sortClasses}}">
              <fa-icon class="up" (click)="sortTable(titleColumn.sortColumnName, 'a')" [icon]="sortIcon"></fa-icon>
              <fa-icon class="down" (click)="sortTable(titleColumn.sortColumnName, 'd')" [icon]="sortIcon"></fa-icon>
            </span>
          </th>
          <th class="table-settings">
            <div class="cell-buttons" class="drop-down-buttons btn-group"
              dropdown (isOpenChange)="onOpenChange($event, tableId + 'th-button-animated')">

              <button *ngIf="showHideColumns" title="More Actions" class="icon-button primary icon-no-background" dropdownToggle
                [id]="tableId + 'th-button-animated'" (click)="setPosition($event)" [attr.aria-controls]="tableId + 'th-button-animated'">
                <fa-icon [icon]="settinggIcon"></fa-icon>
              </button>

              <div [id]="tableId + 'th-dropdown-animated'" *dropdownMenu class="dropdown-menu dropdown-menu-right"
              role="menu" [attr.aria-labelledby]="tableId + 'th-button-animated'">
                <!-- <h3>Table options</h3> -->
                <!-- <div>
                  <button>
                    <fa-icon [icon]="enlargeIcon"></fa-icon>
                    <span>Full Screen</span>
                  </button>
                </div> -->
                <h4>Filters</h4>
                <div class="input-container toggle-container" [class.min]="">
                  <input
                    type="checkbox"
                    class="toggle"
                    id="toggleFilters"
                    (click)="ToggleFiltersVisibility($event)"
                    [checked]="showFilters" />
                  <label for="toggleFilters" class="toggle-label right-sided">
                    <span class="text">Show/Hide</span>
                  </label>
                </div>
                <div class="density">
                  <h4>Density</h4>
                  <ul>
                    <li>
                      <button (click)="SetDensity('default')" [class.active]="density.toString() === 'default'" title="Default" class="density-icon default">
                        <span ></span>
                      </button>
                      <!-- <span>Default</span> -->
                    </li>
                    <li>
                      <button (click)="SetDensity('comfortable')" [class.active]="density.toString() === 'comfortable'" title="Comfortable" class="density-icon comfortable">
                        <span></span>
                      </button>
                      <!-- <span>Comfortable</span> -->
                    </li>
                    <li>
                      <button (click)="SetDensity('compact')" [class.active]="density.toString() === 'compact'" title="Compact" class="density-icon compact">
                        <span></span>
                      </button>
                      <!-- <span>Compact</span> -->
                    </li>
                  </ul>
                </div>

                <ul>
                  <li *ngFor="let col of showHideColumns" role="menuitem">

                    <div class="input-container toggle-container" [class.min]="">
                      <input
                        type="checkbox"
                        class="toggle"
                        [id]="col.columnName"
                        (click)="ToggleColumnVisibility($event, col)"
                        [checked]="!col.notVisible" />
                      <label [for]="col.columnName" class="toggle-label right-sided">
                        <span class="text">{{col.columnText}}</span>
                      </label>
                    </div>
                  </li>
                </ul>

              </div>


            </div>
          </th>

        </tr>
        <tr class="child-row">
          <th></th>
          <th *ngFor="let column of subColumns | visibleColumns" [ngClass]="column.columnClasses"
            [class.role-container-column]="column.columnType.toString() === 'user_role'" dropdown
            #dropdownContainer="bs-dropdown">
            <span #columnText class="columnName">{{column.columnText}}</span>
            <span *ngIf="column.sortable" class="sort" ngClass="{{column.sortClasses}}">
              <fa-icon class="up" (click)="sortTable(column.sortColumnName, 'a')" [icon]="sortIcon"></fa-icon>
              <fa-icon class="down" (click)="sortTable(column.sortColumnName, 'd')" [icon]="sortIcon"></fa-icon>
            </span>
            <div *ngIf="column.filters" class="filter-container">
              <fa-icon [title]="column.filters.filterName" class="filter-icon"
                [class.active]="column.filters.optionSelected !== null" [icon]="filterIcon" dropdownToggle></fa-icon>
              <fa-icon [icon]="checkIcon" class="check-icon" *ngIf="column.filters.optionSelected !== null"></fa-icon>
              <div id="someId" class="filter-popup dropdown-menu dropdown-menu-right" *dropdownMenu>
                <div *ngIf="column.filters.filterType === 'options'">
                  <h4>Filter by {{column.filters.filterName}}</h4>
                  <ng-select [(ngModel)]="column.filters.optionSelected" appearance="outline"
                    placeholder="{{column.filters.filterPlaceholder}}" labelForId="test" [searchable]="false"
                    [clearable]="true" (change)="filterSelected(column, dropdownContainer)">
                    <ng-option *ngFor="let option of column.filters.filterOptions" [value]="option.value" [innerHTML]="option.text"></ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
          </th>
          <th></th>
          <!-- <th class="table-settings">
            <div class="cell-buttons" class="drop-down-buttons btn-group"
              dropdown (isOpenChange)="onOpenChange($event, 'th-button-animated')">

              <button title="More Actions" class="icon-button primary icon-no-background" dropdownToggle
                [id]="'th-button-animated'" (click)="setPosition($event)" [attr.aria-controls]="'th-button-animated'">
                <fa-icon [icon]="settinggIcon"></fa-icon>
              </button>

              <ul [id]="'th-dropdown-animated'" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" [attr.aria-labelledby]="'th-button-animated'">
                <li *ngFor="let col of showHideColumns" role="menuitem">

                  <div class="input-container toggle-container" [class.min]="">
                    <input
                      type="checkbox"
                      class="toggle"
                      [id]="col.columnName"
                      (click)="ToggleColumnVisibility($event, col)"
                      [checked]="!col.notVisible" />
                    <label [for]="col.columnName" class="toggle-label right-sided">
                      <span class="text">{{col.columnText}}</span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </th> -->
        </tr>
      </thead>
      <ng-container *ngFor="let dataRow of data | pagination: pageParams;  trackBy:trackByIdentifier; let rowNumber = index;">
        <tbody [@listAnimation]="data.length">
          <tr [class.highlight]="dataRow.highlighted" class="title-row" [@listAnimation]="data.length">
            <td class="accordion-button-column">
              <div class="accordion-button-inner">
                <button class="accordionButton" (click)="toggleAccordion($event, dataRow)"
                  [class.accordion-open]="dataRow.showDetails">
                  <!-- <fa-icon [icon]="accordionIcon"></fa-icon> -->
                </button>
              </div>
            </td>
            <td [colSpan]="titleColSpan">{{dataRow.titleData.display}}</td>
            <td class="buttons-container-column">
              <div class="cell-buttons"  class="drop-down-buttons btn-group"
                dropdown (isOpenChange)="onOpenChange($event, tableId + 'button-animated' + rowNumber)"
                *ngIf="dataRow.actions">

                <button title="More Actions" class="icon-button primary icon-no-background" dropdownToggle
                  [id]="tableId + 'button-animated' + rowNumber" (click)="setPosition($event)" [attr.aria-controls]="tableId + 'button-animated' + rowNumber">
                  <fa-icon [icon]="dotsIcon"></fa-icon>
                </button>

                <ul [id]="tableId + 'dropdown-animated' + rowNumber" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                  role="menu" [attr.aria-labelledby]="tableId + 'button-animated' + rowNumber">
                  <li *ngFor="let btn of dataRow.actions" role="menuitem" [ngClass]="btn.buttonCustomClass">
                    <button [title]="btn.buttonText" *ngIf="isIconButton(btn.type)" class="icon-button primary"
                      [class.danger]="btn.type === 'delete' || btn.type === 'revoke'" [ngClass]="btn.buttonCustomClass"
                      (click)="buttonClick(btn, dataRow.titleData, dataRow)" [class.loading]="btn.loading" [disabled]="btn.disabled">
                      <fa-icon [icon]="getIconOfButton(btn.type)"></fa-icon>
                    </button>
                    <a (click)="buttonClick(btn, dataRow.titleData, dataRow)">
                      <span>{{btn.buttonText}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <ng-container [@accordionAnimation]="dataRow.accordionState" *ngIf="dataRow.showDetails">
            <tr *ngFor="let rows of dataRow.subList;  let subRowNumber = index;" [@listAnimation]="data.length">
              <td></td>
              <td *ngFor="let dataCell of rows.listRow | visibleColumns; let iCol = index; trackBy:trackByColumnName"
                [ngClass]="dataCell.columnClasses" [class.date-column]="dataCell.type === 'date'"
                [class.buttons-container-column]="areButtons(dataCell.type)"
                [class.role-container-column]="dataCell.type.toString() === 'user_role'"
                [class.highlighted]="dataCell.highlighted"
                [class.status-container]="dataCell.type.toString() === 'status'"
                [class.type-container]="dataCell.type.toString() === 'type'" [class.centered]="dataCell.centered"
                (mouseenter)="colMouseEnter(dataCell)" (mouseleave)="colMouseLeave(dataCell)"
                [class.col-hover]="dataCell.colHover">

                <span *ngIf="dataCell.type === 'text_info'" class="text-with-info">
                    <span>{{dataCell.display}}</span>
                    <button (click)="buttonClick(cellInfoButton, dataCell, dataRow)" [title]="cellInfoButton.buttonText"
                      class="icon-button primary icon-no-background">
                      <fa-icon [icon]="infoIcon"></fa-icon>
                    </button>

                </span>

                <a *ngIf="expandable && firstColExpand && iCol === 0" role="button"
                  (click)="toggleAccordion($event, dataRow)" [class.not-applicable]="dataCell.valueNotApplicable">
                  <span *ngIf="dataCell.tooltipText" [tooltip]="dataCell.tooltipText" container="body" trigger="hover"
                    placement="left">{{dataCell.display}}</span>
                  <span *ngIf="!dataCell.tooltipText">{{dataCell.display}}</span>
                </a>

                <span *ngIf="isText(dataCell.type) && !(expandable && firstColExpand && iCol === 0)" [class.not-applicable]="dataCell.valueNotApplicable">
                  <span *ngIf="dataCell.tooltipText" [tooltip]="dataCell.tooltipText" container="body" trigger="hover"
                    placement="left">{{dataCell.display}}</span>
                  <span *ngIf="!dataCell.tooltipText">{{dataCell.display}}</span>
                </span>

                <span *ngIf="isDate(dataCell.type) && !dataCell.valueNotApplicable" class="date-cell centered">
                  <span [tooltip]="dataCell.display | date : 'medium'" container="body" trigger="hover"
                    placement="left">{{dataCell.display | date : 'yyy/MM/dd'}}</span>
                </span>

                <span *ngIf="isDate(dataCell.type) && dataCell.valueNotApplicable"
                  class="date-cell not-applicable centered">{{dataCell.display}}</span>

                <span *ngIf="dataCell.type.toString() === 'type'"
                  [ngClass]="dataCell.productType?.class">{{dataCell.productType?.text}}</span>

                <div *ngIf="dataCell.type.toString() === 'status'" class="status"
                  [ngClass]="dataCell.productStatus?.class" [tooltip]="dataCell.productStatus?.hoverText" container="body" trigger="click">
                  <fa-icon *ngIf="dataCell.productStatus?.icon" [icon]="dataCell.productStatus?.icon"></fa-icon>
                  <span [ngClass]="dataCell.productStatus?.class">{{dataCell.productStatus?.text}}</span>
                </div>

                <user-role-icon *ngIf="dataCell.type.toString() === 'user_role'" [userRole]="dataCell.userRole"  [isGlobalAdmin]="(dataCell.isGlobalAdmin ? true : false)">
                </user-role-icon>

                <div class="cell-buttons" *ngIf="dataCell.buttons?.length > 0" class="drop-down-buttons btn-group"
                  dropdown (isOpenChange)="onOpenChange($event, 'button-animated' + subRowNumber)">

                  <button title="More Actions" class="icon-button primary icon-no-background" dropdownToggle
                    [id]="'button-animated' + subRowNumber" (click)="setPosition($event)" [attr.aria-controls]="'button-animated' + subRowNumber">
                    <fa-icon [icon]="dotsIcon"></fa-icon>
                  </button>

                  <ul [id]="'dropdown-animated' + subRowNumber" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                    role="menu" [attr.aria-labelledby]="'button-animated' + subRowNumber">
                    <li *ngFor="let btn of dataCell.buttons" role="menuitem" [ngClass]="btn.buttonCustomClass">
                      <button [title]="btn.buttonText" *ngIf="isIconButton(btn.type)" class="icon-button primary"
                        [class.danger]="btn.type === 'delete' || btn.type === 'revoke'" [ngClass]="btn.buttonCustomClass"
                        (click)="buttonClick(btn, dataCell, dataRow)" [class.loading]="btn.loading" [disabled]="btn.disabled">
                        <fa-icon [icon]="getIconOfButton(btn.type)"></fa-icon>
                      </button>
                      <a (click)="buttonClick(btn, dataCell, dataRow)">
                        <span>{{btn.buttonText}}</span>
                      </a>
                    </li>
                  </ul>
                </div>

              </td>
              <td class="buttons-container-column">
                <div class="cell-buttons"  class="drop-down-buttons btn-group"
                  dropdown (isOpenChange)="onOpenChange($event, tableId + 'button-animated' + rowNumber + '_' + subRowNumber)"
                  *ngIf="rows.actions.length > 0">

                  <button title="More Actions" class="icon-button primary icon-no-background" dropdownToggle
                    [id]="tableId + 'button-animated' + rowNumber + '_' + subRowNumber" (click)="setPosition($event)" [attr.aria-controls]="tableId + 'button-animated' + rowNumber + '_' + subRowNumber">
                    <fa-icon [icon]="dotsIcon"></fa-icon>
                  </button>

                  <ul [id]="tableId + 'dropdown-animated' + rowNumber + '_' + subRowNumber" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                    role="menu" [attr.aria-labelledby]="tableId + 'button-animated' + rowNumber + '_' + subRowNumber">
                    <li *ngFor="let btn of rows.actions" role="menuitem" [ngClass]="btn.buttonCustomClass">
                      <button [title]="btn.buttonText" *ngIf="isIconButton(btn.type)" class="icon-button primary"
                        [class.danger]="btn.type === 'delete' || btn.type === 'revoke'" [ngClass]="btn.buttonCustomClass"
                        (click)="buttonClick(btn, null, dataRow)" [class.loading]="btn.loading" [disabled]="btn.disabled">
                        <fa-icon [icon]="getIconOfButton(btn.type)"></fa-icon>
                      </button>
                      <a (click)="buttonClick(btn, null, dataRow)">
                        <span>{{btn.buttonText}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </ng-container>
    </table>


    <table *ngIf="!subRows && data" class="grid1-table" [class.any-row-expanded]="anyRowExpanded()"
      [class.internal-table]="internalTable" (click)="CloseExpanded($event)">
      <thead *ngIf="!hideHeader">
        <tr [style]="rowStyle" #headerRow>
          <th *ngIf="expandable"></th>
          <th *ngFor="let column of columns | visibleColumns | sortColumns" [ngClass]="column.columnClasses"
            [class.role-container-column]="column.columnType.toString() === 'user_role'" dropdown
            #dropdownContainer="bs-dropdown" [id]="'th_'+column.columnName">
            <div class="th-inner">
              <span *ngIf="!column.thHtml" #columnText class="columnName">{{column.columnText}}</span>
              <span *ngIf="column.thHtml" #columnText class="columnName" [innerHtml]="column.columnText"></span>
              <span *ngIf="column.sortable" class="sort" ngClass="{{column.sortClasses}}">
                <fa-icon class="up" (click)="sortTable(column.sortColumnName, 'a')" [icon]="sortIcon"></fa-icon>
                <fa-icon class="down" (click)="sortTable(column.sortColumnName, 'd')" [icon]="sortIcon"></fa-icon>
              </span>
              <div *ngIf="column.filters" class="filter-container">

                <fa-icon [id]="'filterButton_'+column.columnName" [title]="column.filters.filterName" class="filter-icon"
                  [class.active]="column.filters.optionSelected !== null" [icon]="filterIcon" (click)="toggleFilterPopup(column)"></fa-icon>
                <fa-icon [icon]="checkIcon" class="check-icon" *ngIf="column.filters.optionSelected !== null"></fa-icon>

                <div id="someId" class="filter-popup dropdown-menu dropdown-menu-right" *dropdownMenu>
                  <div *ngIf="column.filters.filterType === 'options'">
                    <h4>Filter by {{column.filters.filterName}}</h4>
                    <ng-select [(ngModel)]="column.filters.optionSelected" appearance="outline"
                      placeholder="{{column.filters.filterPlaceholder}}" labelForId="test" [searchable]="false"
                      [clearable]="true" (change)="filterSelected(column, dropdownContainer)">
                      <ng-option *ngFor="let option of column.filters.filterOptions" [value]="option.value" [innerHTML]="option.text"></ng-option>
                    </ng-select>
                  </div>
                </div>


              </div>
            </div>


          </th>
          <th class="table-settings">
            <div class="cell-buttons" class="drop-down-buttons btn-group"
              dropdown (isOpenChange)="onOpenChange($event, tableId + 'th-button-animated')">

              <!-- TODO: once there are more options in the table settings, this button will be visible all the time, but every option will have if statement -->
              <button *ngIf="showHideColumns.length > 0" title="More Actions" class="icon-button primary icon-no-background" #tableSettingsButton
                [id]="tableId + 'th-button-animated'" (click)="toggleTableMenu()" [attr.aria-controls]="tableId + 'th-button-animated'">
                <fa-icon [icon]="settinggIcon"></fa-icon>
              </button>

              <button *ngIf="refreshButton" class="icon-button primary icon-no-background" [id]="tableId + 'refresh'" (click)="refresh()"
                [attr.aria-controls]="tableId + 'refres'">
                <fa-icon [icon]="refreshIcon"></fa-icon>
              </button>

              <!-- <div [id]="tableId + 'th-dropdown-animated'" *dropdownMenu class="dropdown-menu dropdown-menu-right"
              role="menu" [attr.aria-labelledby]="tableId + 'th-button-animated'">
                <h4>Filters</h4>
                <div class="input-container toggle-container" [class.min]="">
                  <input
                    type="checkbox"
                    class="toggle"
                    id="toggleFilters"
                    (click)="ToggleFiltersVisibility($event)"
                    [checked]="showFilters" />
                  <label for="toggleFilters" class="toggle-label right-sided">
                    <span class="text">Show/Hide</span>
                  </label>
                </div>
                <div class="density">
                  <h4>Density</h4>
                  <ul>
                    <li>
                      <button (click)="SetDensity('default')" [class.active]="density.toString() === 'default'" title="Default" class="density-icon default">
                        <span ></span>
                      </button>
                    </li>
                    <li>
                      <button (click)="SetDensity('comfortable')" [class.active]="density.toString() === 'comfortable'" title="Comfortable" class="density-icon comfortable">
                        <span></span>
                      </button>
                    </li>
                    <li>
                      <button (click)="SetDensity('compact')" [class.active]="density.toString() === 'compact'" title="Compact" class="density-icon compact">
                        <span></span>
                      </button>
                    </li>
                  </ul>
                </div>

                <ul>
                  <li *ngFor="let col of showHideColumns" role="menuitem">

                    <div class="input-container toggle-container" [class.min]="">
                      <input
                        type="checkbox"
                        class="toggle"
                        [id]="col.columnName"
                        (click)="ToggleColumnVisibility($event, col)"
                        [checked]="!col.notVisible" />
                      <label [for]="col.columnName" class="toggle-label right-sided">
                        <span class="text">{{col.columnText}}</span>
                      </label>
                    </div>
                  </li>
                </ul>

              </div> -->


            </div>
          </th>
        </tr>
      </thead>
      <tbody [@listAnimation]="data.length">
        <tr class="spacer"></tr>
        <ng-container
          *ngFor="let dataRow of data | pagination: pageParams;  trackBy:trackByIdentifier; let rowNumber = index;">
          <tr [class.highlighted]="dataRow.highlighted" [style]="rowStyle" [class.details-expanded]="dataRow.showDetails">
            <td *ngIf="expandable" class="accordion-button-column">
                <div class="accordion-button-inner">
                  <button class="accordionButton" (click)="toggleAccordion($event, dataRow)"
                    [class.accordion-open]="dataRow.showDetails">
                    <!-- <fa-icon [icon]="accordionIcon"></fa-icon> -->
                  </button>
                </div>

              <!-- <button [title]="btn.buttonText" *ngIf="isIconButton(btn.type)" class="icon-button primary"
                    [class.danger]="btn.type === 'delete' || btn.type === 'revoke'"
                    [ngClass]="btn.buttonCustomClass" (click)="buttonClick(btn, dataCell, dataRow)"
                    [class.loading]="btn.loading">
                    <fa-icon [icon]="getIconOfButton(btn.type)"></fa-icon>
                </button> -->
            </td>
            <td *ngFor="let dataCell of dataRow.listRow | visibleColumns | sortColumns; let iCol = index; trackBy:trackByColumnName"
              [ngClass]="dataCell.columnClasses" [class.date-column]="dataCell.type === 'date'"
              [class.buttons-container-column]="areButtons(dataCell.type) || dataCell.type.toString() === 'info'"
              [class.info-container-column]="dataCell.type.toString() === 'info'"
              [class.role-container-column]="dataCell.type.toString() === 'user_role'"
              [class.empty-column]="dataCell.type.toString() === 'empty'"
              [class.highlighted]="dataCell.highlighted"
              [class.click-for-info]="dataCell.clickForInfo"
              [class.status-container]="dataCell.type.toString() === 'status'"
              [class.file-download]="dataCell.type.toString() === 'file_download'"
              [class.type-container]="dataCell.type.toString() === 'type'" [class.centered]="dataCell.centered"
              (mouseenter)="colMouseEnter(dataCell)" (mouseleave)="colMouseLeave(dataCell)"
              [class.col-hover]="dataCell.colHover"
              [title]="(dataCell.highlighted ? 'You' : '')">

              <span *ngIf="dataCell.type === 'text_info'" class="text-with-info">
                  <span>{{dataCell.display}}</span>
                  <button (click)="buttonClick(cellInfoButton, dataCell, dataRow)" [title]="cellInfoButton.buttonText"
                    class="icon-button primary icon-no-background">
                    <fa-icon [icon]="infoIcon"></fa-icon>
                  </button>

              </span>

              <a *ngIf="dataCell.type.toString() === 'file_download' && dataCell.value" (click)="buttonClick(downloadButton,dataCell,dataRow)" [title]="'Download result file: ' + dataCell.display">
                <!-- <span>{{dataCell.display}}</span> -->
                <fa-icon [icon]="downloadIcon"></fa-icon>
              </a>

              <a *ngIf="dataCell.type.toString() === 'file_download' && !dataCell.value" class="no-file-to-download disabled" [title]="'No result file'">
                <!-- <span>{{dataCell.display}}</span> -->
                <fa-icon [icon]="downloadIcon"></fa-icon>
              </a>

              <a *ngIf="expandable && firstColExpand && iCol === 0" role="button"
                (click)="toggleAccordion($event, dataRow)" [class.not-applicable]="dataCell.valueNotApplicable">
                <span *ngIf="dataCell.tooltipText" [tooltip]="dataCell.tooltipText" container="body" trigger="hover"
                  placement="left">{{dataCell.display}}</span>
                <span *ngIf="!dataCell.tooltipText">{{dataCell.display}}</span>
              </a>

              <span *ngIf="isText(dataCell.type) && !(expandable && firstColExpand && iCol === 0) && !dataCell.clickForInfo" [class.not-applicable]="dataCell.valueNotApplicable">
                <span *ngIf="!dataCell.tdHtml && dataCell.tooltipText" [tooltip]="dataCell.tooltipText" container="body" trigger="hover"
                  placement="left">{{dataCell.display}}</span>
                <span *ngIf="!dataCell.tdHtml && !dataCell.tooltipText">{{dataCell.display}}</span>
                <span *ngIf="dataCell.tdHtml" [innerHtml]="dataCell.display"></span>
              </span>

              <fa-icon *ngIf="dataCell.clickForInfo" [icon]="infoIcon"></fa-icon>

              <span *ngIf="isText(dataCell.type) && !(expandable && firstColExpand && iCol === 0) && dataCell.clickForInfo" [class.not-applicable]="dataCell.valueNotApplicable" (click)="buttonClick(infoButton, dataCell, dataRow)">
                <span *ngIf="!dataCell.tdHtml && dataCell.tooltipText" [tooltip]="dataCell.tooltipText" container="body" trigger="hover"
                  placement="left">{{dataCell.display}}</span>
                <span *ngIf="!dataCell.tdHtml && !dataCell.tooltipText">{{dataCell.display}}</span>
                <span *ngIf="dataCell.tdHtml" [innerHtml]="dataCell.display"></span>
                <!-- <fa-icon [icon]="infoIcon"></fa-icon> -->
              </span>



              <span *ngIf="isDate(dataCell.type) && !dataCell.valueNotApplicable" class="date-cell centered">
                <span [tooltip]="dataCell.display | date : 'medium'" container="body" trigger="hover"
                  placement="left">{{dataCell.display | date : 'yyy/MM/dd'}}</span>
              </span>

              <span *ngIf="isDate(dataCell.type) && dataCell.valueNotApplicable && !dataCell.tdHtml"
                class="date-cell  centered">{{dataCell.display}}</span>
              <span *ngIf="isDate(dataCell.type) && dataCell.valueNotApplicable && dataCell.tdHtml"
                class="date-cell  centered" [innerHtml]="dataCell.display"></span>

              <span *ngIf="dataCell.type.toString() === 'type'"
                [ngClass]="dataCell.productType?.class">{{dataCell.productType?.text}}</span>

              <div *ngIf="dataCell.type.toString() === 'status'" class="status"
                [ngClass]="dataCell.productStatus?.class" [tooltip]="dataCell.productStatus?.hoverText" container="body" trigger="click">
                <fa-icon *ngIf="dataCell.productStatus?.icon" [icon]="dataCell.productStatus?.icon"></fa-icon>
                <span [ngClass]="dataCell.productStatus?.class">{{dataCell.productStatus?.text}}</span>
              </div>

              <user-role-icon *ngIf="dataCell.type.toString() === 'user_role'" [userRole]="dataCell.userRole" [isGlobalAdmin]="(dataCell.isGlobalAdmin? true : false)">
              </user-role-icon>

              <div *ngIf="dataCell.type.toString() === 'info'" class="cell-buttons info-button">
                <button class="icon-button primary icon-no-background info-button" title="info"
                  (click)="buttonClick(infoButton, dataCell, dataRow)">
                  <fa-icon [icon]="infoIcon"></fa-icon>
                </button>
              </div>

              <!-- <div class="cell-buttons" *ngIf="dataCell.buttons?.length > 0" class="drop-down-buttons btn-group"
                dropdown (isOpenChange)="onOpenChange($event, 'button-animated' + rowNumber)"> -->
              <div class="cell-buttons" *ngIf="dataCell.type.toString() === 'buttons'" class="drop-down-buttons btn-group"
                dropdown (isOpenChange)="onOpenChange($event, 'button-animated' + rowNumber)">

                <!-- <button *ngIf="infoButtonInRow(dataCell.buttons)" class="icon-button primary icon-no-background" title="info"
                  (click)="buttonClick(infoButton, dataCell, dataRow)">
                  <fa-icon [icon]="infoIcon"></fa-icon>
                </button> -->

                <!-- <button title="More Actions" class="icon-button primary icon-no-background" [disabled]="!moreThanInfo(dataCell.buttons)" dropdownToggle
                  [id]="'button-animated' + rowNumber" (click)="setPosition($event)" [attr.aria-controls]="'button-animated' + rowNumber">
                  <fa-icon [icon]="dotsIcon"></fa-icon>
                </button> -->

                <button title="More Actions" class="icon-button primary icon-no-background more-actions"  *ngIf="dataCell.buttons.length === 0" [disabled]="true"
                  [id]="'button-animated' + rowNumber" (click)="setPosition($event)" [attr.aria-controls]="'button-animated' + rowNumber">
                  <fa-icon [icon]="dotsIcon"></fa-icon>
                </button>

                <button title="More Actions" class="icon-button primary icon-no-background more-actions"  *ngIf="dataCell.buttons.length !== 0" dropdownToggle
                  [id]="'button-animated' + rowNumber" (click)="setPosition($event)" [attr.aria-controls]="'button-animated' + rowNumber">
                  <fa-icon [icon]="dotsIcon"></fa-icon>
                </button>

                <ul [id]="'dropdown-animated' + rowNumber" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                  role="menu" [attr.aria-labelledby]="'button-animated' + rowNumber">
                  <li *ngFor="let btn of filteredButtons(dataCell.buttons)" role="menuitem" [ngClass]="btn.buttonCustomClass" [class.red]="btn.type === 'revoke'" [class.not-allowed]="btn.disabled">
                    <button [title]="btn.buttonText" *ngIf="isIconButton(btn.type)" class="icon-button primary"
                      [class.red]="btn.type === 'delete' || btn.type === 'revoke'" [ngClass]="btn.buttonCustomClass"
                      (click)="buttonClick(btn, dataCell, dataRow)" [class.loading]="btn.loading" [disabled]="btn.disabled">
                      <fa-icon [icon]="getIconOfButton(btn.type)"></fa-icon>
                    </button>
                    <a (click)="buttonClick(btn, dataCell, dataRow)">
                      <span>{{btn.buttonText}}</span>
                    </a>
                  </li>
                </ul>
              </div>

            </td>
          </tr>
          <tr class="details" *ngIf="dataRow.showDetails">
            <td colspan="7" [class.no-padding]="dataRow.accordionState === 'closed'">
              <div class="expandable-row-inner" [@accordionAnimation]="dataRow.accordionState">
                <ng-container [ngTemplateOutlet]="detailsTemplate"></ng-container>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <!-- * Hiding this for now -->
    <!-- TODO: I will think about this later -->
    <!-- <div class="no-rsults-message" *ngIf="noResults">
      <h5>{{noResultsText}}</h5>
    </div> -->



  </div>

  <div #filtersContainer class="thead-filters">
    <div *ngFor="let col of columns | visibleColumns" [id]="'filterContainer_'+col.columnName" class="filter-container">
      <div *ngIf="col.filters" class="filter" [class.show]="col.filters.active">
        <div *ngIf="col.filters.filterType === 'options'">
          <!-- <h4>Filter by {{col.filters.filterName}}</h4> -->
          <ng-select [(ngModel)]="col.filters.optionSelected" appearance="outline"
            placeholder="{{col.filters.filterPlaceholder}}" labelForId="test" [searchable]="false"
            [clearable]="true" (change)="filterSelected(col, dropdownContainer)">
            <ng-option *ngFor="let option of col.filters.filterOptions" [value]="option.value" [innerHTML]="option.text"></ng-option>
          </ng-select>
        </div>
        <div *ngIf="col.filters.filterType === 'text'">
          <input [placeholder]="col.filters.filterPlaceholder" type="text" [name]="col.filters.filtername" [(ngModel)]="col.filters.optionSelected" (change)="filterSelected(col, dropdownContainer)" class="form-control">
        </div>
        <div *ngIf="col.filters.filterType === 'autocomplete'">
          <input [placeholder]="col.filters.filterPlaceholder" [typeaheadHideResultsOnBlur]="false" (typeaheadOnSelect)="filterSelected(col, dropdownContainer)" [(ngModel)]="col.filters.optionSelected" [typeahead]="col.filters.autocompleteList" class="form-control">
        </div>
      </div>
    </div>
  </div>

  <div [id]="tableId + 'th-dropdown-animated'" #tableSettings class="table-settings-menu"
    [class.show]="showTableMenu" role="menu" [attr.aria-labelledby]="tableId + 'th-button-animated'">
    <!-- TODO: add later filters functionality -->
    <!-- <h4>Filters</h4>
    <div class="input-container toggle-container" [class.min]="">
      <input type="checkbox" class="toggle" id="toggleFilters"
        (click)="ToggleFiltersVisibility($event)" [checked]="showFilters" />

      <label for="toggleFilters" class="toggle-label right-sided">
        <span class="text">Show/Hide</span>
      </label>
    </div> -->

    <!-- TODO: add later density functionality -->
    <!-- <div class="density2">
      <h4>Density</h4>
      <ul>
        <li [class.selected]="density.toString() === 'cft'" title="Comfortable" aria-label="Comfortable">
          <span>Comfortable</span>
          <div (click)="SetDensity('comfortable')" class="icon icon-comfortable">
            <div class="bar bar1"></div>
            <div class="bar bar2"></div>
          </div>
        </li>
        <li [class.selected]="density.toString() === 'dft'" title="Default" aria-label="Default">
          <span>Default</span>
          <div (click)="SetDensity('default')" class="icon icon-medium">
            <div class="bar bar1"></div>
            <div class="bar bar1"></div>
            <div class="bar bar2"></div>
          </div>
        </li>
        <li [class.selected]="density.toString() === 'cpt'" title="Compact" aria-label="Compact">
          <span>Compact</span>
          <div (click)="SetDensity('compact')" class="icon icon-compact">
            <div class="bar bar1"></div>
            <div class="bar bar1"></div>
            <div class="bar bar1"></div>
            <div class="bar bar2"></div>
          </div>
        </li>
      </ul>
    </div> -->

    <h4>Columns visibility and order</h4>
    <ul class="columns-sorting-and-toggling" cdkDropList (cdkDropListDropped)="drop($event)">
      <li *ngFor="let col of showHideColumns, index as i" role="menuitem" cdkDrag>
        <div class="dragged-content input-container toggle-container1 checkbox-container " [class.min]="">
          <input
            type="checkbox"
            class="toggle1 form-check-input"
            [id]="col.columnName"
            (click)="ToggleColumnVisibility($event, col)"
            [checked]="!col.notVisible" />
          <label [for]="col.columnName" class="toggle-label1 right-sided1 form-check-label">
            <span class="text">{{col.sortColumnText || col.columnText}}</span>
          </label>

        </div>
        <fa-icon [icon]="gripIcon" cdkDragHandle></fa-icon>
      </li>
    </ul>
  </div>



</div>
