<div class="side-modal-backdrop" (click)="cancel()"></div>
<div class="popup-summary" [class.active]="summaryPopupActive" [class.closed]="summaryPopupClosed" (click)="toggleSummaryPopup()">


  <div class="summary-inner">
    <div class="summary-button">
      <p>
        <span>Form Summary</span>
        <span class="close-button-container">
          <button title="close form" class="popup-summary-close-button  ">&#xab;</button>
        </span>


      </p>

    </div>

    <lib-form-summary
      [title]="product2?.name"
      [subTitle]="formActionText"
      [fields]="fieldsSummaryNew"
      [leftBorder]="true"></lib-form-summary>

    <!-- <div class="content">
      <h4>{{product2?.name}}</h4>
      <h5>{{formActionText}}</h5>
      <p [class.valid]="fieldsSummary['licenseType'].valid">
        <span class="label">{{fieldsSummary['licenseType'].label}}</span>
        <span class="value">{{fieldsSummary['licenseType'].text}}</span>
      </p>
      <p [class.valid]="fieldsSummary['uses'].valid">
        <span class="label">{{fieldsSummary['uses'].label}}</span>
        <span class="value">{{fieldsSummary['uses'].text}}</span>
      </p>
      <p [class.valid]="fieldsSummary['startDate'].valid">
        <span class="label">{{fieldsSummary['startDate'].label}}</span>
        <span class="value">{{fieldsSummary['startDate'].text}}</span>
      </p>
      <p [class.valid]="fieldsSummary['endDate'].valid">
        <span class="label">{{fieldsSummary['endDate'].label}}</span>
        <span class="value">{{fieldsSummary['endDate'].text}}</span>
      </p>
      <p [class.valid]="fieldsSummary['licenseLength'].valid">
        <span class="label">{{fieldsSummary['licenseLength'].label}}</span>
        <span class="value">{{fieldsSummary['licenseLength'].text}}</span>
      </p>
      <p [class.valid]="fieldsSummary['deployment'].valid">
        <span class="label">{{fieldsSummary['deployment'].label}}</span>
        <span class="value">{{fieldsSummary['deployment'].text}}</span>
      </p>
    </div> -->


  </div>
</div>


<div *ngIf="quoteForm" class="side-form-container" [fullScreen]="true" [libLoading]="loading">
    <form (keydown.enter)="fixEnterKeyIssue($event)" id="quoteForm" stickyScroll scrollContainerId="quoteContainer" [bottomOffset]="0" novalidate (submit)="sendRequest()" [formGroup]="quoteForm">
        <div class="scrollable-fields-container" >
            <h2>{{product2?.name}}</h2>
            <h3>{{formActionText}}</h3>
            <p *ngIf="action === 'new'">Fill out this form to get quote for using <strong>{{product2?.name}}</strong> based on your needs.</p>
            <p *ngIf="action === 'renew'">We have filed out the form below for you, based on your last <strong>{{product2?.name}}</strong> license. Make neccessary changes based on your current needs.</p>

            <lib-accordion [accordionBody]="contactInfoBody" [accordionHeader]="contactInfoHeader" #contactAccordion [startOpen]="true"></lib-accordion>

            <ng-template #contactInfoHeader>
                <h4>Contact Information <a class="pointer" *ngIf="contactFieldsDisabled" (click)="setContactFieldsEditable()"><fa-icon [icon]="editIcon"></fa-icon></a></h4>
                <p *ngIf="contactFieldsDisabled" class="input-instruction">Contact Info is prefiled with info on the account. Click <button [attr.disabled]="(contactFieldsDisabled ? null : 'disabled')" role="button" class="text-button auto-height" (click)="setContactFieldsEditable()">Edit Contact Info</button>, if you want to change it.<br>The changes won't affect information stored on your account.</p>
            </ng-template>

            <ng-template #contactInfoBody>
                <lib-form-field [disabled]="quoteFormSettings['fullName'].disabled" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['fullName']" [minBottomMargin]="true"></lib-form-field>

                <lib-form-field [disabled]="quoteFormSettings['company'].disabled" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['company']" [minBottomMargin]="true"></lib-form-field>

                <lib-form-field [disabled]="quoteFormSettings['job'].disabled" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['job']" [minBottomMargin]="true"></lib-form-field>

                <lib-form-field [disabled]="quoteFormSettings['email'].disabled" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['email']" [minBottomMargin]="true"></lib-form-field>

                <lib-form-field [disabled]="quoteFormSettings['phone'].disabled" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['phone']" [noBottomMargin]="true"></lib-form-field>

                <lib-form-field [disabled]="quoteFormSettings['industry'].disabled" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['industry']" [noBottomMargin]="true"></lib-form-field>
            </ng-template>


            <div class="fields-group blue-background">

                <lib-form-field [minBottomMargin]="true" (dropdownChanged)="LicenseTypeSelectet($event.value)" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['licenseType']"></lib-form-field>

                <lib-accordion [insideGroup]="true" #UsageAccordion [accordionBody]="UsageAccordionBody" [hideHeader]="true"></lib-accordion>

                <lib-accordion [insideGroup]="true" #ProjectAccordion [accordionBody]="ProjectAccordionBody" [hideHeader]="true"></lib-accordion>

                <lib-accordion [insideGroup]="true" #EnterpriseAccordion [accordionBody]="EnterpriseAccordionBody" [hideHeader]="true"></lib-accordion>

            </div>

            <ng-template #UsageAccordionBody>
              <div class="accordion-content-header">
                <h4>Usage License</h4>
                <p class="input-instruction">Usage License is based on numer of uses of the product with no time restriction</p>

              </div>

              <div class="fields-group border">
                <h4>License Start Date</h4>

                <div class="btn-group" style="margin: 1em 0;" btnRadioGroup>
                  <label class="btn btn-success mb-0" [class.active]="!customStartDate" btnRadio="asap" value="asap" (click)="toggleStartDateValue('asap')">ASAP</label>
                  <label class="btn btn-success mb-0" [class.active]="customStartDate" btnRadio="custom" value="custom" (click)="toggleStartDateValue('custom')">Custom</label>
                </div>

                <!-- <div class="date-toggle-container">
                  <label class="required1">Start Date:</label>
                  <lib-toggle-switch [checked]="customStartDate" (change)="toggleStartDate()" [labelOn]="'Custom'" [labelOff]="'ASAP'"></lib-toggle-switch>

                </div> -->
                <!-- <p class="input-instruction">Set project to start ASAP<span class="input-tooltip-icon" [tooltip]="'As soon as the license is paid for.'" container="body">
                  <fa-icon [icon]="questionIcon"></fa-icon></span> or choose custom date for Your project to start.
                </p> -->

                <div [class.hidden]="customStartDate">
                  <p class="value">Start Project <strong>ASAP</strong></p>
                </div>

                <div [class.hidden]="!customStartDate">
                  <div [class.valid]="quoteForm.get('startDate').valid && quoteForm.get('startDate').touched" class="column-content" [class.not-active]="!customStartDate">
                    <lib-form-field (blurEvent)="customStartDateUpdated()" [disableUsHolidays]="true" [daysFromToday]="maxStartDateDays" [small]="true" [inlineLayout]="true" [noBottomMargin]="true" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['startDate']"></lib-form-field>
                  </div>
                </div>

              </div>

              <div class="fields-group border">
                <lib-select-form-field
                    [addValueUseCustomFunction]="true"
                    (dropdownChanged)="usesUpdated($event.value)"
                    (valueAdded)="addCustomUsesNumber($event)"
                    [customValue]="true"
                    [noBottomMargin]="true"
                    [addValueButtonText]="'Add & Select'"
                    [textFieldSettings]="quoteFormSettings['usesFreeField']"
                    [formGroupParameter]="quoteForm"
                    [fieldSettings]="quoteFormSettings['uses']">
                </lib-select-form-field>
              </div>
            </ng-template>


            <ng-template #ProjectAccordionBody>
                <div class="accordion-content-header">
                    <h4>Project License</h4>
                    <p class="input-instruction">Project License type requires selecting Number of Uses and Project Length.</p>
                </div>

                <!-- <div class="fields-group border">
                    <lib-select-form-field
                        [addValueUseCustomFunction]="true"
                        (dropdownChanged)="usesUpdated($event.value)"
                        (valueAdded)="addCustomUsesNumber($event)"
                        [customValue]="true"
                        [noBottomMargin]="true"
                        [addValueButtonText]="'Add & Select'"
                        [textFieldSettings]="quoteFormSettings['usesFreeField']"
                        [formGroupParameter]="quoteForm"
                        [fieldSettings]="quoteFormSettings['uses']">
                    </lib-select-form-field>
                </div> -->

                <div class="fields-group border">
                  <h4>Project Start Date</h4>

                  <div class="btn-group" style="margin: 1em 0;" btnRadioGroup>
                    <label class="btn btn-success mb-0" [class.active]="!customStartDate" btnRadio="asap" value="asap" (click)="toggleStartDateValue('asap')">ASAP</label>
                    <label class="btn btn-success mb-0" [class.active]="customStartDate" btnRadio="custom" value="custom" (click)="toggleStartDateValue('custom')">Custom</label>
                  </div>

                  <!-- <div class="date-toggle-container">
                    <label class="required1">Start Date:</label>
                    <lib-toggle-switch [checked]="customStartDate" (change)="toggleStartDate()" [labelOn]="'Custom'" [labelOff]="'ASAP'"></lib-toggle-switch>

                  </div> -->
                  <!-- <p class="input-instruction">Set project to start ASAP<span class="input-tooltip-icon" [tooltip]="'As soon as the license is paid for.'" container="body">
                    <fa-icon [icon]="questionIcon"></fa-icon></span> or choose custom date for Your project to start.
                  </p> -->

                  <div [class.hidden]="customStartDate">
                    <p class="value">Start Project <strong>ASAP</strong></p>
                  </div>

                  <div [class.hidden]="!customStartDate">
                    <div [class.valid]="quoteForm.get('startDate').valid && quoteForm.get('startDate').touched" class="column-content" [class.not-active]="!customStartDate">
                      <lib-form-field (blurEvent)="customStartDateUpdated()" [disableUsHolidays]="true" [daysFromToday]="maxStartDateDays" [small]="true" [inlineLayout]="true" [noBottomMargin]="true" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['startDate']"></lib-form-field>
                    </div>
                  </div>



                  <!-- <div class="two-columns-with-divider">
                    <div class="left-column column" [class.active]="!customStartDate">

                      <div class="column-content" [class.not-active]="customStartDate">
                        <p class="value centered-text">Start Project <strong>ASAP</strong></p>
                      </div>

                      <div class="checkbox-hover">
                        <input type="radio" class="cover-radio" (change)="toggleStartDate()" id="startDateAsap" value="false" name="customStartDate" [(checked)]="!customStartDate">

                        <label for="startDateAsap">
                        </label>
                      </div>
                    </div>

                    <div class="vertical-divider">
                      <div class="center-element">
                        <span>OR</span>
                      </div>
                    </div>

                    <div class="right-column column" [class.active]="customStartDate">
                      <div [class.valid]="quoteForm.get('startDate').valid && quoteForm.get('startDate').touched" class="column-content" [class.not-active]="!customStartDate">
                        <lib-form-field (blurEvent)="customStartDateUpdated()" [disableUsHolidays]="true" [daysFromToday]="maxStartDateDays" [small]="true" [inlineLayout]="true" [noBottomMargin]="true" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['startDate']"></lib-form-field>
                      </div>

                      <div class="checkbox-hover">
                        <input type="radio" class="cover-radio" (change)="toggleStartDate()" id="startDateCustom" value="true" name="customStartDate" [(checked)]="customStartDate">
                        <label for="startDateCustom">
                        </label>
                      </div>

                    </div>
                  </div> -->
                </div>



                <div class="fields-group border">

                  <h4>Project Length</h4>

                  <!-- <p *ngIf="product?.status === 'expired'">Choose how long you want to extend you project for.</p> -->
                  <!-- <div class="date-toggle-container">

                    <label class="required1">Project Length:</label>
                    <lib-toggle-switch [checked]="customProjectLength" (change)="toggleProjectLength()" [labelOff]="'Predefined'" [labelOn]="'Custom'"></lib-toggle-switch>

                  </div> -->

                  <div class="btn-group" style="margin: 1em 0;" btnRadioGroup>
                    <label class="btn btn-success mb-0" [class.active]="!customProjectLength" btnRadio="predefined" value="predefined" (click)="toggleProjectLengthValue('predefined')">Predefined List</label>
                    <label class="btn btn-success mb-0" [class.active]="customProjectLength" btnRadio="custom" value="custom" (click)="toggleProjectLengthValue('custom')">Custom Term</label>
                  </div>

                  <div [class.hidden]="customProjectLength">
                    <lib-select-form-field [inlineLayout]="true" (dropdownChanged)="setLicenseDates()" [noBottomMargin]="true" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['licenseLength']"></lib-select-form-field>
                  </div>

                  <div [class.hidden]="!customProjectLength">
                    <!-- <lib-select-form-field [inlineLayout]="true" (dropdownChanged)="customProjectLenghtSelected($event.value)" [minBottomMargin]="true" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['lengthTerm']"></lib-select-form-field> -->
                    <lib-form-field [value]="quoteForm.value['lengthValue']" (onKey)="customProjectValue()" (blurEvent)="customProjectValue()" (onChange)="customProjectValue()" [minNumber]="lengthValueMin" [maxNumber]="lengthValueMax" [inlineLayout]="true" [noBottomMargin]="true" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['lengthValue']"></lib-form-field>
                  </div>

                  <!-- <div class="two-columns-with-divider">
                    <div class="left-column column" [class.active]="!customProjectLength">
                      <div class="column-content" [class.not-active]="customProjectLength">

                        <lib-select-form-field [inlineLayout]="true" (dropdownChanged)="setLicenseDates()" [noBottomMargin]="true" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['licenseLength']"></lib-select-form-field>
                      </div>

                      <div class="checkbox-hover">
                        <input type="radio" class="cover-radio" (change)="toggleProjectLength()" id="projectLenghtPredefined" value="true" name="customProjectLength" [(checked)]="!customProjectLength">
                        <label for="projectLenghtPredefined">
                        </label>
                      </div>
                    </div>

                    <div class="vertical-divider">
                      <div class="center-element">
                        <span>OR</span>
                      </div>
                    </div>

                    <div class="right-column column" [class.active]="customProjectLength">
                      <div class="column-content vertical-alignment" [class.not-active]="!customProjectLength">
                        <lib-select-form-field [inlineLayout]="true" (dropdownChanged)="customProjectLenghtSelected($event.value)" [minBottomMargin]="true" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['lengthTerm']"></lib-select-form-field>
                        <lib-form-field [value]="quoteForm.value['lengthValue']" (onKey)="customProjectValue()" (blurEvent)="customProjectValue()" (onChange)="customProjectValue()" [minNumber]="lengthValueMin" [maxNumber]="lengthValueMax" [inlineLayout]="true" [noBottomMargin]="true" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['lengthValue']"></lib-form-field>
                      </div>

                      <div class="checkbox-hover">
                        <input type="radio" class="cover-radio" (change)="toggleProjectLength()" id="projectLengthCustom" value="true" name="customProjectLength" [(checked)]="customProjectLength">
                        <label for="projectLengthCustom">
                        </label>
                      </div>
                    </div>
                  </div> -->
                </div>

                <div class="fields-group border">
                  <h4>Team size</h4>
                  <!-- <lib-form-field
                    [formGroupParameter]="quoteForm"
                    [minNumber]="1"
                    [noBottomMargin]="true"
                    [inlineLayout]="true"
                    [fieldSettings]="quoteFormSettings['numberOfUsers']">
                  </lib-form-field> -->

                  <lib-select-form-field
                    (dropdownChanged)="usersUpdated($event.value)"
                    [inlineLayout]="true"
                    [noBottomMargin]="true"
                    [formGroupParameter]="quoteForm"
                    [fieldSettings]="quoteFormSettings['numberOfUsers']">
                  </lib-select-form-field>
                </div>


                <p class="field-display-value">
                  <span class="label">Deployment</span>
                  <span class="value">Cloud</span>
                </p>



                <!-- <p class="input-instruction" *ngIf="product?.status === 'expired' && extendedDate !== null">New Project end date will be set to {{extendedDate | date: 'shortDate'}}.</p>
                <p class="input-instruction" *ngIf="(product?.status === 'valid' || product?.status === 'expiring') && extendedDate !== null">You will extend your project to {{extendedDate | date: 'shortDate'}}.</p> -->

                <!-- <p class="input-instruction">The license incules unlimited number of users.</p> -->
            </ng-template>

            <ng-template #EnterpriseAccordionBody>

              <div class="accordion-content-header">
                <h4>Enterprise License</h4>
                <p class="input-instruction">Annual Subscription Only</p>
                <!-- <p class="input-instruction">The license is annual subscription. It won't be renewed automatically, but you will receive reminder to renew the license.</p> -->
              </div>

              <!-- <div class="fields-group border">
                <h4>Team size</h4>

                <lib-select-form-field
                    (dropdownChanged)="usersUpdated($event.value)"
                    [inlineLayout]="true"
                    [noBottomMargin]="true"
                    [formGroupParameter]="quoteForm"
                    [fieldSettings]="quoteFormSettings['numberOfUsers']">
                </lib-select-form-field>
              </div> -->


              <p class="field-display-value">
                <span class="label">Team size</span>
                <span class="value">Unlimited</span>
              </p>

              <div class="fields-group border">
                <h4>Deployment</h4>

                <div class="btn-group" style="margin: 1em 0;" btnRadioGroup>
                  <label class="btn btn-success mb-0" [class.active]="enterpriseDeployment.val==='cloud'" btnRadio="Cloud" value="cloud" (click)="toggleDeployment('cloud','Cloud')">Cloud</label>
                  <label class="btn btn-success mb-0" [class.active]="enterpriseDeployment.val=='onPrem'" btnRadio="On Prem" value="onPrem" (click)="toggleDeployment('onPrem','On Prem')">On Prem</label>
                </div>
              </div>


              <!-- <div>
                  <lib-radio-group [inline]="false" [leftAligned]="true" (changeEvent)="deploymentChanged()" [formGroupParameter]="quoteForm" [fieldSettings]="quoteFormSettings['deployment']"></lib-radio-group>
              </div> -->

              <!-- <p class="field-display-value">
                <span class="label">Deployment</span>
                <span class="value">On Premises</span>
              </p> -->

              <!-- <div class="fields-group border">
                <ul class="input-instruction">
                  <li>Annual Subscription Only</li>
                  <li>Number of Uses is <strong>unlimited</strong></li>
                  <li>number of users is <strong>unlimited</strong></li>
                </ul>


              </div> -->
            </ng-template>


            <div class="recaptcha-container">
              <re-captcha
                  name="captcha"
                  required
                  formControlName="captcha"
                  siteKey="6LcYsIIUAAAAAC4v-rzZ4zovoSM3oDPbLan3KbwM">
              </re-captcha>
            </div>

            <lib-form-field [formGroupParameter]="quoteForm"  [fieldSettings]="quoteFormSettings['comments']"></lib-form-field>


        </div>

        <div class="form-group bottom-absolute">
            <button (click)="cancel()" type="button" class="secondary">Cancel</button>
            <button [disabled]="!quoteForm.valid" class="primary" type="submit">Submit</button>
        </div>

    </form>

</div>


