import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const accordionAnimation = trigger('accordionAnimation', [
  state('open', style({
    overflow: 'visible',
    height: '*',
    opacity: '1'
  })),
  state('closed', style({
    overflow: 'hidden',
    height: '0px',
    opacity: '0'
  })),
  transition('open => closed', [
    style({height: '*',opacity: '1', transform: 'scale(1)'}),
    group([
      animate('0.35s ease-in', style({opacity: '0', transform: 'scale(.95)'})),
      animate('.25s .35s ease-in-out', style({height: '0px'}))
    ])
  ]),

  transition('closed => open', [
    style({height: '0px', opacity: '0', transform: 'scale(.95)'}),
    group([
      animate('.35s .25s ease-out', style({opacity: '1', transform: 'scale(1)'})),
      animate('.25s ease-out', style({height: '*'}))
    ])
  ])
]);

@Component({
  selector: 'lib-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [accordionAnimation]
})
export class AccordionComponent implements OnInit {
  @Input() accordionBody: TemplateRef<any>;
  @Input() accordionHeader: TemplateRef<any>;
  open = false;
  accordionIcon = faChevronRight;
  @Input() hideHeader = false;
  @Input() hideButton = false;

  @Input() startOpen = false;
  @Input() insideGroup = false;

  accordionState = "closed";


  constructor() { }

  ngOnInit(): void {
    if(this.startOpen) this.openAccordion();
  }

  public toggleAccordion(event): void {
    this.open = !this.open;
    this.accordionState = this.open ? "open" : "closed";
    let target = event.target || event.srcElement || event.currentTarget;
    target.blur();
  }

  public openAccordion(): void {
    this.open = true;
    this.accordionState = 'open';
  }

  public closeAccordion(): void {
    this.open = false;
    this.accordionState = 'closed';
  }

}
