import { Pipe, PipeTransform } from '@angular/core';
import { NgNavigation, MenuItemType } from '../../shared/ng-navigation/ng-navigation';

@Pipe({
    name: 'subnav',
    pure: true
})
export class subnavPipe implements PipeTransform {
    transform(items: NgNavigation[], filter: string): NgNavigation[] {
        return items.filter(item => item.parent === filter);
    }
}