import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiCompany, ICompany } from '../models/company';
import { InviteAndCompanyDetails } from '../models/invites';
import { faqs } from '../models/other';
import { ApiLicenseExtended, ApiProduct, ApiProductCategory, ApiProductExtended } from '../models/product';
import { UiSettings } from '../models/ui-settings';
import { ApiUser, IUser } from '../models/user';
import { Uses } from '../models/uses';
import { AppSettingsService } from './app-settings.service';
import { Schema } from '../models/schema';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService
  ) { }

  handleError(err: HttpErrorResponse) {
    return throwError(err);
  }

  // SYSTEM APIs
  getSystemVersion(): Observable<any> {
    return this.http.get<any>(this.appSettings.getApi('systemVersion')).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  getSystemHealth(): Observable<any> {
    return this.http.get<any>(this.appSettings.getApi('systemHealth')).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // User APIs

  // Get user
  // userId: optional
  // if left blank, current user in response
  getUser(userId?: string, companyId?: string): Observable<ApiUser> {

    if (userId) {
      let apiUrl = (companyId ? this.appSettings.getApi('userDetails',userId, companyId) : this.appSettings.getApi('currentCompanyUserDetails', userId));
      // console.log(apiUrl);
      return this.http.get<ApiUser>(apiUrl, {withCredentials: true}).pipe(
        tap(data => {
          // console.log(data);
        }),
        catchError(this.handleError)
      );
    } else {
      return this.http.get<ApiUser>(this.appSettings.getApi('currentUser'), {withCredentials: true}).pipe(
        tap(data => {
          // console.log(data);
        }),
        catchError(this.handleError)
      );
    }
  }

  // Update user by user ID
  updateUser(userId: string, userData: ApiUser | {}, companyId?: string): Observable<ApiUser> {
    let apiUrl = (companyId ? this.appSettings.getApi('userDetails',userId, companyId) : this.appSettings.getApi('currentCompanyUserDetails', userId));
    return this.http.put<ApiUser>(apiUrl, userData, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  updateUserUI(uiSettings: UiSettings): Observable<ApiUser> {
    return this.http.put<ApiUser>(this.appSettings.getApi('currentUser'),{'displaySettings':uiSettings},{withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  deleteCurrentUser(optOut?: boolean): Observable<any> {
    let apiUrl = `${this.appSettings.getApi('currentUser')}${(optOut?'?optOut=1':'')}`;
    return this.http.delete<any>(apiUrl,{withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Get users list
  // companyId: optional
  // if not set, current company users
  getUsersList(companyId?: string): Observable<IUser[]> {
    let apiUrl = (companyId ? this.appSettings.getApi('usersList', companyId) : this.appSettings.getApi('currentCompanyUsersList'))
    return this.http.get<IUser[]>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  downloadUserData(): Observable<any> {
    let apiUrl = this.appSettings.getApi('currentUserDetails');
    return this.http.get<any>(apiUrl,{withCredentials: true}).pipe(
      tap(data =>{}),
      catchError(this.handleError)
    )
  }
  // Get user data by user ID
  // TODO: Wait for this API to be done
  getUserData(userId: string, companyId?: string): Observable<any> {
    let apiUrl = (companyId ? this.appSettings.getApi('usersList', companyId) : this.appSettings.getApi('currentCompanyUserData'))
    return this.http.get<any>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  removeUser(userId: string, companyId?: string): Observable<boolean | HttpResponse<boolean>> {
    let apiUrl = (companyId ? this.appSettings.getApi('userDetails',userId, companyId) : this.appSettings.getApi('currentCompanyUserDetails', userId));
    return this.http.delete<boolean | HttpResponse<boolean>>(apiUrl, {observe : 'response', withCredentials: true}).pipe(
      map(data => {return data.ok}),
      catchError(this.handleError)
    );
  }

  // COMPANY APIs
  // Get list of companies
  getCompaniesList(): Observable<ICompany[]> {
    return this.http.get<ICompany[]>(this.appSettings.getApi('companiesList'), {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Get company details
  // companyId: optional
  // if left blank, current company used
  getCompany(companyId?: string): Observable<ApiCompany> {
    let apiUrl = (companyId ? this.appSettings.getApi('companyDetails', companyId) : this.appSettings.getApi('currentCompanyDetails') );
    return this.http.get<ApiCompany>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  deleteCompany(companyId: string): Observable<any> {
    let apiUrl = this.appSettings.getApi('companyDetails', companyId);
    return this.http.delete<ICompany>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  updateCompany(updateData: {},companyId?: string): Observable<ICompany> {
    let apiUrl = (companyId ? this.appSettings.getApi('companyDetails', companyId) : this.appSettings.getApi('currentCompanyDetails') );
    return this.http.put<ICompany>(apiUrl, updateData, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Get company actions list
  getActionsList(companyId?: string): Observable<any[]> {
    // TODO: create action model
    let apiUrl = (companyId ? this.appSettings.getApi('actionsList', companyId) : this.appSettings.getApi('currentCompanyActionsList'));
    return this.http.get<any[]>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }


  // Get company action details
  getActionDetails(actionId: string, companyId?: string): Observable<any> {
    // TODO: create action model
    let apiUrl = (companyId ? this.appSettings.getApi('actionDetails', companyId, actionId) : this.appSettings.getApi('currentCompanyActionDetails', actionId));
    return this.http.get<any>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Impersonate Company
  impersonateCompany(companyId: string): Observable<any> {
    let apiUrl = this.appSettings.getApi('impersonateCompany');
    return this.http.post(apiUrl, {id: companyId}, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Get session company
  sessionCompany(): Observable<any> {
    let apiUrl = this.appSettings.getApi('impersonateCompany');
    return this.http.get(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Invites

  // Get list of invites
  getInvites(companyId?: string): Observable<InviteAndCompanyDetails[]> {
    // TODO: rename and modify invites model
    let apiUrl = (companyId ? this.appSettings.getApi('invitesList', companyId) : this.appSettings.getApi('currentCompanyInvitesList'));
    return this.http.get<InviteAndCompanyDetails[]>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Create invite
  createInvite(email: string, companyId?: string): Observable<any> {
    // TODO: in invites model ad property for {code: string}, which is invite ID
    let apiUrl = (companyId ? this.appSettings.getApi('invitesList', companyId) : this.appSettings.getApi('currentCompanyInvitesList'));
    return this.http.post<any>(apiUrl, {email: email}, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Get invite details
  getInviteDetails(inviteId: string, companyId?: string): Observable<InviteAndCompanyDetails> {
    // TODO: rename and modify invites model
    let apiUrl = (companyId ? this.appSettings.getApi('inviteDetails', companyId, inviteId) : this.appSettings.getApi('currentCompanyInviteDetails', inviteId));
    return this.http.get<InviteAndCompanyDetails>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }


  // Delete invite
  deleteInvite(inviteId: string, companyId?: string): Observable<any> {
    let apiUrl = (companyId ? this.appSettings.getApi('inviteDetails', companyId, inviteId) : this.appSettings.getApi('currentCompanyInviteDetails', inviteId));
    return this.http.delete<any>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Get Invitation for response
  getInvitation(inviteId: string): Observable<any> {
    return this.http.get<any>(this.appSettings.getApi('invitationDetails', inviteId), {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Accept invitation
  acceptInvitation(inviteId: string): Observable<any> {
    return this.http.post<any>(this.appSettings.getApi('invitationAccept'),{code: inviteId}, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // PRODUCTS

  // Get products list
  getProducts(companyId?: string): Observable<ApiProductExtended[]> {
    let apiUrl = (companyId ? this.appSettings.getApi('companyProduct',companyId) : this.appSettings.getApi('products'));
    return this.http.get<ApiProductExtended[]>(this.appSettings.getApi('products')).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  getProductCategories(): Observable<ApiProductCategory[]> {
    let apiUrl = this.appSettings.getApi('productCategories');
    return this.http.get<ApiProductCategory[]>(apiUrl).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }



  // LICENSES

  getLicenses(companyId?: string): Observable<ApiLicenseExtended[]> {
    let apiUrl = (companyId ? this.appSettings.getApi('licensesList',companyId) : this.appSettings.getApi('currentCompanyLicensesList'));
    return this.http.get<ApiLicenseExtended[]>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Get list of licenses

  // Get license details

  // Create license

  createTrialLicense(productId: string, companyId: string): Observable<ApiLicenseExtended> {
    let body = {
      productId: productId,
      licenseParams: {
        type: 'TrialLicense'
      }
    }
    return this.http.post<ApiLicenseExtended>(this.appSettings.getApi('licensesList',companyId), body, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  createLicense(companyId: string, body: Object): Observable<ApiLicenseExtended> {
    return this.http.post<ApiLicenseExtended>(this.appSettings.getApi('licensesList',companyId), body, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  getCompanyLicense(companyId: string, licenseId: string): Observable<ApiLicenseExtended> {
    return this.http.get<ApiLicenseExtended>(this.appSettings.getApi('license', companyId, licenseId), {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  deleteLicense(companyId: string, licenseId: string): Observable<any> {
    return this.http.delete<any>(this.appSettings.getApi('license', companyId, licenseId), {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  sendSupportMessage(body: Object, withCredentials?: boolean): Observable<any> {
    return this.http.post<any>(this.appSettings.getApi('message'),body,{withCredentials: withCredentials || false}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  getFaqs(): Observable<faqs[]> {
    return this.http.get<faqs[]>(this.appSettings.getApi('faq')).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Update license (renew or change data for existing license)





  // -----------------------------------------------------------------------------
  // Uses
  // -----------------------------------------------------------------------------
  // Get uses list
  getUses(companyId?: string): Observable<Uses[]> {
    let apiUrl = (companyId ? this.appSettings.getApi('usesList', companyId) : this.appSettings.getApi('currentCompanyUsesList'));
    return this.http.get<Uses[]>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  getSingleUse(useId: string, companyId: string): Observable<Uses> {
    let apiUrl = this.appSettings.getApi('appUse',companyId,useId);
    return this.http.get<Uses>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  getParseHistory(companyId: string): Observable<Schema[]> {
    let apiUrl = this.appSettings.getApi('parseHistory',companyId);
    return this.http.get<Schema[]>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  getRestgenSpecification(companyId: string, cacheKey: string, documentKey: string): Observable<any> {
    let apiUrl = this.appSettings.getApi('restgenSpecification',companyId,cacheKey,documentKey);

    return this.http.get<any>(apiUrl,{withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    )
  }

  getAllUses(companyId?: string): Observable<Uses[]> {
    let apiUrl = (companyId ? this.appSettings.getApi('usesList', companyId) : this.appSettings.getApi('currentCompanyUsesList'));
    apiUrl += '?isReturnAllUses=true';
    apiUrl += '&fetchMemberBasedResponse=true';

    return this.http.get<Uses[]>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  getUsesBYCategory(categories: string[], companyId?: string): Observable<Uses[]> {
    let apiUrl = (companyId ? this.appSettings.getApi('usesList', companyId) : this.appSettings.getApi('currentCompanyUsesList'));
    categories.forEach((cat,i)=>{
      apiUrl += (i === 0 ? `?productCategoriesIds=${cat}` : `&productCategoriesIds=${cat}`);
    });
    apiUrl += '&allowEmptyResponse=true';
    apiUrl += '&fetchMemberBasedResponse=true';
    return this.http.get<Uses[]>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  postUsesByProductCategory(productCatId: string, body: Object): Observable<Uses[]> {
    let apiUrl = (productCatId ? this.appSettings.getApi('usesByProductCategory', productCatId) : this.appSettings.getApi('currentCompanyUsesList'));
    return this.http.post<Uses[]>(apiUrl,body,{withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  postUsesByProduct(productId: string, body: Object): Observable<Uses[]> {
    let apiUrl = (productId ? this.appSettings.getApi('usesByProduct', productId) : this.appSettings.getApi('currentCompanyUsesList'));
    apiUrl += '&fetchMemberBasedResponse=true';
    return this.http.post<Uses[]>(apiUrl,body,{withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  getUsesByProduct(productId?: string, companyId?: string): Observable<Uses[]> {
    let apiUrl = (productId ? this.appSettings.getApi('usesByProduct', productId) : this.appSettings.getApi('currentCompanyUsesList'));
    return this.http.get<Uses[]>(apiUrl, {withCredentials: true}).pipe(
      tap(data => {}),
      catchError(this.handleError)
    );
  }

  // Contact forms

  // General contact

  // Quote

  // Demo

}
