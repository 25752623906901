import { IProductStatus, IProductType } from "../../../models/product";
import { UserRoles, UserTypesData } from "../../../models/user";


export interface NgParentColumns {
    columnName: string,
    columnText: string,
    sortable: boolean,
    columnType: ColumnTypes,
    filters: ColumnFilter,
    columnOrder?: number,
    notVisible?: boolean,
    clickable?: boolean,
    buttons?: NgListButton[],
    columnClasses?: string,
    sortClasses?: string,
    customMapping?: string[],
    combinedValue?: string,
    displayNotAppplicable?: string,
    rowIdentifier?: boolean,
    tooltipText?: string,
    specialHandling?: boolean,
    sortColumnName?: string, // temporary property - API needs to be fixed
    titleColumn?: boolean,
    subColumnParent?: boolean,
    canShowHide?: boolean,
    displayFunction?: string,
    thHtml?: boolean,
    tdHtml?: boolean,
    sortColumnText?: string,
    clickForInfo?: boolean,
    noHeader?: boolean
}
export interface NgListColumns extends NgParentColumns {
    subColumns?: NgParentColumns[],
}

export enum ColumnTypes {
    text = 'text',
    date = 'date',
    buttons = 'buttons',
    user_role = 'user_role',
    status = 'status',
    type = 'type',
    text_info = 'text_info',
    boolean = 'boolean',
    file_download = 'file_download',
    info = 'info',
    empty = 'empty'
}

export interface ColumnFilter {
    filterId?: string,
    filterName: string,
    filterType: columnFilterTypes,
    filterOptions?: FilterOptionData[],
    filterPlaceholder: string,
    optionSelected: string,
    active?: boolean,
    compareType?: TypesOfCompare,
    autocompleteList?: string[]
}

export interface FilterOptionData {
    value: any,
    text: string
}

export enum columnFilterTypes {
    text = 'text',
    dropdown = 'dropdown',
    radioOptins = 'radioOptions',
    options = 'options',
    autocomplete = 'autocomplete',
    partialText = 'partialText'
    // we can add date range later
}

export interface NgListDataRow {
    listRow: NgListDataCell[],
    rowIdentifier: any,
    showDetails?: boolean,
    accordionState?: string,
    titleData?: NgListDataCell,
    subList?: NgListDataRow[],
    actions?: NgListButton[],
    highlighted?: boolean
}

export interface NgListDataCell {
    value: any,
    display: string,
    link?: string,
    type: ColumnTypes,
    columnName: string,
    columnOrder?: number,
    buttons?: NgListButton[],
    notVisible?: boolean,
    userRole?: UserTypesData,
    isGlobalAdmin?: boolean,
    highlighted?: boolean,
    productStatus?: IProductStatus,
    productType?: IProductType,
    valueNotApplicable?: boolean,
    centered?: boolean,
    tooltipText?: string,
    columnClasses?: string,
    colHover?: boolean,
    tdHtml?: boolean,
    clickForInfo?: boolean
}

export interface NgListButton {
    buttonText: string,
    type: ButtonTypes,
    subType?: string
    value: any,
    loadgin?: boolean,
    permission?: string,
    userRole?: UserRoles[],
    buttonCustomClass?: string,
    showButtonFunction?: string,
    disabled?: boolean
}

export enum ButtonTypes {
    none = 'none',
    download = 'download',
    info = 'info',
    upgrade = 'upgrade',
    resend = 'resend',
    revoke = 'revoke',
    delete = 'delete',
    cutom = 'custom',
    user = 'user',
    user_remove = 'user_remove',
    user_delete = 'user_delete',
    renew = 'renew',
    quote = 'quote',
    demo = 'demo',
    impersonate = 'impersonate',
    license_add = 'license_add',
    license_revoke ='license_revoke',
    license_renew = 'license_renew',
    license_paid = 'license_paid',
    expand = 'expand',
    cell_info = 'cell_info',
    cell_download = 'cell_download',
    trial = 'trial',
    resend_invite = 'resend_invite',
    generate = 'generate',
    swagger = 'swagger'
}

export interface AppliedFilters {
    field: string,
    value: string | boolean | number,
    type?: TypesOfCompare
}

export enum TypesOfCompare {
  equal = 'equal',
  partialEqual = 'partialEqual'
}
export interface ListUiParams {
    numberOfPages: number,
    currentPage: number,
    itemsPerPage: number,
    filters: AppliedFilters[],
    sorting: {
      field: string,
      order: sortingOrder
    },
}

export class ListParams implements ListUiParams {
    // static defaultListParams(): ListUiParams {
    //   throw new Error('Method not implemented.');
    // }
    numberOfPages: number;
    currentPage: number;
    itemsPerPage: number;
    filters: AppliedFilters[];
    sorting: {
      field: string,
      order: sortingOrder
    };
    constructor(
        numberOfPages: number,
        currentPage: number,
        itemsPerPage: number,
        filters: FilterOptionData[],
        sorting: {
            field: string,
            order: sortingOrder
          }
        ) {}

    static defaultListParams():ListUiParams {
        return new ListParams(0, 1, 10, [], {field:"", order:sortingOrder.asc})
    }
    setNumberOfPages(totalResults: number) {
        this.numberOfPages = Math.ceil(totalResults / this.itemsPerPage);
    }
}


export enum sortingOrder {
    asc = 'a',
    desc = 'd'
}

// export enum density {
//     default = 'default',
//     comfortable = 'comfortable',
//     compact = 'compact'
// }
