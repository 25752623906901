import { Pipe, PipeTransform } from '@angular/core';
import { NgNavigation, MenuItemType } from '../../shared/ng-navigation/ng-navigation';

@Pipe({
    name: 'appLinks',
    pure: true
})
export class appLinksPipe implements PipeTransform {
    transform(items: NgNavigation[], filter: boolean): NgNavigation[] {
        return items.filter(item => item.appLink === true);
    }
}