import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFormField, InputTypes } from '../../../models/formField';
import { NotificationService } from '../../notification/notification.service';
import { FormsService } from '../../../services/forms.service';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { ApiService } from '../../../services/api.service';
import { UserService } from '../../../services/user.service';
import { FormResultMessageFormat } from '../../notification/notification';
import { AppSettingsService } from '../../../services/app-settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-whitepaper-form',
  templateUrl: './whitepaper-form.component.html',
  styleUrls: ['./whitepaper-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WhitepaperFormComponent implements OnInit {

  whitepaperFormSettings: {} = {};
  whitepaperForm: FormGroup;
  formReady = false;
  formCreated = false;

  loading = false;

  // constructor() { }

  whitepaperPreForm: IFormField[] = [
    {
      inputName: "firstName",
      id: "firstNameId",
      placeholder: "Your First and Last Name",
      label: "First Name",
      value: "",
      inputType: InputTypes.text
    },
    {
      inputName: "lastName",
      id: "lastNameId",
      placeholder: "Your First and Last Name",
      label: "Last Name",
      value: "",
      inputType: InputTypes.text
    },
    {
      inputName: "phone",
      id: "phoneId",
      placeholder: "212 555 5555",
      label: "Phone Number",
      value: "",
      inputType: InputTypes.tel
    },
    {
      inputName: "email",
      id: "emailId",
      placeholder: "email@gmail.com",
      label: "Email Address",
      value: "",
      inputType: InputTypes.email
    },
    {
      inputName: "companyName",
      id: "companyNameId",
      placeholder: "Your First and Last Name",
      label: "Company Name",
      value: "",
      inputType: InputTypes.text
    },
    {
      inputName: "jobTitle",
      id: "jobTitleId",
      placeholder: "Your First and Last Name",
      label: "Job Title",
      value: "",
      inputType: InputTypes.text
    },
    {
      inputName: "industry",
      id: "industryId",
      placeholder: "Your First and Last Name",
      label: "Industry",
      value: "",
      inputType: InputTypes.select
    }
  ];

  privacyLink: string;

  private initialFormValues = {};

  _notificationSvc: NotificationService;

  constructor(
    private fb: FormBuilder,
    private formService: FormsService,
    private confirmService: ConfirmDialogService,
    private uiService: UserInterfaceService,
    private apiService: ApiService,
    private userService: UserService,
    private notif: NotificationService,
    private settings: AppSettingsService,
    private router: Router
  ) {
    this._notificationSvc = new NotificationService();

   }

  ngOnInit(): void {
    this.privacyLink = this.settings.baseSetting.projectUrls['platform'] + '/privacy';
    let settings = {};

    let form = this.formService.createForm('whitepaper', this.whitepaperPreForm);

    form.forEach(el => {
      this.whitepaperFormSettings[el.inputName] = el;
    });

    this.whitepaperForm = this.fb.group(this.formService.convertToReactiveForm(form));
    this.formReady = true;
    this.initialFormValues = this.whitepaperForm.value;
    this.formCreated = true;
  }

  sendContactMessage():void {
    this.loading = true;
    let payload = new FormData();
    payload.append('type','contact');
    payload.append('contact.fullName',this.whitepaperForm.value['firstName'] + " " + this.whitepaperForm.value['lastName']);
    payload.append('contact.email',this.whitepaperForm.value['email']);
    payload.append('contact.phone',JSON.stringify(this.userService.setDisplayPhone(this.whitepaperForm.value['phone'])));
    payload.append('subject',`Whitepaper request from ${this.whitepaperForm.value['firstName']} ${this.whitepaperForm.value['lastName']}`);
    let content = {message: `Company name: ${this.whitepaperForm.value['companyName']}, Job Title: ${this.whitepaperForm.value['jobTitle']}, Indutry ${this.whitepaperForm.value['industry']}`};
    payload.append('content',JSON.stringify(content));

    this.apiService.sendSupportMessage(payload).subscribe({
      next: result => {
        this.loading = false;
        this.notif.success('Success','Forn sent successfully. The WitePaper is downloading',FormResultMessageFormat.popup,6000,null);
        this.resetForm();
        setTimeout(() => {
          var link = document.createElement('a');
          link.href = '/assets/downloads/Avoiding Technical Debt With Smart Code.pdf';
          link.download = 'Avoiding Technical Debt With Smart Code.pdf';
          link.dispatchEvent(new MouseEvent('click'));
          setTimeout(() => {
            this.router.navigate(["/whitepaper-confirm"], { replaceUrl: true });
          },2000);
        }, 100);
      },
      error: err => {console.log(err)}
    })
  }

  resetForm(): void {
    this.whitepaperForm.reset(this.initialFormValues);
    this.whitepaperForm.markAsPristine();
    this.whitepaperForm.markAsUntouched();
  }

  reset() {
    this.confirmService.confirm(this.uiService.getConfirmMessage('clearTheForm'))
      .subscribe((answer) => {
        if(answer) {
          this.resetForm();
        }
      });
  }

}
