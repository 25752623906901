<div 
    *ngIf="numberOfPages > 1" 
    class="pagination-buttons pagination-buttons-new"
    [class.long]="numberOfPages > 5"
    [class.short]="numberOfPages < 5 && numberOfPages > 2"
    [class.very-short]="numberOfPages < 3">
    <button class="secondary" [disabled]="firstPage()" (click)="setPage(currentPage-1)">
        <fa-icon [icon]="prevIcon"></fa-icon>
    </button>
    <ul class="pagination-buttons pagination-buttons-new">
        <!-- <li [class.disabled]="firstPage()" (click)="setPage(1)">
            <fa-icon [icon]="firstIcon"></fa-icon>
        </li>
        <li [class.disabled]="firstPage()" (click)="setPage(currentPage-1)">
            <fa-icon [icon]="prevIcon"></fa-icon>
        </li> -->
        <li *ngFor="let x of [].constructor(numberOfPages); let i = index" [hidden]="!displayNumberOrDots(i)" [class.active]="i === currentPage - 1" [class.dots]="displayDots(i)" class="ng-binding ng-scope">
            <span (click)="setPage(i + 1)" *ngIf="displayNumber(i)">
                {{ i + 1 }}
            </span>
            <fa-icon *ngIf="displayDots(i)" [icon]="ellipsisIcon"></fa-icon>
        </li>
        <!-- <li [class.disabled]="lastPage()" (click)="setPage(currentPage + 1)">
            <fa-icon [icon]="nextIcon"></fa-icon>
        </li>
        <li [class.disabled]="lastPage()" (click)="setPage(numberOfPages)">
            <fa-icon [icon]="lastIcon"></fa-icon>
        </li> -->
    </ul>
    <button class="secondary" [disabled]="lastPage()" (click)="setPage(currentPage + 1)">
        <fa-icon [icon]="nextIcon"></fa-icon>
    </button>
</div>