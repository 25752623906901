import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  percentageText:number = 0;
  pathLength: number;
  @Input() backwards: boolean = false;
  @Input() percent: number;
  @Input() text: string;
  @Input() times: boolean = false;
  @ViewChild('path') pathElement: ElementRef;
  @ViewChild('number') numberElement: ElementRef;

  public start() {
    this.counting();
  }

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let pathEl = this.pathElement.nativeElement;
    this.pathLength = pathEl.getTotalLength();

    this.renderer.setStyle(pathEl,    'stroke-dasharray',this.pathLength + ' ' + this.pathLength);
    if(this.backwards) {
      this.renderer.setStyle(pathEl, 'stroke-dashoffset',0);
    } else {
      this.renderer.setStyle(pathEl, 'stroke-dashoffset',this.pathLength);
    }


  }

  private counting() {
    this.percentageText = (this.backwards ? (100 - this.percent)/100 : this.percent/100);
    this.renderer.setStyle(this.pathElement.nativeElement, 'stroke-dashoffset',this.pathLength * (1 - this.percent/100));
  }

}
