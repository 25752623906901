<div *ngIf="fieldSettings" class="form-group"
    [class.min-bottom-margin]="minBottomMargin"
    [class.no-bottom-margin]="noBottomMargin">
    <div [formGroup]="getFormGroupName()" [class.inline-layout]="inlineLayout">

        <label
            *ngIf="fieldSettings.label && (!checkInputType(['toggle'])) && !noLabel"
            [ngClass]="{'required': fieldSettings.required === true}"
            [class.move-up]="fieldSettings.instruction && inlineLayout"
            [for]="getId()"
            [style.minWidth.em]="minLabelWidth"
            >{{fieldSettings.label}}</label>

        <span tabindex="0"
            class="input-tooltip-icon"
            *ngIf="fieldSettings.tooltipText"
            tooltip="{{fieldSettings.tooltipText}}"
            animation-duration="500"
            tooltip-class="input-tooltip"
            hide-delay="500"
            hideDelayAfterClick="5000"
            trigger="hover"
            placement="top"
            max-width="300">
            <fa-icon [icon]="faQuestionCircle"></fa-icon>
        </span>

        <p *ngIf="fieldSettings.instruction && !noLabel && !inlineLayout" class="input-instruction" [class.inline-instruction]="inlineInstruction">
            {{fieldSettings.instruction}}
        </p>
        <p *ngIf="fieldSettings.instructionHtml && !noLabel && !inlineLayout" class="input-instruction" [innerHTML]="fieldSettings.instructionHtml"></p>

        <div class="input-container"
            [class.small]="small"
            [class.is-valid]="fieldValid === true"
            [ngClass]="{'is-invalid': (fieldSettings.errorMessages && fieldSettings.errorMessages.length > 0) || requiredInvalid() || dateRequiredError }">
            <input
                *ngIf="checkInputType(['text','email','number'])"
                class="form-control"
                [class.form-control-lg]="!small"
                [autofocus]="fieldSettings.autofocus"
                [attr.disabled]="(disabled ? 'disabled' : null)"
                [type]="fieldSettings.inputType"
                [id]="getId()"
                [min]="minNumber"
                [max]="maxNumber"
                (keydown.enter)="keyEntered()"
                (input)="textInput()"
                (change)="inputChanged($event)"
                (blur)="validationOnBlur()"
                (focus)="onFocus()"
                (input)="validationOnKey($event)"
                [placeholder]="fieldSettings.placeholder || ''"
                [autocomplete]="fieldSettings.autocompleteOff ? 'off' : ''"
                formControlName="{{fieldSettings.inputName}}"
                [class.is-valid]="fieldValid === true"
                [ngClass]="{'is-invalid': (fieldSettings.errorMessages && fieldSettings.errorMessages.length > 0) || requiredInvalid() }" />

            <input
                *ngIf="checkInputType(['passwordConfirm','password'])"
                class="form-control form-control-lg"
                [attr.disabled]="(disabled ? 'disabled' : null)"
                [type]="passwordFieldType"
                [id]="getId()"
                (change)="inputChanged()"
                (blur)="validationOnBlur()"
                (focus)="onFocus()"
                (input)="validationOnKey($event)"
                [placeholder]="fieldSettings.placeholder || ''"
                [autocomplete]="fieldSettings.autocompleteOff ? 'off' : ''"
                formControlName="{{fieldSettings.inputName}}"
                [class.is-valid]="fieldValid === true"
                [ngClass]="{'is-invalid': (fieldSettings.errorMessages && fieldSettings.errorMessages.length > 0) || requiredInvalid() }" />

                <div class="show-password" *ngIf="checkInputType(['password','passwordConfirm'])">
                    <fa-icon [icon]="faEye" (click)="togglePassword('password')"></fa-icon>
                    <fa-icon [icon]="faEyeSlash" [@toggleOpacity]="passwordFieldType" (click)="togglePassword('text')"></fa-icon>
                </div>

            <input
                *ngIf="checkInputType(['aatel'])"
                class="form-control form-control-lg"
                [attr.disabled]="(disabled ? 'disabled' : null)"
                [type]="fieldSettings.inputType"
                [id]="getId()"
                (blur)="validationOnBlur()"
                (input)="validationOnKey($event)"
                prefix="+1 "
                mask="(000) 000 - 0000"
                (focus)="onFocus()"
                [placeholder]="fieldSettings.placeholder || ''"
                [autocomplete]="fieldSettings.autocompleteOff ? 'off' : ''"
                formControlName="{{fieldSettings.inputName}}"
                [class.is-valid]="fieldValid === true"
                [ngClass]="{'is-invalid': (fieldSettings.errorMessages && fieldSettings.errorMessages.length > 0) || requiredInvalid() }" />

                <!-- NEED TEL INPUT HERE - Get some tel input plugin
                Need DATE Input - with plugin -->
            <input
                *ngIf="checkInputType(['date'])"
                class="form-control custom-select"
                [class.form-control-lg]="!small"
                [attr.disabled]="(disabled ? 'disabled' : null)"
                [type]="'text'"
                [id]="getId()"
                (change)="inputChanged()"
                (focus)="onFocus()"
                (input)="validationOnKey($event)"
                [placeholder]="fieldSettings.placeholder || ''"
                [autocomplete]="'off'"
                formControlName="{{fieldSettings.inputName}}"
                [class.is-valid]="fieldValid === true"
                bsDatepicker
                [daysDisabled]="(noWeekend ? [6,0] : [])"
                [datesDisabled]="disabledDates"
                (onHidden)="validationOnBlur()"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [bsConfig]="{ isAnimated: true, adaptivePosition: true, containerClass: 'theme-dark-blue', isAnimated: true }"
                [ngClass]="{'is-invalid': (fieldSettings.errorMessages && fieldSettings.errorMessages.length > 0) || requiredInvalid() || dateRequiredError }" />


            <input
                *ngIf="checkInputType(['toggle'])"
                type="checkbox"
                [attr.disabled]="(disabled ? 'disabled' : null)"
                class="custom-control-input"
                [id]="getId()"
                formControlName="{{fieldSettings.inputName}}"
                [ngClass]="{'is-invalid': (fieldSettings.errorMessages && fieldSettings.errorMessages.length > 0) || requiredInvalid() }"  />

            <ng-select
                #Selecter
                *ngIf="checkInputType(['select'])"
                class="form-control form-control-lg"
                appearance="outline"
                [id]="getId()"
                [disabled]="disabled"
                placeholder="{{fieldSettings.placeholder}}"
                labelForId="fieldSettings.label"
                [searchable]="false"
                [clearable]="fieldSettings.clearButton || false"
                formControlName="{{fieldSettings.inputName}}"
                (change)="selectChanged($event)"
                [ngClass]="{'is-invalid': (fieldSettings.errorMessages && fieldSettings.errorMessages.length > 0) || requiredInvalid() }" >
                <ng-option *ngFor="let option of fieldSettings.options" [value]="option.value">{{option.text}}
                  <!-- <span class="selected-default">default</span> -->
                </ng-option>
                <ng-template *ngIf="selectAddButton" ng-footer-tmp>
                    <ng-container [ngTemplateOutlet]="selectFooterTempalte"></ng-container>
                    <!-- <div class="select-add-form">
                        <input (keydown.enter)="SelectButtonAction($event)" type="number" #addValue name="adding-value">
                        <button type="submit" class="secondary" (click)="SelectButtonAction($event)">{{selectAddButtonText}}</button>
                    </div> -->

                </ng-template>
            </ng-select>

            <div *ngIf="checkInputType(['tel'])">
                <ngx-intl-tel-input [cssClass]="'custom'"
                [id]="getId()"
                [disabled]="fieldSettings.disabled"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true" [searchCountryFlag]="true"
                [phoneValidation]="true" [separateDialCode]="separateDialCode"
                [numberFormat]="PhoneNumberFormat.International" formControlName="{{fieldSettings.inputName}}">
            </ngx-intl-tel-input>
            </div>


            <!-- <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
				[enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
				[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
				[selectedCountryISO]="CountryISO.India" [maxLength]="15"
				[phoneValidation]="true" [separateDialCode]="separateDialCode"
				[numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone">
			</ngx-intl-tel-input> -->

            <textarea
                *ngIf="checkInputType(['textarea'])"
                [attr.disabled]="fieldSettings.disabled"
                [placeholder]="fieldSettings.placeholder || ''"
                [id]="getId()"
                (blur)="validationOnBlur()"
                [rows]="fieldSettings.rows || 10"
                class="form-control form-control-lg"
                formControlName="{{fieldSettings.inputName}}">

            </textarea>


            <div (fileDropped)="onFileDropped($event)" libDnd [class.full-screen]="codeFullScreen" [id]="fieldSettings.id" class="code-editor-container"  [class.code-disabled]="disabled" *ngIf="fieldSettings.inputType === 'code'">
                <ngx-monaco-editor style="height: 100%" (onInit)="onInit($event)" [placeholder]="fieldSettings.placeholder" [options]="editorOptions" (focus)="monacoFocus()" (blur)="monacoBlur()" formControlName="{{fieldSettings.inputName}}"></ngx-monaco-editor>
                <div *ngIf="showMonacoPlacehoder" (click)="hideMonacoPlaceholder()" class="monaco-placeholder">
                  <div class="placeholder-text">{{fieldSettings.placeholder}}</div>
                </div>
                <fa-icon (click)="codeFullScreenToggle()" class="full-screen-button" [icon]="fullScreenIcon"></fa-icon>
                <fa-icon (click)="codeFullScreenToggle()" class="reset-screen-button" [icon]="resetScreenIcon"></fa-icon>
                <div class="code-editor-footer">
                    <span class="close-fs-text" [class.animating]="codeFsAnimating" [class.doneAmination]="codeFsDoneAnimation">ESC key to exit Full Screen mode.</span>
                    <span class="code-language">{{fieldSettings.codeLanguage}}</span>
                    <button class="code-editor-copy-button" [class.code-copied]="codeCopied" (click)="copyToClipboard()" title="copy to clipboard">COPY</button>
                </div>
            </div>

            <!-- <div [class.full-screen]="codeFullScreen" class="code-editor-container" [class.code-disabled]="disabled" *ngIf="fieldSettings.inputType === 'code'">
                <ngs-code-editor
                    [theme]="ceTheme"
                    [codeModel]="ceModel"
                    [options]="ceOptions"
                    (valueChanged)="onCodeChanged($event)"
                    (onInit)="inInit($event)">
                </ngs-code-editor>
                <fa-icon (click)="codeFullScreenToggle()" class="full-screen-button" [icon]="fullScreenIcon"></fa-icon>
                <fa-icon (click)="codeFullScreenToggle()" class="reset-screen-button" [icon]="resetScreenIcon"></fa-icon>
                <div class="code-editor-footer">
                    <span class="code-language">{{fieldSettings.codeLanguage}}</span>
                    <button class="code-editor-copy-button" [class.code-copied]="codeCopied" (click)="copyToClipboard()" title="copy to clipboard">COPY</button>
                </div>
            </div> -->



            <div class="errors-container" [class.right-side-errors]="rightSideErrors">
                <span class="inline-field-errors" *ngIf="requiredInvalid() || dateRequiredError" [@slideInOut]>{{fieldSettings.requiredErrorMessage}}</span>
                <span class="inline-field-errors" *ngFor="let errMgs of fieldSettings.errorMessages" [@slideInOut]>{{errMgs}}</span>
                <span class="inline-field-errors password-validator" *ngIf="checkInputType(['password']) && hasFocus" [@slideInOut]>
                    <span>Your password must have:</span>
                    <span [ngClass]="hasError('hasNumber')  ? 'text-danger' : 'text-success'">At least 1 Number (0..9)</span><br>
                    <span [ngClass]="hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">At least 1 small letter (a..z)</span><br>
                    <span [ngClass]="hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">At least 1 CAPITAL LETTER (A..Z)</span><br>
                    <span [ngClass]="hasError('hasSpecialCharacters')  ? 'text-danger' : 'text-success'">At least 1 special character (!@#..)</span><br>
                    <span [ngClass]="hasError('minlength')  ? 'text-danger' : 'text-success'">Min 8 characters</span>
                </span>
            </div>

            <p *ngIf="fieldSettings.instruction && !noLabel && inlineLayout" class="input-instruction instruction-below-input">
              {{fieldSettings.instruction}}
              <span *ngIf="fieldSettings.countCharacters" style="float: right;">
                <span>{{charCount}}</span><span *ngIf="fieldSettings.characterLimit"> of {{fieldSettings.characterLimit}}</span>
              </span>
            </p>
            <div class="character-counter" *ngIf="fieldSettings.countCharacters && !inlineLayout">
              <span>{{charCount}}</span><span *ngIf="fieldSettings.characterLimit"> of {{fieldSettings.characterLimit}}</span>
            </div>

        </div>



        <!-- <div class="character-counter" *ngIf="fieldSettings.countCharacters">
            <span>{{charCount}}</span><span *ngIf="fieldSettings.characterLimit"> of {{fieldSettings.characterLimit}}</span>
        </div> -->

    </div>
</div>
