import { Pipe, PipeTransform } from '@angular/core';
import { NgNavigation, MenuItemType } from '../../shared/ng-navigation/ng-navigation';

@Pipe({
    name: 'pageLinks',
    pure: true
})
export class pageLinksPipe implements PipeTransform {
    transform(items: NgNavigation[], filter: boolean): NgNavigation[] {
        return items.filter(item => item.pageLink === true);
    }
}