import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { SearchPipe } from './search.pipe';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faCompress, faCompressAlt, faExchange, faExpand, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { Multiselect } from './multiselect';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

const itemAnimation = trigger('itemAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0, transform: 'scale(.95)' }), animate('500ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))],
      { optional: true }
    ),
  ])
]);


@Component({
  selector: 'lib-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [ SearchPipe ],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({opacity: 0, transform: 'scale(.95)', height: 0}),
        animate('500ms ease-in-out', style({opacity: 1, transform: 'scale(1)', height: '1.625em'}))
      ]),
      transition(':leave',[
        style({opacity:1, transform: 'scale(1)',height: '1.625em'}),
        animate('500ms ease-in-out', style({opacity:0, transition: 'scale(.9)', height: 0}))
      ])
    ])
  ]
})
export class MultiselectComponent implements OnInit {
  private eventsSubscription: Subscription;

  @ViewChild('input1', {static: false}) inputEl: ElementRef;

  @Input() events: Observable<void>;

  // @Input() inputList: string[] | {text: string, value: string}[] = [];
  @Input() initialList: Multiselect[] = [];
  @Input() stringInitialList: string[] = [];
  @Input() stringOutputList: string[] = [];
  @Input() stringInput = false;
  @Input() stringOutput = false;
  @Input() sideBySide = true;
  @Input() initialOutputList: string[] = [];
  @Input() inputListName = 'Input List';
  @Input() outputListName = 'Output List';

  @Input() multiselectorVisible = true;

  @Output() exportOutputList: EventEmitter<string[]> = new EventEmitter<string[]>();

  inputList: Multiselect[] = [];
  filteredInputList: Multiselect[] = [];

  filteredOutputList: Multiselect[] = [];
  outputList: Multiselect[] = [];
  selectedList: string[] = [];

  inpuQuery: string;
  outputQuery: string;

  addAllIcon = faAngleDoubleRight;
  removeAllIcon = faAngleDoubleLeft;
  addIcon2 = faAngleRight;
  addIcon = faPlus;
  removeIcon = faTrash;
  removeIcon2 = faAngleLeft;
  clearIcon = faTimes;
  revertIcon = faExchange;

  fullScreenIcon = faExpand;
  normalScreenIcon = faCompress;
  minIcon = faCompressAlt;

  inputTotalNo: number;
  inputSelectedNo: number;
  inputFilteredNo: number;

  outputTotalNo: number;
  outputSelectedNo: number;
  outputFilteredNo: number;


  selectedAll: boolean;
  selectedAllOutput: boolean;

  constructor(
    private renderer: Renderer2,
    public searchPipe: SearchPipe
    ) { }



  ngOnChanges(changes: SimpleChanges): void {
    if(changes['stringInitialList'] && changes['stringInitialList'].currentValue !== changes['stringInitialList'].previousValue) {
      this.setList();
    } else if(changes['multiselectorVisible'] && changes['multiselectorVisible'].currentValue !== changes['multiselectorVisible'].previousValue) {
      if(changes['multiselectorVisible'].currentValue) {
        setTimeout(() => {
          this.exportOutputString();
        }, 100);

      }
    }
  }

  private resetOutputList(): void {
    this.outputList = [];
    this.filteredOutputList = [];
    this.selectedAllOutput = false;
    this.outputFilteredNo = 0;
    this.outputTotalNo = 0;
    this.outputSelectedNo = 0;
    this.outputQuery = '';
  }

  private setList(): void {
    if(this.stringInput) {
      this.inputList = this.multiselectFromString(this.stringInitialList);
    } else {
      this.inputList = this.initialList;
    }
    this.filteredInputList = this.inputList;
    this.inputTotalNo = this.inputList.length;
    this.inputFilteredNo = this.inputList.length;
    this.inputSelectedNo = 0;
    this.inpuQuery = '';
    this.resetOutputList();
  }


  ngOnInit(): void {
    // this.setList();

    this.eventsSubscription = this.events.subscribe(() => {
      setTimeout(() => this.inputEl.nativeElement.focus(), 0);
    });
  }



  private multiselectFromString(inputString: string[]): Multiselect[] {
    let outputList: Multiselect[] = [];
    let i = 0;
    inputString.forEach(el=>{
      outputList.push({id: i,value:inputString[i],display:inputString[i],selected:false,added: false});
      i++;
    });
    return outputList;
  }

  reverseSelected(): void {
    this.filteredInputList.forEach(el=>{el.selected = !el.selected});
  }
  addItem(item: Multiselect): void {
    this.outputList.push(item);
    this.inputList.splice(this.inputList.indexOf(item),1);
    this.filterInputList(this.inpuQuery);
    this.filterInputList(this.outputQuery,true)
    this.exportOutputString();
  }

  removeItem(item: Multiselect): void {
    this.inputList.push(item);
    this.outputList.splice(this.outputList.indexOf(item),1);
    this.filterInputList(this.inpuQuery);
    this.filterInputList(this.outputQuery,true);
    this.exportOutputString();
  }

  inputListSomeChecked(): boolean {
    return this.filteredInputList.some(i=>i.selected) && !this.filteredInputList.every(i=>i.selected);
  }

  removeSelected(): void {
    let listToRemove = this.outputList.filter(item=>item.selected);
    let i = 0;
    let n = listToRemove.length;
    this.resetQuery(false);
    if(n>20) {
      for(i=0;i<n;i++) {
        this.inputList.push(listToRemove[i]);
        this.outputList.splice(this.outputList.indexOf(listToRemove[i]),1);
        this.filterInputList(this.inpuQuery);
        this.filterInputList(this.outputQuery,true)
        listToRemove[i].selected = false;
      }
      this.selectedAllOutput = false;
      if(this.filteredOutputList.length === 0) this.outputQuery = '';
      this.filterInputList(this.inpuQuery);
      this.filterInputList(this.outputQuery,true);
      this.exportOutputString();
    } else {
      const removingItems = setInterval(()=>{
        if(i<n) {
          this.inputList.push(listToRemove[i]);
          this.outputList.splice(this.outputList.indexOf(listToRemove[i]),1);
          this.filterInputList(this.inpuQuery);
          this.filterInputList(this.outputQuery,true)
          listToRemove[i].selected = false;
          i++;
        } else {
          clearInterval(removingItems);
          this.selectedAllOutput = false;
          if(this.filteredOutputList.length === 0) this.outputQuery = '';
          this.filterInputList(this.inpuQuery);
          this.filterInputList(this.outputQuery,true);
          this.exportOutputString();
        }
      },10);
    }

  }

  addSelected(): void {
    let listToAdd = this.filteredInputList.filter(item=>item.selected);
    let i = 0;
    let n = listToAdd.length;
    this.resetQuery(true);
    if(n>20) {
      for(i=0;i<n;i++) {
        this.outputList.push(listToAdd[i]);
        this.inputList.splice(this.inputList.indexOf(listToAdd[i]),1)
        this.filterInputList(this.inpuQuery);
        this.filterInputList(this.outputQuery,true)
        listToAdd[i].selected = false;
      }
      this.selectedAll = false;
      if(this.filteredInputList.length === 0) this.inpuQuery = '';
      this.filterInputList(this.inpuQuery);
      this.filterInputList(this.outputQuery,true);
      this.exportOutputString();
    } else {
      const pushingItems = setInterval(()=>{
        if(i<n) {
          this.outputList.push(listToAdd[i]);
          this.inputList.splice(this.inputList.indexOf(listToAdd[i]),1)
          this.filterInputList(this.inpuQuery);
          this.filterInputList(this.outputQuery,true)
          listToAdd[i].selected = false;
          i++;
        } else {
          clearInterval(pushingItems);
          // TODO: uncheck SELECT ALL
          // TODO2: filteredInputList.length === 0 => clear query
          this.selectedAll = false;
          if(this.filteredInputList.length === 0) this.inpuQuery = '';
          this.filterInputList(this.inpuQuery);
          this.filterInputList(this.outputQuery,true);
          this.exportOutputString();
        }
      },10);
    }



  }

  public restSelection(): void {
    this.outputList = [];
    this.resetQuery(false);
    this.resetQuery(true);
    this.filterInputList(this.outputQuery,true);
    this.exportOutputString();
    this.setList();
  }

  resetQuery(output?:boolean):void {
    if(output) {
      this.outputQuery = "";
      this.filterInputList(this.inpuQuery,true);
    } else {
      this.inpuQuery = "";
      this.filterInputList(this.inpuQuery);
    }
  }

  filterInputList(query: string, output?: boolean): void {
    if(output) {
      this.filteredOutputList = this.searchPipe.transform(this.outputList,'display',query);
      this.outputFilteredNo = this.filteredOutputList.length;
    } else {
      this.filteredInputList = this.searchPipe.transform(this.inputList,'display',query);
      this.inputFilteredNo = this.filteredInputList.length;
    }

  }

  exportOutputString(): void {
    let outputString: string[] = [];
    this.outputList.forEach(el=>{
      outputString.push(el.value);
    });
    this.exportOutputList.emit(outputString);
  }



  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  selectAll(selectedAll: boolean): void {
    this.filteredInputList.forEach(el => el.selected = !selectedAll);
  }
  selectAllOutput(selectedAllOutput: boolean): void {
    this.outputList.forEach(el=>el.selected = !selectedAllOutput);
  }

  getTotalNumber(output?:boolean): number {
    return (output ? this.outputList.length : this.inputList.length);
  }

  getFilteredNumber(output?:boolean): number {
    return (output? this.filteredOutputList.length : this.filteredInputList.length);
  }

  getSelectedNumber(output?:boolean): number {
    return (output? this.outputList.filter(i=>i.selected).length : this.inputList.filter(i=>i.selected).length);
  }

}
