import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-signing-callback',
    template: `<div class="spinner-container black">
    <div class="loading-spinner"></div>
  </div>`
})

export class SigninRedirectCallbackComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        // debugger;


        // alert("SIGNIN CALLBACK");
        this.authService.completeLogin().then(user => {
            let returnRoute = user?.state || '/';
            this.router.navigate([returnRoute], { replaceUrl: true });

        }).catch(err => {
            console.log(err);
            this.authService.silentSignin();
            // .then(logged => {
            //     console.log(logged);
            //     this.router.navigate(['/'], { replaceUrl: true });
            // });

        });
    }
}
