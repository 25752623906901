import { Component, Input, OnInit } from '@angular/core';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { UserTypesData } from 'projects/nine-gold-lib/src/lib/models/user';

@Component({
  selector: 'user-role-icon',
  templateUrl: './user-role-icon.component.html',
  styleUrls: ['./user-role-icon.component.scss']
})
export class UserRoleIconComponent implements OnInit {
  @Input() userRole: UserTypesData;
  @Input() isGlobalAdmin: boolean = false;
  globalAdminIcon = faShieldAlt;
  text: string;

  constructor() { }

  ngOnInit() {
    this.text = (this.isGlobalAdmin ? this.userRole.text + ' / Global Admin' : this.userRole.text);
  }

}
