import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()

export class BaseService {

  constructor(private http: HttpClient) { }

  fakeAPI(url: string): Observable<any> {
    return new Observable<null>();
  }

  getAPI(url: string): Observable<any> {
    const headers = new Headers({'Content-Type': 'application/json'});
    return this.http.get(url)
      .pipe(map((response: Response) => response),
      catchError(this.handleError));
  }
  private handleError(error: Response) {
    return throwError(error);
  }
}
