import { Injectable, ElementRef } from '@angular/core';
import { IFormField, InputTypes, ValidationRuleTypes } from '../models/formField';
// import { FormResultMessageFormat } from '../shared/notification/notification';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
// import { element } from 'protractor';
import { Validators, FormGroup, AbstractControl, FormControlName, Validator } from '@angular/forms';
// import { NotificationService } from '../shared/notification/notification.service';
import { ServerValidators } from './server.validator';
import { CustomValidators } from './custom.validator';
// import { request } from 'http';
import { AppconfigService } from './appconfig/appconfig.service';
import { NotificationService } from '../shared/notification/notification.service';
import { FormResultMessageFormat } from '../shared/notification/notification';

/* The call will provide

1. Create form form with fields
2. Get fields from API
3. Get remaining fields data from JSON

*/
/** */
@Injectable({
  providedIn: 'root'
})
export class FormsService {
  errorMessage: string;

  private messageSource = new BehaviorSubject<string>('');
  currentMessage = this.messageSource.asObservable();

  constructor(private http: HttpClient,
    private notificationService: NotificationService,
    private appConfig: AppconfigService
    ) { }


    changeMessage(message: string) {
      this.messageSource.next(message)
    }



  getFormValidation(formLocation: string, inputForm: IFormField[]): Observable<IFormField[]> {
    return this.http.get<IFormField[]>(formLocation)
      .pipe(
        map(data => {
          data = this.mapForms(data, inputForm);
          return data;
        } ),
        catchError(this.handleError)
      );
  }

  getErrorMessages(inputForm: IFormField[]): any {
    // change return type to IFieldErrors[IFieldError]
    let errorMessages = {};
    inputForm.forEach(element => {
      let key = element.inputName;
      if(element.validationRules && element.validationRules.length > 0) {
        errorMessages[key] = {};
        element.validationRules.forEach(rule => {
          let type = rule.type.toLowerCase();

          if(type !== 'required') {
            errorMessages[key][rule.type.toLowerCase()] = rule.errorMessage;
          } else {
            element.requiredErrorMessage = rule.errorMessage;
          }

        });
      }
    });
    return errorMessages;
  }


  convertToReactiveForm(inputForm: IFormField[]): any {
    let outputForm = {};
    inputForm.forEach(element => {
      let key = element.inputName;
      let value = element.value;
      let validationRules = [];
      if (element.validationRules && element.validationRules.length > 0) {
        validationRules = this.setValidationRules(element.validationRules);
        outputForm[key] = [value, validationRules];
      } else {
        outputForm[key] = [value];
      }

    });
    return outputForm;
    // Receive form settings object (IFormFiledsp[]) and return opbect for form builder
  }

  ruleNeedsParameter(ruleType: ValidationRuleTypes): boolean {
    return (
      ruleType === ValidationRuleTypes.pattern ||
      ruleType === ValidationRuleTypes.maxLength ||
      ruleType === ValidationRuleTypes.minLength ||
      ruleType === ValidationRuleTypes.max ||
      ruleType === ValidationRuleTypes.min
    )
  }

  public createForm(formName: string, inputForm: IFormField[]): any {
    let formValidatotionRules = this.appConfig.getValidationRules(formName);
    let mappedForm = this.mapForms(formValidatotionRules, inputForm);
    return mappedForm
  }

  getForm(location: string): Observable<IFormField[]> {
    return this.http.get<IFormField[]>(location)
      .pipe(
        tap(data => {
          // console.log('All: ' + JSON.stringify(data))
        }
      ),
        catchError(this.handleError)
      );
  }

  private mapForms(formData: IFormField[], inputForm: IFormField[]): IFormField[] {
    inputForm.forEach(element => {
      let item = formData.find(i => i.inputName === element.inputName);
      if (item) {
        if(element.inputType === InputTypes.select || element.inputType === InputTypes.radio) {
          element["options"] = item.options || null;
        }
        element["validationRules"] = item.validationRules || null;

        if(item.validationRules) {
          item.validationRules.forEach(rule => {
            if (rule.type.toString().toLocaleLowerCase() === 'maxlength') {
              element.characterLimit = rule.parameter;
            }
            if (rule.type.toString() === 'maxFileSize') {
              element['maxFileSize'] = rule.parameter;
            }
            if (rule.type.toString() === 'extension') {
              element['allowedExtensions'] = rule.parameter;
            }
            if(rule.type.toString() === "required") {
              element['required'] = true;
              element['requiredErrorMessage'] = rule.errorMessage;
            } else {
              element['errorMessagesList'] = element['errorMessagesList'] || {};
              element['errorMessagesList'][rule.type.toLowerCase()] = rule.errorMessage;
            }
          });
        }



      }
      element.value = item.value || element.value;

    });
    return inputForm;
  }


  postForm(ApiUrl: string, formData: any, token = ''): Observable<Object> {
    return this.http.post(ApiUrl, formData, {withCredentials: true } ).pipe(
      tap(data => {
        // console.log(JSON.stringify(data))
      }),
      catchError(this.handleError)
    );
  }

  register(ApiUrl: string, formData: any): Observable<Object> {
    return this.http.post(ApiUrl, formData).pipe(tap(data => console.log(JSON.stringify(data))),
    // err => {return err}
    catchError(this.handleError)
    );
  }

  formSuccess(message: string, messageFormat: FormResultMessageFormat, form: FormGroup, modalToClose?: string, redirect?: string): void {
    // form.reset();
    this.notificationService.success('Hello World','This is a success !', FormResultMessageFormat.modal, 3000, null);

  }

  private setValidationRules(rulesFromSettings: any[]): any[] {
    let validatorsArray: any[] = [];
    rulesFromSettings.forEach(
      rule => {
        if (rule.type === ValidationRuleTypes.passwordValidator) {
          validatorsArray.push(CustomValidators.patternValidator(/\d/, { hasNumber: true }));
          validatorsArray.push(CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }));
          validatorsArray.push(CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }));
          validatorsArray.push(CustomValidators.patternValidator(/[\W_]/, { hasSpecialCharacters: true }))
        }
        else if (rule.type === ValidationRuleTypes.passwordConfirm) {
          validatorsArray.push(CustomValidators.sameValue(rule.parameter));
        }
        else if (rule.type === ValidationRuleTypes.extension || rule.type === ValidationRuleTypes.maxFileSize) {
          // skip
        }
        else if (this.ruleNeedsParameter(rule.type)) {
          validatorsArray.push(Validators[rule.type](rule.parameter));
        } else {
          validatorsArray.push(Validators[rule.type]);
        }
      }
    )
    // it will loop through forSettings[validatorRules] and push to validatorsArray
    return validatorsArray;
  }

  formError(err: HttpErrorResponse, form: FormGroup, formSettings: {}, inlineElement?: ElementRef): void {
    // console.log(err);
    // this.notificationService.changeMessage("TEST");
    let errorMessage = '';
    if (err.error) {
      if (err.status >= 300 && err.status < 400) {
        // for 300 errors just show standard popup
        // and log error
      }
      if (err.status >= 400 && err.status < 500) {
        // 4XX errors
        // 400 - bad request
        if (err.status === 400) {
          // 400 error - validation
          // show popup and inline errors
          // no need to log error
          let title = "Validation Error";
          let friendlyMessage = err.error.friendlyMessage || "Validation Error";
          this.notificationService.error(title, friendlyMessage, FormResultMessageFormat.popup, 5000, null);

          if (err.error.validationErrors) {
            Object.keys(err.error.validationErrors).forEach(key => {
              console.log(key);

              let inputControl = form.get(key);
              formSettings[key]['errorMessagesList'] = formSettings[key]['errorMessagesList'] || {};
              formSettings[key]['errorMessagesList']['serverError'] = err.error.validationErrors[key];

              if (formSettings[key]['validationRules']) {
                let validationRules = this.setValidationRules(formSettings[key]['validationRules']);
                validationRules.push(ServerValidators.differentValue(form.get(key).value))
                form.get(key).setValidators(validationRules);
              } else {
                form.get(key).setValidators(ServerValidators.differentValue(form.get(key).value))
              }
              form.get(key).setErrors({ serverError: err.error.validationErrors[key] });
              form.get(key).markAsPristine();

            });
          }


        } else if(err.status === 401) {
          // 401 - unathorized - usually authentication error
          // show popup or inline error (for login form)
          // need to watch for form valid if inline error and stop when valid
          let title = "Authentication Error";
          let friendlyMessage = err.error.friendlyMessage || "Authentication Error";
          if (inlineElement) {
            this.notificationService.error(title, friendlyMessage, FormResultMessageFormat.inline, 3000, inlineElement);
            this.watchForValidForm(form);
          } else {
            this.notificationService.error(title, friendlyMessage, FormResultMessageFormat.popup, 3000, null);
          }
        } else if(err.status === 403) {
          // 403 - forbidden - user have no rights to open that page (use guards here)
          // this should be stopped by guard, but if for some reason page opens but then error show while loading resources, this needs to show
          // show popup
          // log
        } else if(err.status === 404) {
          // 404 - not found (will redirect to homepage with popup = not found)
        } else if(err.status === 408) {
          // 408 Request Timeout
          // popup server timeout
        } else if(err.status === 413) {
          this.notificationService.error('Total upload exceeds maximum size','',FormResultMessageFormat.popup,8000,null);
        } else if(err.status === 406) {
          let title = "Validation Error";
          let friendlyMessage = err.error.friendlyMessage || "Validation Error";
          this.notificationService.error(title,friendlyMessage,FormResultMessageFormat.popup,8000,null);
        }
        // 401 - unathorized
        // 402 - payment required - not needed
        // 403 - forbidden - user have no rights to open that page (use guards here)
        // 404 - not found (will redirect to homepage with popup = not found)
        // 405 - method not allowed
        // 406 - not accetable
        // 407 - Proxy Authentication Required
        // 408 Request Timeout
        // 413 Payload Too Large
      } else if (err.status >= 500) {
        let title = "Server Error";
        let friendlyMessage = err.error.friendlyMessage || "Server Error";
        this.notificationService.error(title, friendlyMessage,FormResultMessageFormat.popup,8000,null);
        // 5XX errors
        // need to show server error and send every error to log API

      }
    }
  }

  private watchForValidForm(form: FormGroup) {
    // TODO
    // watch until form is valid
    // then remove inline errors and stop watching
    // and destro watcher
  }



  handleError(err: HttpErrorResponse) {
// in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    // console.log(err);

    console.log(err.status);

    if (err.error) {
      if (err.status === 400 && err.error.validation_errors) {

        // let friendlyMessage = err.error.friendlyMessage || "Validation Error";
        // this.notificationService.error(friendlyMessage, friendlyMessage, FormResultMessageFormat.popup, 3000);

        // throw Error(friendlyMessage);

      }
      // A client-side or network error occurred. Handle it accordingly.
      if(err.error.validation_errors) {
        errorMessage = err.error.friendlyMessage;
      }
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    // console.error(errorMessage);
    return throwError(err);
    // return err;
  }
}
