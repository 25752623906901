import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Injector, OnInit } from '@angular/core';
import { FilterPipe } from '../pipes/filter.pipe';
import { OrderByPipe } from '../pipes/orderBy.pipe';
import { AppliedFilters, ButtonTypes, ColumnFilter, columnFilterTypes, ColumnTypes, ListParams, ListUiParams, NgListButton, NgListColumns, NgListDataCell, NgListDataRow, TypesOfCompare } from '../shared/listComponents/ng-list/ng-list-data';
import { ApiService } from './api.service';
import { AppSettingsService } from './app-settings.service';
import { ProductService } from './product.service';
import { UserService } from './user.service';


type listApiDataType = any;


@Injectable({
  providedIn: 'root'
})
export class ListsService {

  public lp: ListUiParams = ListParams.defaultListParams();

  uiSortFilterPage = false;
  hardLoadTimestamp: Date;
  apiHardLoadTimeout: number;
  // defines list sorting, filtering and pagination
  // default: false - server side parameters
  // if set true, will be done in UI,

  public listApiName: string;
  public ApiFunctionName: string;
  public ApiParameter: string = '';
  ApiUrl: string; // URL for received from settings service based on listApiName


  public listParameters = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "",
      order: ""
    }
  }

  public resutlsText = "";

  columns: NgListColumns[] = [];
  listData: NgListDataRow[] = [];
  rawListData: NgListDataRow[] = [];

  itemsPerPageList = [
    {
      value: 10,
      text: 10
    },
    {
      value: 25,
      text: 25
    },
    {
      value: 50,
      text: 50
    },
    {
      value: 100,
      text: 100
    }
  ];

  apiResutls: listApiDataType[] = [];

  appliedFilters: ColumnFilter[] = [];

  parametersUpdated: EventEmitter<any> = new EventEmitter<any>();
  loadResultsStarted: EventEmitter<any> = new EventEmitter();
  loadResultsEnded: EventEmitter<any> = new EventEmitter();

  constructor(
    // public injector: Injector,
    public userService: UserService,
    public settings: AppSettingsService,
    public sortPipe: OrderByPipe,
    public filterPipe: FilterPipe,
    public http: HttpClient,
    public productService: ProductService,
    public apiService: ApiService
    ) {
      this.apiHardLoadTimeout = this.settings.apiHardLoadTimeout;
      this.setInitialData();
      this.columns = this.CreateColumns();
      // this.updateListParams();
      this.setApiName();
      this.ApiUrl = this.settings.getApi(this.listApiName);
      // this.LoadTableData(true);
      this.customOnInit();
  }

  public highlightRow(id: string) {
    let hRow = this.listData.find(row => row.rowIdentifier === id);
    if(hRow) hRow.highlighted = true;
  }

  public unHighlightRow(id: string) {
    let hRow = this.listData.find(row => row.rowIdentifier === id);
    if(hRow) hRow.highlighted = false;
  }

  updateListParams(): void {
    this.listParameters = {
      numberOfPages: 1,
      currentPage: 1,
      itemsPerPage: 10,
      filters: [],
      sorting: {
        field: "fullName",
        order: "a"
      }
    }
  }

  setInitialData(): void {
    this.setApiDataType();
    this.updateListParams();
    this.setSortSettings();
    this.setInitialColumns();
  }

  customOnInit(): void {
    // placeholder
  }

  setInitialColumns(): void {
    // placeholder
  }

  setSortSettings(): void {
    // this.uiSortFilterPage = true;
  }

  setApiName(): void {
    // placeholder
    // this.listApiName = '...'; - replace this line
  }

  setApiDataType(): void {
    // type listApiDataType = any;
  }

  private get_text_width(txt: string, font: string):number {
    var element = document.createElement('canvas');
    var context = element.getContext("2d");
    context.font = font;
    return context.measureText(txt).width;
  }

  inlineFilter(list: any[], filters: AppliedFilters[]): any[] {
    return this.filterPipe.transform(list, filters);
  }

  inlineSort(list: any[], sortField: string, sortOrder: string): any[] {
    return this.sortPipe.transform(list, sortField, sortOrder);
  }

  public addColumnClasses(columns: NgListColumns[]): NgListColumns[] {
    columns.forEach(col => {
      col.columnClasses = (col.columnClasses || '') + " " + (col.filters ? "filters " : "") +
                          (col.sortable ? "sortable " : "") +
                          (col.columnType === ColumnTypes.date ? "date-column " : "");

      if(col.columnType === ColumnTypes.buttons) {
        col.columnClasses += 'actions-column ';

        // TODO: remove below classes - not needed for dropdown only buttons
        let columnTextWidth = this.get_text_width(col.columnText, "16px NunitoSans-Bold");

        if(col.buttons.length === 1 && columnTextWidth < (112 - 20)) {
          col.columnClasses += "one-button";
        } else if(col.buttons.length <= 2 && columnTextWidth < (128 - 20)) {
          col.columnClasses += "two-buttons";
        } else if(col.buttons.length <= 3 && columnTextWidth < (144 - 20)) {
          col.columnClasses += "three-buttons";
        }
      }
      if(col.columnType === ColumnTypes.user_role) {
        col.columnClasses += "role-container-column";
      }
      col.columnClasses.trim();
    });
    return columns;
  }

  CreateColumns(): NgListColumns[] {

    //  Creating columns object based on initial data
    //  TODO: move filters data to project setup (eventually these needs to be received from API) - the filters are generator types

    let columns: NgListColumns[] = [];

    columns = this.addColumnClasses(this.columns);
    return columns;
  }

  async LoadTableData(fromApi = false):Promise<void> {

    setTimeout(() => {
      this.loadResultsStarted.emit();
    }, 100);


    this.setApiName();
    this.ApiUrl = (this.ApiParameter ? this.settings.getApi(this.listApiName,this.ApiParameter) : this.settings.getApi(this.listApiName));
    if(!this.uiSortFilterPage) {
      if(this.ApiFunctionName) {
        await this.apiService[this.ApiFunctionName]((this.ApiParameter ? this.ApiParameter : null)).subscribe((results:listApiDataType) => {
          this.listData = this.MapDataNew<listApiDataType>(results, this.columns);
          this.loadResultsEnded.emit();
          this.parametersUpdated.emit(this.listParameters);
        });
      } else {
        await this.http.get(this.ApiUrl,{withCredentials: true})
        .subscribe((results:listApiDataType[]) => {
        this.listData = this.MapDataNew<listApiDataType>(results, this.columns);
      });
      }

    } else {
      let timeNow = new Date();
      let timeOut: boolean = !this.hardLoadTimestamp || (timeNow.getTime() - this.hardLoadTimestamp.getTime() >= this.apiHardLoadTimeout);
      if(fromApi || timeOut) {
        if(this.ApiFunctionName) {
          await this.apiService[this.ApiFunctionName]((this.ApiParameter ? this.ApiParameter : null)).toPromise().then(
            (results:listApiDataType[]) => {
              this.apiResutls = results;
              this.hardLoadTimestamp = new Date();
              this.rawListData = this.MapDataNew<listApiDataType>(results,this.columns);
            }
          )
        } else {
          await this.http.get(this.ApiUrl,{withCredentials: true})
          .toPromise().then((results:listApiDataType[]) => {
            this.apiResutls = results;
            this.hardLoadTimestamp = new Date();
            this.rawListData = this.MapDataNew<listApiDataType>(results, this.columns);
          });
        }

      }
      let unorderedList = this.inlineFilter(this.rawListData, this.listParameters.filters);
      this.listParameters.numberOfPages = Math.ceil(unorderedList.length / this.listParameters.itemsPerPage);
      this.listData = this.inlineSort(unorderedList, this.listParameters.sorting.field, this.listParameters.sorting.order);
      if(this.columns.some(col=>col.filters?.filterType === columnFilterTypes.autocomplete)) {

      }
      let colIndex: number = 0;
      this.columns.forEach(col => {
        if(col.filters?.filterType === columnFilterTypes.autocomplete) {
          let options: string[] = [];

          this.listData.forEach(d=>{
            if(!options.includes(d.listRow[colIndex].value)) {
              options.push(d.listRow[colIndex].value);
            }

          });
          col.filters.autocompleteList = options;
        }
        colIndex++;
      })
      setTimeout(() => {

        this.loadResultsEnded.emit();
        this.parametersUpdated.emit(this.listParameters);
      }, 200);
    }

  }

  MapDataNew<T>(resultsData: T[], columns: NgListColumns[]): NgListDataRow[] {

    function pushNewButton(inputButton: NgListButton, buttonsList: NgListButton[]) {
      let cellBtn: NgListButton = inputButton;
      cellBtn.subType = inputButton.subType || null;
      cellBtn.buttonCustomClass = inputButton.buttonCustomClass || "";
      cellBtn.disabled = inputButton.disabled;
      buttonsList.push(cellBtn);
    }
    let rows: NgListDataRow[] = [];

    resultsData.forEach(resultRow => {
      let row = <NgListDataRow>{};
      row['listRow'] = [];

      columns.forEach(column => {
        let element = <NgListDataCell>{};
        element.columnName = column.columnName;
        element.columnOrder = column.columnOrder;
        element.type = column.columnType;
        element.notVisible = column.notVisible || undefined;
        element.clickForInfo = column.clickForInfo;

        if(column.specialHandling) {
          this.specialHandling(element,resultRow);
        } else {

          if(column.columnType === ColumnTypes.buttons) {
            element.buttons = [];
              column.buttons?.forEach(btn => {
                if(!btn.permission || this.userService.getPermissions()[btn.permission]) {
                  let currentRole = (resultRow['roles'] ? this.userService.getHighestRoleFromArray(resultRow['roles']) : null);



                  if(btn.showButtonFunction) {
                    if(this[btn.showButtonFunction] && this[btn.showButtonFunction](resultRow)) {
                      let cellBtn = <NgListButton>{};
                      cellBtn.buttonText = btn.buttonText;
                      cellBtn.type = btn.type;
                      cellBtn.subType = btn.subType || null;
                      cellBtn.buttonCustomClass = btn.buttonCustomClass || "";
                      cellBtn.disabled = btn.disabled;
                      element.buttons.push(cellBtn);
                    }

                  } else {
                    if(btn.type === ButtonTypes.renew) {
                      if(this.canRenew(resultRow)) {
                        let cellBtn = <NgListButton>{};
                        cellBtn.buttonText = btn.buttonText;
                        cellBtn.type = btn.type;
                        cellBtn.subType = btn.subType || null;
                        cellBtn.buttonCustomClass = btn.buttonCustomClass || "";
                        cellBtn.disabled = btn.disabled;
                        element.buttons.push(cellBtn);
                      }
                    } else if(btn.type === ButtonTypes.demo) {
                      if(this.canDemo(resultRow)) {
                        let cellBtn = <NgListButton>{};
                        cellBtn.buttonText = btn.buttonText;
                        cellBtn.type = btn.type;
                        cellBtn.subType = btn.subType || null;
                        cellBtn.buttonCustomClass = btn.buttonCustomClass || "";
                        cellBtn.disabled = btn.disabled;
                        element.buttons.push(cellBtn);
                      }
                    } else if(btn.type === ButtonTypes.upgrade) {
                      if(this.canUpgrade(resultRow)) {
                        let cellBtn = <NgListButton>{};
                        cellBtn.buttonText = btn.buttonText;
                        cellBtn.type = btn.type;
                        cellBtn.subType = btn.subType || null;
                        cellBtn.buttonCustomClass = btn.buttonCustomClass || "";
                        cellBtn.disabled = btn.disabled;
                        element.buttons.push(cellBtn);
                      }
                    } else if(btn.type === ButtonTypes.license_add) {
                      if(this.canAddLicense(resultRow)) {
                        pushNewButton(btn, element.buttons);
                      }
                    } else if(btn.type === ButtonTypes.license_paid) {
                      if(this.canMarkLicensePaid(resultRow)) {
                        pushNewButton(btn, element.buttons);
                      }
                    } else if(btn.type === ButtonTypes.license_renew) {
                      if(this.canRenewLicense(resultRow)) {
                        pushNewButton(btn, element.buttons);
                      }
                    } else if(!btn.userRole || btn.userRole.includes(currentRole.name)) {
                      let cellBtn = <NgListButton>{};
                      cellBtn.buttonText = btn.buttonText;
                      cellBtn.type = btn.type;
                      cellBtn.subType = btn.subType || null;
                      cellBtn.buttonCustomClass = btn.buttonCustomClass || "";
                      cellBtn.disabled = btn.disabled;
                      element.buttons.push(cellBtn);
                    }

                  }

                }

              });
              row.actions = element.buttons;
          } else if(column.columnType === ColumnTypes.user_role) {
            // TODO: Maybe instead of having this if statement here
            // TODO: just create function customColumnHandling() which would handle column type user_role in this example
            // TODO: the function would just have placeholder in main parent lists.service and implementation in users-list.service

            element.userRole = this.userService.getHighestRoleFromArray(resultRow[column.columnName]);
            element.isGlobalAdmin = resultRow[column.columnName].includes("administrator");
            element.value = element.userRole.name;
          } else if(column.columnType === ColumnTypes.status) {
            element.productStatus = this.productService.getStatusIcon(resultRow[column.columnName]);
          } else if(column.columnType === ColumnTypes.type) {
            element.productType = this.productService.getTypeItem(resultRow[column.columnName]);
          } else if(column.columnType === ColumnTypes.date) {
            if(resultRow[column.columnName] === null) {
              element.valueNotApplicable = true;
              element.value = "N/A";
              element.display = "N/A";
              if(column.displayFunction) {
                element.display = this[column.displayFunction](element.value);
              }
              element.columnClasses += ' date-not-applicable';
              element.tdHtml = column.tdHtml;
            } else {
              element.value = resultRow[column.columnName];
              element.display = resultRow[column.columnName];
            }
          } else {

            if(resultRow[column.columnName] === "N/A") element.valueNotApplicable = true;
            if(column.columnClasses.includes('centered')) element.centered = true;

            let tempValue = resultRow;

            if(column.customMapping) {
              column.customMapping.forEach(x => {
                tempValue = (tempValue ? tempValue[x] : null);
              });
              element.value = tempValue;
              element.display = tempValue?.toString() || '';

            } else if(column.combinedValue) {

              let test = column.combinedValue.replace(/%\w+%/g, (str) => {
                // return resultRow[str.substr(1,str.length-2)];
                return resultRow[str.substr(1,str.length-2)];
              });
              element.value = test;
              element.display = test.toString();

            } else {

              element.value = resultRow[column.columnName];
              element.display = resultRow[column.columnName];

            }

            element.columnClasses = column.columnClasses;
            element.tdHtml = column.tdHtml;

            if(column.displayNotAppplicable) {
              if(element.value === column.displayNotAppplicable) {
                element.display = "N/A";
                element.valueNotApplicable = true;
              }
            }

            if(column.displayFunction) {
              element.display = this[column.displayFunction](element.value);
            }

            if(column.tooltipText) {
              if(element.display === "N/A") {
                element.tooltipText = null
              } else {
                element.tooltipText = column.tooltipText.replace(/%\w+%/g, (str) => {
                  return resultRow[str.substr(1,str.length-2)];
                });
              }
            }

            if(column.rowIdentifier) {
              row.rowIdentifier = element.value;

            }
            if(this.HighlightCell(element.value)) element.highlighted = true;
          }

        }


        row.listRow.push(element);
      });

      row.listRow.sort((a, b) => a.columnOrder - b.columnOrder );
      rows.push(row);

    });

    return rows;
  }

  specialHandling(el: NgListDataCell, row: listApiDataType): void {
    // Placeholder for special handling function defined by list type
  }

  canRenew(row: listApiDataType): boolean {
    // This is placeholder for child service implementation
    return false;
  }

  canAddLicense(row: listApiDataType): boolean {
    // This is placeholder for child service implementation
    return false;
  }
  canRenewLicense(row: listApiDataType): boolean {
    // This is placeholder for child service implementation
    return false;
  }
  canMarkLicensePaid(row: listApiDataType): boolean {
    // This is placeholder for child service implementation
    return false;
  }

  // canQuote(row: listApiDataType): boolean {
  //   // This is placeholder for child service implementation
  //   return false;
  // }

  canDemo(row: listApiDataType): boolean {
    // This is placeholder for child service implementation
    return false;
  }

  canUpgrade(row: listApiDataType): boolean {
    // This is placeholder for child service implementation
    return false;
  }

  HighlightCell(value: any): boolean {
    // This is placeholder for child service implementation
    return false;
  }

  MapData(resultsData: any[], columns: NgListColumns[]): NgListDataRow[] {
    // This is placeholder for child service implementation
    let rows: NgListDataRow[] = [];
    return rows;
  }

  GetResultsText(): string {
    return this.resutlsText;
  }

  SetResultsText(totalResults: number): void {
    this.resutlsText = `<span class='text'>Showing:</span>
                            <span class='value'>
                              ${this.listParameters.itemsPerPage * (this.listParameters.currentPage - 1) + 1}
                              &hellip;
                              ${((this.listParameters.itemsPerPage * this.listParameters.currentPage) < totalResults ? this.listParameters.itemsPerPage * this.listParameters.currentPage : totalResults)}
                            </span>
                            <span class='text'>of total</span><span class='value'>${totalResults}</span>`;
  }


  LoadPage(pageNumer: number, fromApi = false): void {
    this.listParameters.currentPage = pageNumer;
    this.LoadTableData(fromApi);
  }


  // ***********************************************

  // Filters Functionality

  GetAppliedFilters(): ColumnFilter[] {
    return this.appliedFilters;
  }

  SetFilterFromColumn(column: NgListColumns): void {
    // TODO: Change to allow multiple filters
    // Right now this only allows one filter per search query
    // Before adding more filters make sure API allows for this
    if(column.filters.optionSelected !== null) {
      let f:AppliedFilters = this.listParameters.filters.find(f => f.field === column.columnName);
      if(!!f) {
        f.value = column.filters.optionSelected;
        this.appliedFilters.find(f => f.filterId === column.filters.filterId).optionSelected = column.filters.optionSelected;
      } else {
        this.listParameters.filters.push({field: column.columnName,value: column.filters.optionSelected, type: column.filters.compareType || TypesOfCompare.equal});
        this.appliedFilters.push(column.filters);
      }

      // this.appliedFilters = [];
      // this.appliedFilters.push(column.filters);
    } else {
      this.listParameters.filters = this.listParameters.filters.filter(f => f.field !== column.columnName);
      this.appliedFilters = this.appliedFilters.filter(f => f.filterId !== column.filters.filterId);
      // let i = this.listParameters.filters.find(column.filters);
      // if(i > -1) {
      //   this.listParameters.filters.splice(i,1);

      // }
      // let j = this.appliedFilters.indexOf(column.filters);
      // if(j > -1) this.appliedFilters.splice(j,1);
      // this.listParameters.filters = [];
      // this.appliedFilters = [];
    }
    // this.appliedFilters = this.listParameters.filters;
    this.LoadPage(1);
  }

  clearFilter(filter: ColumnFilter): void {
    let col = this.columns.find(col => col.filters?.filterName === filter.filterName);
    col.filters.optionSelected = null;
    this.appliedFilters = this.appliedFilters.filter(f => f.filterId !== filter.filterId);
    this.listParameters.filters = this.listParameters.filters.filter(f => f.field !== col.columnName);
    // this.appliedFilters = [];
    // this.listParameters.filters = [];
    this.LoadPage(1);
  }

  // ***********************************************

  // Sorting Functionality

  GetSorting(): any {
    return this.listParameters.sorting;
  }

  UpdateSort(columnName: string): void {
    if(this.listParameters.sorting.field === columnName) {
      this.listParameters.sorting.order = (this.listParameters.sorting.order === "a" ? "d" : "a");
    } else {
      this.listParameters.sorting.field = columnName;
      this.listParameters.sorting.order = "a";
    }
    this.LoadPage(1);
  }

  UpdateSortAndOrder(columnName: string, order: string): void {
    this.listParameters.sorting.field = columnName;
    this.listParameters.sorting.order = order;
    this.LoadPage(1);
  }

  GetApiRowById(propertyName: any, value: any): listApiDataType {
    return this.apiResutls.find(x => x[propertyName] === value);
  }


  // ***********************************************

  // Items Per Page dropdown functionality

  GetItemsPerPageList(): any[] {
    return this.itemsPerPageList;
  }

  GetItemsPerPage(): number {
    return this.listParameters.itemsPerPage;
  }

  SetItemsPerPage(itemsPerPage: number): void {
    this.listParameters.itemsPerPage = itemsPerPage;
    this.LoadPage(1);
    // this.parametersUpdated.emit(this.listParameters);
  }

  // ***********************************************


  // Pagination Functions

  SetPaginationParams(currentPage: number): void {
    this.listParameters.currentPage = currentPage;
    // this.listParameters.numberOfPages = totalPages;
    this.LoadPage(currentPage);
    // this.parametersUpdated.emit(this.listParameters);
  }

  GetCurrentPage(): number {
    return this.listParameters.currentPage;
  }

  GetTotalPages(): number {
    return this.listParameters.numberOfPages;
  }

  // ***********************************************


  // Getting Table data

  GetTableColumns(): NgListColumns[] {
    return this.columns;
  }

  GetTableRows(): NgListDataRow[] {
    return this.listData;
  }

  GetRawDataById(idName: string, idValue: string): listApiDataType {
    return this.apiResutls.find(x => x[idName] === idValue);
  }

}
