import { Injectable } from '@angular/core';
import { ColsUI, Density, formFieldUI, formsUI, GA_settings, generatTableUI, tableUI, UiSettings } from '../models/ui-settings';
import { ApiUser } from '../models/user';
import { NgListColumns } from '../shared/listComponents/ng-list/ng-list-data';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UiSettingsService {

  colorMode = 'default';

  globalAdmin: GA_settings = {};
  forms: formsUI[];

  uiTables: tableUI[] = [];
  generalTableUI: generatTableUI = {
    filters: false,
    lockFirst: false,
    dens: Density.default
  }

  constructor(
    private apiService: ApiService,
    // private userService: UserService
  ) {
    // this.readFromStorate();
    this.readFromUserService()
  }

  addFormFieldValue(formName: string, fieldName: string, value: string): void {
    let form = this.forms?.find(f=>f.formName === formName);
    if(!!form) {
      let field:formFieldUI = form.fields.find(f=>f.fieldName === fieldName);
      if (!!field) {
        field.prefaredValue = value;
      } else {
        field = {fieldName: fieldName,prefaredValue: value};
        form.fields.push(field);
      }
    } else {
      let field:formFieldUI = {fieldName: fieldName,prefaredValue: value};
      let fields:formFieldUI[] = [];
      fields.push(field);
      if(!this.forms) this.forms = [];
      this.forms.push({formName: formName,fields:fields});
    }
    this.saveToStorage();
    this.saveToApi();
  }

  getFormFieldValue(formName: string, fieldName: string): string {
    let form = this.forms?.find(f=>f.formName === formName);
    if(form) {
      return form.fields.find(f=>f.fieldName === fieldName)?.prefaredValue || null;
    } else return null;
  }

  convertColumnsToTable(columns: NgListColumns[]): ColsUI[] {
    // Converting data from columns (showHide, order, columnName) to UI Tables list
    let uiTables: ColsUI[] = [];
    columns.forEach(col => {
      if(col.canShowHide) {
        uiTables.push({
          name: col.columnName,
          show: !col.notVisible,
          order: col.columnOrder
        });
      }
    });
    return uiTables;
  }

  checkIfTableInSettings(tableName: string): boolean {
    return !!this.uiTables.find(el => el.table === tableName);
  }

  getTableData(tableName: string): tableUI {
    return this.uiTables.find(el => el.table === tableName);
  }

  updateTableFromUI(tableData: tableUI): void {
    // Update uiTable from list service
    let tableIndex = this.uiTables.indexOf(this.uiTables.find(el => el.table === tableData.table));
    if(tableIndex > -1) {
      // update existing
      this.uiTables[tableIndex] = tableData;
    } else {
      this.uiTables.push(tableData);
    }
    this.saveToStorage();
    this.saveToApi();
    // save to storage
    // save to API
  }




  updateGenSettings(settings: generatTableUI): void {
    this.generalTableUI = settings;
    this.saveToStorage();
    this.saveToApi();
    // save to storage
    // save to API
  }

  updateAllTablesGenSettings(settings: generatTableUI):  void {
    this.uiTables.forEach(el => {
      el.filters = settings.filters;
      el.dens = settings.dens;
      el.lockFirst = settings.lockFirst;
    });
    this.saveToStorage();
    this.saveToApi();
    // save to storage
    // save to API
  }

  private setSettings(set: UiSettings): void {
    if(set) {
      this.colorMode = set.colorMode;
      this.generalTableUI = set.generalTableUI;
      this.uiTables = set.tables;
      this.globalAdmin = set.globalAdmin || null;
      this.forms = set.forms || null;
    }

  }

  public saveSettingsFromUser(user: ApiUser): void {
    let settings: UiSettings = user.displaySettings;
    this.setSettings(settings);
  }

  private readFromUserService(): void {
    // this.userService.userUpdated.subscribe((user: ApiUser) => {
    //   let settings: UiSettings = user.displaySettings;
    //   this.setSettings(settings);
    // });
    // let settings: UiSettings = this.userService.getUserInfo().displaySettings;
    // this.setSettings(settings);
  }

  private readFromStorate(): void {
    let dataFromStorage = localStorage.getItem('uiSettings');
    if(dataFromStorage) {
      let dataObject = JSON.parse(dataFromStorage);
      this.colorMode = dataObject?.colorMode;
      this.generalTableUI = dataObject?.generalTableUI;
      this.uiTables = dataObject?.tables;

    }
  }

  public updateGA(key: string, value: any): void {
    if(!this.globalAdmin) this.globalAdmin = {};
    this.globalAdmin[key] = value;
    this.saveToStorage();
    this.saveToApi();
  }

  private saveToApi(): void {
    // console.log("SETTINGS TO API")
    let settings = {
      colorMode: this.colorMode,
      generalTableUI: this.generalTableUI,
      tables: this.uiTables,
      forms: this.forms
    }
    if(this.globalAdmin) settings['globalAdmin'] = this.globalAdmin;
    this.apiService.updateUserUI(settings).subscribe(result => {});
  }

  private saveToStorage(): void {
    let settings = {
      colorMode: this.colorMode,
      generalTableUI: this.generalTableUI,
      tables: this.uiTables,
      forms: this.forms
    }
    if(this.globalAdmin) settings['globalAdmin'] = this.globalAdmin;
    localStorage.setItem('uiSettings', JSON.stringify(settings));
  }
}
