import { Directive, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';

@Directive({
  selector: '[appNgLibHoverPopup]'
})
export class NgLibHoverPopupDirective {
  listenerFn: () => void;
  opened = false;
  cannotClose = false;
  menuParent: any;

  @ViewChild('productmenu') productmenu: ElementRef;
  @ViewChild('productLink') productLink: ElementRef;


  constructor(private elementRef: ElementRef,private renderer: Renderer2) { }

  ngOnInit(): void {
    this.menuParent = this.elementRef.nativeElement;
    this.productLink = this.elementRef.nativeElement.querySelector('a');
  }


  // @HostListener('mouseover', ['$event']) onmouseover(event) {
  //   if(window.innerWidth > 800) {
  //     document.body.click();
  //     this.cannotClose = true;
  //     if(!this.opened) {
  //       this.opened = true;
  //       this.renderer.addClass(this.elementRef.nativeElement, 'open');
  //       this.renderer.addClass(this.elementRef.nativeElement.querySelector("ul"),'show')
  //     }
  //   }
  // }

  // @HostListener('mouseout',['$event']) mouseLeave(event) {
  //   if(window.innerWidth > 800) {
  //     this.cannotClose = false;
  //     setTimeout(() => {
  //         if(this.opened && !this.cannotClose) {
  //           this.opened = false;
  //           this.cannotClose = false;
  //           this.renderer.removeClass(this.elementRef.nativeElement, 'open');
  //           this.renderer.removeClass(this.elementRef.nativeElement.querySelector("ul"),'show')
  //         }
  //     }, 2000);
  //   }
  // }


  @HostListener('document:click', ['$event']) click(event) {


      if(this.opened && (!this.menuParent.contains(event.target) || this.productLink === event.target || event.target.closest('.close-dropdown'))) {
        this.opened = false;
        this.cannotClose = false;
        this.renderer.removeClass(this.elementRef.nativeElement, 'open');
        this.renderer.removeClass(this.elementRef.nativeElement.querySelector("ul"),'show')
        // this.renderer.removeClass(this.ddmenu.nativeElement, 'show');
      } else {
        if (this.elementRef.nativeElement.contains(event.target)) {
          this.opened = true;
          this.cannotClose = true;
          this.renderer.addClass(this.elementRef.nativeElement, 'open');
          this.renderer.addClass(this.elementRef.nativeElement.querySelector("ul"),'show')
        }

      }
    }
}
