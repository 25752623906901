import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}
  
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot,) {
  //     const currentUser = this.authService.isUserAuthenticated();
  //     console.log(currentUser);
  //     if(currentUser) {
  //       return true;
  //     }

  //     this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  // }
  
  // public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   return this.authService.isUserAuthenticated().pipe(
  //     map(data => data === false ? this.router.parseUrl("/") : !!data)
  //     catchError(() => this.router.parseUrl("/")),
  //   );
  // }

  canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean>|boolean {
    return this.authService.isUserAuthenticated().pipe(map((auth) => {
        if (auth) {
            // console.log('authenticated');
            return true;
        }
        // console.log('not authenticated');
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }))
    // .first(); // this might not be necessary - ensure `first` is imported if you use it
}

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean>|boolean {
  //   return this.authService.isUserAuthenticated().pipe(map(User => {
  //     return (User) ? true : false;
  //   }));
  // }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

  //   return this.authService.isUserAuthenticated().pipe(
  //     map((data) => {
  //         if (data['ok']) {
  //           //this.router.navigate(['/overview']); //<-- remove this.
  //           return true; //<- return true if response is ok
  //         } else {
  //           // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  //           return false;  //<- return false if response is not ok
  //         }
  //       },
  //       (error: string) => {
  //         console.log(error);
  //         // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  //         return false;   //<- return false in case of error
  //       })
  //   );
  // }

}
