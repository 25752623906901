import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { ListsService } from '../../../services/lists.service';

@Component({
  selector: 'lib-ng-pagination',
  templateUrl: './ng-pagination.component.html',
  styleUrls: ['./ng-pagination.component.scss']
})
export class NgPaginationComponent implements OnInit {
  prevIcon = faAngleLeft;
  nextIcon = faAngleRight
  lastIcon = faAngleDoubleRight;
  firstIcon = faAngleDoubleLeft;
  ellipsisIcon = faEllipsisH;
  currentPage: number;
  numberOfPages: number;
  // @Input() numberOfPages = 1;
  // @Input() currentPage = 1;
  @Output() pageClicked: EventEmitter<number> = new EventEmitter<number>();
  @Input() listsService: ListsService;

  constructor() { }

  ngOnInit(): void {
    this.currentPage = this.listsService.GetCurrentPage();
    this.numberOfPages = this.listsService.GetTotalPages();
    this.listsService.parametersUpdated.subscribe(
      (params) => {
        this.currentPage = params.currentPage;
        this.numberOfPages = params.numberOfPages;
      }
    )
  }

  firstPage(): boolean {
    return this.currentPage === 1;
  }
  lastPage(): boolean {
    return this.currentPage === this.numberOfPages;
  }

  setPage(pageNumber: number): void {
    if (pageNumber !== this.currentPage && pageNumber > 0 && pageNumber <= this.numberOfPages) {
      this.currentPage = pageNumber;
      // this.pageClicked.emit(pageNumber);
      this.listsService.SetPaginationParams(this.currentPage);
    }
  }

  displayNumberOrDots(index: number): boolean {
    return (index < this.currentPage + 3 && index > this.currentPage - 5) || (index === 0) || (index === this.numberOfPages - 1);
  }

  displayNumber = function(index: number): boolean {
    return (index < this.currentPage + 2 && index > this.currentPage - 4) || (index === 0) || (index === this.numberOfPages - 1);
  }

  displayDots = function(index: number): boolean {
    return ((index === this.currentPage - 4 || index === this.currentPage + 2) && index !== 0 && index !== this.numberOfPages - 1);
  }

}
