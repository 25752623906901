import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NineGoldLibModule } from 'projects/nine-gold-lib/src/lib/nine-gold-lib.module';
import { AppconfigService } from './appconfig.service'
import { HttpClientModule } from '@angular/common/http';

export function init_app(appConfigService: AppconfigService) {
  return () => appConfigService.load();
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule, HttpClientModule
  ],
  providers: [
    AppconfigService,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppconfigService], multi: true }
  ]
})
export class AppconfigModule {
  static forRoot(): ModuleWithProviders<NineGoldLibModule> {
    return {
      ngModule: NineGoldLibModule,
      providers: [AppconfigService]
    }
  }
 }
