﻿// import the required animation functions from the angular animations module
import { trigger, animate, transition, style, query } from '@angular/animations';

export const fadeInAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('fadeInAnimation', [

        // route 'enter' transition
        transition('* => *', [
            query(
              ':enter',
              [style({ opacity: 0 })],
              { optional: true }
            ),
            query(':enter, :leave', style({position: 'fixed', width: '100%', zIndex: '1000', overflow: 'auto'}), {optional: true}),
            query(
              ':leave',
              [style({ opacity: 1 }), animate('.5s ease-out', style({ opacity: 0 }))],
              // [style({ opacity: 1,  transform: "scale(1)" }), animate('.5s ease-out', style({ opacity: 0,  transform: "scale(0.975)" }))],
              { optional: true }
            ),
            query(
              ':enter',
              // [style({ opacity: 0 }), animate('.75s ease-in', style({ opacity: 1 }))],
              [style({ opacity: 0, transform: "translate(0px,-20px)" }), animate('.25s ease-in', style({ opacity: 1, transform: "translate(0,0)" }))],
              // [style({ opacity: 0, transform: "scale(1.025)" }), animate('.75s ease-in', style({ opacity: 1, transform: "scale(1)" }))],
              { optional: true }
            )
            // query(
            //   ':enter',
            //   [style({ opacity: 0 }), animate('.3s ease-in-out', style({ opacity: 1 }))],
            //   { optional: true }
            // )
          ])
    ]);