import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AppSettingsService } from '../../services/app-settings.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { FormResultMessageFormat } from '../../shared/notification/notification';

@Component({
  selector: 'lib-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent implements OnInit {
  @Input() fileUrl: string;
  @Input() fileName: string;

  loading = false;

  constructor(
    private http: HttpClient,
    private notif: NotificationService,
    private appSettings: AppSettingsService
  ) { }

  ngOnInit() {
  }

  download(): void {
    this.loading = true;
    this.http.get(this.appSettings.baseSetting.apiUrl + this.fileUrl, {withCredentials: true, responseType: 'arraybuffer'}).subscribe((response) => {
      let blob = new Blob([response],{type: 'application/octet-stream'});
      let newUrl = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = newUrl;
      a.download = this.fileName;
      a.click();
      this.loading = false;

      // let pwa = window.open(newUrl);
      // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      //   alert( 'Please disable your Pop-up blocker and try again.');
      // }
    },
    (error:any) => {
      this.loading = false;
      this.notif.error('Error', 'There was an Error loading the file.',FormResultMessageFormat.popup,5000,null);
    });
  }

}
