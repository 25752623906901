<div class="container footer-container">
	<footer role="contentinfo">
		<div class="diamond-footer">
			<div class="inner">

                <div class="diamond-nav" *ngIf="linksList">

                    <div>
                        <h2>Diamond Edge IT</h2>
                        <address>
                            <fa-icon [icon]="faMapPinAltIcon"></fa-icon>
                            <a target="_blank" href="https://goo.gl/maps/Lha6egjieM4MdEn57">
                                <span>1065 SW 8th St #251</span><br>
                                <span>Miami, FL 33130</span>
                            </a>
                        </address>
                        <p><a href="tel:+1 855 376 1150"><fa-icon [icon]="faPhoneAltIcon"></fa-icon>(855) 376-1150</a></p>
                        <!-- <a href="mailto:support@diamondedge-it.com" title="Click to send email to Diamond Edge Twitter" aria-label="Click to send email to Diamond Edge Twitter" target="_blank" rel="noopener noreferrer">
                            <fa-icon [icon]="faEnvelopeIcon"></fa-icon>support@diamondedge-it.com
                        </a> -->

                    </div>

					<div class="products">
						<h2>Products</h2>
						<ul>
                            <li *ngFor="let linkItem of productLinks">
                                <a [title]="linkItem.altText"
                                    [attr.aria-label]="linkItem.altText"
                                    *ngIf="linkItem.outerLink"
                                    target="_blank"
                                    [href]="linkItem.link">
                                    {{linkItem.text}}
                                </a>
                                <a [title]="linkItem.altText"
                                    [attr.aria-label]="linkItem.altText"
                                    *ngIf="!linkItem.outerLink"
                                    [routerLink]="[linkItem.link]"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    #routerLinkActiveRef="routerLinkActive"
                                    (click)="scrollToTop(routerLinkActiveRef.isActive)">
                                    {{linkItem.text}}
                                </a>
                            </li>
						</ul>
                    </div>
                    <div class="products">
						<h2>Company</h2>
						<ul>
                            <li *ngFor="let linkItem of companyLinks">
                                <a [title]="linkItem.altText"
                                [attr.aria-label]="linkItem.altText"
                                    *ngIf="linkItem.outerLink"
                                    target="_blank"
                                    [href]="linkItem.link">
                                    {{linkItem.text}}
                                </a>
                                <a [title]="linkItem.altText"
                                    [attr.aria-label]="linkItem.altText"
                                    *ngIf="!linkItem.outerLink"
                                    [routerLink]="[linkItem.link]"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    #routerLinkActiveRef="routerLinkActive"
                                    (click)="scrollToTop(routerLinkActiveRef.isActive)">
                                    {{linkItem.text}}
                                </a>
                            </li>
						</ul>
					</div>
					<div class="services">
						<h2>Resources</h2>
						<ul>
                            <li *ngFor="let linkItem of resourcesLink">
                                <a [title]="linkItem.altText"
                                    [attr.aria-label]="linkItem.altText"
                                    *ngIf="linkItem.outerLink"
                                    target="_blank"
                                    [href]="linkItem.link">
                                    {{linkItem.text}}
                                </a>
                                <a [title]="linkItem.altText"
                                    [attr.aria-label]="linkItem.altText"
                                    *ngIf="!linkItem.outerLink"
                                    [routerLink]="[linkItem.link]">
                                    {{linkItem.text}}
                                </a>
                            </li>
						</ul>
                    </div>
                    <div class="social">
                        <h2>Follow Us</h2>
                        <ul>
                            <li  *ngFor="let linkItem of socialLinks">
                                <a [href]="linkItem.link"
                                    target="_blank"
                                    [title]="linkItem.altText"
                                    [attr.aria-label]="linkItem.altText"
                                    rel="noopener noreferrer">
                                    <fa-icon *ngIf="linkItem.name === 'linkedin'" [icon]="LinkedInIcon"></fa-icon>
                                    <fa-icon *ngIf="linkItem.name === 'youtube'" [icon]="faYoutubeIcon"></fa-icon>
                                    {{linkItem.text}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
			</div>
		</div>
		<div class="legal">
			<div class="inner">
                <p>&copy;{{currentYear}} Diamond Edge IT, Inc. All Rights Reserved. United States Patent 10,331,422 B1.</p>

                <ul *ngIf="linksList">
                    <li *ngFor="let linkItem of legalLinks">
                        <a [title]="linkItem.altText"
                            [attr.aria-label]="linkItem.altText"
                            *ngIf="linkItem.outerLink"
                            target="_blank"
                            [href]="linkItem.link">
                            {{linkItem.text}}
                        </a>
                        <a [title]="linkItem.altText"
                            [attr.aria-label]="linkItem.altText"
                            *ngIf="!linkItem.outerLink"
                            [routerLink]="[linkItem.link]">
                            {{linkItem.text}}
                        </a>
                    </li>
                </ul>
			</div>
		</div>
	</footer>
</div>
