import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormSummary, FormSummaryGroup } from 'projects/nine-gold-lib/src/lib/directives/form-summary/form-summary';
import { IFormField, InputTypes } from 'projects/nine-gold-lib/src/lib/models/formField';
import { Uses } from 'projects/nine-gold-lib/src/lib/models/uses';
import { ApiMapping, ApplicationFormService } from 'projects/nine-gold-lib/src/lib/services/application-form.service';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable(
  // {providedIn: 'root'}
)
export class GeneratorFormService extends ApplicationFormService {
  formName = "generator";

  fieldsSummary: FormSummary[];
  fieldSummaryGroups: FormSummaryGroup[];

  customSuccess = true;

  faqLink: string;

  parseResults: {};

  inputEndpoints: string[] = [];
  outputEndpoints: string[] = [];

  activeCacheKey: string;
  activeDocumentKey: string;

  success: EventEmitter<Uses> = new EventEmitter<Uses>();
  error: EventEmitter<any> = new EventEmitter<any>();

  endpointsLoaded: EventEmitter<number> = new EventEmitter<number>();


  // Generator form specific options
  private _optionalFieldsDisabled = true;

  get optionalFieldsDisabled(): boolean {
    return this._optionalFieldsDisabled;
  }

  set optionalFieldsDisabled(val: boolean) {
    this._optionalFieldsDisabled = val;
    this.setOptionalFields(val);
  }
  // end of Generator form options

  private setOptionalFields(val: boolean): void {
    this.formSettings['generatedContract'].disabled = val;
    this.formSettings['mapping'].disabled = val;
    this.formSettings['generatedContract'].required = !val;
    this.formSettings['mapping'].required = !val;
    if(!val) {
      this.formData.get('generatedContract').setValidators([Validators.required]);
      this.formData.get('mapping').setValidators([Validators.required]);
    } else {
      this.formData.get('generatedContract').setValidators(null);
      this.formData.get('mapping').setValidators(null);
    }
    this.formData.get('generatedContract').updateValueAndValidity();
    this.formData.get('mapping').updateValueAndValidity();
  }

  setFormName(): string {
    return "restgen";
  }

  setInitialFieldsSummary(): void {
    this.fieldsSummary = [
      {name: 'language', label: 'Language', text: '', value: '', valid: false},
      {name: 'projectName', label: 'API Name / Artifact Id', text: '', value: '', valid: false},
      {name: 'groupId', label: 'Group Id', text: '', value: '', valid: false},
      {name: 'endpoints', label: 'Endpoints', text: '', value: '', valid: false},
      {name: 'generatedType', label: 'API Specification', text: '', value: '', valid: false},
      {name: 'customSpecification', label: 'Custom Specification', text: '', value: '',hidden:true, valid: false,isSubtitle: true},
      {name: 'generatedContract', label: 'Filename', text: '', value: '', valid: false, hidden: true, subLevel: true},
      {name: 'mapping', label: 'Mapping Instructions', text: '', value: '', valid: false, hidden: true, subLevel: true},
    ];
  }

  generateSuccess(): void {
    this.success.emit();
  }

  customSuccessHandler(result: any): void {
    this.success.emit(result);
  }

  setApiDataMapping(): ApiMapping[] {
    return [
      {
        ApiProperty: 'language',
        FieldName: 'language'
      },
      {
        ApiProperty: 'projectName',
        FieldName: 'projectName'
      },
      {
        ApiProperty: 'groupId',
        FieldName: 'groupId'
      },
      {
        ApiProperty: 'specification',
        FieldName: 'generatedType'
      },
      {
        ApiProperty: 'provided',
        FieldName: 'generatedContract'
      },
      {
        ApiProperty: 'mapping',
        FieldName: 'mapping'
      },
      {
        ApiProperty: 'endpoints',
        FieldName: 'endpoints'
      },
      {
        ApiProperty: 'cacheKey',
        FieldName: 'cacheKey'
      }
    ];
  }


  setPreFormData(): IFormField[] {
    return [
      {
        inputName: "projectName",
        id: "projectNameId",
        placeholder: "Please choose a name for your file",
        label: "API Name / Artifact Id",
        value: "",
        inputType: InputTypes.text,
        autocompleteOff: true,
        countCharacters: true,
        tooltipText: "Only lower case letters, hyphens and underscores are allowed.  This defines your project name and Maven Artifact ID."
      },
      {
        inputName: "groupId",
        id: "groupIdId",
        placeholder: "Please choose a Group Id",
        label: "Group Id",
        value: "",
        inputType: InputTypes.text,
        autocompleteOff: true,
        countCharacters: true,
        // instruction: "Only use lowercase and dots, this defines your Maven Group ID."
        tooltipText: "This should be your reverse domain name, i.e. com.company.api.  This defines your Maven Group ID."
      },
      {
        inputName: "language",
        id: "languageId",
        placeholder: "Select Language",
        label: "Language",
        value: "",
        inputType: InputTypes.select,
        clearButton: false
      },
      {
        inputName: "generatedType",
        id: "generatedTypeId",
        placeholder: "Choose contract version",
        label: "API Specification",
        value: "",
        inputType: InputTypes.select,
        tooltipText: 'Provide a custom API Specification or generate in listed format.'
      },
      {
        inputName: "generatedContract",
        id: "generatedContractId",
        placeholder: `{
          "@category": "vars.category",
          "user.surname": "lastname",
          "role": "string.tester"
        }`,
        label: "API Specification",
        value: null,
        disabled: this._optionalFieldsDisabled,
        inputType: InputTypes.file,
        instruction: "Upload the schema for your integration API.",
        suggestedExtensions: '.wsdl,.yaml,.json,.raml,.zip'
      },
      {
        inputName: "mapping",
        id: "mappingId",
        placeholder:
`{
  "@category": "vars.category",
  "user.surname": "lastname",
  "role": "string.tester"
}`,
        label: "Mapping Instructions",
        value: "",
        disabled: this._optionalFieldsDisabled,
        inputType: InputTypes.code,
        instructionHtml: '',
        requiredErrorMessage: 'Mapping is required if API Specification is set to Supplied.',
        codeLanguage: "json"
      },
      {
        inputName: 'endpoints',
        label: '',
        value: "",
        disabled: false,
        id: 'endpointsId',
        inputType: InputTypes.text
      },
      {
        inputName: 'cacheKey',
        label: '',
        value: "",
        disabled: false,
        id: 'cacheKeyId',
        inputType: InputTypes.text
      }
    ];
  }

  toggleEndpointsAllCustom(useCustom: boolean): void {
    if(useCustom) {
      this.setEndpoints(this.outputEndpoints);
    } else {
      this.setEndpoints(['all']);
    }
  }

  setEndpoints(endpoints:string[]): void {
    let endpointsString = endpoints.toString();
    this.outputEndpoints = endpoints;
    this.formData.get('endpoints').setValue(endpointsString);
    this.formData.get('endpoints').updateValueAndValidity();

    if(endpoints.length === 1 && endpoints[0] === 'all') {
      this.updateFieldSummary('endpoints','All','all',true);
    } else {
      if(endpoints.length>0) this.formData.get('endpoints').markAsDirty();
      this.updateFieldSummary('endpoints',`${endpoints.length} of ${this.inputEndpoints.length}`,endpoints.toString(),true);
    }
  }

  setCacheKey(cacheKey: string): void {
    this.formData.get('cacheKey').setValue(cacheKey);
    this.formData.get('cacheKey').updateValueAndValidity();
    this.activeCacheKey = cacheKey;
    this.activeDocumentKey = this.activeDocumentKey = `doc-${this.activeCacheKey}`;
  }

  handleError(err: HttpErrorResponse) {
    this.error.emit(err);
    return throwError(err);
  }

  getEndpoits(cacheKey?: string): void {
    let apiUrs = this.appSettingsService.getApi('appUse',this.userService.getCompanyId(),'restgen-endpoints/' + (cacheKey ? cacheKey : this.parseResults['cacheKey']));
    const params = new HttpParams().set('projectName','TEST');
    this.http.get(apiUrs,{withCredentials: true}).subscribe({
      next: result => {
        this.inputEndpoints = result['results'].response.endpoints;
        this.endpointsLoaded.emit(this.inputEndpoints.length);
      },
      error: err => {this.endpointsLoaded.emit(-1)},
    })
  }

  clearEndpoints(clearAll: boolean = true): void {
    if(clearAll) this.inputEndpoints = [];
    this.outputEndpoints = [];
    this.formData.get('endpoints').setValue('');
    this.formData.get('endpoints').markAsPristine();
    this.updateFieldSummary('endpoints','', '', false);
  }

  getParsedStatus(): void {
    let apiUrs = this.appSettingsService.getApi('appUse',this.userService.getCompanyId(),'restgen/parse-status/' + this.parseResults['cacheKey']);
    this.http.get(apiUrs,{withCredentials: true}).subscribe({
      next: result => {},
      error: err => console.log(err)
    })
  }

  parseFile(): void {
    let form = this.formData;
    let fd = new FormData();
    this.loading = true;
    fd.append('language','MULE4');
    fd.append('type',this.formData.value['targetType']);
    fd.append('target',this.formData.value['targetContract']);
    let formApiUrl = this.appSettingsService.getApi('appUse',this.userService.getCompanyId(),'restgen-parse');
    this.formService.postForm(formApiUrl, fd).subscribe({
      next: result => {
        this.loading = false;
        this.parseResults = result['results']?.response;
        // TODO: add special handling (if there is one set)
        if(this.customSuccess) {
          this.customSuccessHandler(result);
        } else {
          this.formService.formSuccess("Generation Successful", FormResultMessageFormat.modal, form);
          this.resetForm();
        }

      },
      error: err=> {
        console.log(err);
        this.loading = false;
        this.formService.formError(err, form, this.formSettings)
      }
    });
  }

  protected customDataPreparation(): void {
    this.formData.value['mapping'] = this.formData.value['mapping'] || '{}';
  }

}
