import { Injectable } from '@angular/core';
import { format, formatDistance, isFuture } from 'date-fns';
import { ListsService } from 'projects/nine-gold-lib/src/lib/services/lists.service';
import { ButtonTypes, columnFilterTypes, ColumnTypes, ListUiParams, sortingOrder } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';
import { Schema, SchemaStatus } from '../../../../../nine-gold-lib/src/lib/models/schema';
import { ApiLicense, ApiLicenseStatus } from 'projects/nine-gold-lib/src/lib/models/product';

type listApiDataType = Schema;


@Injectable({
  providedIn: 'root'
})
export class SchemasListService extends ListsService {

  highestLicenseId:string;
  highestLicense: ApiLicense;

  public loading = false;

  uiSortFilterPage = true;

  listParameters:ListUiParams = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "createdOn",
      order: sortingOrder.desc
    }
  }

  // constructor() { }

  updateListParams(): void {
    this.listParameters = {
      numberOfPages: 1,
      currentPage: 1,
      itemsPerPage: 10,
      filters: [],
      sorting: {
        field: "createdOn",
        order: sortingOrder.desc
      }
    }
  }

  setInitialColumns(): void {
    this.columns = [
      {
        columnName: 'id',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: null,
        rowIdentifier: true,
        notVisible: true
      },
      {
        columnName: 'filename',
        columnText: 'Filename',
        columnType: ColumnTypes.text,
        sortable: true,
        sortColumnName: 'filename',
        filters: {
          filterName: 'filename',
          filterType: columnFilterTypes.autocomplete,
          filterPlaceholder: 'search',
          optionSelected: null
        },
        columnOrder: 1,
      },
      {
        columnName: 'parseStatus',
        columnText: 'Status',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: {
          optionSelected: null,
          filterName: 'Status',
          filterType: columnFilterTypes.options,
          filterPlaceholder: 'Select Status',
          filterOptions: [
            {text: 'Time out',value: 'TIMEOUT'
            },
            {text: 'Completed', value: 'COMPLETED'},
            {text: 'Error',value: 'ERROR'}
          ]
        },
        columnOrder: 2,
        displayFunction: 'setStatusText',
        tdHtml: true
      },
      {
        columnName: 'genId',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        notVisible: true
      },
      {
        columnName: 'documentationStatus',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        notVisible: true
      },
      {
        columnName: 'documentKey',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        notVisible: true
      },
      {
        columnName: 'cacheKey',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        notVisible: true
      },
      {
        columnName: 'productId',
        columnText: 'Product',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        notVisible: true,
        canShowHide: false
      },
      {
        columnName: 'createdOn',
        columnText: 'Created',
        sortColumnName: 'createdOn',
        columnType: ColumnTypes.date,
        sortable: true,
        filters: null,
        canShowHide: true,
        columnOrder: 5
      },
      {
        columnName: 'lastUsageDate',
        columnText: 'Last Used',
        columnType: ColumnTypes.date,
        sortable: false,
        filters: null,
        canShowHide: true,
        columnOrder: 6
      },
      {
        columnName: 'parseCacheExpiry',
        columnText: 'Expiration',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        canShowHide: true,
        columnOrder: 7,
        displayFunction: 'setRemainingTime',
        tdHtml: true
      },
      {
        columnName: 'actions',
        columnText: 'Actions',
        columnType: ColumnTypes.buttons,
        sortable: false,
        filters: null,
        buttons: [
          {
            buttonText: 'Documentation',
            value: null,
            type: ButtonTypes.swagger,
            buttonCustomClass: 'documentation',
            showButtonFunction: 'canViewDocumentation'
          },
          {
            buttonText: 'Generate',
            value: null,
            type: ButtonTypes.generate,
            showButtonFunction: 'canGenerate'
          }
        ]
      }
    ]
  }

  setApiName(): void {
    this.listApiName = 'parseHistory';
    this.ApiFunctionName = 'getParseHistory';
    this.ApiParameter = this.userService.getCompanyId();
  }

  canViewDocumentation(row: listApiDataType): boolean {
    if(!this.highestLicense) {
      this.highestLicenseId = this.productService.getPremiumLicenseId();
      this.highestLicense = this.productService.getLicenseById(this.highestLicenseId);
    }

    if([ApiLicenseStatus.valid,ApiLicenseStatus.low].includes(this.highestLicense.status)) {
      return row.parseStatus === SchemaStatus.COMPLETED || row.parseStatus === SchemaStatus.TIMED_OUT;
    } else {
      return false;
    }

  }

  canGenerate(row: listApiDataType): boolean {
    if(!this.highestLicense) {
      this.highestLicenseId = this.productService.getPremiumLicenseId();
      this.highestLicense = this.productService.getLicenseById(this.highestLicenseId);
    }

    if([ApiLicenseStatus.valid,ApiLicenseStatus.low].includes(this.highestLicense.status)) {
      return row.parseStatus === SchemaStatus.COMPLETED || row.parseStatus === SchemaStatus.TIMED_OUT;
    } else {
      return false;
    }

  }

  setStatusText(status: string): string {
    if(status === 'TIME_OUT') status = 'TIMEOUT';
    if(status === 'IN_PROCESS' || status === 'INPROCESS') status = 'PROCESSING';
    let statusClass = status.toLocaleLowerCase();
    return `<span class="download-status download-status-${statusClass}">${status}<span>`
  }

  setRemainingTime(expirationDate): string {
    if(expirationDate !== null && expirationDate !== undefined) {
      let remainingTime = formatDistance(new Date(), new Date(expirationDate));
      if(isFuture(new Date(expirationDate))) {
        return `<span title="${format(new Date(expirationDate),'MMM d, y')}">in ${remainingTime}</span>`;
      } else {
        return `<span title="${format(new Date(expirationDate),'MMM d, y')}">${remainingTime} ago</span>`;
      }
    } else {
      return "N/A";
    }

  }

  public ReloadItems(): void {
    setTimeout(() => {
      this.LoadTableData(true);
      // this.loadResultsEnded.emit(true);
    }, 2000);

  }

  // setLicensesRemainingTime(): void {
  //   this.apiLicenses.forEach(l => {
  //     if(l.licenseParams.type !== ApiLicenseType.FreeLicense) {
  //       l.remainingTime = formatDistance(new Date(),new Date(l.end));
  //     } else {
  //       l.remainingTime = 'N/A';
  //     }

  //   });
  // }

}
