export interface Schema {
  id: string,
  filename: string,
  parseStatus: SchemaStatus,
  genId: string,
  cacheKey: string,
  productId: string,
  used: boolean,
  createdOn: string,
  lastUsageDate: string,
  parseCacheExpiry: string,
  documentKey: string,
  documentationStatus: SchemaStatus
}

export enum SchemaStatus {
  IN_PROCESS = 'IN_PROCESS',
  ERROR = 'ERROR',
  COMPLETED = 'COMPLETED',
  TIMED_OUT = 'TIMEOUT'
}
